import { authorised } from "../../../request";
import { PublishRequest } from "../../../types/admin/PublishRequest";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
};

export async function getPublishRequests(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number,
    next: null | string,
    previous: null | string,
    results: PublishRequest[]
  }>({
    method: "GET",
    url: `/api/enterprise/publish-request/?${buildQueryString(args)}`,
  });
}
