import React, { useState } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export function DropdownItem(props: {
  children: React.ReactNode | React.ReactNode[] | string | null;
  onClick: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      css={css`
        padding: 5px 20px;
        border: 1px solid ${theme.colors.gray4};
        border-bottom: none;
        cursor: pointer;
        :hover {
          background: ${theme.colors.gray4};
        }
        :first-of-type {
          border-radius: 10px 10px 0 0;
        }
        :last-of-type {
          border-radius: 0 0 10px 10px;
          border-bottom: 1px solid ${theme.colors.gray4};
        }
      `}
    >
      {props.children}
    </div>
  );
}

export function Dropdown(props: {
  children?: React.ReactNode | React.ReactNode[] | string | null;
  options?: React.ReactNode | React.ReactNode[] | string | null;
}) {
  const [opened, setOpened] = useState(false);
  return (
    <div
      css={css`
        position: relative;
      `}
      onClick={() => setOpened(!opened)}
    >
      {props.children}
      <div
        css={css`
          position: absolute;
          background: #fff;
          top: 100%;
          left: 0;
          margin-top: 5px;
          box-sizing: border-box;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          z-index: 1;
          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}
        `}
      >
        {props.options}
      </div>
    </div>
  );
}
