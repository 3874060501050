import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getPublishRequests } from "../../../actions/admin/publishRequest/getPublishRequests";
import { PublishRequest } from "../../../types/admin/PublishRequest";
import { CoursePublishRequestStatus } from "../../../types/Course";
import { formatDateTime } from "../../../helpers/time";
import { theme } from "../../../themes/variables";
import { TableHandler, TableHandlerColumn } from "../../TableHandler";
import { Button } from "../../Button";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close.svg";
import { ReactComponent as LinkIcon } from "../../../assets/icons/Link.svg";

export default function RequestsTable({
  forwardRef,
  loadingId,
  openRejectionModal,
  updateStatus,
}: {
  forwardRef: any;
  loadingId: number | null;
  openRejectionModal: (row: PublishRequest) => void;
  updateStatus: (id: number, status: CoursePublishRequestStatus) => void;
}) {
  const { t } = useTranslation();

  const publishRequestStatuses = [
    {
      key: CoursePublishRequestStatus.PENDING,
      value: t("admin.publish-request.status.pending"),
    },
    {
      key: CoursePublishRequestStatus.REJECTED,
      value: t("admin.publish-request.status.rejected"),
    },
    {
      key: CoursePublishRequestStatus.APPROVED,
      value: t("admin.publish-request.status.approved"),
    },
    {
      key: CoursePublishRequestStatus.CANCELLED,
      value: t("admin.publish-request.status.cancelled"),
    },
  ];

  const tableHandlerColumns: TableHandlerColumn<PublishRequest>[] = [
    {
      label: t("admin.publish-request.page.author"),
      render: (row) => (
        <div>
          {row.author.first_name} {row.author.last_name}
        </div>
      ),
    },
    {
      label: t("header.navigation.organisation"),
      render: (row) => <div>{row.organisation.name}</div>,
    },
    {
      label: t("courses.name"),
      render: (row) => (
        <div>
          {row.course.name}{" "}
          <a href={row.course.preview_link} target="__blank">
            <LinkIcon
              width={10}
              height={10}
              color={
                row.status === CoursePublishRequestStatus.PENDING
                  ? theme.colors.primary
                  : theme.colors.dark
              }
            />
          </a>
        </div>
      ),
    },
    {
      label: t("admin.publish-request.page.submission-date"),
      render: (row) => <div>{formatDateTime(row.submission_date)}</div>,
    },
    {
      label: t("admin.publish-request.page.approved-date"),
      render: (row) => (
        <div>
          {row.approved_date &&
          row.status === CoursePublishRequestStatus.APPROVED
            ? formatDateTime(row.approved_date)
            : "-"}
        </div>
      ),
    },
    {
      label: t("admin.publish-request.page.status"),
      render: (row) => (
        <div
          css={css`
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-right: 4px;
              background-color: ${row.status ===
              CoursePublishRequestStatus.APPROVED
                ? theme.colors.haki
                : row.status === CoursePublishRequestStatus.REJECTED
                ? theme.colors.pomade
                : null};
              ${row.status === CoursePublishRequestStatus.PENDING &&
              css`
                border: 1px solid ${theme.colors.gray3};
              `}
              border-radius: 50%;
            `}
          ></div>
          {publishRequestStatuses.find((el) => el.key === row.status)?.value}
        </div>
      ),
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            white-space: nowrap;
            display: flex;
            justify-content: center;
          `}
        >
          {row.status === CoursePublishRequestStatus.PENDING ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Button
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.dark}`}
                color={theme.colors.dark}
                icon={
                  <ReadIcon
                    color={theme.colors.success}
                    width={14}
                    height={14}
                  />
                }
                hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.success}; border: 1px solid ${theme.colors.transparent};`}
                iconRight
                small
                onClick={() =>
                  updateStatus(row.id, CoursePublishRequestStatus.APPROVED)
                }
                css={css`
                  margin-right: 10px;
                `}
                isSubmitting={loadingId === row.id}
              >
                {t("actions.publish")}
              </Button>

              <Button
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.error}`}
                color={theme.colors.error}
                hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
                icon={
                  <CloseIcon
                    color={theme.colors.error}
                    width={14}
                    height={14}
                  />
                }
                iconRight
                small
                onClick={() => openRejectionModal(row)}
                isSubmitting={loadingId === row.id}
              >
                {t("actions.reject")}
              </Button>
            </div>
          ) : row.status === CoursePublishRequestStatus.APPROVED ? (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.error}`}
              color={theme.colors.error}
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
              icon={
                <CloseIcon color={theme.colors.error} width={14} height={14} />
              }
              iconRight
              small
              onClick={() =>
                updateStatus(row.id, CoursePublishRequestStatus.PENDING)
              }
              isSubmitting={loadingId === row.id}
            >
              {t("actions.unpublish")}
            </Button>
          ) : row.status === CoursePublishRequestStatus.REJECTED ? (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.dark}`}
              color={theme.colors.dark}
              icon={
                <ReadIcon color={theme.colors.success} width={14} height={14} />
              }
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.success}; border: 1px solid ${theme.colors.transparent};`}
              iconRight
              small
              onClick={() =>
                updateStatus(row.id, CoursePublishRequestStatus.PENDING)
              }
              isSubmitting={loadingId === row.id}
            >
              {t("admin.publish-request.page.back-to-review")}
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <TableHandler
        forwardRef={forwardRef}
        tableData={{
          columns: tableHandlerColumns,
        }}
        getFunction={getPublishRequests}
      />
    </div>
  );
}
