import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../components/Button";
import { InputField } from "../fields/InputField";
import { Column, Spacing, Row } from "../../helpers/layout";
import { resetPassword } from "../../actions/auth/resetPassword";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  email: string;
}

export function ResetPasswordForm() {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            await resetPassword(values.email);
            customToast.success(t("status.success"));
            history.push("/login");
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.m}>
              <div
                css={css`
                  text-align: center;
                  margin-top: 0;
                  margin-bottom: 40px;
                  font-weight: 500;
                `}
              >
                {t("auth.reset-info")}
              </div>

              <InputField
                name="email"
                type="text"
                placeholder={t("auth.email")}
              />

              <Row
                justify="flex-end"
                align="center"
                css={css`
                  margin-top: 40px;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 0 auto;
                  `}
                >
                  {t("auth.reset-password")}
                </Button>
              </Row>
            </Column>

            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 10px;
                font-weight: 400;
              `}
            >
              {t("auth.or")}
            </div>

            <Link
              css={css`
                display: flex;
                text-decoration: underline;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
              `}
              to="/login"
            >
              {t("auth.back-to-login")}
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
