import { authorised } from "../../request";
import { Course } from "../../types/Course";

export async function cancelPublishCourse(courseId: string) {
  const API = authorised();

  return API.request<Course>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/cancel-publish/`,
  });
}
