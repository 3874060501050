import { Address } from "../Address";
import { UserProfile } from "../UserProfile";
import { OrderItem } from "./OrderItem";

export enum STATUS_OPTIONS {
  ALL = "ALL",
  PENDING_PAYMENT = "PENDING_PAYMENT",
  COMPLETED = "COMPLETED",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  DRAFT = "DRAFT",
  PROCESSING = "PROCESSING",
}

export enum PAYMENT_OPTIONS {
  BANK_TRANSFER = "BANK_TRANSFER",
  CREDIT_CARD = "CREDIT_CARD",
  DIRECT_DEBIT = "DIRECT_DEBIT",
  PAYPAL = "PAYPAL",
}

export interface Order {
  uuid: string;
  initiator: Pick<UserProfile, "avatar" | "first_name" | "last_name">;
  organisation: string;
  status: STATUS_OPTIONS;
  payment_type: PAYMENT_OPTIONS;
  invoice_number: string;
  items: OrderItem[];
  net_price: string;
  vat_price: string;
  total: string;
  payment_date?: string;
  billing_address: Pick<
    Address,
    "city" | "country" | "street_address" | "state" | "postal_code"
  >;
  created: string;
  invoice_link: string;
}

export type OrdersData = {
  count: number;
  next: null | string;
  previous: null | string;
  results: Order[];
};
