import { throttle } from "lodash";
import { useEffect, useState } from "react";

// TODO: Move to context

export function useCheckMobile() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize = throttle(() => {
      setWidth(window.innerWidth);
    }, 200);

    window.addEventListener("resize", resize);
    window.addEventListener("load", resize);
    document.addEventListener("DOMContentLoaded", resize);

    resize();

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("load", resize);
      document.removeEventListener("DOMContentLoaded", resize);
    };
  }, []);

  return width <= 768;
}
