import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { customToast } from "../customToast";
import { Button } from "../../components/Button";
import { InputField } from "../../forms/fields/InputField";
import { updateLesson } from "../../actions/lesson";
import { Lesson } from "../../types/Lesson";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  custom_code: string;
}

export function ContentEditorCustomCode(props: { lesson: Lesson }) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const validationSchema = Yup.object().shape({
    custom_code: Yup.string(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);
      await updateLesson(params.courseId, params.lessonId, {
        custom_code: values.custom_code,
      });

      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        custom_code: props.lesson.custom_code || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, dirty, isValid }) => (
        <Form
          noValidate
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `}
        >
          <div>
            <InputField
              name={"custom_code"}
              type="text"
              label={`${t("lesson.type.custom-code")}:`}
            />
          </div>

          <div
            css={css`
              margin-top: 25px;
              display: flex;
              align-self: flex-start;
              flex-direction: row-reverse;
            `}
          >
            <Button
              isSubmitting={isSubmitting}
              type="submit"
              disabled={!dirty || !isValid}
            >
              {t("actions.save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
