import React, { useState } from "react";
import { css } from "styled-components/macro";
import { ChevronRight } from "react-feather";
import { Separator } from "./Separator";

export function ExpansionItem(props: {
  initialValue?: boolean;
  expandSeparator?: boolean;
  label: string;
  caption?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) {
  const [collapse, setCollapse] = useState(props.initialValue || false);

  const collapseClick = () => {
    setCollapse(!collapse);
  };

  return (
    <div>
      <div
        className={props.className}
        css={css`
          display: flex;
          align-items: center;
          padding: 8px 16px;
          min-height: 48px;
          cursor: pointer;
          transition: all 0.2s ease;
          :hover {
            background: rgba(0,0,0,0.22);
          }
          :active {
            background: rgba(0,0,0,0.28);
          }
        `}
        onClick={() => collapseClick()}
      >
        {props.icon &&
          <div>
            {props.icon}
          </div>
        }
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            width: auto;
            min-width: 0;
            max-width: 100%;
            flex: 10000 1 0%;
          `}
        >
          <div css={css`
            font-weight: bold;
          `}>{props.label}</div>
          {props.caption &&
            <div css={css`
              margin-top: 4px;
              color: rgba(0,0,0,0.54);
              max-width: 100%;
            `}>
              {props.caption}
            </div>
          }
        </div>
        <div css={css`
          align-items: flex-end;
          padding-left: 1rem;
        `}>
          <ChevronRight size={20} css={css`
            transition: transform .3s;
            ${collapse ? '' : 'transform: rotate(90deg);'}
          `} />
        </div>
      </div>

      <div
        css={css`
          max-height: ${collapse ? 0 : 1500}px;
          transition: max-height .3s cubic-bezier(0, 1, 0, 1);
          overflow: hidden;
          display: flex;
          flex-direction: column;
        `}
      >
        <div>
          {props.children}
        </div>
        {props.expandSeparator &&
          <Separator />
        }
      </div>
    </div>
  );
}
