import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { Block } from "../../../components/Block";
import { Header } from "../../../components/Header";
import {
  EditCreateSelfAssessment,
  FormValues,
} from "../../../components/SelfAssessmentEditor/EditCreateSelfAssessment";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { getSignUpSelfAssessment } from "../../../actions/signUpSelfAssessments/getSignUpSelfAssessment";
import { updateSignUpSelfAssessment } from "../../../actions/signUpSelfAssessments/updateSignUpSelfAssessment";
import { Footer } from "../../../components/Footer";

export function EditSignUpSelfAssessmentItem() {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams<{
    selfAssessmentId: string;
  }>();

  const { data: item, error: itemError } = useNewFetch(
    getSignUpSelfAssessment,
    {
      selfAssessmentId: params.selfAssessmentId,
    }
  );

  if (!item || itemError) {
    return pageLoading(itemError);
  }

  const tabsData = [
    {
      title: t("self-assessment.questions"),
      link: `/admin/self-assessment`,
      forceHightlight: true,
    },
    {
      title: t("self-assessment.performance"),
      link: `/admin/self-assessment/performance`,
    },
  ];

  const submitUpdateTest = async (data: FormValues) => {
    await updateSignUpSelfAssessment({
      selfAssessmentId: params.selfAssessmentId,
      data: {
        ...data,
        answers: data.answers ? data.answers : [],
      },
    });

    customToast.success(t("status.success"));

    history.push(`/admin/self-assessment`);
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{item.title}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div>
            <EditCreateSelfAssessment
              initialData={item}
              onSubmitCallback={submitUpdateTest}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
