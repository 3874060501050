import { authorised } from "../../../request";
import { Page, PageStatus } from "../../../types/admin/Page";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  query?: string;
  status?: PageStatus | string;
};

export async function getPages(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number;
    next: null | string;
    previous: null | string;
    results: Page[];
  }>({
    method: "GET",
    url: `/api/enterprise/pages/?${buildQueryString(args)}`,
  });
}
