import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, FieldArrayRenderProps, FieldProps } from "formik";
import { useState } from "react";
import { Trash2 } from "react-feather";
import { TextareaField } from "../../../forms/fields/TextareaField";
import { Button } from "../../Button";
import { theme } from "../../../themes/variables";
import { SelfAssessmentAnswer } from "../../../types/SelfAssessment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../Modal";

export function SelfAssessmentAnswers(props: { name: string; label?: string }) {
  const { t } = useTranslation();

  const [currentChoiceIndex, setCurrentChoiceIndex] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const onDeleteModalConfirm = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.remove(currentChoiceIndex);
    setCurrentChoiceIndex(
      currentChoiceIndex === 0 ? 0 : currentChoiceIndex - 1
    );
    setDeleteModal(false);
  };

  return (
    <Field
      name={props.name}
      render={({ field }: FieldProps) => {
        const answers: SelfAssessmentAnswer[] = field.value || [];

        return (
          <FieldArray
            name={props.name}
            render={(arrayHelpers) => (
              <div
                css={css`
                  margin-bottom: 1rem;
                `}
              >
                {props.label && <label>{props.label}</label>}
                {answers.map((answer, idx) => {
                  return (
                    <div
                      css={css`
                        display: flex;
                        width: 100%;
                        margin-bottom: 1rem;
                        align-items: center;
                      `}
                      key={idx}
                    >
                      <div
                        css={css`
                          width: 100%;
                        `}
                      >
                        <TextareaField
                          name={`${props.name}[${idx}].answer`}
                          maxLength={500}
                          css={css`
                            min-height: 50px;
                            width: 100%;
                          `}
                        />
                      </div>
                      <div
                        css={css`
                          display: flex;
                        `}
                      >
                        <button
                          type="button"
                          css={css`
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            white-space: nowrap;
                            border: 0;
                            text-transform: none;
                            -webkit-appearance: none;
                            outline: none;
                            background: transparent;
                            padding-left: 20px;
                            :hover svg {
                              color: ${theme.colors.error};
                            }
                          `}
                          onClick={() => {
                            setCurrentChoiceIndex(idx);
                            setDeleteModal(true);
                          }}
                        >
                          <Trash2 />
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                  `}
                >
                  <Button
                    onClick={() => {
                      arrayHelpers.push({
                        answer: "",
                      });
                    }}
                    background={theme.colors.white}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`}
                  >
                    {t("lesson.quiz.question.add-choice")}
                  </Button>
                </div>

                <Modal
                  modalIsOpen={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  contentLabel={`${t("actions.sure")}?`}
                >
                  <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                    {`${t("actions.sure")}?`}
                  </ModalHeader>

                  <ModalBody>{t("actions.no-undo")}</ModalBody>

                  <ModalFooter>
                    <Button
                      background={theme.colors.white}
                      color={theme.colors.dark}
                      border={`1px solid ${theme.colors.dark}`}
                      onClick={() => setDeleteModal(false)}
                    >
                      {t("actions.cancel")}
                    </Button>

                    <Button onClick={() => onDeleteModalConfirm(arrayHelpers)}>
                      {t("actions.delete")}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            )}
          ></FieldArray>
        );
      }}
    />
  );
}
