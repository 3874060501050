import { authorised } from "../../../request";
import { SignupField } from "../../../types/admin/SignupField";

type SignupFieldArgs = Omit<SignupField, "uuid">

type Args = {
  uuid: string;
  section: "campus" | "studio";
  data: SignupFieldArgs;
}

export async function updateSignupField({uuid, section, data}: Args) {
  const API = authorised();

  return API.request<SignupField>({
    method: "PUT",
    url: `/api/enterprise/signup-field/${section}/${uuid}/`,
    data
  });
}
