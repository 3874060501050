import { authorised } from "../../../request";
import { Slider } from "../../../types/admin/Slider";

type Args = {
  id: number;
  slider: Slider;
};

export async function updateSlider(data: Args) {
  const API = authorised();

  return await API.request<Slider>({
    method: "PUT",
    url: `/api/enterprise/sliders/${data.id}/`,
    data: data.slider,
  });
}
