import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Tab, Tabs } from "../../../components/Tabs";
import {
  EditCreateSkill,
  FormValues,
} from "../../../components/Admin/Skill/EditCreateSkill";

import { useNewFetch } from "../../../useAPI";
import { pageLoading } from "../../../helpers/pageLoading";
import { getSkill, updateSkill } from "../../../actions/admin/skill";
import { Footer } from "../../../components/Footer";

export function SkillEditPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    skillId: string;
  }>();

  const history = useHistory();

  const { data: skill, error: skillError } = useNewFetch(getSkill, {
    skillId: params.skillId,
  });

  if (!skill || skillError) {
    return pageLoading(skillError);
  }

  const onSubmit = async (data: FormValues) => {
    await updateSkill({ id: +params.skillId, data: data });

    history.push(`/admin/skills`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.competency.title"),
      link: `/admin/competencies`,
    },
    {
      title: t("admin.skill.title"),
      link: "/admin/skills",
      forceHightlight: true,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.skill.edit-skill")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateSkill
            initialData={skill}
            onSubmitCallback={onSubmit}
            title={t("admin.skill.edit-skill")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
