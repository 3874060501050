import React from "react";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Wrapper } from "../../../components/Wrapper";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { useParams } from "react-router-dom";
import { Headers } from "../../../helpers/layout";
import { Block } from "../../../components/Block";
import { MenuStructureEditor } from "../../../components/MenuStructureEditor";
import { Link } from "react-router-dom";
import { Footer } from "../../../components/Footer";
import { useTranslation } from "react-i18next";

export function MenuManagerPage() {
  const { t } = useTranslation();
  const params = useParams<{
    type: string;
    location: string;
  }>();

  const isCampus = params.type === "campus";
  const isHeader = params.location === "header";

  return (
    <>
      <Wrapper>
        <SEO>
          <title>Menu</title>
        </SEO>
        <Header />
      </Wrapper>

      <Wrapper>
        <Block
          css={css`
            padding: 66px;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                width: 250px;
              `}
            >
              <Headers.H3
                css={css`
                  line-height: 120%;
                `}
              >
                {t("menu.title")}
              </Headers.H3>
              <div
                css={css`
                  display: flex;
                  margin: 34px 0px;
                `}
              >
                <Headers.H4
                  css={css`
                    font-weight: 500;
                    color: ${isCampus
                      ? theme.colors.black
                      : theme.colors.gray4};
                  `}
                >
                  <Link to={`/admin/menus/campus/${params.location}`}>
                    Campus
                  </Link>
                </Headers.H4>

                <div
                  css={css`
                    width: 28px;
                  `}
                ></div>

                <Headers.H4
                  css={css`
                    font-weight: 500;
                    color: ${!isCampus
                      ? theme.colors.black
                      : theme.colors.gray4};
                  `}
                >
                  <Link to={`/admin/menus/studio/${params.location}`}>
                    Studio
                  </Link>
                </Headers.H4>
              </div>
              <hr
                css={css`
                  margin: 0px !important;
                  color: ${theme.colors.gray2} !important;
                `}
              />
              <div
                css={css`
                  margin-top: 34px;
                `}
              >
                <Headers.H4
                  css={css`
                    color: ${isHeader
                      ? theme.colors.black
                      : theme.colors.gray4};
                  `}
                >
                  <Link to={`/admin/menus/${params.type}/header`}>
                    {t("menu.header_menu")}
                  </Link>
                </Headers.H4>
                <Headers.H4
                  css={css`
                    margin-top: 10px;
                    color: ${!isHeader
                      ? theme.colors.black
                      : theme.colors.gray4};
                  `}
                >
                  <Link to={`/admin/menus/${params.type}/footer`}>
                    {t("menu.footer_menu")}
                  </Link>
                </Headers.H4>
              </div>
            </div>
            <div
              css={css`
                width: 2px;
                background-color: ${theme.colors.gray2};
                margin: 0px 60px;
              `}
            ></div>
            <MenuStructureEditor />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
