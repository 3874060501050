import { buildQueryString } from "../../../helpers/buildQueryString";
import { authorised } from "../../../request";
import { OrdersData } from "../../../types/admin/Order";

type Args = {
  limit?: number;
  offset?: number;
  query?: string;
  type?: string;
};

export async function getOrders(args: Args) {
  const API = authorised();

  return await API.request<OrdersData>({
    method: "GET",
    url: `/api/enterprise/orders/?${buildQueryString(args)}`,
  });
}
