import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { DraggableComponent } from "../../components/Draggable";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers, Spacing, Row } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { CourseAdditionalMaterial } from "../../types/CourseAdditionalMaterial";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { getAdditionalMaterials } from "../../actions/additionalMaterial";
import { updateCourseAdditionalMaterialOrder } from "../../actions/courseAdditionalMaterial";
import { pageLoading } from "../../helpers/pageLoading";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Footer } from "../../components/Footer";

export function AdditionalMaterialsOverviewPage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const params = useParams<{
    courseId: string;
  }>();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  const { data: additionalMaterials, error: additionalMaterialsError } =
    useNewFetch(getAdditionalMaterials, {
      courseId: params.courseId,
    });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  if (!additionalMaterials || additionalMaterialsError) {
    return pageLoading(additionalMaterialsError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  const onDragEnd = async (items: CourseAdditionalMaterial[]) => {
    try {
      await updateCourseAdditionalMaterialOrder({
        items,
        courseId: params.courseId,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {additionalMaterials.length ? (
            <DraggableComponent
              items={additionalMaterials}
              background={() => "transparent"}
              getDraggableId={(row) => row.additional_material.uuid}
              isDragDisabled={false}
              onDragEnd={(items) => onDragEnd(items)}
              flat
              controls={(item) => {
                return (
                  <Link
                    to={`/courses/${course.uuid}/additional_materials/${item.additional_material.uuid}/edit`}
                  >
                    <Button
                      small
                      children={t("actions.edit")}
                      css={css`
                        margin-right: 10px;
                      `}
                      background={theme.colors.transparent}
                      hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                      color={theme.colors.dark}
                      border={`1px solid ${theme.colors.dark}`}
                    />
                  </Link>
                );
              }}
            >
              {(item) => {
                return (
                  <>
                    <Link
                      to={`/courses/${course.uuid}/additional_materials/${item.additional_material.uuid}/edit`}
                    >
                      {item.additional_material.title}
                    </Link>
                  </>
                );
              }}
            </DraggableComponent>
          ) : (
            <Headers.H3>
              {t("additional-material.no-additional-material")}
            </Headers.H3>
          )}
          <Row
            justify={"flex-end"}
            align={"center"}
            css={css`
              margin-top: ${Spacing.xl};
            `}
          >
            <Button
              small
              type="submit"
              onClick={() => {
                history.push(
                  `/courses/${course.uuid}/additional_materials/new`
                );
              }}
            >
              {t("additional-material.create")}
            </Button>
          </Row>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
