import { authorised } from "../../../request";

type Args = {
  section: "campus" | "studio";
  uuid: string;
}

export async function deleteSignupField({uuid, section}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/signup-field/${section}/${uuid}/`
  });
}
