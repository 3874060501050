import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { DraggableComponent } from "../../components/Draggable";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers, Spacing, Row } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { getCheatSheets } from "../../actions/cheatSheet";
import { pageLoading } from "../../helpers/pageLoading";
import { CheatSheetOrdered } from "../../types/CheatSheet";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { reorderCheats } from "../../actions/cheatSheet/reorderCheats";
import { Footer } from "../../components/Footer";

export function CheatSheetOverviewPage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const params = useParams<{
    courseId: string;
  }>();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  const { data: cheatSheets, error: cheatSheetsError } = useNewFetch(
    getCheatSheets,
    {
      courseId: params.courseId,
    }
  );

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  if (!cheatSheets || cheatSheetsError) {
    return pageLoading(cheatSheetsError);
  }

  const onDragEnd = async (items: CheatSheetOrdered[]) => {
    try {
      await reorderCheats(params.courseId, items);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: false,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {cheatSheets.length ? (
            <DraggableComponent
              items={cheatSheets}
              background={() => "transparent"}
              getDraggableId={(row) => row.cheat_sheet.uuid}
              isDragDisabled={false}
              onDragEnd={(items) => onDragEnd(items)}
              flat
              controls={(item) => {
                return (
                  <Link
                    to={`/courses/${course.uuid}/cheat_sheet/${item.cheat_sheet.uuid}/edit`}
                  >
                    <Button
                      small
                      children={t("actions.edit")}
                      css={css`
                        margin-right: 10px;
                      `}
                      background={"transparent"}
                      color={theme.colors.dark}
                      border={`1px solid ${theme.colors.dark}`}
                    />
                  </Link>
                );
              }}
            >
              {(item) => {
                return (
                  <>
                    <Link
                      to={`/courses/${course.uuid}/cheat_sheet/${item.cheat_sheet.uuid}/edit`}
                    >
                      {item.cheat_sheet.name}
                    </Link>
                  </>
                );
              }}
            </DraggableComponent>
          ) : (
            <Headers.H3>{t("cheat-sheet.no-cheat-sheet")}</Headers.H3>
          )}
          <Row
            justify={"flex-end"}
            align={"center"}
            css={css`
              margin-top: ${Spacing.xl};
            `}
          >
            <Button
              small
              className="create-lesson"
              onClick={() => {
                history.push(`/courses/${course.uuid}/cheat_sheet/new`);
              }}
            >
              {t("cheat-sheet.create")}
            </Button>
          </Row>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
