import React, { useState } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { getMenu } from "../actions/menu/getMenu";
import { useTranslation } from "react-i18next";
import { Headers } from "../helpers/layout";
import { useParams } from "react-router-dom";
import { useNewFetch } from "../useAPI";
import { pageLoading } from "../helpers/pageLoading";
import { updateMenuItem } from "../actions/menu_item/updateMenuItem";
import { createMenuItem } from "../actions/menu_item/createMenuItem";
import { ReactComponent as PlusIcon } from "../assets/icons/Plus.svg";
import { Button } from "./Button";
import { DraggableComponent } from "./Draggable";
import { MenuMenuItem } from "../types/admin/MenuMenuItem";
import { updateMenuMenuItemOrder } from "../actions/menuMenuItem/updateMenuMenuItem";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { customToast } from "./customToast";
import { ReactComponent as DeleteIcon } from "../assets/icons/Delete.svg";
import { ReactComponent as EditIcon } from "../assets/icons/Edit.svg";
import { ReactComponent as DragIcon } from "../assets/icons/Drag.svg";
import {
  ChangeMenuItemForm,
  FormValues,
} from "../forms/admin/ChangeMenuItemForm";
import { deleteMenuItem } from "../actions/menu_item/deleteMenuItem";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { ModalDelete } from "../modals/ModalDelete";

export function MenuStructureEditor() {
  const [openedItemUUID, setOpenedItemUUID] = useState<string | null>(null);
  const [hideControls, setHideControls] = useState(false);
  const [deleteModal, setDeleteModal] = useState<string | null>(null);
  const { refetch: refetchEnterprise } = useEnterprise();
  const [isCreating, setIsCreating] = useState(false);

  const { t } = useTranslation();

  const params = useParams<{
    type: "campus" | "studio";
    location: "header" | "footer";
  }>();

  const {
    data: menu,
    error: menuError,
    refetch,
  } = useNewFetch(getMenu, {
    type: params.type,
    location: params.location,
  });

  if (!menu || menuError) {
    return pageLoading(menuError);
  }

  const onSubmit = async (values: FormValues) => {
    if (!openedItemUUID) return;
    await updateMenuItem(openedItemUUID, values);
    await refetch();
    await refetchEnterprise();
    setOpenedItemUUID(null);
    setHideControls(false);
    customToast.success(t("status.success"));
  };

  const onDelete = async (itemID: string) => {
    await deleteMenuItem(itemID);
    await refetch();
    await refetchEnterprise();
    setDeleteModal(null);
    customToast.success(t("status.success"));
  };

  const isDragDisabled = typeof openedItemUUID === "string";

  const onDragEnd = async (menuId: string, items: MenuMenuItem[]) => {
    try {
      await updateMenuMenuItemOrder({ menuId, items });
      await refetchEnterprise();
      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const createNewMenuItem = async () => {
    try {
      setIsCreating(true);
      const response = await createMenuItem({
        menuLocation: params.location,
        menuType: params.type,
      });
      await refetchEnterprise();
      await refetch();
      setOpenedItemUUID(response.data.uuid);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <div
      css={css`
        width: 500px;
      `}
    >
      <Headers.H1
        css={css`
          line-height: 100%;
        `}
      >
        {t("menu.items_title")}
      </Headers.H1>
      <div
        css={css`
          margin-top: 50px;
          width: 100%;
        `}
      >
        <DraggableComponent
          items={menu.items}
          isDragDisabled={isDragDisabled}
          getDraggableId={(row) => row.item.uuid}
          onDragEnd={(items) => onDragEnd(menu.uuid, items)}
          hideControls={hideControls}
          background={(item) =>
            item.item.uuid === openedItemUUID ? "#fbefde;" : theme.colors.gray1
          }
          css={css`
            :hover {
              ${isDragDisabled ? "" : "background-color: #fbefde;"}
            }
          `}
          dragIcon={
            <DragIcon
              width={21}
              height={21}
              css={css`
                cursor: grab;
              `}
            />
          }
          showControlsOnHover
          controls={(item) => {
            const isOpened = item.item.uuid === openedItemUUID;

            return (
              <>
                {isOpened ? (
                  <div></div>
                ) : (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Button
                      css={css`
                        margin-right: 18px;
                      `}
                      onClick={() => {
                        setOpenedItemUUID(item.item.uuid);
                        setHideControls(true);
                      }}
                    >
                      <EditIcon
                        width={16}
                        height={16}
                        css={css`
                          margin-right: 10px;
                        `}
                      />
                      {t("actions.edit")}
                    </Button>
                    <DeleteIcon
                      width={21}
                      height={21}
                      css={css`
                        cursor: pointer;
                      `}
                      onClick={() => setDeleteModal(item.item.uuid)}
                    />
                  </div>
                )}
              </>
            );
          }}
        >
          {(row) => {
            const isOpened = row.item.uuid === openedItemUUID;

            return (
              <div>
                {isOpened ? (
                  <ChangeMenuItemForm
                    onSubmit={onSubmit}
                    data={row.item}
                    onCancel={() => {
                      setOpenedItemUUID(null);
                      setHideControls(false);
                    }}
                    onDelete={async () => {
                      await deleteMenuItem(row.item.uuid);
                      await setOpenedItemUUID(null);
                      setHideControls(false);
                      await refetch();
                      await refetchEnterprise();
                      customToast.success(t("status.success"));
                    }}
                  ></ChangeMenuItemForm>
                ) : (
                  <Headers.H4>{row.item.name}</Headers.H4>
                )}
              </div>
            );
          }}
        </DraggableComponent>
        <Button
          background="transparent"
          color={theme.colors.black}
          isSubmitting={isCreating}
          hoverStyles={`background: ${theme.colors.black}; color: ${theme.colors.white};`}
          icon={
            <PlusIcon
              className="plus"
              width={20}
              height={20}
              css={css`
                margin-right: 10px;
              `}
            />
          }
          onClick={() => createNewMenuItem()}
        >
          {t("menu.add_item")}
        </Button>
      </div>
      <ModalDelete
        isOpen={!!deleteModal}
        onClose={() => setDeleteModal(null)}
        onDelete={() => {
          if (!deleteModal) return;
          onDelete(deleteModal);
        }}
      />
    </div>
  );
}
