import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { SectionWrapper } from "../EditCreateQuiz";
import { TextareaField } from "../../../forms/fields/TextareaField";
import { RadioField } from "../../../forms/fields/RadioField";

export function TrueFalse(props: { name: string }) {
  const { t } = useTranslation();

  const statementVerity: { label: string; value: boolean }[] = [
    { label: t("lesson.quiz.truth"), value: true },
    { label: t("lesson.quiz.falsity"), value: false },
  ];

  return (
    <>
      <TextareaField
        label={`${t("lesson.quiz.statement")}:`}
        name={`${props.name}[0].primary_text`}
        maxLength={500}
        css={css`
          min-height: 100px;
        `}
      />
      <SectionWrapper
        content="flex-start"
        label={`${t("lesson.quiz.statement-verity")}:`}
      >
        {statementVerity.map((stement, idx) => (
          <RadioField
            labelRight
            name={`${props.name}[0].is_correct`}
            label={stement.label}
            value={stement.value}
            key={idx}
            css={css`
              margin-right: 1rem;
            `}
          />
        ))}
      </SectionWrapper>
    </>
  );
}
