import { authorised } from "../../request";
import { CheatSheetOrdered } from "../../types/CheatSheet";

type Args = {
  courseId: string;
};

export async function getCheatSheets(data: Args) {
  const API = authorised();

  return API.request<CheatSheetOrdered[]>({
    method: "GET",
    url: `/api/studio/courses/${data.courseId}/cheat-sheet/`,
  });
}
