import { authorised } from "../../../request";
import { UserSegment } from "../../../types/admin/UserSegment";

type UserSegmentArgs = Omit<UserSegment, "id" | "users_count">;

type Args = {
  id: number;
  data: UserSegmentArgs;
};

export async function updateUserSegment({ id, data }: Args) {
  const API = authorised();

  return API.request<UserSegment>({
    method: "PUT",
    url: `/api/enterprise/user-segment/${id}/`,
    data,
  });
}
