import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { toast } from "react-toastify";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";

import { Headers } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { pageLoading } from "../../helpers/pageLoading";
import { getSelfAssessment } from "../../actions/courseSelfAssessments/getCourseSelfAssessment";
import {
  EditCreateSelfAssessment,
  FormValues,
} from "../../components/SelfAssessmentEditor/EditCreateSelfAssessment";
import { updateSelfAssessment } from "../../actions/courseSelfAssessments/updateCourseSelfAssessment";
import { Footer } from "../../components/Footer";

export function EditSelfAssessmentItem() {
  const { t } = useTranslation();
  const history = useHistory();

  const params = useParams<{
    courseId: string;
    selfAssessmentId: string;
  }>();

  const { data: item, error: itemError } = useNewFetch(getSelfAssessment, {
    courseId: params.courseId,
    selfAssessmentId: params.selfAssessmentId,
  });

  if (!item || itemError) {
    return pageLoading(itemError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: true,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
  ];

  const submitUpdateTest = async (data: FormValues) => {
    await updateSelfAssessment({
      courseId: params.courseId,
      selfAssessmentId: params.selfAssessmentId,
      data: {
        ...data,
        answers: data.answers ? data.answers : [],
      },
    });

    toast.success(t("status.success"));

    history.push(`/courses/${params.courseId}/self-assessments`);
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{item.title}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{item.title}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div>
            <EditCreateSelfAssessment
              initialData={item}
              onSubmitCallback={submitUpdateTest}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
