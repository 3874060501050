import { buildQueryString } from "../../../helpers/buildQueryString";
import { authorised } from "../../../request";

export type UserStatus = "all" | "activated" | "deactivated" | "pending";

type Args = {
  date_from?: string;
  date_to?: string;
  status?: UserStatus;
  type: "CAMPUS" | "STUDIO";
};

export async function downloadUsersCsv(args?: Args) {
  const API = authorised();

  return await API.request<string>({
    method: "GET",
    url: `/api/enterprise/users/csv/?${buildQueryString(args)}`,
  });
}
