import { authorised } from "../../request";

type Args = {
  user_id: string | number;
}

export async function stopCollaborationOrganisationUser({user_id}: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/studio/organisation/users/stop-collaboration/`,
    data: {
      user_id
    }
  });
}
