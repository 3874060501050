import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";

export default function StatusFilter({
  options,
  className,
  defaultOption,
  label,
  onChangeOption,
}: {
  options: { value: string; label: string }[];
  className?: string;
  defaultOption?: string;
  label?: string;
  onChangeOption: (event: ChangeEvent<HTMLSelectElement>) => void;
}) {
  return (
    <label
      className={className}
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
      `}
    >
      {label && (
        <span
          css={css`
            margin-right: 8px;
            white-space: nowrap;
            font-size: 16px;
          `}
        >
          {label}
        </span>
      )}
      <div
        css={css`
          position: relative;
          :before {
            left: 5px;
            top: 10px;
            width: 5px;
            height: 5px;
            position: absolute;
            transform: scale(0.8);
            background-repeat: no-repeat;
            background-size: contain;
          }
          :after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='orange' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
            right: 0.5rem;
            top: 4px;
            position: absolute;
            pointer-events: none;
            transform: scale(0.8);
          }
        `}
      >
        <select
          css={css`
            outline: none;
            width: fit-content;
            min-width: 130px;
            max-width: 250px;
            padding: 2px 30px 2px 20px;
            border-radius: 20px;
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.black};
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            font-size: 16px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            :-ms-expand {
              display: none;
            }
          `}
          onChange={(e) => onChangeOption(e)}
        >
          {options.map((option, id) => {
            return (
              <option
                selected={defaultOption === option.value}
                value={option.value}
                key={id}
              >
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </label>
  );
}
