import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { customToast } from "../../customToast";
import { theme } from "../../../themes/variables";
import { SignupFieldType, SignupField } from "../../../types/admin/SignupField";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { InputRepeater } from "../../../forms/fields/InputRepeater";
import { InputField } from "../../../forms/fields/InputField";
import { RadioFields } from "../../../forms/fields/RadioFields";

import { serverErrorHandler } from "../../../helpers/serverErrorHandler";

export type FormValues = Omit<SignupField, "uuid">;

function SectionWrapper(props: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  return (
    <div
      css={css`
        padding: 40px;
        background: ${theme.colors.white};
        border-radius: 20px;
        margin-bottom: 20px;
        :last-of-type {
          margin-bottom: 0;
        }
        > div {
          margin-bottom: 32px;
          :last-of-type {
            margin-bottom: 0;
          }
        }
      `}
    >
      {props.children}
    </div>
  );
}

export function EditCreateSignupModal(props: {
  label: string;
  signupField?: SignupField;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const inputsContainer = useRef<HTMLDivElement>(null);

  const types = [
    {
      label: t("signup_fields.text_field"),
      value: SignupFieldType.TEXT_FIELD,
    },
    {
      label: t("signup_fields.textarea"),
      value: SignupFieldType.TEXT_AREA,
    },
    {
      label: t("signup_fields.select"),
      value: SignupFieldType.SELECT,
    },
    {
      label: t("signup_fields.radio"),
      value: SignupFieldType.RADIO,
    },
    {
      label: t("signup_fields.checkbox"),
      value: SignupFieldType.CHECKBOX,
    },
  ];

  const isRequiredChoices = [
    {
      label: t("signup_fields.yes"),
      value: true,
    },
    {
      label: t("signup_fields.no"),
      value: false,
    },
  ];

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(),
    name: Yup.string().required(),
    hint: Yup.string(),
    placeholder: Yup.string(),
    required: Yup.boolean(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmit(values);

      props.setIsOpen(false);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      contentStyles={{ backgroundColor: theme.colors.gray1, maxWidth: "810px" }}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          type: props.signupField?.type || SignupFieldType.TEXT_FIELD,
          hint: props.signupField?.hint || "",
          name: props.signupField?.name || "",
          required: props.signupField?.required || false,
          placeholder: props.signupField?.placeholder || "",
          options: props.signupField?.options || [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form noValidate>
            <ModalHeader closeIcon onClose={() => props.setIsOpen(false)}>
              {props.label}
            </ModalHeader>

            <ModalBody>
              {values.type === SignupFieldType.TEXT_FIELD ||
              values.type === SignupFieldType.TEXT_AREA ? (
                <SectionWrapper>
                  <RadioFields
                    label={`${t("signup_fields.chose_type")}:*`}
                    labelRight
                    choices={types}
                    name="type"
                  />

                  <div>
                    <InputField
                      label={`${t("signup_fields.field_name")}:*`}
                      placeholder={t("signup_fields.type_here")}
                      name="name"
                    />
                  </div>

                  <div>
                    <InputField
                      label={`${t("signup_fields.hint")}:`}
                      placeholder={t("signup_fields.type_here")}
                      name="hint"
                    />
                  </div>

                  <RadioFields
                    label={t("signup_fields.is_required")}
                    labelRight
                    choices={isRequiredChoices}
                    name="required"
                  />

                  <div>
                    <InputField
                      label={`${t("signup_fields.placeholder")}:`}
                      placeholder={t("signup_fields.type_here")}
                      name="placeholder"
                    />
                  </div>
                </SectionWrapper>
              ) : (
                <div>
                  <SectionWrapper>
                    <RadioFields
                      label={`${t("signup_fields.chose_type")}:*`}
                      labelRight
                      choices={types}
                      name="type"
                    />

                    <div>
                      <InputField
                        label={`${t("signup_fields.field_name")}:*`}
                        placeholder={t("signup_fields.type_here")}
                        name="name"
                      />
                    </div>
                  </SectionWrapper>

                  <SectionWrapper>
                    <div ref={inputsContainer}>
                      <InputRepeater<string>
                        name="options"
                        label={t("signup_fields.options")}
                      >
                        {({ idx, arrayHelpers }) => {
                          return (
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                flex: 1 0 10%;
                              `}
                            >
                              <span
                                css={css`
                                  font-weight: bold;
                                  position: relative;
                                  top: 0;
                                  padding-right: 25px;
                                `}
                              >
                                {idx + 1}.
                              </span>

                              <InputField
                                css={css`
                                  flex: 1 0 10%;
                                `}
                                name={`options[${idx}].value`}
                                onkeydown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    arrayHelpers.push("");
                                    setTimeout(() => {
                                      if (inputsContainer.current) {
                                        const tmpLastInput =
                                          inputsContainer.current.querySelector<HTMLInputElement>(
                                            ".input_repeater:last-of-type input"
                                          );
                                        if (tmpLastInput) {
                                          tmpLastInput.focus();
                                        }
                                      }
                                    }, 10);
                                  }
                                }}
                              ></InputField>
                            </div>
                          );
                        }}
                      </InputRepeater>
                    </div>
                  </SectionWrapper>

                  <SectionWrapper>
                    <div>
                      <InputField
                        label={`${t("signup_fields.hint")}:`}
                        placeholder={t("signup_fields.type_here")}
                        name="hint"
                      />
                    </div>

                    <RadioFields
                      label={t("signup_fields.is_required")}
                      labelRight
                      choices={isRequiredChoices}
                      name="required"
                    />
                  </SectionWrapper>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              <Button
                background={theme.colors.gray1}
                color={theme.colors.dark}
                hoverStyles={`opacity: 0.85;`}
                onClick={() => props.setIsOpen(false)}
              >
                {t("actions.cancel")}
              </Button>

              <Button
                type="submit"
                isSubmitting={isSubmitting}
                disabled={!isValid}
              >
                {t("actions.save")}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
