import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { SelfAssessmentType } from "../../types/SelfAssessment";
import { SelfAssessmentAnswers } from "./AnswerContentTypeFields/SelfAssessmentAnswers";

export function AnswerContent(props: {
  name: string;
  type: SelfAssessmentType;
}) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        max-width: 100%;
        width: 100%;
        margin-top: 1rem;
      `}
    >
      <div
        css={css`
          padding: 1rem;
          border: 1px solid ${theme.colors.gray4};
          border-radius: 8px;
        `}
      >
        {props.type !== SelfAssessmentType.OPEN_QUESTION && (
          <SelfAssessmentAnswers
            label={t("self-assessment.choices")}
            name={`answers`}
          />
        )}
      </div>
    </div>
  );
}
