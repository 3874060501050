import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import {
  EditCreateLesson,
  FormSubmitValues,
} from "../../components/EditCreateLesson";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { createLesson } from "../../actions/lesson/createLesson";
import { Headers } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export function NewLessonPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
  }>();

  const history = useHistory();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  const onSubmit = async (data: FormSubmitValues) => {
    const { data: response } = await createLesson(params.courseId, {
      ...data,
      duration: Number(data.duration),
    });

    customToast.success(t("status.success"));

    history.push(
      `/courses/${params.courseId}/lessons/${response.uuid}/content_editor`
    );
  };

  const tabsData = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/new`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${params.courseId}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("new_lesson.title")}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{t("new_lesson.title")}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateLesson onSubmitCallback={onSubmit} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
