import { authorised } from "../../request";
import { Faq } from "../../types/Faq";

type Args = {
  searchText?: string;
};

export async function getEnterpriseFaqList({ searchText }: Args = {}) {
  const API = authorised();

  return API.request<Faq[]>({
    method: "GET",
    url: `/api/enterprise/faq-items/?${
      searchText ? `question=${searchText}` : ""
    }`,
  });
}
