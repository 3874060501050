import React, { useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation, Trans } from "react-i18next";
import getBlobDuration from "get-blob-duration";
import { theme } from "../themes/variables";
import { customToast } from "../components/customToast";
import { Row } from "../helpers/layout";
import { File as FileType, AcceptedMimeTypes } from "../types/File";
import { DropFile, DroppedFile } from "../components/DropFile";
import { Button } from "../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../components/Modal";
import { FilesRow } from "../components/FilesRow";
import { InputComponent } from "../components/InputComponent";

import { uploadFile, getFiles } from "../actions/file";
import { serverErrorHandler } from "../helpers/serverErrorHandler";

import { ReactComponent as LeftSvg } from "../assets/icons/Left.svg";
import { ReactComponent as DownloadImageIcon } from "../assets/icons/DownloadImage.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/Cross.svg";

const limit = 10;

const checkDroppedFile = (p: any): p is DroppedFile => {
  return p && p.file instanceof File;
};

const supportedFormats = [
  {
    title: "MP3",
    type: AcceptedMimeTypes.MP3,
  },
  {
    title: "Mpeg",
    type: AcceptedMimeTypes.MPEG,
  },
  {
    title: "PDF",
    type: AcceptedMimeTypes.PDF,
  },
  {
    title: "DOC",
    type: AcceptedMimeTypes.DOC,
  },
  {
    title: "DOCX",
    type: AcceptedMimeTypes.DOCX,
  },
  {
    title: "XLS",
    type: AcceptedMimeTypes.XLS,
  },
  {
    title: "XLSX",
    type: AcceptedMimeTypes.XLSX,
  },
  {
    title: "PPT",
    type: AcceptedMimeTypes.PPT,
  },
  {
    title: "PPTX",
    type: AcceptedMimeTypes.PPTX,
  },
  {
    title: "SVG",
    type: AcceptedMimeTypes.SVG,
  },
  {
    title: "WEBA",
    type: AcceptedMimeTypes.WEBA,
  },
  {
    title: "WEBM",
    type: AcceptedMimeTypes.WEBM,
  },
];

export function FileUploadModal(props: {
  isVisible: boolean;
  onSelect: (image: FileType) => void;
  onClose: () => void;
  value?: FileType | null;
  acceptedMimeTypes?: AcceptedMimeTypes[];
}) {
  const { t } = useTranslation();

  const [search, setSearch] = useState(false);

  const [searchText, setSearchText] = useState<string | null>("");

  const [maxFilesCount, setMaxFilesCount] = useState<number | null>(null);

  const [loadedFiles, setLoadedFiles] = useState<FileType[]>([]);
  const offset = useRef(0);

  const [selectedFile, setSelectedFile] = useState<
    FileType | DroppedFile | null
  >(props.value || null);

  const [droppedFile, setDroppedFile] = useState<DroppedFile | null>(null);

  const [loadMore, setLoadmore] = useState(false);

  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const clear = () => {
    setLoadedFiles([]);
    setDroppedFile(null);
    setSearchText("");
    setMaxFilesCount(0);
  };

  useEffect(() => {
    if (!search) {
      clear();
    }
  }, [search]);

  useEffect(() => {
    if (searchText) {
      getFiles({
        limit,
        name: searchText,
        offset: 0,
        mime_type: props.acceptedMimeTypes,
      }).then((data) => {
        const newFiles = data.data.results;

        offset.current = newFiles.length;
        setLoadedFiles(newFiles);
        setMaxFilesCount(data.data.count);
      });
    } else {
      getFiles({
        limit,
        offset: 0,
        mime_type: props.acceptedMimeTypes,
      }).then((data) => {
        const newFiles = data.data.results;

        offset.current = newFiles.length;

        setLoadedFiles(newFiles);
        setMaxFilesCount(data.data.count);
      });
    }
  }, [searchText, props.isVisible, props.acceptedMimeTypes]);

  useEffect(() => {
    if (!loadMore) return;

    if (searchText) {
      getFiles({
        limit,
        name: searchText,
        offset: offset.current,
        mime_type: props.acceptedMimeTypes,
      }).then((data) => {
        setLoadedFiles((prev) => {
          const newImages = [...prev, ...data.data.results];

          offset.current = newImages.length;

          return newImages;
        });
        setMaxFilesCount(data.data.count);
        setLoadmore(false);
      });
    } else {
      getFiles({
        limit,
        offset: offset.current,
        mime_type: props.acceptedMimeTypes,
      }).then((data) => {
        setLoadedFiles((prev) => {
          const newImages = [...prev, ...data.data.results];

          offset.current = newImages.length;

          return newImages;
        });
        setMaxFilesCount(data.data.count);
        setLoadmore(false);
      });
    }
  }, [loadMore, props.acceptedMimeTypes, searchText]);

  const haveMoreToLoad =
    search && maxFilesCount !== null && loadedFiles.length < maxFilesCount;

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setProgress(percentCompleted);
  };

  const onConfirm = async () => {
    if (!loading) {
      if (checkDroppedFile(selectedFile) && droppedFile) {
        try {
          setLoading(true);
          setProgress(0);

          let duration = 0;

          switch (droppedFile.file.type) {
            case AcceptedMimeTypes.MP3:
              duration = await getBlobDuration(droppedFile.file);
              break;
            case AcceptedMimeTypes.MPEG:
              duration = await getBlobDuration(droppedFile.file);
              break;
            case AcceptedMimeTypes.WEBA:
              duration = await getBlobDuration(droppedFile.file);
              break;
            case AcceptedMimeTypes.WEBM:
              duration = await getBlobDuration(droppedFile.file);
              break;
          }

          const uploadedFile = await uploadFile({
            file: droppedFile.file,
            duration: Math.floor(duration),
            onUploadProgress,
          });

          setLoading(false);
          setProgress(0);

          setSelectedFile(uploadedFile);

          props.onSelect(uploadedFile);
        } catch (error) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(error),
            })
          );
        }
      } else {
        props.onSelect(selectedFile as FileType);
      }

      setSearch(false);
      props.onClose();
      setMaxFilesCount(null);
      setSelectedFile(null);
      setDroppedFile(null);
      setLoadedFiles([]);
    }
  };

  const onClose = () => {
    setSearch(false);
    props.onClose();
    setMaxFilesCount(null);
    setSelectedFile(null);
    setDroppedFile(null);
    setLoadedFiles([]);
  };

  return (
    <Modal
      modalIsOpen={props.isVisible}
      onClose={onClose}
      contentStyles={{
        padding: "25px 40px 40px 40px",
        backgroundColor: theme.colors.gray1,
        maxWidth: "1040px",
      }}
    >
      <ModalHeader
        onClose={onClose}
        closeIcon
        css={css`
          ${!search && `justify-content: flex-end;`}
        `}
      >
        {search ? (
          <Button
            icon={<LeftSvg width={20} height={20} />}
            flat
            background={theme.colors.transparent}
            color={theme.colors.dark}
            hoverStyles={`opacity: 0.85;`}
            css={css`
              font-size: 16px;
              line-height: 22px;
              font-weight: normal;
            `}
            onClick={() => {
              setSearch(false);
            }}
          >
            {t("media.back")}
          </Button>
        ) : null}
      </ModalHeader>

      <ModalBody>
        {search ? (
          <div>
            <div
              css={css`
                margin-bottom: 1rem;
              `}
            >
              <InputComponent
                placeholder={t("media.search-file")}
                icon={<SearchIcon width={15} height={15} />}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <FilesRow
              value={checkDroppedFile(selectedFile) ? null : selectedFile}
              onSelect={(file) => setSelectedFile(file)}
              onDoubleClick={async (file) => {
                setSelectedFile(file);
                await onConfirm();
              }}
              files={loadedFiles}
            />
          </div>
        ) : (
          <>
            <DropFile
              onFileAdded={(file) => {
                setDroppedFile(file);
                setSelectedFile(file);
              }}
              onFileDeleted={() => {
                setDroppedFile(null);

                if (checkDroppedFile(selectedFile)) {
                  setSelectedFile(null);
                }
              }}
              selectedFile={
                checkDroppedFile(selectedFile) ? selectedFile : null
              }
              onClickPreview={() => setSelectedFile(droppedFile)}
              acceptedMimeTypes={props.acceptedMimeTypes}
              initialMessage={
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  `}
                >
                  <DownloadImageIcon
                    css={css`
                      margin-bottom: 12px;
                    `}
                  />
                  <div>
                    <Trans i18nKey="media.drag-and-drop-file">
                      Drag and drop your files here, or
                      <span
                        css={css`
                          color: ${theme.colors.primary};
                        `}
                      >
                        browse
                      </span>
                      from your computer
                    </Trans>
                  </div>
                  <div
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                      color: ${theme.colors.gray6};
                    `}
                  >
                    {t("media.supported-formats")}:{" "}
                    {supportedFormats.map((el, idx) => {
                      if (
                        props.acceptedMimeTypes &&
                        !props.acceptedMimeTypes.includes(el.type)
                      ) {
                        return (
                          <span
                            key={idx}
                            css={css`
                              opacity: 0.3;
                              pointer-events: none;
                            `}
                          >
                            {el.title}
                            {idx < supportedFormats.length - 1 ? ", " : ""}
                          </span>
                        );
                      }

                      return (
                        <span key={idx}>
                          {el.title}
                          {idx < supportedFormats.length - 1 ? ", " : ""}
                        </span>
                      );
                    })}
                  </div>
                </div>
              }
            />
            <Row
              justify="space-between"
              css={css`
                margin: 30px 0 20px 0;
              `}
            >
              <div
                css={css`
                  font-size: 20px;
                  line-height: 30px;
                `}
              >
                {t("media.recent")}:
              </div>

              <Button
                flat
                background={theme.colors.transparent}
                color={theme.colors.dark}
                hoverStyles={`opacity: 0.85;`}
                icon={<SearchIcon width={20} height={20} />}
                onClick={() => setSearch(true)}
                css={css`
                  font-size: 16px;
                `}
              >
                {t("media.search")}
              </Button>
            </Row>

            <FilesRow
              value={checkDroppedFile(selectedFile) ? null : selectedFile}
              onSelect={(file) => setSelectedFile(file)}
              onDoubleClick={async (file) => {
                setSelectedFile(file);
                await onConfirm();
              }}
              files={loadedFiles}
            />
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {haveMoreToLoad && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 0;
              width: 100%;
            `}
          >
            <Button
              flat
              icon={
                <CrossIcon
                  width={20}
                  height={20}
                  color={theme.colors.primary}
                />
              }
              background={theme.colors.transparent}
              color={theme.colors.dark}
              hoverStyles="opacity: 0.85;"
              onClick={() => {
                setLoadmore(true);
              }}
              css={css`
                font-weight: bold;
              `}
            >
              {t("media.load")}
            </Button>
          </div>
        )}

        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          onClick={onClose}
          css={css`
            min-width: auto;
            z-index: 1000;
          `}
        >
          {t("actions.cancel")}
        </Button>

        <Button
          onClick={() => onConfirm()}
          disabled={!selectedFile || loading}
          background={
            loading
              ? `linear-gradient(90deg, ${theme.colors.dark} ${progress}%, ${theme.colors.gray3} 0%)`
              : !!selectedFile
              ? theme.colors.dark
              : theme.colors.gray3
          }
          color={theme.colors.white}
          css={css`
            min-width: auto;
            z-index: 1000;
          `}
        >
          {t("actions.select")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
