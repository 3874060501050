import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Button } from "../Button";
import { Avatar } from "../Avatar";
import { TableHandler, TableHandlerColumn } from "../TableHandler";
import {
  OrganisationUser,
  OrganisationUserRole,
} from "../../types/OrganisationUser";
import { getOrganisationUsers } from "../../actions/organisationUser";
import { UserMeta } from "../../types/UserProfile";
import { ReactComponent as StopIcon } from "../../assets/icons/Stop.svg";

export default function UserTable({
  forwardRef,
  organisationUserRoleLabels,
  isMe,
  openStopCollaborationModal,
}: {
  forwardRef: any;
  organisationUserRoleLabels: { key: OrganisationUserRole; value: string }[];
  isMe: (user: UserMeta) => boolean;
  openStopCollaborationModal: (user: OrganisationUser) => void;
}) {
  const { t } = useTranslation();

  const tableHandlerColumns: TableHandlerColumn<OrganisationUser>[] = [
    {
      label: "ID",
      render: (row) => <div>{row.user.id}</div>,
    },
    {
      label: t("account.avatar"),
      render: (row) => (
        <Avatar
          css={css`
            margin: 0 auto;
          `}
          size={40}
          url={row.user.avatar?.image}
        />
      ),
    },
    {
      label: t("account.email"),
      render: (row) => <div>{row.user.email}</div>,
    },
    {
      label: t("admin.users.page.fullname"),
      render: (row) => (
        <div>{row.user.first_name + " " + row.user.last_name}</div>
      ),
    },
    {
      label: t("account.role"),
      render: (row) => (
        <div>
          {
            organisationUserRoleLabels.find((el) => el.key === row.role?.key)
              ?.value
          }
        </div>
      ),
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            white-space: nowrap;
          `}
        >
          {isMe(row.user) ? (
            "-"
          ) : (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.error}`}
              color={theme.colors.error}
              icon={
                <StopIcon width={14} height={14} color={theme.colors.error} />
              }
              iconRight
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
              small
              onClick={() => openStopCollaborationModal(row)}
            >
              {t("organisation.stop-collaboration")}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <TableHandler
      forwardRef={forwardRef}
      tableData={{
        columns: tableHandlerColumns,
      }}
      getFunction={getOrganisationUsers}
    />
  );
}
