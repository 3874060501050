import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { File as FileType, AcceptedMimeTypes } from "../../../../types/File";
import { theme } from "../../../../themes/variables";
import { Spacing } from "../../../../helpers/layout";
import { Button } from "../../../Button";
import { AudioPlayer } from "../../../AudioPlayer";
import { FileUploadModal } from "../../../../modals/FileUploadModal";
import { ReactComponent as DocIcon } from "../../../../assets/icons/DOC.svg";

export function FileHandler(props: {
  value: FileType | null;
  className?: string;
  acceptedMimeTypes?: AcceptedMimeTypes[];
  onSelect: (image: FileType) => void;
  removeFile: () => void;
}) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const mime_type = props.value?.mime_type;
  const isAudio =
    mime_type === AcceptedMimeTypes.MP3 ||
    mime_type === AcceptedMimeTypes.MPEG ||
    mime_type === AcceptedMimeTypes.WEBA ||
    mime_type === AcceptedMimeTypes.WEBM;

  return (
    <div
      className={props.className}
      css={css`
        margin-bottom: 1rem;
      `}
    >
      <div
        css={css`
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: bold;
        `}
      >
        {`${t("media.file")}:`}
      </div>

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: ${props.value ? `100%` : "auto"};
            display: flex;
            flex-direction: column;
          `}
        >
          {props.value ? (
            isAudio ? (
              <AudioPlayer
                src={props.value.file}
                title={props.value.title || props.value.upload_name}
              />
            ) : (
              <div
                css={css`
                  background: #fff;
                  border-radius: 20px;
                  padding: 30px 30px;

                  svg {
                    width: 25px;
                    height: auto;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    flex-flow: row wrap;
                  `}
                >
                  <DocIcon
                    color={theme.colors.primary}
                    css={css`
                      margin-right: 20px;
                    `}
                  />
                  <div>{props.value.upload_name}</div>
                </div>
              </div>
            )
          ) : (
            <div
              css={css`
                width: 150px;
                height: 100%;
                background: ${theme.colors.gray2};
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              `}
              onClick={() => setModal(true)}
            >
              {t("media.no-file")}
            </div>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: ${Spacing.m};
            > button {
              margin: ${Spacing.xs};
            }
          `}
        >
          <Button
            background={theme.colors.dark}
            color={theme.colors.white}
            onClick={() => setModal(true)}
          >
            {t("actions.change")}
          </Button>

          {props.value && (
            <Button
              background={theme.colors.transparent}
              color={theme.colors.dark}
              hoverStyles={`opacity: 0.85;`}
              onClick={() => props.removeFile()}
            >
              <span
                css={css`
                  text-decoration: underline;
                `}
              >
                {t("actions.delete")}
              </span>
            </Button>
          )}
        </div>
      </div>

      <FileUploadModal
        onSelect={async (file) => await props.onSelect(file)}
        value={props.value}
        isVisible={modal}
        onClose={() => {
          setModal(false);
        }}
        acceptedMimeTypes={props.acceptedMimeTypes}
      />
    </div>
  );
}
