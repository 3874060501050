import React, { useEffect, useRef } from "react";
import getStroke from "perfect-freehand";
import { css } from "styled-components/macro";
import { Drawing } from "../../types/Drawing";
import {
  getDrawingRect,
  getSvgPathFromStroke,
  penStrokeConfig,
  highligterStrokeConfig,
} from "../../helpers/draw";
import { useDebounce } from "use-debounce/lib";
import produce from "immer";

export function DrawingRenderer(props: {
  drawing: Drawing | null;
  viewBox?: string;
  onPointerDown?: (e: React.PointerEvent<SVGSVGElement>) => void;
  onPointerMove?: (e: React.PointerEvent<SVGSVGElement>) => void;
}) {
  const drawingType = props.drawing ? props.drawing.type : "pen";
  const isHighlighter = drawingType === "highlighter";
  const strokeConfig = isHighlighter ? highligterStrokeConfig : penStrokeConfig;

  return (
    <svg
      viewBox={props.viewBox}
      onPointerDown={props.onPointerDown}
      onPointerMove={props.onPointerMove}
      style={{ touchAction: "none", width: "100%", height: "100%" }}
    >
      {props.drawing &&
        props.drawing.marks.map((mark) => {
          return (
            <path
              fill={isHighlighter ? "yellow" : "#000"}
              fillOpacity={isHighlighter ? "0.5" : "1"}
              d={getSvgPathFromStroke(getStroke(mark.points, strokeConfig))}
            />
          );
        })}
    </svg>
  );
}

export function DrawArea(props: {
  mode: "drawing" | "highlighting";
  onDrawEnd: (
    drawing: Drawing,
    data: {
      width: number;
      height: number;
      top: number;
      left: number;
    }
  ) => void;
}) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const [drawing, setDrawing] = React.useState<Drawing | null>(null);
  const [debouncedDrawing] = useDebounce(drawing, 2000);
  const drawingStartTime = useRef(0);

  useEffect(() => {
    if (!debouncedDrawing) return;

    const { left, top, width, height, trimmed } =
      getDrawingRect(debouncedDrawing);

    props.onDrawEnd(trimmed, {
      width,
      height,
      top: top[1],
      left: left[0],
    });
  }, [debouncedDrawing, props]);

  function handlePointerDown(e: React.PointerEvent<SVGSVGElement>) {
    e.preventDefault();

    const rect = divRef.current?.getBoundingClientRect();

    if (!rect) return;

    // Mouse position
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (!drawingStartTime.current) {
      drawingStartTime.current = new Date().getTime();
    }

    setDrawing((drawing) => {
      const pointTime = new Date().getTime() - drawingStartTime.current;

      return {
        ...drawing,
        type: props.mode === "drawing" ? "pen" : "highlighter",
        marks: [
          ...(drawing ? drawing.marks : []),
          {
            points: [[x, y, e.pressure, pointTime]],
          },
        ],
      };
    });
  }

  function handlePointerMove(e: React.PointerEvent<SVGSVGElement>) {
    e.preventDefault();

    const rect = divRef.current?.getBoundingClientRect();

    if (!rect) return;

    // Mouse position
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (e.buttons === 1 && drawing && drawing.marks.length) {
      setDrawing((drawing) => {
        return produce(drawing, (drawingDraft) => {
          if (!drawingDraft) return drawingDraft;

          drawingDraft.marks[drawingDraft.marks.length - 1] = {
            points: [
              ...drawingDraft.marks[drawingDraft.marks.length - 1].points,
              [
                x,
                y,
                e.pressure,
                new Date().getTime() - drawingStartTime.current,
              ],
            ],
          };
        });
      });
    }
  }
  return (
    <div
      ref={divRef}
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      `}
    >
      <DrawingRenderer
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        drawing={drawing}
      />
    </div>
  );
}
