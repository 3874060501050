import React, { useState, useEffect, useCallback } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CustomEmbedBlock } from "../../../../types/Block";
import { RequiredDataType } from "../../../../types/RequiredDataType";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { InputMaskComponent } from "../../../InputMaskComponent";
import { Separator } from "../../../Separator";
import { EntitySelectionComponent } from "../../../EntitySelectionComponent";
import { MediaPreview } from "../../../Video/MediaPreview";
import { MediaInputFieldInternal } from "../../../../forms/fields/MediaInputField";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { BlockControls } from "../components/BlockControls";

import { getTests } from "../../../../actions/test";

export function BlockEmbedVideo(props: {
  block: CustomEmbedBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomEmbedBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const { t } = useTranslation();

  const params = useParams<{
    lessonId: string;
  }>();

  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [content, setContent] = useState<string>(props.block.data.url || "");

  const [duration, setDuration] = useState(
    props.block.data.duration || "00:00:00"
  );

  const [testsPartial, setTestsPartial] = useState<RequiredDataType[]>(
    props.block.data.tests || []
  );

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setContent(props.block.data.url || "");
    setDuration(props.block.data.duration || "00:00:00");
  };

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;

  const entityTestsSearchCallback = useCallback(
    async (searchText: string): Promise<RequiredDataType[]> => {
      const response = await getTests({
        query: searchText,
        lessonId: params.lessonId,
      });

      return response.data
        .filter((item) => item.in_video)
        .map((item) => {
          return {
            id: item.uuid || "",
            label: item.internal_name,
          };
        });
    },
    [params.lessonId]
  );

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.url && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            <MediaPreview url={block.data.url} />
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block}
        header={
          <div>
            <span
              css={css`
                color: ${theme.colors.primary};
                margin-right: 12px;
              `}
            >
              Aa
            </span>

            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
        onCancel={onCancel}
        onConfirm={async (value) => {
          const updatedData: CustomEmbedBlock = {
            ...value,
            data: {
              url: content,
              duration,
              tests: testsPartial,
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <MediaInputFieldInternal
              inputLabeL={`${t("lesson.video-url")}:`}
              mediaLabel={`${t("lesson.preview")}:`}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>

          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <InputMaskComponent
              type="time"
              label={`${t("lesson.meta.duration")}:`}
              onChange={(e) => setDuration(e.target.value)}
              value={duration}
            />
          </div>

          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <Separator
              css={css`
                margin: 30px 0 !important;
              `}
            />
            <Headers.H4
              css={css`
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: bold;
              `}
            >
              {t("lesson.meta.tests")}:
            </Headers.H4>

            <EntitySelectionComponent
              value={testsPartial}
              onConfirm={(items) => {
                setTestsPartial(items);
              }}
              emptyMessage={t("lesson.meta.tests-empty")}
              onRemove={(index) => {
                setTestsPartial(testsPartial.filter((_, idx) => index !== idx));
              }}
              searchCallback={entityTestsSearchCallback}
            />
          </div>
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
