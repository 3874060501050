import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { getSelfAssessmentsSignUpPerformance } from "../../../actions/signUpSelfAssessments/getSelfAssessmentsSignUpPerformance";
import { Block } from "../../../components/Block";
import { Button } from "../../../components/Button";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import SelfAssessmentsPerformance from "../../../components/SelfAssessmentPerformance";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import { Row, Headers } from "../../../helpers/layout";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";

export function SignUpSelfAssessmentPerformance() {
  const { t } = useTranslation();

  const { data: selfAssessments, error: selfAssessmentsError } = useNewFetch(
    getSelfAssessmentsSignUpPerformance
  );

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  const tabsData = [
    {
      title: t("self-assessment.questions"),
      link: `/admin/self-assessment`,
    },
    {
      title: t("self-assessment.performance"),
      link: `/admin/self-assessment/performance`,
      forceHightlight: true,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("self-assessment.performance")}</title>
        </SEO>
        <Helmet title={t("self-assessment.performance")} />
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        {selfAssessments?.length ? (
          <div
            css={css`
              margin-top: 1rem;
            `}
          >
            <SelfAssessmentsPerformance selfAssessmentItems={selfAssessments} />
          </div>
        ) : (
          <Block>
            <Row justify="center">
              <Headers.H3
                css={css`
                  margin-bottom: 2rem;
                `}
              >
                {t("self-assessment.no_items")}
              </Headers.H3>
            </Row>
            <Row justify="center">
              <Link to={`/admin/self-assessment/new`}>
                <Button small>{t("actions.create")}</Button>
              </Link>
            </Row>
          </Block>
        )}
        <Footer />
      </Wrapper>
    </>
  );
}
