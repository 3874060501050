import { authorised } from "../../../request";
import { SignupField } from "../../../types/admin/SignupField";

export async function reorderSignupField(items: SignupField[], section: "campus" | "studio") {
  const API = authorised();

  return API.request<SignupField[]>({
    method: "PUT",
    url: `/api/enterprise/signup-field/${section}/reorder/`,
    data: { items: items },
  });
}
