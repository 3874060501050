import React, { useState } from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Column, Spacing, Row } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { Button } from "../../components/Button";
import { MenuItem } from "../../types/admin/MenuItem";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/Checkmark_white.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import { ModalDelete } from "../../modals/ModalDelete";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

export interface FormValues {
  name: string;
  url: string;
}

export function ChangeMenuItemForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
  onCancel: () => void;
  onDelete: () => void;
  data: MenuItem;
}) {
  const [deleteModal, setDeleteModal] = useState(false);

  const { t } = useTranslation();

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: props.data.name,
          url: props.data.url,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          url: Yup.string().required(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form
            noValidate
            css={css`
              padding-top: 5px;
            `}
          >
            <Column
              gutter={Spacing.xl}
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <InputField
                name={"name"}
                type="text"
                label={`${t("menu.name_label")}:`}
                errorBottom
                hideTooltip
              />

              <InputField
                name={"url"}
                type="text"
                label={`${t("menu.url_label")}:`}
                placeholder={`${t("menu.url_example")}: /courses`}
                errorBottom
                hideTooltip
              />

              <div
                css={css`
                  margin: 45px 0 20px 0;
                `}
              >
                <Row align="center" justify="space-between">
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!isValid}
                  >
                    <CheckIcon
                      width={16}
                      height={16}
                      css={css`
                        margin-right: 10px;
                      `}
                    />
                    {t("actions.save")}
                  </Button>
                  <div
                    onClick={props.onCancel}
                    css={css`
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <CloseIcon
                      width={16}
                      height={16}
                      css={css`
                        margin-right: 10px;
                      `}
                    />
                    {t("actions.cancel")}
                  </div>
                  <div
                    onClick={() => setDeleteModal(true)}
                    css={css`
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <DeleteIcon
                      width={16}
                      height={16}
                      css={css`
                        margin-right: 10px;
                      `}
                    />
                    {t("actions.delete")}
                  </div>
                </Row>
              </div>
            </Column>
          </Form>
        )}
      </Formik>
      <ModalDelete
        isOpen={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          console.log(deleteModal);
        }}
        onDelete={() => {
          if (!deleteModal) return;
          props.onDelete();
        }}
      />
    </div>
  );
}
