import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { Tabs } from "../../../components/Tabs";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { Button } from "../../../components/Button";
import { Separator } from "../../../components/Separator";
import { TextInCircle } from "../../../components/TextInCircle";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../components/Modal";
import { SEO } from "../../../components/SEO";
import { Headers, Row } from "../../../helpers/layout";
import { Test } from "../../../types/Test";
import { theme } from "../../../themes/variables";

import { useNewFetch } from "../../../useAPI";
import { getLesson } from "../../../actions/lesson";
import { getTests, deleteTest } from "../../../actions/test";
import { pageLoading } from "../../../helpers/pageLoading";

import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { Footer } from "../../../components/Footer";

const QuizGroup = (props: {
  title: string;
  hint?: React.ReactNode | React.ReactNode[];
  quizes: Test[];
  setData: (newData: Test[] | ((d: Test[] | null) => Test[] | null)) => void;
}) => {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const [deleteQuiz, setDeleteQuiz] = useState<null | Test>(null);

  if (!props.quizes.length) {
    return null;
  }

  const onDelete = async () => {
    const quiz = deleteQuiz;

    if (!quiz) return;

    try {
      await deleteTest({
        lessonId: params.lessonId,
        testId: quiz.uuid!,
      });

      props.setData((current) => {
        return current!.filter((q) => q.uuid !== quiz.uuid);
      });

      setDeleteQuiz(null);

      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <div
      css={css`
        margin-bottom: 2rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <TextInCircle>{props.quizes.length}</TextInCircle>
        <div>
          <div
            css={css`
              font-weight: bold;
            `}
          >
            {props.title}
          </div>
          {props.hint && (
            <div
              css={css`
                margin-top: 4px;
                font-size: 0.9rem;
              `}
            >
              {props.hint}
            </div>
          )}
        </div>
      </div>

      {props.quizes.map((quiz, idx) => {
        return (
          <Fragment key={idx}>
            <div
              css={css`
                padding: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div>
                <span
                  css={css`
                    font-weight: bold;
                    margin-right: 1rem;
                  `}
                >
                  {idx + 1}
                </span>

                <Link
                  to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/${quiz.uuid}/edit`}
                >
                  {quiz.internal_name}
                </Link>
              </div>

              <div>
                <Link
                  to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/${quiz.uuid}/performance`}
                >
                  <Button
                    small
                    css={css`
                      margin-right: 1rem;
                    `}
                    background={theme.colors.transparent}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  >
                    {t("lesson.quiz.perfomance")}
                  </Button>
                </Link>

                <Link
                  to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/${quiz.uuid}/edit`}
                >
                  <Button
                    small
                    css={css`
                      margin-right: 1rem;
                    `}
                    background={"transparent"}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  >
                    {t("actions.edit")}
                  </Button>
                </Link>

                <Button
                  small
                  background={theme.colors.transparent}
                  border={`1px solid ${theme.colors.error}`}
                  color={theme.colors.error}
                  hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
                  onClick={() => {
                    setDeleteQuiz(quiz);
                  }}
                >
                  {t("lesson.quiz.delete")}
                </Button>
              </div>
            </div>
            <Separator />
          </Fragment>
        );
      })}

      <div
        css={css`
          display: flex;
          flex-direction: row-reverse;
          margin-top: 1rem;
        `}
      >
        <Link
          to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/new`}
        >
          <Button small>{t("lesson.quiz.create")}</Button>
        </Link>
      </div>
      <Modal
        modalIsOpen={deleteQuiz !== null}
        onClose={() => setDeleteQuiz(null)}
        contentLabel={t("actions.sure")}
      >
        <ModalHeader closeIcon onClose={() => setDeleteQuiz(null)}>
          {t("actions.sure")}
        </ModalHeader>

        <ModalBody>{t("actions.no-undo")}</ModalBody>

        <ModalFooter>
          <Button
            background={theme.colors.white}
            color={theme.colors.dark}
            border={`1px solid ${theme.colors.dark}`}
            hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
            onClick={() => setDeleteQuiz(null)}
          >
            {t("actions.cancel")}
          </Button>

          <Button onClick={() => onDelete()}>{t("actions.delete")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export interface FormValues {
  video_url: string;
}

export function TestsOverviewPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const { data: lesson, error: lessonError } = useNewFetch(getLesson, {
    courseId: params.courseId,
    lessonId: params.lessonId,
  });

  const {
    data: tests,
    error: testsError,
    setData,
  } = useNewFetch(getTests, { lessonId: params.lessonId });

  if (!lesson || lessonError) {
    return pageLoading(lessonError);
  }

  if (!tests || testsError) {
    return pageLoading(testsError);
  }

  const {
    videoQuiz,
    repetitionQuiz,
    evaluationQuiz,
    all,
  }: {
    videoQuiz: Test[];
    repetitionQuiz: Test[];
    evaluationQuiz: Test[];
    all: Test[];
  } = tests.reduce(
    (
      data: {
        videoQuiz: Test[];
        repetitionQuiz: Test[];
        evaluationQuiz: Test[];
        all: Test[];
      },
      row
    ) => {
      if (row.in_video) {
        data.videoQuiz.push(row);
      }
      if (row.in_repetition) {
        data.repetitionQuiz.push(row);
      }
      if (row.in_exam) {
        data.evaluationQuiz.push(row);
      }

      data.all.push(row);

      return data;
    },
    {
      videoQuiz: [],
      repetitionQuiz: [],
      evaluationQuiz: [],
      all: [],
    }
  );

  const tabsData = [
    {
      title: t("breadcrumbs.content_editor"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/content_editor`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/edit`,
    },
    {
      title: t("breadcrumbs.testing"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/testing`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: lesson.course!.name,
      url: `/courses/${params.courseId}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{`${lesson.name} ${t("lesson.testing")}`}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{`${lesson.name}`}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {videoQuiz.length ||
          repetitionQuiz.length ||
          evaluationQuiz.length ||
          all.length ? (
            <div
              css={css`
                margin-top: 1rem;
              `}
            >
              <QuizGroup
                title={t("lesson.quiz.overview.video.title")}
                quizes={videoQuiz}
                setData={setData}
              />
              <QuizGroup
                title={t("lesson.quiz.overview.repetition.title")}
                quizes={repetitionQuiz}
                setData={setData}
              />
              <QuizGroup
                title={t("lesson.quiz.overview.evaluation.title")}
                quizes={evaluationQuiz}
                setData={setData}
              />
              <QuizGroup
                title={t("lesson.quiz.overview.all.title")}
                quizes={all}
                setData={setData}
              />
            </div>
          ) : (
            <>
              <Row justify="center">
                <Headers.H3
                  css={css`
                    margin-bottom: 2rem;
                  `}
                >
                  {t("lesson.quiz.no-quizes")}
                </Headers.H3>
              </Row>
              <Row justify="center">
                <Link
                  to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/new`}
                >
                  <Button>{t("actions.create")}</Button>
                </Link>
              </Row>
            </>
          )}
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
