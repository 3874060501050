import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { customToast } from "../customToast";
import { Button } from "../../components/Button";
import { FileUploadField } from "../../forms/fields/FileUploadField";
import { InputRepeater } from "../../forms/fields/InputRepeater";
import { InputField } from "../../forms/fields/InputField";
import { InputMaskField } from "../../forms/fields/InputMaskField";
import { updateLesson } from "../../actions/lesson";
import { updateFile } from "../../actions/file";
import { Lesson } from "../../types/Lesson";
import { AcceptedMimeTypes } from "../../types/File";
import { File, Transcript } from "../../types/File";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

interface FormValues {
  audio: File | null;
  transcript: Transcript[] | null;
}

export function ContentEditorPodcast(props: { lesson: Lesson }) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const validationSchema = Yup.object().shape({
    file: Yup.object().nullable(),
    transcript: Yup.array().of(
      Yup.object().shape({
        timecode: Yup.string()
          .required()
          .matches(
            /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
            t("lesson.quiz.error.timecode")
          ),
        content: Yup.string().required(),
      })
    ),
  });

  const inputsContainer = useRef<HTMLDivElement>(null);

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      if (values.audio) {
        await Promise.all([
          updateFile({
            fileId: values.audio?.uuid,
            title: values.audio.title,
            transcript: values.transcript,
          }),
          updateLesson(params.courseId, params.lessonId, {
            audio: values.audio || null,
          }),
        ]);
      } else {
        await updateLesson(params.courseId, params.lessonId, {
          audio: values.audio || null,
        });
      }

      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        audio: props.lesson.audio || null,
        transcript: props.lesson.audio ? props.lesson.audio.transcript : [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, dirty, isValid, values }) => {
        return (
          <Form
            noValidate
            css={css`
              display: grid;
              grid-template-columns: 50% 50%;
            `}
          >
            <div>
              <FileUploadField
                label={`${t("media.file")}:`}
                name={"audio"}
                acceptedMimeTypes={[
                  AcceptedMimeTypes.MP3,
                  AcceptedMimeTypes.MPEG,
                  AcceptedMimeTypes.WEBA,
                  AcceptedMimeTypes.WEBM,
                ]}
              />

              {values.audio && (
                <div ref={inputsContainer}>
                  <InputRepeater<string>
                    name="transcript"
                    label={t("lesson.podcast.transcript.title")}
                  >
                    {({ idx, arrayHelpers }) => {
                      return (
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            flex: 1 0 10%;
                          `}
                        >
                          <span
                            css={css`
                              font-weight: bold;
                              position: relative;
                              top: 0;
                              flex: 0 0 5%;
                            `}
                          >
                            {idx + 1}.
                          </span>

                          <InputMaskField
                            type="time"
                            name={`transcript[${idx}].timecode`}
                            css={css`
                              flex: 0 0 125px;
                              padding-right: 20px;
                              margin-bottom: 0;
                            `}
                          />

                          <InputField
                            css={css`
                              flex: 1;
                            `}
                            name={`transcript[${idx}].content`}
                            onkeydown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                arrayHelpers.push("");
                                setTimeout(() => {
                                  if (inputsContainer.current) {
                                    const tmpLastInput =
                                      inputsContainer.current.querySelector<HTMLInputElement>(
                                        ".input_repeater:last-of-type input"
                                      );

                                    if (tmpLastInput) {
                                      tmpLastInput.focus();
                                    }
                                  }
                                }, 10);
                              }
                            }}
                          ></InputField>
                        </div>
                      );
                    }}
                  </InputRepeater>
                </div>
              )}
            </div>

            <div
              css={css`
                margin-top: 25px;
                display: flex;
                align-self: flex-start;
                flex-direction: row-reverse;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                type="submit"
                disabled={!dirty || !isValid}
              >
                {t("actions.save")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
