import { authorised } from "../../../request";
import { Competency } from "../../../types/admin/Competency";

type CompetencyArgs = Omit<Competency, "id">

type Args = {
  id: number;
  data: CompetencyArgs;
}

export async function updateCompetency({id, data}: Args) {
  const API = authorised();

  return API.request<Competency>({
    method: "PUT",
    url: `/api/enterprise/competencies/${id}/`,
    data
  });
}
