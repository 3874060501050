import { authorised } from "../../../request";
import { Page } from "../../../types/admin/Page";

type PageArgs = Omit<Page, "uuid" | "created" | "author">

type Args = {
  data: PageArgs;
}

export async function createPage({data}: Args) {
  const API = authorised();

  return API.request<Page>({
    method: "POST",
    url: `/api/enterprise/pages/`,
    data
  });
}
