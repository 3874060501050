import React from "react";
import { css } from "styled-components/macro";
import { Check } from "react-feather";
import { theme } from "../../../../themes/variables";

export function LayoutItem(props: {
  items: string[];
  onClick: (value: string[]) => void;
  chosen: boolean;
}) {
  return (
    <div
      css={css`
        display: flex;
        width: 200px;
        border: 1px solid
          ${props.chosen ? theme.colors.dark : theme.colors.transparentBlack2};
        color: ${props.chosen
          ? theme.colors.dark
          : theme.colors.transparentBlack2};
        margin-bottom: 20px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
      `}
      onClick={() => props.onClick(props.items)}
    >
      {props.chosen && (
        <span
          css={css`
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: ${theme.colors.primary};
            border-radius: 50%;
            line-height: 0;
            padding: 3px;
          `}
        >
          <Check size={15} color={theme.colors.white} />
        </span>
      )}

      {props.items.map((item, idx) => {
        return (
          <div
            key={idx}
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 20px;
              width: ${item === "33%" ? "33.334%" : item};
              border-right: 1px solid
                ${props.chosen
                  ? theme.colors.dark
                  : theme.colors.transparentBlack2};
              :first-of-type {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              :last-of-type {
                border-right: none;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
              ${props.chosen &&
              `
                background: ${theme.colors.gray1};
              `}
            `}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
}
