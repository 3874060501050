import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { theme } from "../../../themes/variables";
import { EnterpriseAddress } from "../../../types/Enterprise";
import { Column } from "../../../helpers/layout";
import { InputField } from "../../fields/InputField";
import { Button } from "../../../components/Button";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { ReactComponent as AddressIcon } from "../../../assets/icons/Address.svg";

export type FormValues = EnterpriseAddress;

export default function EnterpriseAddressSettingsForm({
  data,
  onSubmit,
}: {
  data: EnterpriseAddress;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const onSubmitChanges = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        border-radius: 20px;
        background-color: ${theme.colors.white};
        width: 100%;
        padding: 65px 60px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          street_address: data.street_address,
          country: data.country,
          city: data.city,
          postal_code: data.postal_code,
        }}
        validationSchema={Yup.object().shape({
          street_address: Yup.string(),
          country: Yup.string(),
          city: Yup.string(),
          postal_code: Yup.string(),
        })}
        onSubmit={onSubmitChanges}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <div
              css={css`
                display: flex;
              `}
            >
              <Column
                css={css`
                  display: flex;
                  width: 80%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <AddressIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.address")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.street_address")}
                  name={"street_address"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.country")}
                  name={"country"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.city")}
                  name={"city"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.postal_code")}
                  name={"postal_code"}
                />
              </Column>
              <Column>
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                    css={css`
                      display: flex;
                      margin: auto 0 0 auto;
                      font-size: 16px;
                      line-height: 18px;
                    `}
                    icon={
                      <ReadIcon
                        width={16}
                        height={16}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    <div
                      css={css`
                        margin-right: 11px;
                      `}
                    >
                      {t("enterprise_settings.save")}
                    </div>
                  </Button>
                </div>
              </Column>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
