import React from "react";
import { VideoElementImage } from "../../../types/Video";
import { css } from "styled-components/macro";

export function VideoImageElement(props: {
  element: VideoElementImage;
  currentTime: number;
}) {
  const { element } = props;

  return (
    <div>
      <img
        src={element.image.image}
        alt=""
        css={css`
          width: 100%;
          height: auto;
          pointer-events: none;
        `}
      />
    </div>
  );
}
