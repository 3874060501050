import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

export async function getSignUpSelfAssessments() {
  const API = authorised();

  return API.request<SelfAssessment[]>({
    method: "GET",
    url: `/api/enterprise/self-assessments/`,
  });
}
