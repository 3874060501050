import React from "react";
import { Play, Pause, Minimize, Maximize } from "react-feather";
import styled, { css } from "styled-components/macro";
import Slider from "react-rangeslider";

const PlainButton = styled.button`
  background: none;
  border: none;
  padding: 5px;
  margin: 0;
  cursor: pointer;
`;

export function VideoControls(props: {
  videoDuration: number;
  currentTime: number;
  isPlaying: boolean;
  isFullscreen: boolean;
  onChangeTime: (time: number) => void;
  onPlay: () => void;
  onRestart: () => void;
  onMaximize?: () => void;
  onMinimize?: () => void;
}) {
  const { videoDuration, currentTime, isPlaying, isFullscreen } = props;

  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: 2;
      `}
    >
      <div
        css={css`
          position: relative;
        `}
      >
        <Slider
          min={0}
          max={videoDuration}
          value={currentTime}
          step={100}
          onChange={(value) => {
            props.onChangeTime(value);
          }}
          format={(value) => `${Math.floor(value / 1000)}s`}
        />
      </div>
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          padding: 2px;
        `}
      >
        <div
          css={css`
            margin-right: 10px;
          `}
        >
          {currentTime < videoDuration ? (
            <PlainButton
              onClick={() => {
                props.onPlay();
              }}
            >
              {isPlaying ? <Pause /> : <Play />}
            </PlainButton>
          ) : (
            <PlainButton
              onClick={() => {
                props.onRestart();
              }}
            >
              <Play />
            </PlainButton>
          )}
        </div>
        <div>
          {Math.floor(currentTime / 1000)}s / {Math.floor(videoDuration / 1000)}
          s
        </div>
        <div
          css={css`
            flex: 0 0 100px;
          `}
        ></div>
        <div
          css={css`
            flex: 1 0 1%;
          `}
        ></div>
        {props.onMaximize && props.onMinimize && (
          <div>
            {isFullscreen ? (
              <PlainButton onClick={props.onMinimize}>
                <Minimize />
              </PlainButton>
            ) : (
              <PlainButton onClick={props.onMaximize}>
                <Maximize />
              </PlainButton>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
