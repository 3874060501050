export enum QuestionType {
  TRUE_FALSE = "TRUE_FALSE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export type TestVariantAnswer = {
  image: string | null;
  is_correct: boolean;
  primary_text: string;
  secondary_text: string;
};

export type TestVariant = {
  answers?: TestVariantAnswer[];
  explanation: string;
  title?: string;
  id?: number;
};

export type Test = {
  in_exam: boolean;
  in_repetition: boolean;
  in_video: boolean;
  internal_name: string;
  is_required: boolean;
  question_type: QuestionType;
  uuid?: string;
  timecode?: string;
  variants?: TestVariant[];
};

export type TestVariantPerformance = {
  id: number;
  submissions: {
    [date: string]: {
      count: number;
      avg: number;
    };
  };
};

export type TestPerformance = Pick<Test, "uuid" | "internal_name"> & {
  variants: TestVariantPerformance[];
  total_submissions: number;
  avg_percent_passed_tests: number;
  avg_percent_passed_tests_first_attempt: number;
};
