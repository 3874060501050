import { authorised } from "../../request";
import { Menu } from "../../types/admin/Menu";

type Args = {
  type: string;
  location: string;
};

export async function getMenu({ type, location }: Args) {
  const API = authorised();

  return API.request<Menu>({
    method: "GET",
    url: `/api/enterprise/menus/${type}/${location}/`,
  });
}
