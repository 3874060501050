import { authorised } from "../../request";
import { Expert } from "../../types/Expert";

type Args = {
  expertId: number;
  data: Omit<Expert, "id">;
}

export async function updateExpert({data, expertId}: Args) {
  const API = authorised();

  return API.request({
    method: "PUT",
    url: `/api/studio/organisation/experts/${expertId}/`,
    data,
  });
}
