import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import {
  EditCreateJourney,
  FormValues,
} from "../../components/EditCreateJourney";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { createJourney } from "../../actions/journeys/createJourney";
import { Headers } from "../../helpers/layout";
import { Footer } from "../../components/Footer";

export function NewJourneyPage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    const response = await createJourney(data);

    history.push(`/admin/journeys/${response.data.uuid}/syllabus`);
  };

  const tabsData = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/admin/journeys/new`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("journeys.title"),
      url: "/admin/journeys",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("journeys.new-title")}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{t("journeys.new-title")}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateJourney onSubmitCallback={onSubmit} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
