import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link, RouteComponentProps, useParams } from "react-router-dom";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { customToast } from "../../../components/customToast";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { Headers } from "../../../helpers/layout";
import { SignupField } from "../../../types/admin/SignupField";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Wrapper } from "../../../components/Wrapper";
import { Block } from "../../../components/Block";
import { SignupDefaultFields } from "../../../components/Admin/Signup/SignupDefaultFields";
import { ReviewSignupField } from "../../../components/Admin/Signup/ReviewSignupField";
import { AddSignupField } from "../../../components/Admin/Signup/AddSignupField";
import { FormValues } from "../../../components/Admin/Signup/EditCreateSignupModal";

import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import {
  getSignupFields,
  createSignupField,
  updateSignupField,
  deleteSignupField,
  reorderSignupField,
} from "../../../actions/admin/signup";
import { Footer } from "../../../components/Footer";

export function EnterpriseSignupFieldsPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const params = useParams<{
    section: "campus" | "studio";
  }>();

  const {
    data: signupFields,
    error: signupFieldsError,
    refetch,
    setData,
  } = useNewFetch(getSignupFields, {
    section: params.section,
  });

  const onSubmit = useCallback(
    async (data: FormValues, uuid?: string) => {
      if (uuid) {
        await updateSignupField({
          data,
          uuid,
          section: params.section,
        });
      } else {
        await createSignupField({
          data,
          section: params.section,
        });
      }
      refetch();
    },
    [refetch, params.section]
  );

  const onDelete = useCallback(
    async (uuid: string) => {
      await deleteSignupField({
        uuid,
        section: params.section,
      });
      refetch();
    },
    [refetch, params.section]
  );

  const reorder = (
    list: SignupField[],
    startIndex: number,
    endIndex: number
  ): SignupField[] => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  if (!signupFields || signupFieldsError) {
    return pageLoading(signupFieldsError);
  }

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reordered = reorder(
      signupFields,
      result.source.index,
      result.destination.index
    );

    setData(reordered);

    try {
      await reorderSignupField(reordered, params.section);

      refetch();
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("signup_fields.title")}</title>
        </SEO>
        <Helmet title={t("signup_fields.title")} />
        <Header />
      </Wrapper>

      <Wrapper>
        <Block>
          <div
            css={css`
              max-width: 740px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 40px;
              `}
            >
              <Headers.H3
                css={css`
                  margin-right: 100px;
                `}
              >
                {t("signup_fields.header")}
              </Headers.H3>

              <Link to={`/admin/signup/campus`}>
                <div
                  css={css`
                    padding-bottom: 3px;
                    margin-right: 24px;
                    font-size: 16px;
                    line-height: 22px;
                    cursor: pointer;
                    width: fit-content;
                    color: ${theme.colors.gray5};

                    ${params.section === "campus" &&
                    css`
                      border-bottom: 3px solid ${theme.colors.primary};
                      color: ${theme.colors.dark};
                    `}
                  `}
                >
                  {t("header.navigation.admin.users.campus")}
                </div>
              </Link>

              <Link to={`/admin/signup/studio`}>
                <div
                  css={css`
                    padding-bottom: 3px;
                    margin-right: 24px;
                    font-size: 16px;
                    line-height: 22px;
                    color: ${theme.colors.gray5};
                    cursor: pointer;
                    width: fit-content;

                    ${params.section === "studio" &&
                    css`
                      border-bottom: 3px solid ${theme.colors.primary};
                      color: ${theme.colors.dark};
                    `}
                  `}
                >
                  {t("header.navigation.admin.users.studio")}
                </div>
              </Link>
            </div>

            <div
              css={css`
                margin-bottom: 20px;
              `}
            >
              <SignupDefaultFields />
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    css={css`
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    {signupFields.map((signupField, index) => (
                      <div
                        key={signupField.uuid}
                        css={css`
                          margin-bottom: 20px;
                          :last-of-type {
                            margin-bottom: 0;
                          }
                        `}
                      >
                        <ReviewSignupField
                          index={index}
                          signupField={signupField}
                          onSubmit={onSubmit}
                          onDelete={onDelete}
                        />
                      </div>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div
              css={css`
                margin-top: 50px;
              `}
            >
              <AddSignupField onSubmit={onSubmit} />
            </div>
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
