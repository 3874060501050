import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Field, FieldProps, useFormikContext } from "formik";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { InputErrors } from "./InputErrors";
import { formatDate } from "../../helpers/time";
import { InputLabel } from "../../components/InputLabel";

const CustomDatePicker = forwardRef<HTMLButtonElement>((props, ref) => {
  const { value, placeholder, className } = props as {
    value: string;
    placeholder: string;
    className?: string;
  };

  return (
    <button
      type="button"
      css={css`
        display: flex;
        justify-content: space-between;
        width: 260px;
        height: 40px;
        border: 1px solid ${theme.colors.gray4};
        background-color: ${theme.colors.transparent};
        border-radius: 20px;
        padding: 6px 20px;
        color: ${theme.colors.black};
        cursor: pointer;
        align-items: center;
      `}
      className={className}
      {...props}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            line-height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {(value && formatDate(value)) || placeholder}
        </div>

        <CalendarIcon
          width={20}
          height={18}
          color={theme.colors.primary}
          css={css`
            min-width: 14px;
            min-height: 14px;
            margin-left: 10px;
          `}
        />
      </div>
    </button>
  );
});

export default function DateField(props: {
  hideTooltip?: boolean;
  maxLength?: number;
  errorBottom?: boolean;
  name: string;
  id?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { validate, ...other } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={other.name} validate={validate} type="button">
      {({ field, meta }: FieldProps) => {
        return (
          <div className={other.className}>
            <InputLabel label={props.label} />

            <DatePicker
              {...field}
              {...other}
              selected={(field.value && new Date(field.value)) || null}
              onChange={(val: any) => {
                setFieldValue(field.name, val);
              }}
              customInput={<CustomDatePicker />}
            />

            {!other.hideTooltip && (
              <InputErrors
                currentLength={String(field.value).length}
                maxLength={other.maxLength}
                error={meta.error}
                touched={meta.touched}
              />
            )}
            {other.errorBottom && meta.error && meta.touched && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                  margin-left: 25px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
