import { DateTime } from "luxon";
import { PerformancePeriod } from "../actions/performance/getCoursePerformanceChart";
import { TFunction } from "i18next";

const getDayOfTheWeek = (dayIndex: number, t: TFunction) => {
  return (
    [
      t("day.monday"),
      t("day.tuesday"),
      t("day.wednesday"),
      t("day.thursday"),
      t("day.friday"),
      t("day.saturday"),
      t("day.sunday"),
    ][dayIndex] || ""
  );
};

export function getFormattedDate(
  date: string,
  period: PerformancePeriod,
  t: TFunction
) {
  const dateTime = DateTime.fromISO(date);

  if (period === PerformancePeriod.WEEK) {
    return getDayOfTheWeek(dateTime.weekday - 1, t);
  }

  return `${dateTime.toFormat("MM-dd")}`;
}
