import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { theme } from "../../../themes/variables";
import { EnterpriseDRM } from "../../../types/Enterprise";
import { Column } from "../../../helpers/layout";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/Settings.svg";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { Button } from "../../../components/Button";
import { SwitcherField } from "../../fields/SwitcherField";

export type FormValues = EnterpriseDRM;

export function EnterpriseDRMSettingsForm({
  data,
  onSubmit,
}: {
  data: EnterpriseDRM;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const onSubmitChanges = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        border-radius: 20px;
        background-color: ${theme.colors.white};
        width: 100%;
        padding: 65px 60px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          is_image_saving_disabled: data.is_image_saving_disabled,
          is_page_download_disabled: data.is_page_download_disabled,
          is_printing_disabled: data.is_printing_disabled,
          is_text_copying_disabled: data.is_text_copying_disabled,
        }}
        validationSchema={Yup.object().shape({
          is_image_saving_disabled: Yup.boolean(),
          is_page_download_disabled: Yup.boolean(),
          is_printing_disabled: Yup.boolean(),
          is_text_copying_disabled: Yup.boolean(),
        })}
        onSubmit={onSubmitChanges}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <div
              css={css`
                display: flex;
              `}
            >
              <Column
                css={css`
                  display: flex;
                  width: 80%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 32px;
                  `}
                >
                  <SettingsIcon
                    color={theme.colors.primary}
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.drm_title")}
                  </div>
                </div>
                <SwitcherField
                  name="is_text_copying_disabled"
                  showStatus
                  label={t("enterprise_settings.text_protect")}
                  hint={t("enterprise_settings.text_protect_hint")}
                />

                <SwitcherField
                  name="is_image_saving_disabled"
                  showStatus
                  label={t("enterprise_settings.image_protect")}
                  hint={t("enterprise_settings.image_protect_hint")}
                />

                <SwitcherField
                  name="is_page_download_disabled"
                  showStatus
                  label={t("enterprise_settings.page_protect")}
                  hint={t("enterprise_settings.page_protect_hint")}
                />

                <SwitcherField
                  name="is_printing_disabled"
                  showStatus
                  label={t("enterprise_settings.print_protect")}
                  hint={t("enterprise_settings.print_protect_hint")}
                />
              </Column>
              <Column>
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                    css={css`
                      display: flex;
                      margin: auto 0 0 auto;
                      font-size: 16px;
                      line-height: 18px;
                    `}
                    icon={<ReadIcon color={theme.colors.white} />}
                    iconRight
                  >
                    <div
                      css={css`
                        margin-right: 11px;
                      `}
                    >
                      {t("enterprise_settings.save")}
                    </div>
                  </Button>
                </div>
              </Column>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
