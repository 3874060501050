import { curry } from "lodash";

export function hexToRgb(hex: string) {
  let bigint = 0;

  if (hex.match("#")) {
    bigint = parseInt(hex.slice(1), 16);
  } else {
    bigint = parseInt(hex, 16);
  }

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

const getColorByIndex = (colors: string[], index: number) => {
  return colors[index % colors.length];
};

export const getColorByIndexCurry = curry(getColorByIndex);

export const getBrandedColorByIndex = getColorByIndexCurry([
  "#F9EFE0",
  "#E5E1EE",
  "#FAE7D9",
  "#FAC39C",
  "#C8B0FF",
  "#F6812F",
]);

export const getChartColorByIndex = getColorByIndexCurry([
  "#A47AFF",
  "#E95B2E",
  "#F8B856",
  "#1566CA",
  "#2C5104",
  "#62139F",
  "#E1523B",
  "#FF9129",
  "#730411",
]);
