import { authorised } from "../../../request";
import { EnterpriseDRM } from "../../../types/Enterprise";

export async function updateEnterpriseDRMSettings(
  data: EnterpriseDRM
) {
  const API = authorised();

  return await API.request<EnterpriseDRM>({
    method: "PUT",
    url: `/api/enterprise/settings/drm/`,
    data,
  });
}
