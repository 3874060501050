import { authorised } from "../../request";
import { Course } from "../../types/Course";
import { RequiredDataType } from "../../types/RequiredDataType";

export type IUpdateCourse = Pick<
  Course,
  "name" | "description" | "learning_points" | "level"
> & {
  faq_items: RequiredDataType[];
};

export async function updateCourse(courseId: string, data: IUpdateCourse) {
  const API = authorised();

  return API.request<Course>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/`,
    data,
  });
}
