import { RequiredDataType } from "../../types/RequiredDataType";
import { authorised } from "../../request";
import { Journey } from "../../types/Journey";

export type CreateJourney = Pick<
  Journey,
  "name" | "description" | "image" | "subtitle"
> & {
  faq_items: RequiredDataType[];
  user_segments: RequiredDataType[];
};

export async function createJourney(data: CreateJourney) {
  const API = authorised();

  return API.request<Journey>({
    method: "POST",
    url: `/api/enterprise/journeys/`,
    data,
  });
}
