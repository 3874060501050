import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Draggable } from "react-beautiful-dnd";
import { theme } from "../../../themes/variables";
import { InputComponent } from "../../../components/InputComponent";
import { TextareaComponent } from "../../../components/TextareaComponent";
import { SelectComponent } from "../../../components/SelectComponent";
import { RadioComponents } from "../../../components/RadioComponents";
import { CheckboxComponents } from "../../../components/CheckboxComponents";
import { ActionModal } from "../../../components/ActionModal";
import { EditCreateSignupModal, FormValues } from "./EditCreateSignupModal";
import { SignupFieldType, SignupField } from "../../../types/admin/SignupField";

import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { ReactComponent as MoveIcon } from "../../../assets/icons/Move.svg";

export function ReviewSignupField(props: {
  signupField: SignupField;
  onSubmit: (values: FormValues, uuid: string) => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
  index: number;
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <Draggable draggableId={props.signupField.uuid} index={Number(props.index)}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          css={css`
            padding: 40px;
            background: ${theme.colors.gray1};
            border-radius: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                flex: 0 1 100%;
              `}
            >
              {props.signupField.type === SignupFieldType.TEXT_FIELD && (
                <InputComponent
                  label={`${props.signupField.name}:${
                    +props.signupField.required ? "*" : ""
                  }`}
                  placeholder={props.signupField.placeholder || ""}
                  hint={props.signupField.hint || ""}
                />
              )}

              {props.signupField.type === SignupFieldType.TEXT_AREA && (
                <TextareaComponent
                  label={`${props.signupField.name}:${
                    +props.signupField.required ? "*" : ""
                  }`}
                  placeholder={props.signupField.placeholder || ""}
                  hint={props.signupField.hint || ""}
                />
              )}

              {props.signupField.type === SignupFieldType.SELECT && (
                <SelectComponent
                  choices={props.signupField.options.map((el) => {
                    return {
                      label: el.value,
                      value: el.value,
                    };
                  })}
                  label={`${props.signupField.name}:${
                    +props.signupField.required ? "*" : ""
                  }`}
                  hint={props.signupField.hint || ""}
                />
              )}

              {props.signupField.type === SignupFieldType.RADIO && (
                <RadioComponents
                  value={
                    props.signupField.options.length > 0
                      ? props.signupField.options[0].value
                      : null
                  }
                  choices={props.signupField.options.map((el) => {
                    return {
                      label: el.value,
                      value: el.value,
                    };
                  })}
                  label={`${props.signupField.name}:${
                    +props.signupField.required ? "*" : ""
                  }`}
                  labelRight
                  hint={props.signupField.hint || ""}
                />
              )}

              {props.signupField.type === SignupFieldType.CHECKBOX && (
                <CheckboxComponents
                  value={null}
                  choices={props.signupField.options.map((el) => {
                    return {
                      label: el.value,
                      value: el.value,
                    };
                  })}
                  label={`${props.signupField.name}:${
                    +props.signupField.required ? "*" : ""
                  }`}
                  hint={props.signupField.hint || ""}
                />
              )}
            </div>

            <div
              css={css`
                display: flex;
                flex: 1 0 140px;
                justify-content: flex-end;
              `}
            >
              <div>
                <button
                  css={css`
                    padding: 0;
                    margin: 0;
                    background: ${theme.colors.transparent};
                    outline: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 20px;
                    :hover svg {
                      color: ${theme.colors.primary};
                    }
                  `}
                  onClick={() => setIsOpen(true)}
                >
                  <EditIcon width={18} height={18} color={theme.colors.dark} />
                </button>
              </div>

              <div>
                <button
                  css={css`
                    padding: 0;
                    margin: 0;
                    background: ${theme.colors.transparent};
                    outline: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 20px;
                    :hover svg {
                      color: ${theme.colors.error};
                    }
                  `}
                  onClick={() => setDeleteModal(true)}
                >
                  <DeleteIcon
                    width={18}
                    height={18}
                    color={theme.colors.dark}
                  />
                </button>
              </div>

              <div>
                <button
                  {...provided.dragHandleProps}
                  css={css`
                    padding: 0;
                    margin: 0;
                    background: ${theme.colors.transparent};
                    outline: none;
                    border: none;
                    cursor: pointer;
                    margin-left: 20px;
                    :hover svg {
                      color: ${theme.colors.darkGreenHover};
                    }
                    :active svg {
                      color: ${theme.colors.darkGreenFocus};
                    }
                  `}
                >
                  <MoveIcon width={18} height={18} color={theme.colors.dark} />
                </button>
              </div>
            </div>

            <EditCreateSignupModal
              label={t("signup_fields.edit")}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              signupField={props.signupField}
              onSubmit={(e) => props.onSubmit(e, props.signupField.uuid)}
            />

            <ActionModal
              deleteModal={deleteModal}
              setActionModal={(modal: boolean) => setDeleteModal(modal)}
              contentBody={t("actions.no-undo")}
              actionButtonText={t("actions.delete")}
              onConfirm={() => props.onDelete(props.signupField.uuid)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}
