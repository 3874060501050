import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
  selfAssessmentId: string;
};

export async function getSelfAssessment({ courseId, selfAssessmentId }: Args) {
  const API = authorised();

  return API.request<SelfAssessment>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/self-assessments/${selfAssessmentId}/`,
  });
}
