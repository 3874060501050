import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../../components/customToast";
import { useHistory, useParams } from "react-router-dom";
import { theme } from "../../../themes/variables";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Button } from "../../../components/Button";
import { DangerZone } from "../../../components/DangerZone";
import { ActionModal } from "../../../components/ActionModal";
import {
  EditCreateUserSegment,
  FormValues,
} from "../../../components/Admin/UserSegment/EditCreateUserSegment";

import { useNewFetch } from "../../../useAPI";
import { pageLoading } from "../../../helpers/pageLoading";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import {
  getUserSegment,
  updateUserSegment,
  deleteUserSegment,
} from "../../../actions/admin/userSegment";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { Footer } from "../../../components/Footer";

export function UserSegmentEditPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    userSegmentId: string;
  }>();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const { data: userSegment, error: userSegmentError } = useNewFetch(
    getUserSegment,
    {
      id: params.userSegmentId,
    }
  );

  if (!userSegment || userSegmentError) {
    return pageLoading(userSegmentError);
  }

  const onSubmit = async (data: FormValues) => {
    await updateUserSegment({ id: +params.userSegmentId, data: data });

    history.push(`/admin/user-segment`);
  };

  const deleteModalOnConfirm = async () => {
    try {
      await deleteUserSegment({ id: +params.userSegmentId });

      setDeleteModal(false);

      history.push(`/admin/user-segment`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("user_segment.edit")}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateUserSegment
            initialData={userSegment}
            onSubmitCallback={onSubmit}
            title={t("user_segment.edit")}
          />

          <DangerZone
            css={css`
              margin-top: 32px;
            `}
          >
            <Button
              onClick={() => setDeleteModal(true)}
              icon={
                <DeleteIcon width={14} height={14} color={theme.colors.white} />
              }
              iconRight
            >
              {t("user_segment.delete")}
            </Button>

            <ActionModal
              deleteModal={deleteModal}
              setActionModal={(modal: boolean) => setDeleteModal(modal)}
              contentBody={t("actions.no-undo")}
              actionButtonText={t("actions.delete")}
              onConfirm={deleteModalOnConfirm}
            />
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
