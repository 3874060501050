import { authorised } from "../../../request";
import { PublishRequest } from "../../../types/admin/PublishRequest";
import { CoursePublishRequestStatus } from "../../../types/Course";

type PublishRequestArgs = {
  status: CoursePublishRequestStatus;
  rejection_reason?: string;
};

type Args = {
  id: string | number;
  data: PublishRequestArgs;
}

export async function updatePublishRequest({id, data}: Args) {
  const API = authorised();

  return API.request<PublishRequest>({
    method: "PUT",
    url: `/api/enterprise/publish-request/${id}/`,
    data
  });
}
