import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getEnterpriseBillingSettings } from "../../../actions/admin/settings/getEnterpriseBillingSettings";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import EnterpriseBillingSettingsForm from "../../../forms/admin/settings/EnterpriseBillingSettingsForm";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { FormValues } from "../../../forms/admin/settings/EnterpriseBillingSettingsForm";
import { updateEnterpriseBillingSettings } from "../../../actions/admin/settings/updateEnterpriseBillingSettings";
import { customToast } from "../../../components/customToast";
import { Footer } from "../../../components/Footer";

export default function EnterpriseBillingSettings() {
  const { t } = useTranslation();

  const {
    data: billingSettings,
    error: billingSettingsError,
    setData,
  } = useNewFetch(getEnterpriseBillingSettings);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const response = await updateEnterpriseBillingSettings(values);

      customToast.success(t("status.success"));
      setData(response.data);
    },
    [setData, t]
  );

  if (!billingSettings || billingSettingsError) {
    return pageLoading(billingSettingsError);
  }

  const tabsData = [
    {
      title: t("enterprise_settings.general"),
      link: `/admin/settings/general`,
    },
    {
      title: t("enterprise_settings.address"),
      link: `/admin/settings/address`,
    },
    {
      title: t("enterprise_settings.billing"),
      link: `/admin/settings/billing`,
      forceHightlight: true,
    },
    {
      title: t("enterprise_settings.contact"),
      link: `/admin/settings/contact`,
    },
    {
      title: "DRM",
      link: `/admin/settings/drm`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("enterprise_settings.billing")}</title>
        </SEO>
        <Helmet title={t("enterprise_settings.billing")} />
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <EnterpriseBillingSettingsForm
          data={billingSettings}
          onSubmit={onSubmit}
        />
        <Footer />
      </Wrapper>
    </>
  );
}
