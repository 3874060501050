import React from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Button } from "../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../components/Modal";

export function ModalDelete(props: {
  onDelete: () => void;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={t("actions.sure")}
    >
      <ModalHeader closeIcon onClose={props.onClose}>
        {t("actions.sure")}
      </ModalHeader>

      <ModalBody>{t("actions.no-undo")}</ModalBody>

      <ModalFooter>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
          onClick={props.onClose}
        >
          {t("actions.cancel")}
        </Button>

        <Button onClick={props.onDelete}>{t("actions.delete")}</Button>
      </ModalFooter>
    </Modal>
  );
}
