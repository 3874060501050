import { authorised } from "../../request";
import { MenuMenuItem } from "../../types/admin/MenuMenuItem";

export async function updateMenuMenuItemOrder(data: {
  menuId: string;
  items: MenuMenuItem[];
}) {
  const API = authorised();

  return API.request<MenuMenuItem[]>({
    method: "PUT",
    url: `/api/enterprise/menus/${data.menuId}/reorder/`,
    data: {
      items: data.items,
    },
  });
}
