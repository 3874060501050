import { authorised } from "../../request";
import { LessonFeedback } from "../../types/LessonFeedback";

export async function processStudioFeedback(
  uuid: string,
  data: Pick<LessonFeedback, "is_read">
) {
  const API = authorised();

  return await API.request<Pick<LessonFeedback, "is_read">>({
    method: "PUT",
    url: `/api/studio/feedback/${uuid}/process/`,
    data,
  });
}
