import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { CheckboxComponent } from "./CheckboxComponent";
import { Hint } from "./Hint";

export interface CheckboxOption {
  label: string;
  value: string;
}

export function CheckboxComponents(props: {
  name?: string;
  label: string;
  hint?: string;
  choices: CheckboxOption[];
  value: string | null;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div>
      <div
        css={css`
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: bold;
        `}
      >
        {props.label}
      </div>

      {props.choices.map((choice, idx) => (
        <CheckboxComponent
          key={idx}
          label={choice.label}
          name={props.name}
          labelRight={props.labelRight}
          disabled={props.disabled}
          readonly={props.readonly}
          value={choice.value === props.value}
          className={props.className}
          onChange={props.onChange}
          css={css`
            margin-right: 2rem;
            margin-bottom: 1rem;
            :last-of-type {
              margin-right: 0;
            }
          `}
        ></CheckboxComponent>
      ))}

      {props.hint && <Hint>{props.hint}</Hint>}
    </div>
  );
}
