import styled, { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export const FormSidebar = styled.div`
  width: 340px;
  padding: 44px;
  box-sizing: border-box;
  border-radius: 20px;
  background: ${theme.colors.gray1};

  hr {
    margin: 20px 0 !important;
  }
`;

export const FormParent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormMain = styled.div`
  flex: 0 0 50%;
`;

export function FormSidebarContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex: 0 0 340px;
        align-items: flex-end;
      `}
    >
      <div
        css={css`
          bottom: 60px;
          position: sticky;
        `}
      >
        {children}
      </div>
    </div>
  );
}
