import { authorised } from "../../../request";
import { SliderOrdered } from "../../../types/admin/Slider";

export async function reorderSliders(items: SliderOrdered[]) {
  const API = authorised();

  return API.request<SliderOrdered[]>({
    method: "PUT",
    url: `/api/enterprise/sliders/reorder/`,
    data: { items: items },
  });
}
