import { authorised } from "../../request";
import { Lesson } from "../../types/Lesson";

export async function deleteLesson(courseId: string, lessonId: string) {
  const API = authorised();

  return API.request<Lesson>({
    method: "DELETE",
    url: `/api/studio/courses/${courseId}/lessons/${lessonId}/`,
  });
}
