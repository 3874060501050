import styled, { css } from "styled-components/macro";
import { useEnterprise } from "../contexts/EnterpriseContext";
import { Link } from "react-router-dom";

export function NavigationFooter(props: {}) {
  const { enterprise } = useEnterprise();

  return (
    <Nav>
      <ul
        css={css`
          flex-direction: "row";

          li {
            &:after {
              content: "|";
            }

            &:last-child:after {
              content: "";
            }
          }
        `}
      >
        {enterprise.studio_footer_menu &&
          enterprise.studio_footer_menu.items.map((item) => {
            if (item.item.url.match(/^http/)) {
              return (
                <li key={item.id}>
                  <a target="_blank" rel="noreferrer" href={item.item.url}>
                    {item.item.name}
                  </a>
                </li>
              );
            }

            return (
              <li key={item.id}>
                <Link to={item.item.url}>{item.item.name}</Link>
              </li>
            );
          })}
      </ul>
    </Nav>
  );
}

const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
  }

  a {
    padding: 0 20px;
    text-decoration: none;

    :hover {
      font-weight: 600;
    }
  }
`;
