import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Tab, Tabs } from "../../../components/Tabs";
import {
  EditCreateCompetency,
  FormValues,
} from "../../../components/Admin/Competency/EditCreateCompetency";
import { createCompetency } from "../../../actions/admin/competency";
import { Footer } from "../../../components/Footer";

export function CompetencyCreatePage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    await createCompetency({ data });

    history.push(`/admin/competencies`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.competency.title"),
      link: `/admin/competencies`,
      forceHightlight: true,
    },
    {
      title: t("admin.skill.title"),
      link: "/admin/skills",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.competency.new-competency")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateCompetency
            onSubmitCallback={onSubmit}
            title={t("admin.competency.new-competency")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
