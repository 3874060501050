import { authorised } from "../../../request";
import { EnterpriseBilling } from "../../../types/Enterprise";

export async function updateEnterpriseBillingSettings(
  settings: EnterpriseBilling
) {
  const API = authorised();

  return await API.request<EnterpriseBilling>({
    method: "PUT",
    url: `/api/enterprise/settings/billing/`,
    data: settings,
  });
}
