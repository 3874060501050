import React from "react";
import { css } from "styled-components/macro";
import { Check, X, AlertCircle } from "react-feather";
import { theme } from "../../themes/variables";
import { Tooltip } from "../../components/Tooltip";

export function InputErrors(props: {
  error?: string;
  touched?: boolean;
  haveHint?: boolean;
  maxLength?: number;
  currentLength?: number;
  className?: string;
}) {
  const { error, touched, maxLength, currentLength, className, haveHint } =
    props;
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        position: relative;
      `}
    >
      {error && touched && (
        <div
          css={css`
            cursor: pointer;
          `}
        >
          <Tooltip
            text={error}
            className={className}
            css={css`
              position: absolute;
              top: ${haveHint ? "-49px" : "-18px"};
              transform: translate(0, -50%);
              right: 0;
            `}
          >
            <AlertCircle size={18} color={theme.colors.primary} />
          </Tooltip>
        </div>
      )}

      {maxLength && (
        <div
          css={css`
            color: ${error && touched
              ? theme.colors.redMain
              : theme.colors.greenMain};
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: auto;
            font-size: 0.9rem;
            font-weight: bold;
            padding-top: 8px;
          `}
        >
          {error && touched ? (
            <X
              color={theme.colors.redMain}
              size={18}
              css={css`
                padding-right: 6px;
              `}
            />
          ) : (
            <Check
              color={theme.colors.greenMain}
              size={18}
              css={css`
                padding-right: 6px;
              `}
            />
          )}
          {currentLength} / {maxLength}
        </div>
      )}
    </div>
  );
}
