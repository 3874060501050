import React, { useState, useRef, useEffect } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Trash2, Plus } from "react-feather";
import { CustomPodcastBlock } from "../../../../types/Block";
import {
  File as FileType,
  Transcript,
  AcceptedMimeTypes,
} from "../../../../types/File";
import { theme } from "../../../../themes/variables";
import { Button } from "../../../Button";
import { InputComponent } from "../../../InputComponent";
import { InputMaskComponent } from "../../../InputMaskComponent";
import { AudioPlayer } from "../../../AudioPlayer";
import { Row, Headers, Column, Spacing } from "../../../../helpers/layout";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { FileHandler } from "../components/FileHandler";
import { BlockControls } from "../components/BlockControls";

export function BlockPodcast(props: {
  block: CustomPodcastBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomPodcastBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const { t } = useTranslation();

  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [content, setContent] = useState<FileType | null>(
    props.block.data.audio || null
  );

  const [duration, setDuration] = useState(
    props.block.data.duration || "00:00:00"
  );

  const [transcript, setTranscript] = useState<Transcript[]>(
    props.block.data.audio?.transcript || []
  );

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const transcriptsContainer = useRef<HTMLDivElement>(null);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setContent(props.block.data.audio || null);
    setDuration(props.block.data.duration || "00:00:00");
  };

  const addTranscript = () => {
    setTranscript((prev) => {
      return prev.concat([{ content: "", timecode: "" }]);
    });
  };

  const removeTranscript = (index: number) => {
    setTranscript((prev) => {
      return prev.filter((_, idx) => idx !== index);
    });
  };

  const onChangeTranscriptTimecode = (value: string, index: number) => {
    setTranscript((prev) => {
      return prev.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            timecode: value,
          };
        }

        return el;
      });
    });
  };

  const onChangeTranscriptContent = (value: string, index: number) => {
    setTranscript((prev) => {
      return prev.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            content: value,
          };
        }

        return el;
      });
    });
  };

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.audio && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            {(block.data.audio.mime_type === AcceptedMimeTypes.MP3 ||
              block.data.audio.mime_type === AcceptedMimeTypes.MPEG ||
              block.data.audio.mime_type === AcceptedMimeTypes.WEBA ||
              block.data.audio.mime_type === AcceptedMimeTypes.WEBM) && (
              <AudioPlayer
                src={block.data.audio.file}
                title={block.data.audio.title || block.data.audio.upload_name}
              />
            )}
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block}
        header={
          <div>
            <span
              css={css`
                color: ${theme.colors.primary};
                margin-right: 12px;
              `}
            >
              Aa
            </span>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
        onCancel={onCancel}
        onConfirm={async (value) => {
          const updatedData: CustomPodcastBlock = {
            ...value,
            data: {
              audio: content
                ? {
                    ...content,
                    transcript,
                  }
                : null,
              duration,
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div>
            <FileHandler
              value={content}
              removeFile={() => setContent(null)}
              onSelect={(file) => {
                setContent(file);
              }}
              acceptedMimeTypes={[
                AcceptedMimeTypes.MP3,
                AcceptedMimeTypes.MPEG,
                AcceptedMimeTypes.WEBA,
                AcceptedMimeTypes.WEBM,
              ]}
            />

            <div
              css={css`
                margin-bottom: 1rem;
              `}
            >
              <InputMaskComponent
                type="time"
                label={t("lesson.meta.duration")}
                onChange={(e) => setDuration(e.target.value)}
                value={duration}
              />
            </div>

            {content && (
              <Column
                gutter={Spacing.m}
                className="input_repeater_container"
                ref={transcriptsContainer}
              >
                <div>{t("lesson.podcast.transcript.title")}</div>

                {transcript.map((val, index) => (
                  <div key={index} className="input_repeater">
                    <Row align={"center"} gutter={Spacing.m}>
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                          flex: 1 0 10%;
                        `}
                      >
                        <span
                          css={css`
                            font-weight: bold;
                            position: relative;
                            top: 0;
                            flex: 0 0 5%;
                          `}
                        >
                          {index + 1}.
                        </span>

                        <InputMaskComponent
                          type="time"
                          value={val.timecode}
                          onChange={(e) =>
                            onChangeTranscriptTimecode(e.target.value, index)
                          }
                          css={css`
                            flex: 0 0 125px;
                            padding-right: 20px;
                            margin-bottom: 0;
                          `}
                        />

                        <div
                          css={css`
                            flex: 1;
                          `}
                        >
                          <InputComponent
                            value={val.content}
                            onChange={(e) =>
                              onChangeTranscriptContent(e.target.value, index)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                addTranscript();
                                setTimeout(() => {
                                  if (transcriptsContainer.current) {
                                    const tmpLastInput =
                                      transcriptsContainer.current.querySelector<HTMLInputElement>(
                                        ".input_repeater:last-of-type input"
                                      );

                                    if (tmpLastInput) {
                                      tmpLastInput.focus();
                                    }
                                  }
                                }, 10);
                              }
                            }}
                          />
                        </div>
                      </div>

                      <button
                        name="input_repeater_remove"
                        title={t("actions.delete")}
                        css={css`
                          padding: 0;
                          margin: 0;
                          cursor: pointer;
                          white-space: nowrap;
                          border: 0;
                          text-transform: none;
                          -webkit-appearance: none;
                          outline: none;
                          background: transparent;
                          position: relative;
                          padding-left: 20px;
                          :hover {
                            color: ${theme.colors.error};
                          }
                        `}
                        type="button"
                        onClick={() => removeTranscript(index)}
                      >
                        <Trash2 />
                      </button>
                    </Row>
                  </div>
                ))}

                <Button
                  name="add_input"
                  onClick={addTranscript}
                  icon={<Plus size={14} />}
                  type={"button"}
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
                  css={css`
                    align-self: flex-end;
                    width: auto;
                  `}
                >
                  {t("actions.add")}
                </Button>
              </Column>
            )}
          </div>
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
