import React, { useState, useRef, useEffect } from "react";

export default function CountAnimation({
  end,
  prefix,
  suffix,
}: {
  end: number;
  prefix?: string;
  suffix?: string;
}) {
  const val = useRef(0);
  const [state, setState] = useState(0);

  const steps = 100;
  const animationDuration = 1000;

  useEffect(() => {
    const step = (end - val.current) / steps;

    const updateCountState = () => {
      const positiveStep = step > 0;
      const reachedEnd = positiveStep ? val.current >= end : val.current <= end;

      if (!reachedEnd) {
        setTimeout(() => {
          let newVal = val.current + step;

          if (positiveStep && newVal > end) {
            newVal = end;
          } else if (!positiveStep && newVal < end) {
            newVal = end;
          }

          setState(newVal);
          val.current = newVal;
          updateCountState();
        }, animationDuration / steps);
      }
    };

    updateCountState();
  }, [end]);

  return (
    <>
      {prefix}
      {Math.ceil(state)}
      {suffix}
    </>
  );
}
