import { authorised } from "../../request";
import { SelfAssessmentPerformance } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
};

export async function getSelfAssessmentsCoursePerformance({ courseId }: Args) {
  const API = authorised();

  return API.request<SelfAssessmentPerformance[]>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/self-assessments/performance/`,
  });
}
