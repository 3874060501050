import React from "react";
import { Field, FieldArray, FieldProps, FieldArrayRenderProps } from "formik";
import { Trash2, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import { Row, Spacing, Column } from "../../helpers/layout";
import { css } from "styled-components/macro";
import { Button } from "../../components/Button";
import { theme } from "../../themes/variables";
import { InputLabel } from "../../components/InputLabel";

export function InputRepeater<T>(props: {
  label?: string | undefined;
  secondLabel?: string | undefined;
  name: string;
  children: (props: {
    idx: number;
    value: T;
    arrayHelpers: FieldArrayRenderProps;
  }) => React.ReactNode;
  defaultValue?: T;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Field
        name={props.name}
        render={({ field }: FieldProps) => {
          const values = field.value || [];
          return (
            <FieldArray
              name={props.name}
              render={(arrayHelpers) => (
                <Column
                  gutter={Spacing.none}
                  className="input_repeater_container"
                >
                  <div
                    css={css`
                      display: flex;
                    `}
                  >
                    {props.label && (
                      <div
                        css={css`
                          flex: 0 0 48%;
                        `}
                      >
                        <InputLabel label={props.label} />
                      </div>
                    )}
                    {props.secondLabel && (
                      <div
                        css={css`
                          font-size: 16px;
                          font-weight: bold;
                        `}
                      >
                        {props.secondLabel}
                      </div>
                    )}
                  </div>
                  {values.map((val: T, index: number) => (
                    <div
                      key={index}
                      className="input_repeater"
                      css={css`
                        margin-bottom: 16px;
                        :last-of-type {
                          margin-bottom: 0px;
                        }
                      `}
                    >
                      <Row align={"center"} gutter={Spacing.m}>
                        {props.children({
                          idx: index,
                          value: val,
                          arrayHelpers,
                        })}

                        <button
                          name="input_repeater_remove"
                          title={t("actions.delete")}
                          css={css`
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            white-space: nowrap;
                            border: 0;
                            text-transform: none;
                            -webkit-appearance: none;
                            outline: none;
                            background: transparent;
                            position: relative;
                            padding-left: 20px;
                            :hover svg {
                              color: ${theme.colors.error};
                            }
                          `}
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <Trash2 />
                        </button>
                      </Row>
                    </div>
                  ))}

                  <Button
                    name="add_input"
                    onClick={() => arrayHelpers.push(props.defaultValue || "")}
                    icon={<Plus size={14} />}
                    type={"button"}
                    background={theme.colors.white}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                    css={css`
                      margin-top: 16px;
                      align-self: flex-end;
                      width: auto;
                    `}
                  >
                    {t("actions.add")}
                  </Button>
                </Column>
              )}
            ></FieldArray>
          );
        }}
      />
    </div>
  );
}
