import { authorised } from "../../request";
import { Test } from "../../types/Test";

type Args = {
  lessonId: string;
  testId: string;
};  

export async function getTest({lessonId, testId}: Args) {
  const API = authorised();

  return API.request<Test>({
    method: "GET",
    url: `/api/studio/lessons/${lessonId}/tests/${testId}/`,
  });
}
