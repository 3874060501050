import { authorised } from "../../request";
import { MenuItem } from "../../types/admin/MenuItem";

export async function updateMenuItem(
  menuItemId: string,
  data: {
    url: string;
    name: string;
  }
) {
  const API = authorised();

  return await API.request<MenuItem>({
    method: "PUT",
    url: `/api/enterprise/menu-item/${menuItemId}/`,
    data,
  });
}
