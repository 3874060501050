import { authorised } from "../../request";
import { Lesson } from "../../types/Lesson";
import { RequiredDataType } from "../../types/RequiredDataType";

export async function createLesson(
  courseId: string,
  data: Partial<Omit<Lesson, "uuid" | "tags" | "instructors">> & {
    instructors: RequiredDataType[];
  }
) {
  const API = authorised();

  return API.request<Lesson>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/lessons/`,
    data,
  });
}
