import { authorised } from "../../request";
import { Journey } from "../../types/Journey";

export async function addJourneyCourses(journeyId: string, courses: string[]) {
  const API = authorised();

  return API.request<Journey>({
    method: "POST",
    url: `/api/enterprise/journeys/${journeyId}/courses/`,
    data: {
      courses,
    },
  });
}
