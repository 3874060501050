import { authorised } from "../../../request";
import { Competency } from "../../../types/admin/Competency";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  ordering?: string;
  query?: string;
};

export async function getCompetencies(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number;
    next: null | string;
    previous: null | string;
    results: Competency[];
  }>({
    method: "GET",
    url: `/api/enterprise/competencies/?${buildQueryString(args)}`,
  });
}
