import { authorised } from "../../../request";

type Args = {
  pageSlug: string;
}

export async function checkPage({pageSlug}: Args) {
  const API = authorised();

  return API.request<{
    exists: boolean
  }>({
    method: "GET",
    url: `/api/enterprise/pages/check/${pageSlug}/`
  });
}
