import React from "react";
import { VideoChartPie } from "../../../types/Video";
import { Pie } from "react-roughviz";

export function VideoChartElement(props: {
  element: VideoChartPie;
  currentTime: number;
}) {
  const { element } = props;

  return (
    <div>
      <Pie
        data={element.data}
        title="Regions"
        colors={["red", "orange", "blue", "skyblue"]}
        roughness={5}
      />
    </div>
  );
}
