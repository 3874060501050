import React, { ChangeEvent, useState } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

export function TextareaComponent(props: {
  id?: string;
  name?: string;
  label?: string;
  description?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
  const [focused, setFocused] = useState(false);
  return (
    <label>
      <InputLabel label={props.label} description={props.description} />

      <textarea
        id={props.id}
        name={props.name}
        className={props.className}
        placeholder={props.placeholder || ""}
        readOnly={props.readonly}
        disabled={props.disabled}
        css={css`
          display: block;
          width: 100%;
          min-height: 200px;
          padding: 10px 24px;
          line-height: 1.5;
          color: ${theme.colors.dark};
          background-color: ${theme.colors.white};
          background-clip: padding-box;
          border: 1px solid
            ${props.error
              ? theme.colors.redMain
              : focused
              ? theme.colors.dark
              : theme.colors.gray4};
          border-radius: 20px;
          outline: none;
          resize: none;
          ::-webkit-scrollbar {
            width: 0 !important;
          }
          overflow: -moz-scrollbars-none;
          -ms-overflow-style: none;
        `}
        {...props.field}
        onFocus={() => setFocused(true)}
        onBlur={(e) => {
          if (props.field) {
            props.field.onBlur(e);
          }
          setFocused(false);
        }}
        onChange={(e) => {
          if (props.field) {
            props.field.onChange(e);
          }
          if (props.onChange) props.onChange(e);
        }}
      />

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
