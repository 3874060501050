import { authorised } from "../../request";
import { Faq } from "../../types/Faq";

type Args = {
  id: string;
};

export async function getStudioFaqItem({ id }: Args) {
  const API = authorised();

  return API.request<Faq>({
    method: "GET",
    url: `/api/studio/faq-items/${id}/`,
  });
}
