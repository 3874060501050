import { authorised } from "../../request";

type Args = {
  email: string;
}

export async function inviteOrganisationUser({email}: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/studio/organisation/users/invite/`,
    data: {
      email
    }
  });
}
