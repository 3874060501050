import { authorised } from "../../request";
import { Faq } from "../../types/Faq";

type Args = {
  answer: string;
  question: string;
};

export async function createStudioFaqItem(data: Args) {
  const API = authorised();

  return API.request<Faq>({
    method: "POST",
    url: `/api/studio/faq-items/`,
    data,
  });
}
