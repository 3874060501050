import React, { useState } from "react";
import { css } from "styled-components/macro";
import { customToast } from "../customToast";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Check, Mail, ThumbsDown } from "react-feather";
import { theme } from "../../themes/variables";
import { FeedbackType, LessonFeedback } from "../../types/LessonFeedback";
import { processStudioFeedback } from "../../actions/feedback/processStudioFeedback";
import { processEnterpriseFeedback } from "../../actions/feedback/processEnterpriseFeedback";
import { Button } from "../Button";
import { Avatar } from "../Avatar";
import defaultAvatar from "../../assets/img/Default_Avatar.png";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Block } from "../Block";

export default function FeedbackContainer({
  feedback,
  type,
}: {
  type: "admin" | "studio";
  feedback: LessonFeedback;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const [isRead, setIsRead] = useState<boolean>(feedback.is_read);

  const formatedDate = new Date(feedback.created).toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const formatTime = new Date(feedback.created).toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const mailTo = () => {
    window.location.assign(
      `mailto:${feedback.user.email}?subject=Re: Feedback about ${feedback.lesson.name}`
    );
  };

  const onUpdate = async () => {
    try {
      const func =
        type === "admin" ? processEnterpriseFeedback : processStudioFeedback;
      const response = await func(feedback.uuid, {
        is_read: !isRead,
      });
      setIsRead(response.data.is_read);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <Block>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            min-height: 50px;
            background-color: ${theme.colors.gray1};
            border-radius: 20px;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;
          `}
        >
          <div
            css={css`
              display: flex;
              font-size: 16px;
              align-items: center;
              color: ${theme.colors.gray3};
              line-height: 16px;
              font-weight: 600;
              cursor: pointer;
            `}
          >
            <div
              css={css`
                :hover {
                  text-decoration: underline;
                }
              `}
              onClick={() =>
                history.push(`/courses/${feedback.lesson.course?.uuid}/edit`)
              }
            >
              &nbsp;{feedback.lesson.course?.name + " > "}
            </div>
            <div
              css={css`
                color: ${theme.colors.black};
                :hover {
                  text-decoration: underline;
                }
              `}
              onClick={() =>
                history.push(
                  `/courses/${feedback.lesson.course?.uuid}/lessons/${feedback.lesson.uuid}/edit`
                )
              }
            >
              &nbsp;{feedback.lesson.name}
            </div>
          </div>
          <div
            css={css`
              display: flex;
              padding: 0 20px;
              align-items: center;
              color: ${theme.colors.white};
              background-color: ${feedback.feedback === FeedbackType.POSITIVE
                ? theme.colors.success
                : theme.colors.error};
              border-radius: 20px;
            `}
          >
            <div
              css={css`
                font-weight: 600;
                font-size: 16px;
                margin-right: 10px;
              `}
            >
              {feedback.feedback === FeedbackType.POSITIVE
                ? t("feedback.positive")
                : t("feedback.negative")}
            </div>
            <ThumbsDown
              size={12}
              css={
                feedback.feedback === FeedbackType.POSITIVE &&
                css`
                  margin-bottom: 3px;
                  transform: scale(-1);
                `
              }
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 36px;
            width: 100%;
          `}
        >
          <Avatar
            url={
              feedback.user.avatar ? feedback.user.avatar.image : defaultAvatar
            }
            size={32}
            css={css`
              margin-right: 18px;
            `}
          />

          <div
            css={css`
              margin-right: 10px;
              font-weight: 400;
            `}
          >
            {feedback.user.first_name + " " + feedback.user.last_name}
          </div>
          <div
            css={css`
              font-size: 16px;
              font-weight: 400;
              color: ${theme.colors.gray4};
            `}
          >
            {"/ " + formatedDate + " um " + formatTime}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 160px 0 50px;
            margin-top: 20px;
          `}
        >
          <div>{feedback.comment}</div>
          <div
            css={css`
              display: flex;
              margin-top: 28px;
              align-items: center;
            `}
          >
            <Button
              css={css`
                background-color: ${theme.colors.orange};
                margin-right: 20px;
                display: flex;
              `}
              onClick={() => mailTo()}
            >
              {t("feedback.get_in")}
              <Mail
                size={12}
                css={css`
                  margin-top: 2px;
                  margin-left: 12px;
                `}
              />
            </Button>
            <Button
              css={css`
                background-color: ${isRead
                  ? theme.colors.black
                  : theme.colors.white};
                border: 1px solid ${theme.colors.black};
                color: ${isRead ? theme.colors.white : theme.colors.black};
              `}
              hoverStyles={`color: ${theme.colors.white}; background-color: ${theme.colors.primary}; border: 1px solid ${theme.colors.primary};`}
              icon={
                <Check
                  size={12}
                  css={css`
                    margin-top: 2px;
                    margin-left: 12px;
                  `}
                />
              }
              iconRight
              onClick={() => onUpdate()}
            >
              {isRead ? t("feedback.mark_unread") : t("feedback.mark_read")}
            </Button>
          </div>
        </div>
      </div>
    </Block>
  );
}
