import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { customToast } from "../../components/customToast";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Block } from "../../components/Block";
import { Button } from "../../components/Button";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Loader } from "../../components/Loader";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import {
  resendConfirmationEmail,
  USER_CONFIRM_EMAIL,
} from "../../actions/auth/resendConfirmationEmail";
import { confirmEmail } from "../../actions/auth/confirmEmail";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Footer } from "../../components/Footer";

export function ConfirmEmailPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const params = new URLSearchParams(props.location.search);

  const token = params.get("token");

  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirming, setConfirming] = useState(token ? true : false);

  const userEmail = localStorage.getItem(USER_CONFIRM_EMAIL);

  const sendEmailConfirm = useCallback(
    async (token) => {
      try {
        setConfirming(true);

        const response = await confirmEmail(token);

        if (response.data.is_verified) {
          history.push("/login");
        } else {
          history.push("/verification-info");
        }
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      } finally {
        setConfirming(false);
      }
    },
    [history, t]
  );

  useEffect(() => {
    if (!token) return;

    sendEmailConfirm(token);
  }, [token, sendEmailConfirm]);

  return (
    <Wrapper>
      <SEO>
        <title>{t("confirm-email.title")}</title>
      </SEO>
      <Header />
      <Block
        css={css`
          background-color: ${theme.colors.white};
        `}
      >
        <div
          css={css`
            width: 450px;
            margin: 0 auto;
            padding: 160px 0;
            text-align: center;

            p {
              margin: 1em 0;
            }
          `}
        >
          {isConfirming ? (
            <Loader />
          ) : (
            <>
              <div
                css={css`
                  margin-bottom: 40px;
                  text-align: center;
                `}
              >
                <Headers.H3>{t("confirm-email.title")}</Headers.H3>

                <p>
                  {t("confirm-email.email-sent", {
                    email: userEmail,
                  })}
                  <br />
                  {t("confirm-email.click-on-link")}
                </p>
              </div>
              <div>
                <p>
                  <b>{t("confirm-email.not-receiving")}</b>
                </p>
                <Button
                  small
                  disabled={!userEmail}
                  isSubmitting={isSubmitting}
                  onClick={async () => {
                    if (!userEmail) return;

                    setSubmitting(true);

                    try {
                      await resendConfirmationEmail(userEmail);
                    } catch (error) {
                      customToast.error(
                        t("status.error", {
                          error: serverErrorHandler(error),
                        })
                      );
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {t("actions.resend-email")}
                </Button>
                <p>{t("confirm-email.check-junk-folder")}</p>
              </div>
            </>
          )}
        </div>
      </Block>
      <Footer />
    </Wrapper>
  );
}
