import { authorised } from "../../request";
import { CheatSheetOrdered } from "../../types/CheatSheet";

export async function reorderCheats(
  courseId: string,
  items: CheatSheetOrdered[]
) {
  const API = authorised();

  return API.request<CheatSheetOrdered[]>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/cheat-sheet/reorder/`,
    data: { items: items },
  });
}
