import { authorised } from "../../../request";

type Args = {
  id: number;
}

export async function deleteCompetency({id}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/competencies/${id}/`
  });
}
