import React from "react";
import { Link } from "react-router-dom";
import { Navigation } from "./Navigation";
import { UserDropdown } from "./UserDropdown";
import { css } from "styled-components/macro";
import { Notifications } from "./Notifications";
import { useAuth } from "../contexts/UserContext";
import { useEnterprise } from "../contexts/EnterpriseContext";

export function Header(props: {}) {
  const { isAuthenticated } = useAuth();
  const { enterprise } = useEnterprise();

  if (!isAuthenticated) {
    return (
      <header
        css={css`
          padding: 30px 0;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <Link to="/">
          <img
            css={css`
              cursor: pointer;
              max-width: 134px;
              max-height: 40px;
              vertical-align: middle;
            `}
            src={enterprise.logo?.image}
            alt={`${enterprise?.name} Logo`}
          />
        </Link>
      </header>
    );
  }

  return (
    <header
      css={css`
        margin-top: 44px;
        margin-bottom: 30px;
        height: 70px;
        padding: 0 30px;
        border-radius: 40px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
      `}
    >
      <Link to="/">
        <img
          css={css`
            cursor: pointer;
            max-width: 134px;
            max-height: 40px;
            vertical-align: middle;
          `}
          src={enterprise.logo?.image}
          alt={`${enterprise?.name} Logo`}
        />
      </Link>
      <Navigation />
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-flow: row wrap;
        `}
      >
        <div
          css={css`
            margin-right: 15px;
          `}
        >
          <Notifications />
        </div>
        <UserDropdown />
      </div>
    </header>
  );
}
