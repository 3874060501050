import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { theme } from "../../../themes/variables";
import { EnterpriseGeneral } from "../../../types/Enterprise";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/Profile.svg";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { Column } from "../../../helpers/layout";
import { InputField } from "../../fields/InputField";
import { Button } from "../../../components/Button";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { TextareaField } from "../../fields/TextareaField";
import { ImageType } from "../../../types/Image";
import { uploadImage } from "../../../actions/image";

export type FormValues = EnterpriseGeneral;

export default function EnterpriseGeneralSettingsForm({
  data,
  onSubmit,
}: {
  data: EnterpriseGeneral;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const onSubmitChanges = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        border-radius: 20px;
        background-color: ${theme.colors.white};
        width: 100%;
        padding: 65px 60px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: data.name,
          logo: data.logo,
          preview_image: data.preview_image,
          stamp: data.stamp,
          description: data.description,
          background_color: data.background_color,
          data_protection_link: data.data_protection_link,
          imprint_link: data.imprint_link,
          agb_link: data.agb_link,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          description: Yup.string(),
        })}
        onSubmit={onSubmitChanges}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <div
              css={css`
                display: flex;
              `}
            >
              <Column
                css={css`
                  display: flex;
                  width: 80%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <ProfileIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.general")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.name_of_school")}
                  name={"name"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <TextareaField
                  label={t("enterprise_settings.description")}
                  name={"description"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <hr />
                <ImageUploadField
                  label={t("enterprise_settings.logo")}
                  name={"logo"}
                  type={ImageType.HIDDEN}
                  uploadFn={uploadImage}
                  disableLibrary
                />
                <InputField
                  label={t("enterprise_settings.background_color")}
                  name={"background_color"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <hr />
                <InputField
                  label={`${t("enterprise_settings.data_protection_link")}:`}
                  name={"data_protection_link"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={`${t("enterprise_settings.imprint_link")}:`}
                  name={"imprint_link"}
                />
                <InputField
                  label={`${t("enterprise_settings.agb_link")}:`}
                  name={"agb_link"}
                />
                <hr />
                <ImageUploadField
                  label={t("enterprise_settings.cover")}
                  name={"preview_image"}
                  type={ImageType.HIDDEN}
                  uploadFn={uploadImage}
                  disableLibrary
                />
                <ImageUploadField
                  label={t("enterprise_settings.stamp")}
                  name={"stamp"}
                  type={ImageType.HIDDEN}
                  uploadFn={uploadImage}
                  disableLibrary
                />
              </Column>
              <Column>
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                    css={css`
                      display: flex;
                      margin: auto 0 0 auto;
                      font-size: 16px;
                      line-height: 18px;
                    `}
                    icon={
                      <ReadIcon
                        width={16}
                        height={16}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    <div
                      css={css`
                        margin-right: 11px;
                      `}
                    >
                      {t("enterprise_settings.save")}
                    </div>
                  </Button>
                </div>
              </Column>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
