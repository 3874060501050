import { authorised } from "../../../request";
import { UserSegment } from "../../../types/admin/UserSegment";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
};

type FilterUserSegment = Pick<UserSegment, "id" | "name">;

export async function getFiltersUserSegments(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number;
    next: null | string;
    previous: null | string;
    results: FilterUserSegment[];
  }>({
    method: "GET",
    url: `/api/studio/filters/user-segments/?${buildQueryString(args)}`,
  });
}
