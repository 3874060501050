import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs, Tab } from "../../components/Tabs";
import { Button } from "../../components/Button";
import { InputComponent } from "../../components/InputComponent";
import { SEO } from "../../components/SEO";
import { EditCreateExpert } from "../../components/Organisation/forms/EditCreateExpert";
import ExpertsTable from "../../components/Organisation/ExpertsTable";

import { ModalDelete } from "../../modals/ModalDelete";
import { useNewFetch } from "../../useAPI";
import { useAuth } from "../../contexts/UserContext";
import { Permission } from "../../types/UserProfile";
import { Expert } from "../../types/Expert";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import {
  deleteExpert,
  createExpert,
  updateExpert,
} from "../../actions/organisationExpert";
import { getOrganisation } from "../../actions/organisation";
import { pageLoading } from "../../helpers/pageLoading";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";
import { Footer } from "../../components/Footer";

export type FormValues = Omit<Expert, "id">;

export function OrganisationExpertsPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);

  const { hasOrganisationPermission } = useAuth();

  const [expertModal, setExpertModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [expertCandidate, setExpertCandidate] = useState<Expert | null>(null);

  const [deleteCandidate, setDeleteCandidate] = useState<Expert | null>(null);

  const [query, setQuery] = useState(params.get("query") || undefined);

  const { data: organisation, error: organisationError } =
    useNewFetch(getOrganisation);

  if (!organisation || organisationError) {
    return pageLoading(organisationError);
  }

  const tabsData: Tab[] = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/organisation`,
    },
  ];

  if (hasOrganisationPermission(Permission.ORGANISATION_MANAGE_USERS)) {
    tabsData.push({
      title: t("breadcrumbs.users"),
      link: `/organisation/users`,
    });
    tabsData.push({
      title: t("organisation.invites"),
      link: `/organisation/invites`,
    });
    tabsData.push({
      title: t("organisation.experts"),
      link: `/organisation/experts`,
    });
  }

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const closeExpertModal = () => {
    setExpertCandidate(null);
    setExpertModal(false);
  };

  const onDelete = async () => {
    if (deleteCandidate) {
      try {
        await deleteExpert({
          expertId: deleteCandidate.id,
        });
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }

      setDeleteModal(false);
      setDeleteCandidate(null);
      ref.current.refetch();
    }
  };

  const onCreate = async (values: FormValues) => {
    await createExpert({
      data: values,
    });

    closeExpertModal();
    ref.current.refetch();
  };

  const onUpdate = async (values: FormValues) => {
    if (expertCandidate) {
      await updateExpert({
        expertId: expertCandidate.id,
        data: values,
      });
    }

    closeExpertModal();
    ref.current.refetch();
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("organisation.title")}</title>
        </SEO>
        <Header />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{organisation.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <Headers.H3
                css={css`
                  display: inline;
                  margin-right: 159px;
                `}
              >
                {t("organisation.experts")}
              </Headers.H3>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <InputComponent
                  placeholder={t("organisation.search-by-name")}
                  icon={<SearchIcon width={15} height={15} />}
                  value={query}
                  onChange={(e) => onSearch(e.target.value)}
                  css={css`
                    min-width: 300px;
                  `}
                />

                <Button
                  css={css`
                    margin-left: 65px;
                  `}
                  background={theme.colors.dark}
                  color={theme.colors.white}
                  icon={
                    <PlusIcon
                      width={14}
                      height={14}
                      color={theme.colors.white}
                    />
                  }
                  iconRight
                  onClick={() => setExpertModal(true)}
                >
                  {t("organisation.create-expert")}
                </Button>
              </div>
            </div>

            <div
              css={css`
                margin-top: 40px;
              `}
            >
              <div
                css={css`
                  position: relative;
                  min-height: 200px;
                `}
              >
                <ExpertsTable
                  forwardRef={ref}
                  setExpertCandidate={(expert: Expert) =>
                    setExpertCandidate(expert)
                  }
                  setDeleteCandidate={(expert: Expert) =>
                    setDeleteCandidate(expert)
                  }
                  setExpertModal={(value: boolean) => setExpertModal(value)}
                  setDeleteModal={(value: boolean) => setDeleteModal(value)}
                />
              </div>

              <EditCreateExpert
                modal={expertModal}
                closeModal={closeExpertModal}
                initialData={expertCandidate}
                onSubmitCallback={expertCandidate ? onUpdate : onCreate}
              />

              <ModalDelete
                isOpen={deleteModal}
                onClose={() => setDeleteModal(false)}
                onDelete={onDelete}
              />
            </div>
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
