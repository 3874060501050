import { authorised } from "../../request";

type Args = {
  selfAssessmentId: string;
};

export async function deleteSignUpSelfAssessment({ selfAssessmentId }: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/self-assessments/${selfAssessmentId}/`,
  });
}
