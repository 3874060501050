import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { theme } from "../themes/variables";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

const defaultMasks: { [key: string]: Array<RegExp | string> } = {
  time: [/[0-2]/, /[0-9]/, ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/],
  price: createNumberMask({
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2,
    integerLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
  }),
  percent: createNumberMask({
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: false,
    decimalSymbol: ".",
    decimalLimit: 0,
    integerLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
  }),
};

export function InputMaskComponent(props: {
  id?: string;
  name?: string;
  type?: "time" | "price" | "percent";
  label?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  icon?: React.ReactNode;
  mask?: Array<RegExp | string> | ((value: string) => Array<RegExp | string>);
  value?: string;
  error?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className={props.className}>
      <InputLabel label={props.label} />

      <div
        css={css`
          position: relative;
        `}
      >
        <MaskedInput
          id={props.id}
          value={props.value}
          {...props.field}
          mask={props.mask || defaultMasks[props.type || "time"]}
          type="text"
          placeholder={props.placeholder || ""}
          aria-label={props.name || ""}
          onChange={(e: any) => {
            props.field && props.field.onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
          css={css`
            display: block;
            width: 100%;
            height: 1rem;
            padding: 10px 24px;
            ${props.icon && "padding-right: 40px;"}
            min-height: 42px;
            line-height: 1.5;
            color: ${theme.colors.dark};
            background-color: ${theme.colors.white};
            background-clip: padding-box;
            border: 1px solid
              ${props.error ? theme.colors.redMain : theme.colors.gray4};
            border-radius: 20px;
            outline: none;
          `}
        ></MaskedInput>
        {props.icon && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(75%, -50%);
            `}
          >
            {props.icon}
          </div>
        )}
      </div>

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
