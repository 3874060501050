import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { useAuth } from "../contexts/UserContext";
import { Permission } from "../types/UserProfile";
import { ReactComponent as UserIcon } from "../assets/icons/User.svg";
import { ReactComponent as HomeIcon } from "../assets/icons/Home.svg";
import { ReactComponent as SchoolIcon } from "../assets/icons/School.svg";
import { ReactComponent as ContentIcon } from "../assets/icons/Union.svg";
import { useClickOutside } from "../hooks/useClickOutside";
import { useEnterprise } from "../contexts/EnterpriseContext";

type PagesList = {
  title: string;
  link?: string;
};

type AdminDropDown = {
  title: string;
  icon: React.ReactNode;
  content: PagesList[];
};

type NavTabs = {
  title: string;
  link?: string;
  dropDown?: boolean;
  dropDownContent?: AdminDropDown[];
  hasPermission?: boolean;
};

export function Navigation(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [dropDownVisible, setDropDownVisible] = useState(false);
  const adminDropdown = useRef<HTMLDivElement>(null);
  const { enterprise } = useEnterprise();

  const handleOpen = () => {
    setDropDownVisible(true);
  };

  const handleClose = () => {
    if (dropDownVisible) setDropDownVisible(false);
  };

  useClickOutside(adminDropdown, handleClose);

  const { hasPermission } = useAuth();

  const adminTabsData: AdminDropDown[] = [
    {
      title: t("header.navigation.admin.users.users"),
      icon: <UserIcon color={theme.colors.primary} />,
      content: [
        {
          title: t("header.navigation.admin.users.campus"),
          link: "/admin/users/campus",
        },
        {
          title: t("header.navigation.admin.users.studio"),
          link: "/admin/users/studio",
        },
        {
          title: t("signup_fields.title"),
          link: "/admin/signup/campus",
        },
        {
          title: t("user_segment.title"),
          link: "/admin/user-segment",
        },
      ],
    },
    {
      title: t("header.navigation.admin.homepage.homepage"),
      icon: <HomeIcon />,
      content: [
        {
          title: t("header.navigation.admin.homepage.slideshow"),
          link: "/admin/sliders",
        },
        {
          title: "Announcements",
          link: "/admin/announcements",
        },
        {
          title: "Menu Manager",
          link: "/admin/menus/campus/header",
        },
      ],
    },
    {
      title: enterprise.name,
      icon: <SchoolIcon />,
      content: [
        {
          title: t("header.navigation.admin.school.settings"),
          link: "/admin/settings/general",
        },
        {
          title: t("header.navigation.admin.self-assessment.self-assessment"),
          link: "/admin/self-assessment",
        },
        {
          title: t("header.navigation.admin.school.orders"),
          link: "/admin/orders",
        },
        {
          title: t("admin.skill.title"),
          link: "/admin/skills",
        },
      ],
    },
    {
      title: t("rich-text-editor.content"),
      icon: <ContentIcon />,
      content: [
        {
          title: t("admin.publish-request.page.title"),
          link: "/admin/publish-request",
        },
        {
          title: t("admin.pages.title"),
          link: "/admin/pages",
        },
        {
          title: t("header.navigation.faq"),
          link: "/admin/faq",
        },
        {
          title: t("feedback.feedback"),
          link: "/admin/feedback",
        },
        {
          title: t("header.navigation.admin.journeys"),
          link: "/admin/journeys",
        },
      ],
    },
  ];

  const menuData: NavTabs[] = enterprise.studio_header_menu
    ? enterprise.studio_header_menu.items.map((item) => {
        return {
          title: item.item.name,
          link: item.item.url,
          hasPermission: true,
        };
      })
    : [];

  const navigationData: NavTabs[] = [
    ...menuData,
    {
      title: t("admin.title"),
      hasPermission: hasPermission(Permission.ENTERPRISE_MANAGE_USERS),
      dropDown: true,
      dropDownContent: adminTabsData,
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
      `}
    >
      {navigationData &&
        navigationData.map(
          (tab, index) =>
            tab.hasPermission && (
              <React.Fragment key={index}>
                <div
                  key={index}
                  css={css`
                    display: flex;
                    align-items: center;
                    position: relative;
                    cursor: pointer;
                  `}
                  onClick={() => {
                    !tab.dropDown && tab.link
                      ? history.push(tab.link)
                      : dropDownVisible
                      ? handleClose()
                      : handleOpen();
                  }}
                >
                  <div
                    css={css`
                      margin: 0;
                      position: relative;
                      margin-right: ${tab.dropDown ? "8px" : "30px"};
                      line-height: 28px;
                      align-items: center;
                      justify-content: center;
                      border-bottom: 3px solid
                        ${tab.link && tab.link === location.pathname
                          ? theme.colors.primary
                          : theme.colors.white};

                      font-size: 16px;
                    `}
                  >
                    {tab.title}
                  </div>

                  {dropDownVisible && tab.dropDown && tab.dropDownContent && (
                    <div
                      ref={adminDropdown}
                      css={css`
                        position: absolute;
                        display: flex;
                        width: fit-content;
                        background-color: ${theme.colors.white};
                        top: calc(100% + 27px);
                        left: 50%;
                        transform: translateX(-48%);
                        padding: 30px 40px;
                        border-radius: 20px;
                        box-sizing: border-box;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
                        z-index: 1;
                        cursor: default;
                      `}
                    >
                      <div
                        css={css`
                          width: 22px;
                          height: 22px;
                          position: absolute;
                          top: -15px;
                          right: 50%;
                          transform: translateX(-50%);
                          overflow: hidden;

                          &::after {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            background: white;
                            transform: translateX(-50%) translateY(-50%)
                              rotate(45deg);
                            top: 100%;
                            left: 50%;
                            box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.15);
                            border-radius: 1px;
                          }
                        `}
                      ></div>
                      {tab.dropDownContent &&
                        tab.dropDownContent.map((el, id) => (
                          <div
                            key={id}
                            css={css`
                              margin-right: 40px;
                              display: flex;
                              flex-direction: column;
                              width: max-content;

                              :last-child {
                                margin-right: 0;
                              }
                            `}
                          >
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                width: 100%;
                                border-bottom: 1px solid ${theme.colors.gray2};
                                padding-bottom: 7px;
                                cursor: pointer;

                                :hover {
                                  border-bottom: 1px solid
                                    ${theme.colors.primary};
                                }
                              `}
                            >
                              {el.icon}
                              <div
                                css={css`
                                  white-space: nowrap;
                                  margin-left: 10px;
                                  font-size: 16px;
                                  line-height: 22px;
                                  cursor: pointer;
                                `}
                              >
                                {el.title}
                              </div>
                            </div>
                            <div
                              css={css`
                                margin-top: 10px;
                                margin-left: 30px;
                                color: ${theme.colors.gray3};
                              `}
                            >
                              {el.content &&
                                el.content.map((page, i) => (
                                  <div
                                    key={i}
                                    css={css`
                                      font-size: 14px;
                                      line-height: 22px;
                                      margin-bottom: 8px;
                                      ${page.link &&
                                      `color:${theme.colors.gray6}; :hover { color: ${theme.colors.black}}; cursor: pointer;`}
                                    `}
                                    onClick={() =>
                                      page.link && history.push(page.link)
                                    }
                                  >
                                    {page.title}
                                  </div>
                                ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
        )}
    </div>
  );
}
