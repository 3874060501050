import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import {
  EditCreateJourney,
  FormValues,
} from "../../components/EditCreateJourney";
import { updateJourney, deleteJourney } from "../../actions/journeys";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { DangerZone } from "../../components/DangerZone";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { useNewFetch } from "../../useAPI";
import { getJourney } from "../../actions/journeys";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export function EditJourneyPage() {
  const { t } = useTranslation();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const params = useParams<{
    id: string;
  }>();

  const onConfirmDelete = async () => {
    try {
      await deleteJourney(params.id);
      setDeleteModal(false);
      history.push("/admin/journeys");
    } catch (error) {
      customToast.error(
        t("journeys.modal.error", {
          name: t("journeys.name"),
        })
      );
    }
  };

  const onSubmit = async (data: FormValues) => {
    await updateJourney(params.id, data);
  };

  const { data: journey, error: journeyError } = useNewFetch(getJourney, {
    journeyId: params.id,
  });

  if (!journey || journeyError) {
    return pageLoading(journeyError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/admin/journeys/${params.id}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/admin/journeys/${params.id}/edit`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("journeys.title"),
      url: "/admin/journeys",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{journey.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{journey.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateJourney
            onSubmitCallback={onSubmit}
            initialData={journey}
          />

          <DangerZone
            css={css`
              margin-top: 5rem;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("journeys.delete")}
            </Button>
            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("journeys.modal.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
