import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { useNewFetch } from "../../../useAPI";
import { useAuth } from "../../../contexts/UserContext";
import { getUserStats, updateUser } from "../../../actions/admin/user";
import { Permission } from "../../../types/UserProfile";
import { TargetUserProfile } from "../../../types/admin/UserProfile";
import { pageLoading } from "../../../helpers/pageLoading";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { Tabs, Tab } from "../../../components/Tabs";
import { InputComponent } from "../../../components/InputComponent";
import UserTable from "../../../components/Admin/UserManagement/UserTable";

import { Button } from "../../../components/Button";
import { ReactComponent as SearchSvg } from "../../../assets/icons/Search.svg";
import { ReactComponent as DownloadSvg } from "../../../assets/icons/Download.svg";
import { UsersCSVModal } from "../../../modals/UserCSVModal";
import { numberFormat } from "../../../components/numberFormat";
import { Footer } from "../../../components/Footer";

export function UserManagementCampusPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);

  const [downloadModal, setDownloadModal] = useState(false);
  const [query, setQuery] = useState(params.get("query") || undefined);

  const { hasPermission, userprofile } = useAuth();
  const [loadingId, setLoadingId] = useState<number | null>(null);

  const {
    data: userStats,
    error: userStatsError,
    refetch: refetchStats,
  } = useNewFetch(getUserStats);

  if (!userStats || userStatsError) {
    return pageLoading(userStatsError);
  }

  const tabsData: Tab[] = [
    {
      title: t("admin.users.page.tabs.campus"),
      link: `/admin/users/campus`,
      notification: userStats.pending_users_count_campus,
    },
    {
      title: t("admin.users.page.tabs.studio"),
      link: "/admin/users/studio",
      notification: userStats.pending_users_count_studio,
    },
  ];

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const closeModal = () => {
    setDownloadModal(false);
  };

  const deactivateUser = async (id: number) => {
    setLoadingId(id);
    await updateUser({
      id,
      data: {
        is_verified: false,
      },
    });

    setLoadingId(null);
    ref.current?.refetch();
    refetchStats();
  };

  const activateUser = async (id: number) => {
    setLoadingId(id);
    await updateUser({
      id,
      data: {
        is_verified: true,
      },
    });

    setLoadingId(null);
    ref.current?.refetch();
    refetchStats();
  };

  const canChangeAdmin = (targetUser: TargetUserProfile): boolean => {
    if (targetUser.role?.key === "ADMIN") {
      return hasPermission(Permission.ENTERPRISE_MANAGE_ADMIN_USERS);
    }

    return hasPermission(Permission.ENTERPRISE_MANAGE_USERS);
  };

  const isMe = (targetUser: TargetUserProfile): boolean => {
    return userprofile?.id === targetUser.id;
  };
  return (
    <>
      <Wrapper>
        <Helmet title={t("admin.users.page.title")} />
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <div
                  css={css`
                    margin-right: 24px;
                  `}
                >
                  <Headers.H4
                    css={css`
                      display: inline-block;
                      font-size: 20px;
                      line-height: 30px;
                      margin-right: 6px;
                      width: 50px;
                      color: ${theme.colors.primary};
                    `}
                  >
                    {userStats.users_count_campus
                      ? numberFormat(userStats.users_count_campus)
                      : 0}
                  </Headers.H4>

                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    {t("breadcrumbs.users")}
                  </span>
                </div>
                <div
                  css={css`
                    margin-right: 24px;
                  `}
                >
                  <Headers.H4
                    css={css`
                      display: inline-block;
                      font-size: 20px;
                      line-height: 30px;
                      margin-right: 6px;
                      width: 50px;
                      color: ${userStats.pending_users_count_campus
                        ? theme.colors.error
                        : theme.colors.success};
                    `}
                  >
                    {userStats.pending_users_count_campus
                      ? numberFormat(userStats.pending_users_count_campus)
                      : 0}
                  </Headers.H4>

                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    {t("admin.users.page.waiting-approval")}
                  </span>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                <div
                  css={css`
                    margin-right: 24px;
                  `}
                >
                  <Headers.H4
                    css={css`
                      display: inline-block;
                      font-size: 20px;
                      line-height: 30px;
                      margin-right: 6px;
                      width: 50px;
                      color: ${theme.colors.primary};
                    `}
                  >
                    {userStats.approved_users_count_campus
                      ? numberFormat(userStats.approved_users_count_campus)
                      : 0}
                  </Headers.H4>
                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    {t("admin.users.page.activate-user")}
                  </span>
                </div>
                <div
                  css={css`
                    margin-right: 24px;
                  `}
                >
                  <Headers.H4
                    css={css`
                      display: inline-block;
                      font-size: 20px;
                      line-height: 30px;
                      margin-right: 6px;
                      width: 50px;
                      color: ${userStats.rejected_users_count_campus
                        ? theme.colors.error
                        : theme.colors.success};
                    `}
                  >
                    {userStats.rejected_users_count_campus
                      ? numberFormat(userStats.rejected_users_count_campus)
                      : 0}
                  </Headers.H4>
                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                    `}
                  >
                    {t("admin.users.page.deactivate-user")}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <InputComponent
                css={css`
                  min-width: 370px;
                `}
                value={query}
                placeholder={t("admin.users.page.search")}
                icon={<SearchSvg width={20} height={20} />}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            <Button
              css={css`
                margin-left: 40px;
              `}
              icon={<DownloadSvg width={14} height={14} />}
              iconRight
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
              onClick={() => setDownloadModal(true)}
            >
              {t("user.csv-download")}
            </Button>
          </div>
          <div
            css={css`
              margin-top: 40px;
            `}
          >
            <div
              css={css`
                position: relative;
                min-height: 200px;
              `}
            >
              <UserTable
                type="CAMPUS"
                forwardRef={ref}
                loadingId={loadingId}
                isMe={(user: TargetUserProfile) => isMe(user)}
                canChangeAdmin={(user: TargetUserProfile) =>
                  canChangeAdmin(user)
                }
                activateUser={(id: number) => activateUser(id)}
                deactivateUser={(id: number) => deactivateUser(id)}
              />
            </div>
          </div>
        </Block>
        <UsersCSVModal
          type="CAMPUS"
          isVisible={downloadModal}
          onClose={() => closeModal()}
        />
        <Footer />
      </Wrapper>
    </>
  );
}
