import React, { useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { css } from "styled-components/macro";
import { Button } from "../Button";
import { VideoInteractive } from "../VideoInteractive/VideoInteractive";
import { VideoSceneEditor } from "./VideoSceneEditor";
import { AddVideoElements } from "./AddVideoElements";
import { StudioProvider } from "../../contexts/StudioContext";
import { Video } from "../../types/Video";

export function VideoEditor(props: {
  video: Video;
  onSave: (video: Video) => void;
}) {
  const [mode, setMode] = useState<"edit" | "preview">("edit");

  return (
    <StudioProvider video={props.video}>
      {(video) => {
        return (
          <DndProvider backend={HTML5Backend}>
            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                margin-bottom: 20px;
              `}
            >
              {mode === "edit" ? (
                <Button
                  onClick={() => {
                    setMode("preview");
                  }}
                  color={"white"}
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  Preview
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setMode("edit");
                  }}
                  color={"white"}
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  Edit
                </Button>
              )}
              <Button
                onClick={() => {
                  props.onSave(video);
                }}
              >
                Save
              </Button>
            </div>
            <div
              css={css`
                display: flex;
                flex-flow: row wrap;
              `}
            >
              <div
                css={css`
                  width: 360px;
                  margin-right: 20px;

                  ${mode === "preview" &&
                  css`
                    pointer-events: none;
                    opacity: 0.4;
                  `}
                `}
              >
                <div>
                  <AddVideoElements />
                </div>
              </div>
              <div
                css={css`
                  flex: 1 0 10%;
                `}
              >
                <div
                  css={css`
                    position: relative;
                  `}
                >
                  {mode === "preview" ? (
                    <VideoInteractive
                      show={true}
                      video={video}
                      onEnded={() => {}}
                    />
                  ) : (
                    <VideoSceneEditor />
                  )}
                </div>
              </div>
            </div>
          </DndProvider>
        );
      }}
    </StudioProvider>
  );
}
