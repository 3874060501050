import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Button } from "../../../../components/Button";
import { BlockType } from "../../../../types/Block";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../../../components/Modal";
import { theme } from "../../../../themes/variables";
import { ReactComponent as GameIcon } from "../../../../assets/icons/Game.svg";
import { ReactComponent as PodcastIcon } from "../../../../assets/icons/Podcast.svg";
import { ReactComponent as TextIcon } from "../../../../assets/icons/Text.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/Video.svg";
import { ReactComponent as CarouselIcon } from "../../../../assets/icons/Carousel.svg";
import { ReactComponent as OwlIcon } from "../../../../assets/icons/Owl.svg";
import { ReactComponent as PPTIcon } from "../../../../assets/icons/Presentation.svg";

interface BlockOption {
  type: BlockType;
  text: string;
  icon: React.ReactNode;
}

export function BlockAdd(props: {
  modal: boolean;
  closeAddModal: () => void;
  onConfirm: (option: BlockType) => void;
}) {
  const { t } = useTranslation();

  const blockTypes: BlockOption[] = [
    {
      type: BlockType.CUSTOM_CODE,
      text: t("lesson.block.types.custom-code"),
      icon: <GameIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.COLUMNS,
      text: t("lesson.block.types.column"),
      icon: <TextIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.EMBED_VIDEO,
      text: t("lesson.block.types.embed-video"),
      icon: <VideoIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.PODCAST,
      text: t("lesson.block.types.podcast"),
      icon: <PodcastIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.CAROUSEL,
      text: t("lesson.block.types.carousel"),
      icon: (
        <CarouselIcon width={20} height={20} color={theme.colors.primary} />
      ),
    },
    {
      type: BlockType.COLLAPSE_BLOCK,
      text: t("lesson.block.types.collapse-block"),
      icon: <OwlIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.PPT_BLOCK,
      text: t("lesson.block.types.ppt-block"),
      icon: <PPTIcon width={20} height={20} color={theme.colors.primary} />,
    },
    {
      type: BlockType.INTERACTIVE_VIDEO,
      text: t("lesson.block.types.interactive-video-block"),
      icon: <VideoIcon width={20} height={20} color={theme.colors.primary} />,
    },
  ];

  const [blockType, setBlockType] = useState<BlockOption | null>(null);

  const onConfirm = () => {
    if (blockType) {
      props.onConfirm(blockType.type);
    }
  };

  const clickedBlockType = (el: BlockOption) => {
    if (el.type === blockType?.type) {
      onConfirm();
    } else {
      setBlockType(el);
    }
  };

  return (
    <Modal
      modalIsOpen={props.modal}
      onClose={() => props.closeAddModal()}
      contentLabel={t("actions.sure")}
      contentStyles={{
        maxWidth: "1000px",
        backgroundColor: theme.colors.white,
      }}
    >
      <ModalHeader closeIcon onClose={() => props.closeAddModal()}>
        {t("lesson.block.add-block")}
      </ModalHeader>

      <ModalBody>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 15px;
          `}
        >
          {blockTypes.map((el) => {
            return (
              <div
                key={el.type}
                css={css`
                  display: flex;
                  align-items: center;
                  text-align: center;
                  padding: 20px;
                  cursor: pointer;
                  border-radius: 20px;
                  background-color: ${theme.colors.gray1};
                  font-size: 16px;
                  border: 1px solid
                    ${blockType && el.type === blockType.type
                      ? theme.colors.blueBackground
                      : theme.colors.gray2};
                `}
                onClick={() => clickedBlockType(el)}
              >
                <div
                  css={css`
                    display: flex;
                    margin-right: 10px;
                  `}
                >
                  {el.icon}
                </div>

                {el.text}
              </div>
            );
          })}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          onClick={() => props.closeAddModal()}
        >
          {t("actions.cancel")}
        </Button>

        <Button onClick={onConfirm} disabled={!blockType}>
          {t("actions.add")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
