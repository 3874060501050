import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { theme } from "../../themes/variables";
import { CollapsableContainer } from "../../components/CollapsableContainer";
import { DraggableComponent } from "../../components/Draggable";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { JourneyCourse } from "../../types/JourneyCourse";
import { Headers } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getJourney } from "../../actions/journeys";
import { pageLoading } from "../../helpers/pageLoading";
import { EntitySelectionModal } from "../../components/EntitySelectionModal";
import { getCourses } from "../../actions/course/getCourses";
import { RequiredDataType } from "../../types/RequiredDataType";
import { addJourneyCourses } from "../../actions/journeys/addJourneyCourses";
import { deleteJourneyCourse } from "../../actions/journeys/deleteJourneyCourse";
import { updateJourneyCourseOrder } from "../../actions/journeyCourse/updateJourneyCourse";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Trash2 } from "react-feather";
import { customToast } from "../../components/customToast";
import { CoursePublishStatus } from "../../components/CoursePublishStatus";
import { ModalDelete } from "../../modals/ModalDelete";
import { Footer } from "../../components/Footer";

const entityCourseSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getCourses({ searchText });

  return response.data.map((item) => {
    return {
      id: String(item.uuid),
      label: (
        <div
          style={{
            position: "relative",
          }}
        >
          {item.name}
          <CoursePublishStatus course={item} />
        </div>
      ),
    };
  });
};

export function JourneyPage() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [deleteCourse, setDeleteCourse] = useState<null | string>(null);
  const history = useHistory();

  const params = useParams<{
    id: string;
  }>();

  const {
    data: journey,
    error: journeyError,
    refetch,
  } = useNewFetch(getJourney, {
    journeyId: params.id,
  });

  if (!journey || journeyError) {
    return pageLoading(journeyError);
  }

  const courses = journey && journey.courses ? journey.courses : [];

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/admin/journeys/${params.id}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/admin/journeys/${params.id}/edit`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("journeys.title"),
      url: "/admin/journeys",
    },
  ];

  const onDragEnd = async (journeyId: string, items: JourneyCourse[]) => {
    try {
      await updateJourneyCourseOrder({ journeyId, items });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{journey.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{journey.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <CollapsableContainer
            key={journey.uuid}
            css={css`
              margin-top: 30px;
              :first-of-type {
                margin-top: 0;
              }
            `}
            isExpandable={journey.courses.length > 0}
            isOpened={true}
            headerControls={[
              <Button
                type="button"
                small
                name="add_course"
                children={t("actions.add-course")}
                onClick={() => {
                  setModal(true);
                }}
              />,
            ]}
            title={
              <Headers.H3>
                {courses.length} {t("courses.title")}
              </Headers.H3>
            }
          >
            <DraggableComponent
              items={journey.courses}
              getDraggableId={(row) => row.course.uuid}
              isDragDisabled={false}
              onDragEnd={(items) => onDragEnd(journey.uuid, items)}
              controls={(row) => {
                return [
                  <Button
                    small
                    background={theme.colors.transparent}
                    border={`1px solid ${theme.colors.dark}`}
                    color={theme.colors.dark}
                    hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.dark}; border: 1px solid ${theme.colors.transparent};`}
                    css={css`
                      margin-right: 10px;
                    `}
                    name="edit-course"
                    onClick={() => {
                      history.push(`/courses/${row.course.uuid}/edit`);
                    }}
                  >
                    {t("journeys.edit")}
                  </Button>,
                  <Button
                    small
                    css={css`
                      height: 32px;
                      vertical-align: middle;
                      min-width: 0;
                    `}
                    name="delete-course"
                    background={theme.colors.transparent}
                    border={`1px solid ${theme.colors.dark}`}
                    color={theme.colors.dark}
                    hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.dark}; border: 1px solid ${theme.colors.transparent};`}
                    onClick={() => {
                      setDeleteCourse(row.course.uuid);
                    }}
                  >
                    <Trash2 size={15} />
                  </Button>,
                ];
              }}
            >
              {(row) => {
                return (
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Link
                      css={css`
                        position: relative;
                      `}
                      to={`/courses/${row.course.uuid}/syllabus`}
                    >
                      {row.course.name}
                      <CoursePublishStatus course={row.course} />
                    </Link>
                  </div>
                );
              }}
            </DraggableComponent>
          </CollapsableContainer>
        </Block>
        <Footer />
      </Wrapper>
      <EntitySelectionModal
        show={modal}
        onClose={() => {
          setModal(false);
        }}
        selectedItems={[]}
        disabledItems={courses.map((course) => ({
          id: course.course.uuid,
          label: course.course.name,
        }))}
        searchCallback={entityCourseSearchCallback}
        onConfirm={async (selected) => {
          await addJourneyCourses(
            journey.uuid,
            selected.map((s) => s.id)
          );

          await refetch();

          setModal(false);
        }}
      />
      <ModalDelete
        isOpen={!!deleteCourse}
        onClose={() => setDeleteCourse(null)}
        onDelete={async () => {
          if (deleteCourse) {
            await deleteJourneyCourse(journey.uuid, deleteCourse);
            await refetch();
          }
          setDeleteCourse(null);
        }}
      />
    </>
  );
}
