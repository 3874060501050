import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { ReactComponent as PlusSVG } from "../../../assets/icons/PlusSmall.svg";
import { Button } from "../../Button";

export default function SlidersActions({
  onSetModal,
}: {
  onSetModal: (modal: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
      `}
    >
      <div
        css={css`
          font-size: 30px;
          line-height: 42px;
        `}
      >
        {t("slider.slides")}
      </div>
      <div>
        <Button
          css={css`
            display: flex;
            align-items: center;
            color: ${theme.colors.black};
            background-color: ${theme.colors.white};
            border: 1px solid ${theme.colors.black};
            padding: 10px 20px;
          `}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          onClick={() => onSetModal(true)}
          icon={<PlusSVG color={theme.colors.primary} />}
          iconRight
        >
          <div
            css={css`
              display: flex;
            `}
          >
            {t("slider.create")}
          </div>
        </Button>
      </div>
    </div>
  );
}
