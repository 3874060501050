import React, { useRef } from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { ReactComponent as PlusIcon } from "../assets/icons/Plus.svg";
import { ReactComponent as MinusIcon } from "../assets/icons/Minus.svg";

export function CollapseItem(props: {
  title: string;
  content: string;
  isActive: boolean;
  onChange: () => void;
}) {
  const content = useRef(null as null | HTMLDivElement);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: fit-content;
      `}
    >
      <button
        onClick={props.onChange}
        css={css`
          background-color: ${theme.colors.transparent};
          text-align: left;
          cursor: pointer;
          display: flex;
          align-items: center;
          border: none;
          outline: none;
          padding: 0;
          transition: background-color 0.6s ease;
        `}
      >
        {props.isActive ? (
          <MinusIcon width={20} height={20} color={theme.colors.dark} />
        ) : (
          <PlusIcon width={20} height={20} color={theme.colors.dark} />
        )}

        <Headers.H4
          css={css`
            font-size: 20px;
            line-height: 30px;
            font-weight: normal;
            width: 100%;
            margin-left: 12px;
            margin-bottom: 0;
          `}
        >
          {props.title}
        </Headers.H4>
      </button>

      <div
        css={css`
          overflow: hidden;
          transition: max-height 0.6s ease;
          max-height: ${!props.isActive
            ? "0px"
            : content &&
              content.current &&
              content.current.scrollHeight + "px"};
        `}
        ref={content}
      >
        <div
          css={css`
            margin-top: 12px;
            margin-left: 32px;
          `}
          dangerouslySetInnerHTML={{ __html: props.content }}
        ></div>
      </div>
    </div>
  );
}
