import { authorised } from "../../request";
import { Expert } from "../../types/Expert";

type Args = {
  data: Omit<Expert, "id">;
}

export async function createExpert({data}: Args) {
  const API = authorised();

  return API.request<Expert>({
    method: "POST",
    url: `/api/studio/organisation/experts/`,
    data,
  });
}
