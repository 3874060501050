import { authorised } from "../../../request";
import { Order } from "../../../types/admin/Order";

type Args = {
  order_id: string;
};

export async function getOrderDetail({ order_id }: Args) {
  const API = authorised();

  return await API.request<Order>({
    method: "GET",
    url: `/api/enterprise/orders/${order_id}/`,
  });
}
