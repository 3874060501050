import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import {
  EditCreateUserSegment,
  FormValues,
} from "../../../components/Admin/UserSegment/EditCreateUserSegment";

import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { createUserSegment } from "../../../actions/admin/userSegment";
import { Footer } from "../../../components/Footer";

export function UserSegmentCreatePage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    try {
      await createUserSegment({ data });

      history.push(`/admin/user-segment`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("user_segment.new")}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateUserSegment
            onSubmitCallback={onSubmit}
            title={t("user_segment.new")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
