import { authorised } from "../../../request";
import { UserSegment } from "../../../types/admin/UserSegment";

type UserSegmentArgs = Omit<UserSegment, "id" | "users_count">;

type Args = {
  data: UserSegmentArgs;
};

export async function createUserSegment({ data }: Args) {
  const API = authorised();

  return API.request<UserSegment>({
    method: "POST",
    url: `/api/enterprise/user-segment/`,
    data,
  });
}
