import { RequiredDataType } from "../../types/RequiredDataType";
import { authorised } from "../../request";
import { Journey } from "../../types/Journey";

export type IUpdateJourney = Pick<
  Journey,
  "name" | "description" | "image" | "subtitle"
> & {
  faq_items: RequiredDataType[];
  user_segments: RequiredDataType[];
};

export async function updateJourney(id: string, data: IUpdateJourney) {
  const API = authorised();

  return API.request<Journey>({
    method: "PUT",
    url: `/api/enterprise/journeys/${id}/`,
    data,
  });
}
