import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "./customToast";
import * as Yup from "yup";
import { InputField } from "../forms/fields/InputField";
import { SelectField } from "../forms/fields/SelectField";
import { TextareaField } from "../forms/fields/TextareaField";
import { EntitySelectionField } from "../forms/fields/EntitySelectionField";
import { InputDurationField } from "../forms/fields/InputDurationField";
import { InputRepeater } from "../forms/fields/InputRepeater";
import { Button } from "./Button";
import { SelectComponent } from "./SelectComponent";
import { Lesson, LessonType, LessonIcon, LessokSkill } from "../types/Lesson";
import { RequiredDataType } from "../types/RequiredDataType";
import LessonPodcast from "../assets/img/Lesson-Podcast.png";
import LessonQuiz from "../assets/img/Lesson-Quiz.svg";
import LessonText from "../assets/img/Lesson-Text.png";
import LessonVideo from "../assets/img/Lesson-Video.png";
import { ReactComponent as LinkIcon } from "../assets/icons/Link.svg";

import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { getInstructors } from "../actions/lesson";
import { getSkills } from "../actions/skill";
import { useNewFetch } from "../useAPI";
import { Instructor } from "../types/Instructor";
import { CheckboxField } from "../forms/fields/CheckboxField";
import { pageLoading } from "../helpers/pageLoading";
import { FormMain, FormParent, FormSidebarContainer } from "./FormSidebar";
import { FormChecklist } from "./FormChecklists";

type LessonItem = Pick<
  Lesson,
  | "name"
  | "description"
  | "type"
  | "instructors"
  | "duration"
  | "icon"
  | "without_enrolment"
  | "skills"
>;

export interface FormValues {
  name: string;
  description: string;
  duration: string;
  type: LessonType;
  icon: LessonIcon | "";
  instructors: RequiredDataType[];
  without_enrolment: boolean;
  skills: LessokSkill[];
}
export type FormSubmitValues = FormValues & {
  icon: LessonIcon;
};

const entityInstructorsSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getInstructors({ searchText });

  return response.data.map((item) => {
    return {
      id: String(item.id),
      label: `${item.first_name} ${item.last_name}`,
    };
  });
};

const instructorItemsHandler = (
  fieldValue: Instructor[]
): RequiredDataType[] => {
  return fieldValue.map((item) => {
    return {
      id: String(item.id),
      label: `${item.first_name} ${item.last_name}`,
    };
  });
};

export function EditCreateLesson(props: {
  onSubmitCallback: (values: FormSubmitValues) => Promise<void>;
  nameMaxLength?: number;
  descriptionMaxLength?: number;
  initialData?: LessonItem | null;
  previewLessonUrl?: string;
}) {
  const {
    onSubmitCallback,
    nameMaxLength = 150,
    descriptionMaxLength = 500,
    initialData,
  } = props;

  const { t } = useTranslation();

  const { data: skills, error: skillsError } = useNewFetch(getSkills, {
    offset: 0,
  });

  if (!skills || skillsError) {
    return pageLoading(skillsError);
  }

  const lessonSkillChoices = skills.map((item) => {
    return {
      label: item.name,
      value: String(item.id),
    };
  });

  const lessonIconChoices: {
    label: string;
    value: LessonIcon | "";
    icon?: string;
  }[] = [
    {
      label: t("lesson.select_icon"),
      value: "",
      icon: "",
    },
    {
      label: t("lesson.type.text"),
      value: LessonIcon.TEXT,
      icon: LessonText,
    },
    {
      label: t("lesson.type.embed"),
      value: LessonIcon.EMBED,
      icon: LessonVideo,
    },
    {
      label: t("lesson.type.test"),
      value: LessonIcon.QUIZ,
      icon: LessonQuiz,
    },
    {
      label: t("lesson.type.podcast"),
      value: LessonIcon.PODCAST,
      icon: LessonPodcast,
    },
  ];

  Yup.addMethod(Yup.array, "unique", function (message, mapper) {
    return this.test("unique", message, function (list) {
      if (!list) return true;
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(nameMaxLength).required(),
    type: Yup.string().required(),
    icon: Yup.string().required(),
    duration: Yup.string().required(),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          skill: Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required(),
          }),
        })
      )
      .test("unique", t("admin.skill.skills-unique"), (list) => {
        return (
          list?.map((el) => el.skill.id).length ===
          new Set(list?.map((el) => el.skill.id)).size
        );
      }),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        name: values.name,
        description: values.description,
        duration: values.duration,
        type: values.type,
        icon: values.icon as LessonIcon,
        instructors: values.instructors,
        without_enrolment: values.without_enrolment,
        skills: values.skills,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: initialData?.name || "",
          description: initialData?.description || "",
          duration: initialData?.duration ? String(initialData.duration) : "",
          type: initialData?.type || LessonType.BLOCK,
          icon: initialData?.icon || "",
          instructors: instructorItemsHandler(initialData?.instructors || []),
          without_enrolment: initialData?.without_enrolment || false,
          skills: initialData?.skills || [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values, errors, setFieldValue }) => {
          return (
            <Form noValidate>
              <FormParent>
                <FormMain>
                  <InputField
                    label={`${t("lesson.fields.title")}*:`}
                    name={"name"}
                    id="name"
                    maxLength={nameMaxLength}
                  />

                  <TextareaField
                    label={`${t("lesson.fields.description")}:`}
                    name={"description"}
                    id="description"
                    maxLength={descriptionMaxLength}
                  />

                  <hr />

                  <InputDurationField
                    id="duration"
                    label={`${t("lesson.meta.duration")}*:`}
                    name={`duration`}
                  />
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 32px;
                    `}
                  >
                    <SelectField
                      label={`${t("media.icon")}*:`}
                      name="icon"
                      id="icon"
                      choices={lessonIconChoices}
                    />
                  </div>

                  <EntitySelectionField
                    id="instructors"
                    name={`instructors`}
                    label={`${t("courses.instructors")}:`}
                    searchCallback={entityInstructorsSearchCallback}
                  />

                  <hr />

                  {!!skills.length && (
                    <div id="skills">
                      <InputRepeater<LessokSkill>
                        name="skills"
                        label={`${t("admin.skill.skill-name")}:`}
                        secondLabel={`${t("admin.skill.skill-level")}:`}
                        defaultValue={{
                          value: 1,
                          skill: skills[0],
                        }}
                      >
                        {({ idx }) => {
                          return (
                            <div
                              css={css`
                                display: flex;
                                align-items: center;
                                flex: 1 0 10%;
                              `}
                            >
                              <SelectComponent
                                value={
                                  values.skills[idx]
                                    ? String(values.skills[idx]?.skill.id)
                                    : "0"
                                }
                                choices={lessonSkillChoices}
                                onChange={(e) => {
                                  const value = skills.find(
                                    (el) => String(el.id) === e.target.value
                                  );

                                  setFieldValue(`skills[${idx}].skill`, value);
                                }}
                                css={css`
                                  margin-right: 15px;
                                `}
                              />

                              <SelectComponent
                                value={
                                  values.skills[idx]
                                    ? String(values.skills[idx]?.value)
                                    : "1"
                                }
                                choices={Array.from(Array(5).keys()).map(
                                  (el) => {
                                    return {
                                      label: `${String(el + 1)} ${t(
                                        "admin.skill.points"
                                      )}`,
                                      value: String(el + 1),
                                    };
                                  }
                                )}
                                onChange={(e) => {
                                  setFieldValue(
                                    `skills[${idx}].value`,
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          );
                        }}
                      </InputRepeater>
                    </div>
                  )}
                  <hr />

                  <div
                    css={css`
                      margin-top: 20px;
                      margin-bottom: 30px;
                      display: flex;
                    `}
                  >
                    <label
                      css={css`
                        margin-right: 20px;
                        font-size: 16px;
                        font-weight: bold;
                      `}
                    >
                      {`${t("lesson.without_enrolment")}:`}
                    </label>
                    <CheckboxField
                      name={"without_enrolment"}
                      id="without_enrolment"
                      value={values.without_enrolment}
                    />
                  </div>
                </FormMain>
                <FormSidebarContainer>
                  <FormChecklist
                    values={values}
                    fields={{
                      required: {
                        name: t("lessons.instructions.name"),
                        duration: t("lessons.instructions.duration"),
                        icon: t("lessons.instructions.icon"),
                      },
                      optional: {
                        description: t("lessons.instructions.description"),
                        instructors: t("lessons.instructions.instructors"),
                        skills: t("lessons.instructions.skills"),
                      },
                    }}
                  >
                    <hr />
                    {!initialData ? (
                      <Button
                        isSubmitting={isSubmitting}
                        type="submit"
                        disabled={!isValid}
                      >
                        {t("actions.create")}
                      </Button>
                    ) : (
                      <div
                        css={css`
                          margin-top: 25px;
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <div>
                          <Button
                            isSubmitting={isSubmitting}
                            type="submit"
                            disabled={!isValid}
                          >
                            {t("actions.update")}
                          </Button>
                        </div>
                        {props.previewLessonUrl && (
                          <div
                            css={css`
                              margin-top: 32px;
                            `}
                          >
                            <a
                              css={css`
                                font-size: 14px;
                              `}
                              target="_blank"
                              rel="noreferrer"
                              href={props.previewLessonUrl}
                            >
                              {t("lesson.preview_lesson")}
                              <LinkIcon
                                css={css`
                                  margin-left: 10px;
                                `}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </FormChecklist>
                </FormSidebarContainer>
              </FormParent>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
