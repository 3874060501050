import { authorised } from "../../../request";
import { Announcement } from "../../../types/admin/Announcement";
import { RequiredDataType } from "../../../types/RequiredDataType";

type Args = {
  uuid: string;
  data: Pick<
    Announcement,
    "title" | "short_description" | "publish_date" | "url"
  > & {
    user_segments?: RequiredDataType[];
  };
};

export async function updateAnnouncement({ uuid, data }: Args) {
  const API = authorised();

  return API.request<Announcement>({
    method: "PUT",
    url: `/api/enterprise/announcements/${uuid}/`,
    data,
  });
}
