import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { Tabs } from "../../../components/Tabs";
import { Breadcrumbs } from "../../../components/Breadcrumbs";
import { SEO } from "../../../components/SEO";
import {
  EditCreateQuiz,
  FormValues,
} from "../../../components/TestingEditorLesson/EditCreateQuiz";
import { TestList } from "../../../components/TestingEditorLesson/TestList";
import { Headers } from "../../../helpers/layout";

import { useNewFetch } from "../../../useAPI";
import { getLesson } from "../../../actions/lesson";
import { getTests, getTest, updateTest } from "../../../actions/test";
import { pageLoading } from "../../../helpers/pageLoading";
import { Footer } from "../../../components/Footer";

export function EditTestPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
    testId: string;
  }>();

  const { data: lesson, error: lessonError } = useNewFetch(getLesson, {
    courseId: params.courseId,
    lessonId: params.lessonId,
  });

  const { data: tests, error: testsError } = useNewFetch(getTests, {
    lessonId: params.lessonId,
  });

  const { data: test, error: testError } = useNewFetch(getTest, {
    lessonId: params.lessonId,
    testId: params.testId,
  });

  if (!lesson || lessonError) {
    return pageLoading(lessonError);
  }

  if (!tests || testsError) {
    return pageLoading(testsError);
  }

  if (!test || testError) {
    return pageLoading(testError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.content_editor"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/content_editor`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/edit`,
    },
    {
      title: t("breadcrumbs.testing"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/testing`,
      forceHightlight: true,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: lesson.course!.name,
      url: `/courses/${params.courseId}/syllabus`,
    },
  ];

  const submitUpdateTest = async (data: FormValues) => {
    await updateTest({
      lessonId: params.lessonId,
      testId: params.testId,
      data: {
        ...data,
        variants: data.variants ? data.variants : [],
      },
    });

    customToast.success(t("status.success"));
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{test.internal_name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{lesson.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div
            css={css`
              display: grid;
              grid-template-columns: 30% 70%;
            `}
          >
            <TestList items={tests} />

            <EditCreateQuiz
              type={lesson.type}
              initialData={test}
              onSubmitCallback={submitUpdateTest}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
