import { authorised } from "../../request";

type Args = {
  lessonId: string;
  testId: string;
};

export async function deleteTest({ lessonId, testId }: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/lessons/${lessonId}/tests/${testId}/`,
  });
}
