import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { css } from "styled-components/macro";
import { ChromePicker } from "react-color";
import { useClickOutside } from "../hooks/useClickOutside";
import { theme } from "../themes/variables";

function Portal(props: {
  children: React.ReactNode | React.ReactNode[] | string | null;
}) {
  const rootElement = document.getElementById("root");

  if (!rootElement) {
    return null;
  }

  return createPortal(props.children, rootElement);
}

export function ColorPicker(props: {
  value?: string;
  onChange?: (value: string) => void;
}) {
  const [display, setDisplay] = useState(false);

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    if (display) setDisplay(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleClose);
    return () => document.removeEventListener("scroll", handleClose);
  });

  const modal = document.querySelector(".ReactModal__Content");

  useEffect(() => {
    if (modal) modal.addEventListener("scroll", handleClose);

    return () => {
      if (modal) modal.removeEventListener("scroll", handleClose);
    };
  });

  const picker = useRef<HTMLDivElement>(null);

  const container = useRef<HTMLDivElement>(null);

  useClickOutside(picker, handleClose);

  return (
    <div
      ref={container}
      css={css`
        position: relative;
      `}
    >
      <button
        onClick={handleOpen}
        css={css`
          display: block;
          padding: 4px 10px;
          border-radius: 10px;
          background: #fff;
          border: 1px solid ${theme.colors.gray4};
        `}
      >
        <div
          css={css`
            width: 100px;
            height: 32px;
            border-radius: 10px;
            border: 1px solid ${theme.colors.gray4};
            background-color: ${props.value};
          `}
        ></div>
      </button>

      {display ? (
        <Portal>
          <div
            ref={picker}
            css={css`
              position: absolute;
              top: ${container.current!.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              220}px;
              left: ${container.current!.getBoundingClientRect().left -
              document.body.getBoundingClientRect().left}px;
              z-index: 10000;
            `}
          >
            <ChromePicker
              color={props.value}
              onChange={(result) =>
                props.onChange && props.onChange(result.hex)
              }
            />
          </div>
        </Portal>
      ) : null}
    </div>
  );
}
