import { authorised } from "../../../request";
import { SignupField } from "../../../types/admin/SignupField";

type SignupFieldArgs = Omit<SignupField, "uuid">

type Args = {
  section: "campus" | "studio";
  data: SignupFieldArgs;
}

export async function createSignupField({section, data}: Args) {
  const API = authorised();

  return API.request<SignupField>({
    method: "POST",
    url: `/api/enterprise/signup-field/${section}/`,
    data
  });
}
