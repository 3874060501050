import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { deletePage } from "../../../actions/admin/page";
import { PageStatus, Page } from "../../../types/admin/Page";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { InputComponent } from "../../../components/InputComponent";
import { Button } from "../../../components/Button";
import { SEO } from "../../../components/SEO";
import { SelectOption } from "../../../components/SelectComponent";
import { Tab, Tabs } from "../../../components/Tabs";
import PagesTable from "../../../components/Admin/PageManagment/PagesTable";
import StatusFilter from "../../../components/Admin/Orders/StatusFilter";
import { ModalDelete } from "../../../modals/ModalDelete";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Plus.svg";
import { Footer } from "../../../components/Footer";

export function PageOverviewPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);

  const [query, setQuery] = useState(params.get("query") || undefined);
  const [status, setStatus] = useState(params.get("status") || undefined);

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const onSelectStatus = (val: string) => {
    ref.current.setQueryArgs("status", val);
    setStatus(val);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteCandidate, setDeleteCandidate] = useState<Page | null>(null);

  const statusOptions: SelectOption[] = [
    {
      label: t("admin.pages.status.all"),
      value: "",
    },
    {
      label: t("admin.pages.status.draft"),
      value: PageStatus.DRAFT,
    },
    {
      label: t("admin.pages.status.published"),
      value: PageStatus.PUBLISHED,
    },
  ];

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  const onDelete = async () => {
    if (deleteCandidate) {
      await deletePage({ id: deleteCandidate.uuid });

      setDeleteCandidate(null);
      setDeleteModal(false);
      ref.current.refetch();
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.pages.title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Headers.H3
                  css={css`
                    margin-right: 150px;
                  `}
                >
                  {t("admin.pages.title")}
                </Headers.H3>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <StatusFilter
                    label={t("orders.status.status") + ":"}
                    options={statusOptions}
                    defaultOption={status}
                    onChangeOption={(e) => onSelectStatus(e.target.value)}
                  />
                </div>
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <InputComponent
                  placeholder={t("admin.pages.search-title")}
                  value={query}
                  icon={<SearchIcon width={15} height={15} />}
                  onChange={(e) => onSearch(e.target.value)}
                />

                <Link to="/admin/pages/new">
                  <Button
                    css={css`
                      margin-left: 63px;
                    `}
                    background={theme.colors.dark}
                    icon={
                      <PlusIcon
                        width={14}
                        height={14}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    {t("admin.pages.new-page")}
                  </Button>
                </Link>
              </div>
            </div>

            <PagesTable
              forwardRef={ref}
              statusOptions={statusOptions}
              setDeleteCandidate={(page: Page) => setDeleteCandidate(page)}
              setDeleteModal={(value: boolean) => setDeleteModal(value)}
            />

            <ModalDelete
              isOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              onDelete={onDelete}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
