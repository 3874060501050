import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Button } from "./Button";
import { Modal } from "./Modal/Modal";
import { ModalBody } from "./Modal/ModalBody";
import { ModalFooter } from "./Modal/ModalFooter";
import { ModalHeader } from "./Modal/ModalHeader";

export function ActionModal({
  deleteModal,
  contentLabel,
  contentBody,
  actionButtonText,
  setActionModal,
  onConfirm,
}: {
  deleteModal: boolean;
  contentLabel?: string;
  contentBody: string;
  actionButtonText: string;
  setActionModal: (modal: boolean) => void;
  onConfirm: () => Promise<void>;
}) {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);

  const onConfirmAction = async () => {
    setIsloading(true);
    await onConfirm();
    setIsloading(false);
  };

  return (
    <Modal modalIsOpen={deleteModal} onClose={() => setActionModal(false)}>
      <ModalHeader closeIcon onClose={() => setActionModal(false)}>
        {contentLabel || t("actions.sure")}
      </ModalHeader>

      <ModalBody>{contentBody}</ModalBody>

      <ModalFooter>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; background: ${theme.colors.primary}; color: ${theme.colors.white};`}
          onClick={() => setActionModal(false)}
        >
          {t("actions.cancel")}
        </Button>

        <Button onClick={onConfirmAction} disabled={isLoading}>
          {actionButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
