import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "styled-components/macro";
import { Trash2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { AcceptedMimeTypes } from "../types/File";
import { Button } from "./Button";
import { AudioPlayer } from "./AudioPlayer";
import { theme } from "../themes/variables";

import { ReactComponent as DocIcon } from "../assets/icons/DOC.svg";
import { ReactComponent as PDFIcon } from "../assets/icons/PDF.svg";
import { ReactComponent as PresentationIcon } from "../assets/icons/Presentation.svg";
import { ReactComponent as SVGIcon } from "../assets/icons/SVG.svg";

export type DroppedFile = {
  preview: string;
  file: File;
};

export function DropFile(props: {
  initialMessage?: React.ReactNode | React.ReactNode[] | string;
  className?: string;
  selectedFile?: DroppedFile | null;
  acceptedMimeTypes?: AcceptedMimeTypes[];
  onFileAdded?: (file: DroppedFile) => void;
  onFileDeleted?: () => void;
  onClickPreview?: () => void;
}) {
  const [preview, setPreview] = useState<string | null>(null);

  const [droppedFile, setDroppedFile] = useState<File | null>(null);

  const { t } = useTranslation();

  const {
    initialMessage = t("actions.drop-files"),
    className,
    selectedFile,
    onFileAdded = () => {},
    onFileDeleted = () => {},
    onClickPreview,
  } = props;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file: File = acceptedFiles[0];

      if (file) {
        setDroppedFile(file);

        const tmpFile = URL.createObjectURL(file);

        onFileAdded({
          preview: tmpFile,
          file,
        });

        setPreview(tmpFile);
      }
    },
    [onFileAdded]
  );

  const deletePreview = () => {
    setPreview(null);
    onFileDeleted();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      {preview ? (
        <div
          onClick={onClickPreview}
          css={css`
            position: relative;
            width: 100%;
            height: 170px;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border-radius: 10px;
            cursor: pointer;
          `}
        >
          {droppedFile && (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5px;
                border-radius: 10px;
                ${selectedFile
                  ? `border: 2px solid ${theme.colors.primary};`
                  : `border: 2px solid ${theme.colors.transparent};`}
              `}
            >
              {(droppedFile.type === AcceptedMimeTypes.MP3 ||
                droppedFile.type === AcceptedMimeTypes.MPEG ||
                droppedFile.type === AcceptedMimeTypes.WEBA ||
                droppedFile.type === AcceptedMimeTypes.WEBM) && (
                <AudioPlayer src={selectedFile?.preview} />
              )}

              {(droppedFile.type === AcceptedMimeTypes.DOC ||
                droppedFile.type === AcceptedMimeTypes.DOCX ||
                droppedFile.type === AcceptedMimeTypes.XLS ||
                droppedFile.type === AcceptedMimeTypes.XLSX) && (
                <DocIcon width={40} height={40} color={theme.colors.primary} />
              )}

              {droppedFile.type === AcceptedMimeTypes.PDF && (
                <PDFIcon width={40} height={40} color={theme.colors.primary} />
              )}

              {(droppedFile.type === AcceptedMimeTypes.PPTX ||
                droppedFile.type === AcceptedMimeTypes.PPT) && (
                <PresentationIcon
                  width={40}
                  height={40}
                  color={theme.colors.primary}
                />
              )}

              {droppedFile.type === AcceptedMimeTypes.SVG && (
                <SVGIcon width={40} height={40} color={theme.colors.primary} />
              )}

              <span
                css={css`
                  font-size: 16px;
                  line-height: 22px;
                  margin-top: 12px;
                `}
              >
                {droppedFile.name}
              </span>
            </div>
          )}

          <Button
            css={css`
              position: absolute;
              top: 0;
              right: 0;
              min-width: 2rem;
              min-height: 2rem;
            `}
            flat
            background={theme.colors.transparent}
            color={theme.colors.dark}
            hoverStyles={`color: ${theme.colors.error}; background: ${theme.colors.transparent};`}
            onClick={() => deletePreview()}
          >
            <div title={t("actions.remove")}>
              <Trash2 size={20} />
            </div>
          </Button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          css={css`
            height: 170px;
            width: 100%;
            border: 1px dashed
              ${isDragActive ? theme.colors.primary : theme.colors.gray2};
            background: ${theme.colors.white};
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            ${isDragActive ? `background: ${theme.colors.primaryLighter};` : ""}
          `}
          className={className}
        >
          <input
            {...getInputProps()}
            accept={Object.values(AcceptedMimeTypes)
              .filter((el) => {
                if (props.acceptedMimeTypes) {
                  return props.acceptedMimeTypes.includes(el);
                }

                return true;
              })
              .map((el) => el)
              .join(",")}
          />

          <div>{initialMessage}</div>
        </div>
      )}
    </div>
  );
}
