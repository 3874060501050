import { authorised } from "../../request";

type Args = {
  id: string | number;
};

export async function deleteStudioFaqItem({ id }: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/faq-items/${id}/`,
  });
}
