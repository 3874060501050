import { authorised } from "../../request";
import { Skill } from "../../types/Skill";

export async function getSkills() {
  const API = authorised();

  return await API.request<Skill[]>({
    method: "GET",
    url: `/api/studio/skills/`,
  });
}
