import React from "react";
import { css } from "styled-components/macro";
import { RadioField } from "./RadioField";
import { Hint } from "../../components/Hint";
import { Field, FieldProps } from "formik";
import { theme } from "../../themes/variables";

export interface RadioOption {
  label: string;
  value: string | boolean;
  icon?: React.ReactNode;
}

export function RadioFields(props: {
  name: string;
  label: string;
  hint?: string;
  choices: RadioOption[];
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
}) {
  return (
    <Field name={props.name}>
      {({ meta }: FieldProps) => {
        return (
          <div>
            <div
              css={css`
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: bold;
              `}
            >
              {props.label}
            </div>
            {props.hint && <Hint>{props.hint}</Hint>}

            {props.choices.map((choice, idx) => (
              <RadioField
                key={idx}
                label={choice.label}
                icon={choice.icon}
                name={props.name}
                labelRight={props.labelRight}
                disabled={props.disabled}
                readonly={props.readonly}
                value={choice.value}
                className={props.className}
                validate={props.validate}
                css={css`
                  margin-right: 2rem;
                  margin-bottom: 1rem;
                  :last-of-type {
                    margin-right: 0;
                  }
                `}
              ></RadioField>
            ))}
            {meta.error && meta.touched && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
