import { UserProfile } from "./UserProfile";
import { Image } from "./Image";
import { CourseLesson } from "./CourseLesson";
import { Faq } from "./Faq";

export enum CourseLevel {
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
}

export enum CourseVisibility {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum CoursePublishRequestStatus {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  APPROVED = "APPROVED",
  CANCELLED = "CANCELLED",
}

export type CoursePublishRequest = {
  id: number;
  submission_date: string;
  approved_date?: string;
  status: CoursePublishRequestStatus;
  rejection_reason?: string;
};

export type Course = {
  name: string;
  course_publish_request: CoursePublishRequest | null;
  uuid: string;
  description?: string;
  subtitle?: string;
  duration?: number;
  owner?: UserProfile;
  level?: CourseLevel;
  visibility?: CourseVisibility;
  image?: Image | null;
  price?: string;
  faq_items?: Faq[];
  learning_points: string[];
  summary_content_html: string;
  course_starts: string;
  course_for: string;
  lessons: CourseLesson[];
  has_certificate: boolean;
  logo?: Image | null;
  preview_link?: string;
  is_external?: boolean;
};

export interface MetaCourse {
  name: string;
  uuid: string;
}
