import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { Button } from "../../../components/Button";
import { Separator } from "../../../components/Separator";
import { EditCreateSignupModal, FormValues } from "./EditCreateSignupModal";

import { ReactComponent as PlusIcon } from "../../../assets/icons/Plus.svg";

export function AddSignupField(props: {
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <Button
          background={theme.colors.transparent}
          color={theme.colors.dark}
          icon={<PlusIcon width={18} height={18} color={theme.colors.dark} />}
          hoverStyles={`opacity: 0.85;`}
          onClick={() => setIsOpen(true)}
        >
          {t("signup_fields.add")}
        </Button>

        <Separator />
      </div>

      <EditCreateSignupModal
        label={t("signup_fields.add_new")}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={props.onSubmit}
      />
    </>
  );
}
