import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { TargetUserProfile } from "../../types/admin/UserProfile";
import { Hint } from "../Hint";
import { Button } from "../Button";
import { InputComponent, InputStyle } from "../InputComponent";
import { Avatar } from "../Avatar";
import { InputLabel } from "../InputLabel";

import { useNewFetch } from "../../useAPI";
import { getUsers } from "../../actions/admin/user";
import { pageLoading } from "../../helpers/pageLoading";

import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as ReadIcon } from "../../assets/icons/Read.svg";

export function UserChose(props: {
  label?: string;
  hint?: string;
  searchType: "CAMPUS" | "STUDIO";
  chosenUsers: Partial<TargetUserProfile>[];
  onChose: (user: TargetUserProfile) => void;
  onRemove: (user: TargetUserProfile) => void;
  className?: string;
}) {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);

  const { data: users, error: usersError } = useNewFetch(getUsers, {
    limit: 100 * (page + 1),
    offset: page * 100,
    query: search,
    type: props.searchType,
    is_verified: "true",
  });

  useEffect(() => {
    setPage(0);
  }, [search]);

  if (!users || usersError) {
    return pageLoading(usersError);
  }

  return (
    <div className={props.className}>
      {props.label && <InputLabel label={props.label} />}

      <div
        css={css`
          border: 1px solid ${theme.colors.gray4};
          border-radius: 20px;
          padding: 0 20px 20px 20px;
        `}
      >
        <InputComponent
          value={search}
          style={InputStyle.UNDERLINE}
          onChange={(e) => setSearch(e.target.value)}
          icon={
            <SearchIcon width={20} height={20} color={theme.colors.primary} />
          }
        />

        <div
          css={css`
            height: 400px;
            overflow: auto;
          `}
        >
          <div
            css={css`
              margin-top: 22px;
              padding-top: 10px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              grid-row-gap: 20px;
              grid-column-gap: 24px;

              ::-webkit-scrollbar {
                width: 20px;
              }

              ::-webkit-scrollbar-thumb {
                background-color: ${theme.colors.primary};
                border-radius: 20px;
                border: 6px solid transparent;
                background-clip: content-box;
              }
              ::-webkit-scrollbar-track {
                background-color: ${theme.colors.gray3};
                border: 9px solid transparent;
                background-clip: content-box;
              }
            `}
          >
            {users.results.map((user) => {
              const isChosen = props.chosenUsers
                .map((el) => el.id)
                .includes(user.id);

              return (
                <div
                  key={user.id}
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                  `}
                  onClick={() => {
                    if (isChosen) {
                      props.onRemove(user);
                    } else {
                      props.onChose(user);
                    }
                  }}
                >
                  <div
                    css={css`
                      position: relative;
                      border-radius: 50%;
                      ${isChosen
                        ? css`
                            border: 2px solid ${theme.colors.primary};
                          `
                        : css`
                            border: 2px solid ${theme.colors.gray4};
                          `}
                    `}
                  >
                    {isChosen && (
                      <div
                        css={css`
                          position: absolute;
                          top: -10px;
                          right: -10px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          background: ${theme.colors.primary};
                          z-index: 1;
                        `}
                      >
                        <ReadIcon
                          width={12}
                          height={12}
                          color={theme.colors.white}
                        />
                      </div>
                    )}

                    <Avatar url={user.avatar?.image} size={40} noBorder />
                  </div>

                  <div
                    css={css`
                      margin-top: 8px;
                      text-align: center;
                      font-size: 16px;
                      ${isChosen
                        ? css`
                            color: ${theme.colors.dark};
                          `
                        : css`
                            color: ${theme.colors.gray4};
                          `}
                    `}
                  >
                    {user.first_name} {user.last_name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {users.next && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
            `}
          >
            <Button small onClick={() => setPage((prev) => prev + 1)}>
              {t("media.load")}
            </Button>
          </div>
        )}
      </div>

      {props.hint && (
        <Hint
          css={css`
            margin-top: 8px;
          `}
        >
          {props.hint}
        </Hint>
      )}
    </div>
  );
}
