import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { InputField } from "../forms/fields/InputField";
import { TextareaField } from "../forms/fields/TextareaField";
import { Button } from "./Button";
import { Journey, JourneyFaq } from "../types/Journey";
import { RequiredDataType } from "../types/RequiredDataType";
import { customToast } from "./customToast";

import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { CreateJourney } from "../actions/journeys";
import { uploadImage } from "../actions/image";
import { getStudioFaqList } from "../actions/faq";
import { EntitySelectionField } from "../forms/fields/EntitySelectionField";
import { ImageUploadField } from "../forms/fields/ImageUploadField";
import { FormMain, FormParent, FormSidebarContainer } from "./FormSidebar";
import { FormChecklist } from "./FormChecklists";
import { UserSegment } from "../types/admin/UserSegment";
import { getUserSegments } from "../actions/admin/userSegment";

export interface FormValues
  extends Omit<CreateJourney, "faq_items" | "user_segments"> {
  faq_items: RequiredDataType[];
  user_segments: RequiredDataType[];
}

type JourneyData = Pick<
  Journey,
  "description" | "name" | "image" | "faq_items" | "subtitle" | "user_segments"
>;

const entityFAQSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getStudioFaqList({ searchText });

  return response.data.map((item) => {
    return {
      id: String(item.id),
      label: item.question,
    };
  });
};
const entityUserSegmentSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getUserSegments({ searchText, limit: 100 });

  return response.data.results.map((item) => {
    return {
      id: String(item.id),
      label: item.name,
    };
  });
};

const faqItemsHandler = (fieldValue: JourneyFaq[]): RequiredDataType[] => {
  return fieldValue.map((item) => {
    return {
      id: String(item.faq_item.id),
      label: item.faq_item.question,
    };
  });
};
const userSegmentsHandler = (fieldValue: UserSegment[]): RequiredDataType[] => {
  return fieldValue.map((item) => {
    return {
      id: String(item.id),
      label: item.name,
    };
  });
};

export function EditCreateJourney(props: {
  onSubmitCallback: (values: FormValues) => Promise<void>;
  titleMaxLength?: number;
  descriptionMaxLength?: number;
  initialData?: JourneyData | null;
}) {
  const {
    onSubmitCallback,
    titleMaxLength = 150,
    descriptionMaxLength = 500,
    initialData,
  } = props;

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(titleMaxLength).required(),
    description: Yup.string().max(descriptionMaxLength),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        name: values.name || "",
        subtitle: values.subtitle || "",
        description: values.description || "",
        image: values.image || null,
        faq_items: values.faq_items || [],
        user_segments: values.user_segments || [],
      });

      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: initialData?.name || "",
          subtitle: initialData?.subtitle || "",
          description: initialData?.description || "",
          image: initialData?.image || null,
          faq_items: faqItemsHandler(initialData?.faq_items || []),
          user_segments: userSegmentsHandler(initialData?.user_segments || []),
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form noValidate>
              <FormParent>
                <FormMain>
                  <InputField
                    id="name"
                    label={`${t("journeys.fields.title")}*:`}
                    name={"name"}
                    maxLength={titleMaxLength}
                  />
                  <EntitySelectionField
                    id="user_segments"
                    name={`user_segments`}
                    label={`${t("journeys.fields.user_segments")}:`}
                    description={
                      !values.user_segments.length
                        ? t("journeys.fields.user_segments_description")
                        : ""
                    }
                    searchCallback={entityUserSegmentSearchCallback}
                  />
                  <hr />
                  <InputField
                    id="subtitle"
                    label={`${t("journeys.fields.subtitle")}:`}
                    name={"subtitle"}
                    maxLength={titleMaxLength}
                  />
                  <TextareaField
                    id="description"
                    label={`${t("journeys.fields.description")}*:`}
                    placeholder={t("journeys.fields.description_description")}
                    name={"description"}
                    maxLength={descriptionMaxLength}
                  />
                  <hr />
                  <ImageUploadField
                    id="image"
                    label={`${t("journeys.fields.image")}:`}
                    name={"image"}
                    uploadFn={uploadImage}
                  />
                  <hr />
                  <EntitySelectionField
                    id="faq_items"
                    name={`faq_items`}
                    label={`${t("journeys.fields.faq")}:`}
                    description={t("journeys.fields.faq_description")}
                    searchCallback={entityFAQSearchCallback}
                  />
                </FormMain>
                <FormSidebarContainer>
                  <FormChecklist
                    values={values}
                    fields={{
                      required: {
                        name: t("journeys.instructions.name"),
                      },
                      optional: {
                        user_segments: t("journeys.instructions.user_segments"),
                        subtitle: t("journeys.instructions.subtitle"),
                        description: t("journeys.instructions.description"),
                        image: t("journeys.instructions.image"),
                        faq_items: t("journeys.instructions.faq_items"),
                      },
                    }}
                  >
                    <hr />
                    {!initialData ? (
                      <Button
                        isSubmitting={isSubmitting}
                        type="submit"
                        disabled={!isValid}
                      >
                        {t("actions.create")}
                      </Button>
                    ) : (
                      <div
                        css={css`
                          margin-top: 25px;
                          display: flex;
                          align-self: flex-start;
                          flex-direction: row-reverse;
                        `}
                      >
                        <div
                          css={css`
                            text-align: right;
                          `}
                        >
                          <Button
                            isSubmitting={isSubmitting}
                            disabled={!isValid}
                            type="submit"
                          >
                            {t("actions.update")}
                          </Button>
                        </div>
                      </div>
                    )}
                  </FormChecklist>
                </FormSidebarContainer>
              </FormParent>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
