import { Card, ProgressCard } from "../../components/Card";
import { css } from "styled-components/macro";
import { theme } from "../../themes/variables";
import { Headers } from "../../helpers/layout";
import { useTranslation } from "react-i18next";
import {
  getCoursePerformanceWidgets,
  PerformancePeriod,
} from "../../actions/performance/getCoursePerformanceWidgets";
import { useNewFetch } from "../../useAPI";
import CountAnimation from "../../components/CountAnimation";

export function PerformanceWidgets(props: {
  period: PerformancePeriod;
  journeyUUID: string | null;
  segmentUUID: string | null;
}) {
  const { t } = useTranslation();
  const { data: widgetData, error } = useNewFetch(getCoursePerformanceWidgets, {
    period: props.period,
    journey_id: props.journeyUUID || undefined,
    segment_id: props.segmentUUID || undefined,
  });

  if (!widgetData || error) return null;

  const cardStyle = css`
    width: 240px;
    flex: 1 0 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 27px;

    &:last-child {
      margin-right: 0;
    }
  `;

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Card
          color={theme.colors.yellowBackground}
          padding={24}
          css={cardStyle}
        >
          <Headers.H4>{t("performance.total-enrolments")}:</Headers.H4>

          <span
            css={css`
              margin-top: 18px;
              font-size: 30px;
              line-height: 42px;
              color: ${theme.colors.primary};
            `}
          >
            <CountAnimation end={widgetData.total_enrolments} />
          </span>
        </Card>
        <Card color={theme.colors.blueViolet} padding={24} css={cardStyle}>
          <Headers.H4>
            {t("performance.avg-percent-course-completions")}:
          </Headers.H4>

          <span
            css={css`
              margin-top: 18px;
              font-size: 30px;
              line-height: 42px;
              color: ${theme.colors.blueMain};
            `}
          >
            <CountAnimation
              end={widgetData.avg_percent_course_completions}
              suffix={"%"}
            />
          </span>
        </Card>
        <ProgressCard
          css={cardStyle}
          threshold={70}
          value={widgetData.avg_percent_passed_tests}
          valueSuffix="%"
          text={`${t("performance.avg-percent-passed-tests")}:`}
        />
        <ProgressCard
          css={cardStyle}
          threshold={70}
          value={widgetData.avg_percent_passed_tests_first_attempt}
          valueSuffix="%"
          text={`${t("performance.avg-percent-passed-tests-first-attempt")}:`}
        />
      </div>
    </div>
  );
}
