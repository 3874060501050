import React from "react";
import { Headers } from "../../helpers/layout";
import { css } from "styled-components/macro";

import { SelectComponent } from "../../components/SelectComponent";
import { useTranslation } from "react-i18next";
import { useNewFetch } from "../../useAPI";
import { getJourneys } from "../../actions/journeys";
import { PerformancePeriod } from "../../actions/performance/getCoursePerformanceChart";
import { getFiltersUserSegments } from "../../actions/admin/userSegment";

export function PerformanceFilters(props: {
  onPeriodChange: (performancePeriod: PerformancePeriod) => void;
  onJourneyChange: (journeyId: string) => void;
  onSegmentChange: (segmentId: string) => void;
  journeyUUID: string | null;
  segmentUUID: string | null;
  period: PerformancePeriod;
}) {
  const { t } = useTranslation();
  const { data: journeys, error: journeysError } = useNewFetch(getJourneys, {
    offset: 0,
    limit: 100,
  });
  const { data: segments, error: segmentsError } = useNewFetch(
    getFiltersUserSegments,
    {
      offset: 0,
      limit: 100,
    }
  );

  const periods: { label: string; value: PerformancePeriod }[] = [
    {
      label: t("period.last-week"),
      value: PerformancePeriod.WEEK,
    },
    {
      label: t("period.month"),
      value: PerformancePeriod.MONTH,
    },
    {
      label: t("period.quarter"),
      value: PerformancePeriod.QUARTER,
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
      `}
    >
      <Headers.H2
        css={css`
          margin-right: 53px;
        `}
      >
        {t("dashboard.title")}
      </Headers.H2>

      {journeys && !journeysError && (
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 57px;
          `}
        >
          <span
            css={css`
              margin-right: 8px;
            `}
          >
            {t("filters.journey")}:
          </span>

          <SelectComponent
            css={css`
              width: 170px;
            `}
            value={props.journeyUUID}
            choices={[
              { label: t("filters.all"), value: "" },
              ...journeys.results.map((el) => {
                return {
                  label: el.name,
                  value: el.uuid,
                };
              }),
            ]}
            onChange={(e) => props.onJourneyChange(e.target.value)}
          />
        </div>
      )}
      {segments && !segmentsError && (
        <div
          css={css`
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            margin-right: 57px;
          `}
        >
          <div
            css={css`
              margin-right: 8px;
              width: auto;
            `}
          >
            {t("filters.user_segment")}:
          </div>

          <SelectComponent
            css={css`
              width: 170px;
            `}
            value={props.segmentUUID}
            choices={[
              { label: t("filters.all"), value: "" },
              ...segments.results.map((el) => {
                return {
                  label: el.name,
                  value: String(el.id),
                };
              }),
            ]}
            onChange={(e) => props.onSegmentChange(e.target.value)}
          />
        </div>
      )}

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            margin-right: 8px;
          `}
        >
          {t("filters.period")}:
        </span>

        <SelectComponent
          value={props.period}
          choices={periods}
          onChange={(e) =>
            props.onPeriodChange(e.target.value as PerformancePeriod)
          }
        />
      </div>
    </div>
  );
}
