import React, { useState } from "react";
import { css } from "styled-components/macro";
import {
  Type,
  Image,
  FilePlus,
  PenTool,
  Edit2,
  Video,
  ArrowUpRight,
  Film,
  PieChart,
  File,
} from "react-feather";
import { useDrag } from "react-dnd";
import { RecordAudio } from "./RecordAudio";
import { useStudio } from "../../contexts/StudioContext";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { FileUploadModal } from "../../modals/FileUploadModal";
import { uploadFile } from "../../actions/file";
import { Headers } from "../../helpers/layout";

const Element = (props: { icon: JSX.Element; label: string; type: string }) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    // "type" is required. It is used by the "accept" specification of drop targets.
    type: "element",
    item: {
      type: props.type,
    },
    // The collect function utilizes a "monitor" instance (see the Overview for what this is)
    // to pull important pieces of state from the DnD system.
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={dragPreview}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      css={css`
        position: relative;
        padding: 10px;
        border-radius: 5px;
        border: 1px dashed ${theme.colors.gray4};
        width: 90px;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 10px;
        flex-direction: column;
        cursor: grab;

        &:hover {
          background: ${theme.colors.white};
          border: 1px dashed ${theme.colors.gray5};
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 0px;
        `}
      >
        {props.icon}
      </div>
      <div>
        <h5
          css={css`
            margin: 0;
          `}
        >
          {props.label}
        </h5>
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          `}
          ref={drag}
        ></div>
      </div>
    </div>
  );
};

export const ControlButton = (props: {
  icon: JSX.Element;
  isActive?: boolean;
  children: string;
  onClick: () => void;
}) => {
  return (
    <button
      css={css`
        width: 100%;
        padding: 33px 0;
        background: ${theme.colors.gray1};
        border: none;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        &:hover {
          background: ${theme.colors.gray2};
        }

        ${props.isActive &&
        css`
          color: #e85b2e;
        `}

        &:first-child {
          border-radius: 10px 10px 0 0;
        }

        &:last-child {
          border-radius: 0 0 10px 10px;
        }
      `}
      onClick={props.onClick}
    >
      <div
        css={css`
          margin-bottom: 10px;
        `}
      >
        {props.icon}
      </div>
      {props.children}
    </button>
  );
};

export function AddVideoElements(props: {}) {
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [showElements, setShowElements] = useState(false);
  const {
    playback,
    video,
    addNewVideoElement,
    drawingMode,
    stopDrawingMode,
    enableDrawingMode,
  } = useStudio();
  const { t } = useTranslation();

  if (!video) return null;

  return (
    <div
      css={css`
        display: flex;
        flex-flow: row wrap;
      `}
    >
      <div
        css={css`
          width: 90px;
        `}
      >
        <RecordAudio
          onRecordingStart={() => {
            playback.play();
          }}
          onRecordingStop={async (audiofile, duration) => {
            const seconds = Math.floor(duration / 1000);
            const response = await uploadFile({
              file: audiofile,
              title: audiofile.name,
              duration: seconds,
            });

            addNewVideoElement({
              id: uuidv4(),
              type: "audio",
              audio: response,
              states: [
                {
                  id: uuidv4(),
                  start_time: playback.currentTime,
                  duration: duration,
                  top: 0,
                  left: 0,
                  scale: 1,
                  rotation: 0,
                },
              ],
            });

            playback.pause();
          }}
        />
        <ControlButton
          onClick={() => {
            stopDrawingMode();
            setShowElements(false);
            setShowFileUploader(true);
          }}
          isActive={showFileUploader}
          icon={<FilePlus />}
        >
          {t("video_editor.elements.add_audio.import_track")}
        </ControlButton>
        <ControlButton
          onClick={() => {
            setShowElements(false);

            if (drawingMode === "drawing") {
              stopDrawingMode();
            } else {
              enableDrawingMode("drawing");
            }
          }}
          isActive={drawingMode === "drawing"}
          icon={<PenTool />}
        >
          {t("video_editor.elements.other.draw_label")}
        </ControlButton>
        <ControlButton
          onClick={() => {
            setShowElements(false);

            if (drawingMode === "highlighting") {
              stopDrawingMode();
            } else {
              enableDrawingMode("highlighting");
            }
          }}
          icon={<Edit2 />}
          isActive={drawingMode === "highlighting"}
        >
          {t("video_editor.elements.other.highlight_label")}
        </ControlButton>
        <ControlButton
          onClick={() => {
            stopDrawingMode();
            setShowElements((v) => !v);
          }}
          icon={<FilePlus />}
          isActive={showElements}
        >
          {t("video_editor.elements.add_elements_label")}
        </ControlButton>
        <ControlButton onClick={() => {}} icon={<Video />}>
          {t("video_editor.elements.record_video")}
        </ControlButton>
      </div>
      {showElements && (
        <div
          css={css`
            flex: 1 0 10%;
            padding: 20px;
            box-sizing: border-box;
            background: ${theme.colors.gray1};
            margin-left: 20px;
            border-radius: 10px;
            border-bottom: 1px solid #ccc;
          `}
        >
          <Headers.H4
            css={css`
              margin-bottom: 20px;
            `}
          >
            Drag element into Video
          </Headers.H4>
          <div
            css={css`
              display: flex;
              flex-flow: row wrap;
            `}
          >
            <Element type="text" label="Text" icon={<Type />} />
            <Element type="image" label="Image" icon={<Image />} />
            <Element type="arrow" label="Arrow" icon={<ArrowUpRight />} />
            <Element type="gif" label="GIF" icon={<Film />} />
            <Element type="chart" label="Chart" icon={<PieChart />} />
            <Element type="pptx" label="PPTX" icon={<File />} />
            <Element type="pdf" label="PDF" icon={<File />} />
          </div>
        </div>
      )}
      <FileUploadModal
        isVisible={showFileUploader}
        onSelect={async (file) => {
          addNewVideoElement({
            id: uuidv4(),
            type: "audio",
            audio: file,
            states: [
              {
                id: uuidv4(),
                start_time: playback.currentTime,
                duration: file.duration * 1000,
                top: 0,
                left: 0,
                scale: 1,
                rotation: 0,
              },
            ],
          });
        }}
        onClose={() => {
          setShowFileUploader(false);
        }}
      />
    </div>
  );
}
