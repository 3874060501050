import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export function Submenu(props: {
  items: Array<{
    label: string;
    to: string;
    isActive: boolean;
  }>;
}) {
  return (
    <nav>
      <ul
        css={css`
          display: flex;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;
        `}
      >
        {props.items.map((item) => {
          return (
            <li
              css={css`
                margin-right: 30px;

                ${item.isActive &&
                css`
                  border-bottom: 3px solid ${theme.colors.primary};
                `}
              `}
              key={item.to}
            >
              <Link to={item.to}>{item.label}</Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
