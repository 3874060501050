import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { useMatomo } from "@datapunt/matomo-tracker-react";

import { AccessibilityProvider } from "./contexts/AccessibilityContext";

import { UserProvider } from "./contexts/UserContext";
import { LoginPage } from "./pages/Auth/Login";
import { RegistrationPage } from "./pages/Auth/Registration";
import { VerificationInfoPage } from "./pages/Auth/VerificationInfo";
import { ResetPasswordPage } from "./pages/Auth/ResetPassword";
import { SetPasswordPage } from "./pages/Auth/SetPassword";
import { AccountPage } from "./pages/Account/Account";
import { ChangePasswordPage } from "./pages/Account/ChangePassword";
import { Page404 } from "./pages/Page404";
import { NoAccessPage } from "./pages/NoAccess";
import { PrivateRoute, PublicRoute } from "./helpers/router";
import { css } from "styled-components/macro";

import { OrganisationPage } from "./pages/Organisation";
import { OrganisationUserManagmentPage } from "./pages/Organisation/OrganisationUserManagment";
import { OrganisationInviteManagmentPage } from "./pages/Organisation/OrganisationInviteManagment";
import { OrganisationInvitePage } from "./pages/Organisation/OrganisationInvite";
import { OrganisationExpertsPage } from "./pages/Organisation/OrganisationExperts";

import { JourneyPage } from "./pages/Journeys/Journey";
import { NewJourneyPage } from "./pages/Journeys/NewJourney";
import { JourneysPage } from "./pages/Journeys/Journeys";
import { EditJourneyPage } from "./pages/Journeys/EditJourney";

import { NewCoursePage } from "./pages/Courses/NewCourse";
import { CoursePage } from "./pages/Courses/Course";
import { EditCoursePage } from "./pages/Courses/EditCourse";
import { CheatSheetOverviewPage } from "./pages/Courses/CheatSheetOverview";
import { NewCheatSheetPage } from "./pages/Courses/NewCheatSheet";
import { EditCheatSheetPage } from "./pages/Courses/EditCheatSheet";

import { NewLessonPage } from "./pages/Lessons/NewLesson";
import { EditLessonPage } from "./pages/Lessons/EditLesson";
import { ContentEditorLessonPage } from "./pages/Lessons/ContentEditorLesson";
import { EditTestPage } from "./pages/Lessons/Tests/EditTest";
import { TestsOverviewPage } from "./pages/Lessons/Tests/TestsOverview";
import { NewTestPage } from "./pages/Lessons/Tests/NewTest";
import { TestPerformancePage } from "./pages/Lessons/Tests/Performance/Performance";
import { ConfirmEmailPage } from "./pages/Auth/ConfirmEmail";

import { AdditionalMaterialsOverviewPage } from "./pages/AdditionalMaterials/AdditionalMaterialsOverview";
import { NewAdditionalMaterialPage } from "./pages/AdditionalMaterials/NewAdditionalMaterials";
import { EditAdditionalMaterialPage } from "./pages/AdditionalMaterials/EditAdditionalMaterials";

import { NotificationPage } from "./pages/Notification";

import { DashboardPage } from "./pages/Dashboard/Dashboard";

import { CheckVersionUpdate } from "./hooks/useCheckVersionUpdate";
import { EnterpriseProvider } from "./contexts/EnterpriseContext";
import { AdminFeedbackPage } from "./pages/Feedback/AdminFeedbackPage";
import { StudioFeedbackPage } from "./pages/Feedback/StudioFeedbackPage";

import { UserManagementCampusPage } from "./pages/Admin/UserManagement/UserManagmentCampus";
import { UserManagementStudioPage } from "./pages/Admin/UserManagement/UserManagmentStudio";
import { PublishRequestPage } from "./pages/Admin/PublishRequest/PublishRequest";

import { PageOverviewPage } from "./pages/Admin/Page/PageOverview";
import { PageCreatePage } from "./pages/Admin/Page/PageCreate";
import { PageEditPage } from "./pages/Admin/Page/PageEdit";

import { Permission } from "./types/UserProfile";
import { SignUpSelfAssessmentPerformance } from "./pages/Admin/SelfAssessments/SignUpSelfAssessmentsPerformance";
import { SignUpSelfAssessments } from "./pages/Admin/SelfAssessments/SignUpSelfAssessments";
import { NewSignUpSelfAssessmentItem } from "./pages/Admin/SelfAssessments/NewSignUpSelfAssessmentItem";
import { EditSignUpSelfAssessmentItem } from "./pages/Admin/SelfAssessments/EditSignUpSelfAssessmentItem";
import { useCheckMobile } from "./hooks/useCheckMobile";
import { MobileFallbackPage } from "./helpers/mobileFallbackPage";
import EnterpriseGeneralSettings from "./pages/Admin/Settings/EnterpriseGeneralSettings";
import EnterpriseAddressSettings from "./pages/Admin/Settings/EnterpriseAddressSettings";
import EnterpriseBillingSettings from "./pages/Admin/Settings/EnterpriseBillingSettings";
import EnterpriseContactSettings from "./pages/Admin/Settings/EnterpriseContactSettings";
import EnterpriseDRMSettings from "./pages/Admin/Settings/EnterpriseDRMSettings";
import { StudioFaqOverviewPage } from "./pages/Faq/StudioFaqOverview";
import { StudioNewFaqPage } from "./pages/Faq/StudioNewFaq";
import { StudioEditFaqPage } from "./pages/Faq/StudioEditFaq";
import { AdminFaqOverviewPage } from "./pages/Faq/AdminFaqOverview";
import { AdminNewFaqPage } from "./pages/Faq/AdminNewFaq";
import { AdminEditFaqPage } from "./pages/Faq/AdminEditFaq";
import { SlidersEditorPage } from "./pages/Admin/SlidersEditor/SlidersEditorPage";
import OrdersListPage from "./pages/Admin/Orders/OrdersListPage";
import OrderDetailsPage from "./pages/Admin/Orders/OrderDetailsPage";

import { CompetencyOverviewPage } from "./pages/Admin/Competency/CompetencyOverview";
import { CompetencyCreatePage } from "./pages/Admin/Competency/CompetencyCreate";
import { CompetencyEditPage } from "./pages/Admin/Competency/CompetencyEdit";

import { SkillOverviewPage } from "./pages/Admin/Skill/SkillOverview";
import { SkillCreatePage } from "./pages/Admin/Skill/SkillCreate";
import { SkillEditPage } from "./pages/Admin/Skill/SkillEdit";

import { UserSegmentOverviewPage } from "./pages/Admin/UserSegment/UserSegmentOverview";
import { UserSegmentCreatePage } from "./pages/Admin/UserSegment/UserSegmentCreate";
import { UserSegmentEditPage } from "./pages/Admin/UserSegment/UserSegmentEdit";

import AnnouncementsPage from "./pages/Admin/Announcements/AnnouncementsPage";
import { EnterpriseSignupFieldsPage } from "./pages/Admin/Signup/EnterpriseSignupFieldsPage";
import { MenuManagerPage } from "./pages/Admin/Menu/MenuManagerPage";
import { CoursesPage } from "./pages/Courses/Courses";
import { CourseSelfAssessment } from "./pages/Courses/CourseSelfAssessment";
import { CourseSelfAssessmentPerformance } from "./pages/Courses/CourseSelfAssessmentsPerformance";
import { NewSelfAssessmentItem } from "./pages/Courses/NewSelfAssessmentItem";
import { EditSelfAssessmentItem } from "./pages/Courses/EditSelfAssessmentItem";

function App() {
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  const isMobile = useCheckMobile();

  return (
    <Suspense fallback="loading">
      <EnterpriseProvider>
        <AccessibilityProvider>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            pauseOnHover={false}
            css={css`
              width: auto;
              svg {
                color: #242424;
              }
            `}
          />
          <UserProvider>
            {isMobile ? (
              <MobileFallbackPage />
            ) : (
              <Router
                getUserConfirmation={(message, callback) => {
                  // this is the default behavior
                  const allowTransition = window.confirm(message);
                  callback(allowTransition);
                }}
              >
                <Switch>
                  <PrivateRoute
                    path="/admin/users/campus"
                    exact
                    component={UserManagementCampusPage}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/users/studio"
                    exact
                    component={UserManagementStudioPage}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />

                  <PrivateRoute
                    path="/admin/publish-request"
                    exact
                    component={PublishRequestPage}
                    permission={Permission.ENTERPRISE_REVIEW_COURSES}
                  />
                  <PrivateRoute
                    path="/admin/pages"
                    exact
                    component={PageOverviewPage}
                    permission={Permission.ENTERPRISE_MANAGE_PAGE}
                  />
                  <PrivateRoute
                    path="/admin/pages/new"
                    exact
                    component={PageCreatePage}
                    permission={Permission.ENTERPRISE_MANAGE_PAGE}
                  />
                  <PrivateRoute
                    path="/admin/pages/:pageId"
                    exact
                    component={PageEditPage}
                    permission={Permission.ENTERPRISE_MANAGE_PAGE}
                  />
                  <PrivateRoute
                    path="/admin/self-assessment"
                    exact
                    component={SignUpSelfAssessments}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/self-assessment/new"
                    exact
                    component={NewSignUpSelfAssessmentItem}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/self-assessment/:selfAssessmentId/edit"
                    exact
                    component={EditSignUpSelfAssessmentItem}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/self-assessment/performance"
                    exact
                    component={SignUpSelfAssessmentPerformance}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/sliders"
                    exact
                    component={SlidersEditorPage}
                    permission={Permission.ENTERPRISE_MANAGE_HOMEPAGE_CONTENT}
                  />
                  <PrivateRoute
                    path="/admin/announcements"
                    exact
                    component={AnnouncementsPage}
                    permission={Permission.ENTERPRISE_MANAGE_HOMEPAGE_CONTENT}
                  />
                  <PrivateRoute
                    path="/admin/settings/general"
                    exact
                    component={EnterpriseGeneralSettings}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/admin/settings/address"
                    exact
                    component={EnterpriseAddressSettings}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/admin/settings/billing"
                    exact
                    component={EnterpriseBillingSettings}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/admin/settings/contact"
                    exact
                    component={EnterpriseContactSettings}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/admin/settings/drm"
                    exact
                    component={EnterpriseDRMSettings}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />

                  <PrivateRoute
                    path="/admin/signup/:section(campus|studio)"
                    exact
                    component={EnterpriseSignupFieldsPage}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />

                  <PrivateRoute
                    path="/admin/menus/:type(campus|studio)/:location(header|footer)"
                    exact
                    component={MenuManagerPage}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />

                  <PrivateRoute
                    path="/admin/competencies"
                    exact
                    component={CompetencyOverviewPage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />
                  <PrivateRoute
                    path="/admin/competencies/new"
                    exact
                    component={CompetencyCreatePage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />
                  <PrivateRoute
                    path="/admin/competencies/:competencyId"
                    exact
                    component={CompetencyEditPage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />

                  <PrivateRoute
                    path="/admin/skills"
                    exact
                    component={SkillOverviewPage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />
                  <PrivateRoute
                    path="/admin/skills/new"
                    exact
                    component={SkillCreatePage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />
                  <PrivateRoute
                    path="/admin/skills/:skillId"
                    exact
                    component={SkillEditPage}
                    permission={
                      Permission.ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES
                    }
                  />

                  <PrivateRoute
                    path="/admin/user-segment"
                    exact
                    component={UserSegmentOverviewPage}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/user-segment/new"
                    exact
                    component={UserSegmentCreatePage}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/user-segment/:userSegmentId"
                    exact
                    component={UserSegmentEditPage}
                    permission={Permission.ENTERPRISE_MANAGE_USERS}
                  />

                  <PrivateRoute
                    path="/admin/orders"
                    exact
                    component={OrdersListPage}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/admin/orders/:order_id"
                    exact
                    component={OrderDetailsPage}
                    permission={Permission.ENTERPRISE_MANAGE_SETTINGS}
                  />
                  <PrivateRoute
                    path="/no-access"
                    exact
                    component={NoAccessPage}
                  />
                  <PublicRoute path="/login" exact component={LoginPage} />
                  <PublicRoute
                    path="/register"
                    exact
                    component={RegistrationPage}
                  />
                  <PublicRoute
                    path="/verification-info"
                    exact
                    component={VerificationInfoPage}
                  />
                  <PublicRoute
                    path="/confirm-email"
                    exact
                    component={ConfirmEmailPage}
                  />
                  <PublicRoute
                    path="/reset-password"
                    exact
                    component={ResetPasswordPage}
                  />
                  <PublicRoute
                    path="/reset-password/:uuid/"
                    exact
                    component={SetPasswordPage}
                  />

                  <PrivateRoute
                    path="/account/me"
                    exact
                    component={AccountPage}
                  />
                  <PrivateRoute
                    path="/account/change-password"
                    exact
                    component={ChangePasswordPage}
                  />
                  <PrivateRoute
                    path="/organisation"
                    exact
                    component={OrganisationPage}
                  />
                  <PrivateRoute
                    path="/organisation/invite"
                    exact
                    component={OrganisationInvitePage}
                  />
                  <PrivateRoute
                    path="/organisation/users"
                    exact
                    component={OrganisationUserManagmentPage}
                    permission={Permission.ORGANISATION_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/organisation/invites"
                    exact
                    component={OrganisationInviteManagmentPage}
                    permission={Permission.ORGANISATION_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/organisation/experts"
                    exact
                    component={OrganisationExpertsPage}
                    permission={Permission.ORGANISATION_MANAGE_USERS}
                  />
                  <PrivateRoute
                    path="/admin/feedback"
                    exact
                    component={AdminFeedbackPage}
                  />
                  <PrivateRoute
                    path="/studio/feedback"
                    exact
                    component={StudioFeedbackPage}
                  />
                  <PrivateRoute path="/" exact component={DashboardPage} />
                  <PrivateRoute
                    path="/studio/faq"
                    exact
                    component={StudioFaqOverviewPage}
                  />
                  <PrivateRoute
                    path="/studio/faq/new"
                    exact
                    component={StudioNewFaqPage}
                  />
                  <PrivateRoute
                    path="/studio/faq/:id/edit"
                    exact
                    component={StudioEditFaqPage}
                  />
                  <PrivateRoute
                    path="/admin/faq"
                    exact
                    component={AdminFaqOverviewPage}
                  />
                  <PrivateRoute
                    path="/admin/faq/new"
                    exact
                    component={AdminNewFaqPage}
                  />
                  <PrivateRoute
                    path="/admin/faq/:id/edit"
                    exact
                    component={AdminEditFaqPage}
                  />
                  <PrivateRoute
                    path="/admin/journeys/new"
                    exact
                    component={NewJourneyPage}
                  />
                  <PrivateRoute
                    path="/admin/journeys"
                    exact
                    component={JourneysPage}
                  />
                  <PrivateRoute
                    path="/admin/journeys/:id/edit"
                    exact
                    component={EditJourneyPage}
                  />
                  <PrivateRoute
                    path="/admin/journeys/:id/syllabus"
                    exact
                    component={JourneyPage}
                  />
                  <PrivateRoute
                    path="/courses/:status(published|in-progress|archived)?"
                    exact
                    component={CoursesPage}
                  />
                  <PrivateRoute
                    path="/courses/new"
                    exact
                    component={NewCoursePage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/syllabus"
                    exact
                    component={CoursePage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/edit"
                    exact
                    component={EditCoursePage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/cheat_sheet"
                    exact
                    component={CheatSheetOverviewPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/cheat_sheet/new"
                    exact
                    component={NewCheatSheetPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/cheat_sheet/:cheatSheetId/edit"
                    exact
                    component={EditCheatSheetPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/new"
                    exact
                    component={NewLessonPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/edit"
                    exact
                    component={EditLessonPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/content_editor"
                    exact
                    component={ContentEditorLessonPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/testing"
                    exact
                    component={TestsOverviewPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/testing/new"
                    exact
                    component={NewTestPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/testing/:testId/edit"
                    exact
                    component={EditTestPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/lessons/:lessonId/testing/:testId/performance"
                    exact
                    component={TestPerformancePage}
                  />
                  {/* additional materials */}
                  <PrivateRoute
                    path="/courses/:courseId/additional_materials"
                    exact
                    component={AdditionalMaterialsOverviewPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/additional_materials/new"
                    exact
                    component={NewAdditionalMaterialPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/additional_materials/:additionalMaterialId/edit"
                    exact
                    component={EditAdditionalMaterialPage}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/self-assessments"
                    exact
                    component={CourseSelfAssessment}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/self-assessments/performance"
                    exact
                    component={CourseSelfAssessmentPerformance}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/self-assessments/new"
                    exact
                    component={NewSelfAssessmentItem}
                  />
                  <PrivateRoute
                    path="/courses/:courseId/self-assessments/:selfAssessmentId/edit"
                    exact
                    component={EditSelfAssessmentItem}
                  />
                  <PrivateRoute
                    path="/notifications"
                    exact
                    component={NotificationPage}
                  />
                  <Route path="/" component={Page404} />
                </Switch>
                {/* <AccessibilityControls /> */}
              </Router>
            )}
          </UserProvider>
        </AccessibilityProvider>
      </EnterpriseProvider>
      <CheckVersionUpdate />
    </Suspense>
  );
}

export default App;
