import React from "react";
import { useTranslation } from "react-i18next";
import { customToast } from "../../components/customToast";
import { css } from "styled-components/macro";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { SEO } from "../../components/SEO";
import { useNewFetch } from "../../useAPI";
import { Headers } from "../../helpers/layout";

import { AccountForm, FormValues } from "../../forms/account/AccountForm";
import { getMe, updateMe } from "../../actions/account";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export function AccountPage() {
  const { t } = useTranslation();

  const {
    data: profileData,
    error: profileDataErrors,
    setData,
  } = useNewFetch(getMe);

  if (!profileData || profileDataErrors) {
    return pageLoading(profileDataErrors);
  }

  const onSubmit = async (values: FormValues) => {
    const response = await updateMe(values);
    customToast.success(t("status.success"));
    setData(response.data);
  };

  const tabsData = [
    {
      title: t("account.personal-info"),
      link: `/account/me`,
    },
    {
      title: t("account.change-password"),
      link: `/account/change-password`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("account.personal-info")}</title>
        </SEO>
        <Header />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H1>{t("account.personal-info")}</Headers.H1>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div
            css={css`
              display: flex;
              width: 100%;
            `}
          >
            {profileData && (
              <AccountForm data={profileData} onSubmit={onSubmit} />
            )}
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
