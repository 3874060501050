import { authorised } from "../../../request";
import { Page } from "../../../types/admin/Page";

type PageArgs = Omit<Page, "uuid" | "created" | "author">

type Args = {
  id: string | number;
  data: PageArgs;
}

export async function updatePage({id, data}: Args) {
  const API = authorised();

  return API.request<Page>({
    method: "PUT",
    url: `/api/enterprise/pages/${id}/`,
    data
  });
}
