import { authorised } from "../../../request";
import { Competency } from "../../../types/admin/Competency";

type CompetencyArgs = Omit<Competency, "id">

type Args = {
  data: CompetencyArgs;
}

export async function createCompetency({data}: Args) {
  const API = authorised();

  return API.request<Competency>({
    method: "POST",
    url: `/api/enterprise/competencies/`,
    data
  });
}
