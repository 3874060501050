import { authorised } from "../../request";

type Args = {
  invitationToken: string | null;
};

export async function revokeInvite({ invitationToken }: Args) {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/studio/organisation/invite/revoke/`,
    data: {
      invitation_token: invitationToken
    }
  });
}
