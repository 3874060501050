import { authorised } from "../../request";
import { MenuItem } from "../../types/admin/MenuItem";

export async function deleteMenuItem(menuItemId: string) {
  const API = authorised();

  return await API.request<MenuItem>({
    method: "DELETE",
    url: `/api/enterprise/menu-item/${menuItemId}/`,
  });
}
