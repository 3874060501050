import { authorised } from "../../../request";
import { Slider } from "../../../types/admin/Slider";

type Args = {
  id: number;
};

export async function deleteSlider(data: Args) {
  const API = authorised();

  return await API.request<Slider>({
    method: "DELETE",
    url: `/api/enterprise/sliders/${data.id}`,
  });
}
