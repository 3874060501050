import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  selfAssessmentId: string;
};

export async function getSignUpSelfAssessment({ selfAssessmentId }: Args) {
  const API = authorised();

  return API.request<SelfAssessment>({
    method: "GET",
    url: `/api/enterprise/self-assessments/${selfAssessmentId}/`,
  });
}
