import { Lesson } from "./Lesson";
import { UserMeta } from "./UserProfile";

export enum FeedbackType {
  ALL = "ALL",
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

export enum FeedbackStatus {
  NEW = "New",
  ALL = "All",
  READ = "Read",
}

export type LessonFeedback = {
  uuid: string;
  created: string;
  feedback: FeedbackType;
  comment: string;
  user: UserMeta;
  lesson: Pick<Lesson, "uuid" | "name" | "course">;
  is_read: boolean;
};
