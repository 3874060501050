import React, { useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { CustomPPTBlock } from "../../../../types/Block";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { BlockControls } from "../components/BlockControls";
import { File } from "../../../../types/File";
import { FileHandler } from "../components/FileHandler";
import { ReactComponent as PPTIcon } from "../../../../assets/icons/Presentation.svg";

export function BlockPPT(props: {
  block: CustomPPTBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomPPTBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [content, setContent] = useState<File | null>(
    props.block.data.file || null
  );

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setContent(props.block.data.file || null);
  };

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.file && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              display: flex;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${block.data.file.file}`}
              width="100%"
              height="600px"
              frameBorder="0"
              title="slides"
            ></iframe>
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block || []}
        onCancel={onCancel}
        header={
          <div>
            <div
              css={css`
                margin-right: 12px;
                width: 30px;
                display: inline-block;
                vertical-align: middle;

                svg {
                  width: 100%;
                }
              `}
            >
              <PPTIcon color={theme.colors.primary} />
            </div>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
        onConfirm={async (value) => {
          const updatedData: CustomPPTBlock = {
            ...value,
            data: {
              file: content,
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <FileHandler
              removeFile={() => setContent(null)}
              value={content}
              onSelect={async (img) => await setContent(img)}
            />
          </div>
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
