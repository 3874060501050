import { authorised } from "../../../request";
import { Slider } from "../../../types/admin/Slider";

export async function createSlider(slider: Slider) {
  const API = authorised();

  return await API.request<Slider>({
    method: "POST",
    url: `/api/enterprise/sliders/`,
    data: slider,
  });
}
