import { authorised } from "../../request";
import { AdditionalMaterial } from "../../types/AdditionalMaterial";

type Args = {
  courseId: string;
  additionalMaterialId: string;
  data: Pick<AdditionalMaterial, "link" | "icon" | "title">;
}

export async function updateAdditionalMaterial({courseId, additionalMaterialId, data}: Args) {
  const API = authorised();

  return API.request<AdditionalMaterial>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/additional-materials/${additionalMaterialId}/`,
    data
  });
}
