import React from "react";
import { Field, FieldProps } from "formik";
import { RadioComponent } from "../../components/RadioComponent";
import { css } from "styled-components/macro";

export function RadioField(props: {
  name: string;
  id?: string;
  label?: React.ReactNode | React.ReactNode[] | string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value: string | number | boolean;
  className?: string;
  icon?: React.ReactNode;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { validate, value, ...other } = props;
  return (
    <Field
      name={other.name}
      type="radio"
      validate={validate}
      value={value}
      component="div"
    >
      {({ field, form }: FieldProps) => {
        const label = props.icon ? (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <div>{props.label}</div>
            <div
              css={css`
                margin-left: 10px;
              `}
            >
              {props.icon}
            </div>
          </div>
        ) : (
          props.label
        );

        return (
          <RadioComponent
            {...other}
            label={label}
            field={field}
            onChange={(e) => {
              const val = e.target.value;

              if (val === "true" || val === "false") {
                form.setFieldValue(props.name, val === "true" ? true : false);
              } else {
                form.setFieldValue(props.name, val);
              }
            }}
          />
        );
      }}
    </Field>
  );
}
