import { css } from "styled-components/macro";
import { Button } from "../components/Button";
import { Loader } from "../components/Loader";
import { InputComponent } from "../components/InputComponent";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../components/Modal";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";

import { ReactComponent as PlusIcon } from "../assets/icons/Plus.svg";
import { ReactComponent as InviteIcon } from "../assets/icons/Invite.svg";
import { ReactComponent as SendMailIcon } from "../assets/icons/SendMail.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { inviteOrganisationUser } from "../actions/organisationUser";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { customToast } from "../components/customToast";

export function OrganisationUserInvite(props: {
  isVisible: boolean;
  onClose: () => void;
  onSent: () => void;
}) {
  const { t } = useTranslation();
  const [inviteEmailSent, setInviteEmailSent] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [invitationLoading, setInvitationLoading] = useState(false);

  const closeInviteModal = () => {
    setInviteEmailSent(false);
    setInviteEmail("");

    props.onClose();
  };

  const inviteUser = async () => {
    if (!inviteEmail) {
      return;
    }
    setInvitationLoading(true);
    try {
      await inviteOrganisationUser({ email: inviteEmail });

      setInviteEmailSent(true);
      props.onSent();
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setInvitationLoading(false);
    }
  };

  return (
    <Modal
      modalIsOpen={props.isVisible}
      onClose={props.onClose}
      contentLabel={t("organisation.invite-to-collaborate")}
      contentStyles={{
        maxWidth: "600px",
        padding: "25px 40px 40px 40px",
      }}
    >
      <ModalHeader
        css={css`
          justify-content: flex-end;
        `}
        closeIcon
        onClose={props.onClose}
      />

      <ModalBody
        css={css`
          padding-top: 0;
        `}
      >
        {inviteEmailSent ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 12px;
            `}
          >
            <SendMailIcon width={24} height={24} color={theme.colors.primary} />

            <Headers.H2
              css={css`
                margin-left: 12px;
              `}
            >
              {t("organisation.invite-sent")}
            </Headers.H2>
          </div>
        ) : (
          <div
            css={css`
              display: flex;
              align-items: center;
              margin-bottom: 33px;

              svg {
                width: 24px;
                height: 24px;
              }
            `}
          >
            <InviteIcon width={20} height={20} color={theme.colors.primary} />
            <Headers.H2
              css={css`
                margin-left: 12px;
              `}
            >
              {t("organisation.invite-to-collaborate")}
            </Headers.H2>
          </div>
        )}

        {invitationLoading ? (
          <Loader />
        ) : inviteEmailSent ? (
          <div>
            {t("organisation.invitation-info-1")} <strong>{inviteEmail}</strong>{" "}
            {t("organisation.invitation-info-2")}
          </div>
        ) : (
          <InputComponent
            label={`${t("account.email")}:`}
            placeholder={t("account.email")}
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
        )}
      </ModalBody>

      <ModalFooter
        css={css`
          justify-content: flex-start;
        `}
      >
        {inviteEmailSent ? (
          <Button
            background={theme.colors.transparent}
            color={theme.colors.dark}
            border={`1px solid ${theme.colors.dark}`}
            onClick={props.onClose}
          >
            {t("actions.cancel")}
          </Button>
        ) : (
          <>
            <Button
              background={theme.colors.primary}
              color={theme.colors.white}
              onClick={inviteUser}
              icon={
                <PlusIcon width={14} height={14} color={theme.colors.white} />
              }
              iconRight
            >
              {t("organisation.invite-user")}
            </Button>

            <Button
              background={theme.colors.transparent}
              color={theme.colors.dark}
              onClick={closeInviteModal}
            >
              {t("actions.cancel")}
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}
