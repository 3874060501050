import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { useHistory } from "react-router-dom";
import { Block } from "../../components/Block";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { SEO } from "../../components/SEO";
import { Avatar } from "../../components/Avatar";
import { Button } from "../../components/Button";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { useNewFetch } from "../../useAPI";
import {
  getOrganisationInvite,
  acceptInvite,
  declineInvite,
} from "../../actions/organisationInvite";
import { pageLoading } from "../../helpers/pageLoading";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { ReactComponent as ConnectIcon } from "../../assets/icons/Connect.svg";
import { ReactComponent as ReadIcon } from "../../assets/icons/Read.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import { Footer } from "../../components/Footer";

export function OrganisationInvitePage() {
  const { t } = useTranslation();

  const history = useHistory();

  const invitationToken = new URLSearchParams(window.location.search).get(
    "invitation_token"
  );

  const { data: invite, error: inviteError } = useNewFetch(
    getOrganisationInvite,
    {
      invitationToken: invitationToken,
    }
  );

  if (!invite || inviteError) {
    return pageLoading(inviteError);
  }

  const accept = async () => {
    try {
      await acceptInvite({
        invitationToken: invitationToken,
      });

      history.push(`/`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const decline = async () => {
    try {
      await declineInvite({
        invitationToken: invitationToken,
      });

      history.push(`/`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("organisation.collaborate-invitation")}</title>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            display: flex;
          `}
        >
          <div>
            <ConnectIcon width={40} height={40} color={theme.colors.primary} />
          </div>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-left: 24px;
            `}
          >
            <Headers.H3
              css={css`
                margin-bottom: 12px;
              `}
            >
              {t("organisation.collaborate-invitation")}
            </Headers.H3>

            <div
              css={css`
                display: flex;
                align-items: center;
                margin-bottom: 40px;
              `}
            >
              <Avatar
                size={40}
                url={invite.author.avatar?.image}
                css={css`
                  margin-right: 16px;
                `}
              />

              <div
                css={css`
                  max-width: 620px;
                `}
              >
                {t("organisation.collaborate-invitation-text", {
                  name: `${invite.author.first_name} ${invite.author.last_name}`,
                  organisation_name: invite.organisation.name,
                })}
              </div>
            </div>

            <div>
              <Button
                color={theme.colors.dark}
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.dark}`}
                icon={
                  <ReadIcon
                    width={14}
                    height={14}
                    color={theme.colors.primary}
                  />
                }
                iconRight
                css={css`
                  margin-right: 20px;
                `}
                onClick={accept}
              >
                {t("organisation.accept-invitation")}
              </Button>

              <Button
                color={theme.colors.error}
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.error}`}
                icon={
                  <CloseIcon
                    width={14}
                    height={14}
                    color={theme.colors.error}
                  />
                }
                iconRight
                onClick={decline}
              >
                {t("actions.decline")}
              </Button>
            </div>
          </div>
        </div>
      </Block>
      <Footer />
    </Wrapper>
  );
}
