import React from "react";
import styled, { css } from "styled-components/macro";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { VideoControls } from "./VideoControls";
import { VideoElements } from "./VideoElements";
import { Video } from "../../types/Video";
import ReactTooltip from "react-tooltip";
import { usePlayback } from "../../hooks/usePlayback";

export const canvasTransitionDuration = 500;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;

  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
`;
export const VideoSafeContainer = styled.div<{
  show: boolean;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  max-height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all ${canvasTransitionDuration}ms;
  overflow: hidden;

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export function VideoInteractive(props: {
  onEnded: () => void;
  show: boolean;
  video: Video;
}) {
  const { video } = props;
  const handle = useFullScreenHandle();
  const { currentTime, setCurrentTime, playing, play, pause, videoDuration } =
    usePlayback(props.video);

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <FullScreen handle={handle}>
        <VideoContainer>
          <VideoSafeContainer show={props.show}>
            <VideoElements
              currentTime={currentTime}
              video={video}
              playing={playing}
              elementWrapper={(rendered, element, state, showElement) => {
                return (
                  <div
                    css={css`
                      position: absolute;
                      transition: all 500ms;
                      z-index: 2;
                      opacity: ${showElement ? 1 : 0};
                      top: ${state.top}px;
                      left: ${state.left}px;
                      transform: rotate(${state.rotation}deg)
                        scale(${state.scale});

                      ${state.width &&
                      state.height &&
                      css`
                        width: ${state.width}px;
                        height: ${state.height}px;
                      `}
                    `}
                  >
                    {rendered}
                  </div>
                );
              }}
            />
            <VideoControls
              currentTime={currentTime}
              videoDuration={videoDuration}
              onChangeTime={(time) => {
                setCurrentTime(time);
              }}
              onPlay={() => {
                if (playing) {
                  pause();
                } else {
                  play();
                }
              }}
              onRestart={() => {
                setCurrentTime(0);
                play();
              }}
              isPlaying={playing}
              isFullscreen={handle.active}
              onMaximize={handle.enter}
              onMinimize={handle.exit}
            />
          </VideoSafeContainer>
        </VideoContainer>
        <ReactTooltip
          place="bottom"
          type="info"
          effect="solid"
          multiline
          css={css`
            max-width: 200px;
          `}
        />
      </FullScreen>
    </div>
  );
}
