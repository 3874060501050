import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import {
  EditCreateCheatSheet,
  CheatSheetItem,
} from "../../components/EditCreateCheatSheet";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { createCheatSheet } from "../../actions/cheatSheet";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export function NewCheatSheetPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
  }>();

  const history = useHistory();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  const onSubmit = async (data: CheatSheetItem) => {
    const { text_content, ...others } = data;
    await createCheatSheet({
      courseId: params.courseId,
      data: {
        ...others,
        text_content: data.text_content || "",
      },
    });

    customToast.success(t("status.success"));

    history.push(`/courses/${params.courseId}/cheat_sheet`);
  };

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
      forceHightlight: true,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: false,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateCheatSheet
            initialData={null}
            onSubmitCallback={onSubmit}
            attachedLessonOptions={course.lessons.map((lesson) => {
              return {
                value: lesson.lesson.uuid,
                label: lesson.lesson.name,
              };
            })}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
