import React, { useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ChevronUp, ChevronDown, Trash2, Plus } from "react-feather";
import { TinyEditor } from "../../../DraftEditor/tinymce";
import { CustomCarouselBlock } from "../../../../types/Block";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { Button } from "../../../Button";
import { Carousel } from "../../../Carousel";
import { LongDashedSeparator } from "../../../Separator";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { IconButton } from "../components/IconButton";
import { BlockControls } from "../components/BlockControls";

export function BlockCarousel(props: {
  block: CustomCarouselBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomCarouselBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const { t } = useTranslation();

  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [blocks, setBlocks] = useState<string[]>(props.block.data.blocks || []);

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setDeleteModal(false);
    setBlocks(props.block.data.blocks || []);
  };

  const onContentChange = (index: number, value: string) => {
    setBlocks((prev) => {
      return prev.map((el, idx) => {
        if (idx === index) {
          return value;
        }

        return el;
      });
    });
  };

  function addBlock(index?: number) {
    setBlocks((prev) => {
      if (typeof index === "number") {
        const firstPart = prev.slice(0, index + 1);
        const lastPart = prev.slice(index + 1, prev.length);
        return [...firstPart, "", ...lastPart];
      }

      return prev.concat([""]);
    });
  }

  function removeBlock(index: number) {
    setBlocks((prev) => {
      return prev.filter((_, idx) => idx !== index);
    });
  }

  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    if (
      startIndex >= 0 &&
      startIndex <= list.length - 1 &&
      endIndex >= 0 &&
      endIndex <= list.length - 1
    ) {
      const result = Array.from(list);

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }

    return list;
  }

  function move(startIndex: number, endIndex: number) {
    setBlocks((prev) => reorder(prev, startIndex, endIndex));
  }

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.blocks && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            {block.data.blocks && <Carousel content={block.data.blocks} />}
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block}
        header={
          <div>
            <span
              css={css`
                color: ${theme.colors.primary};
                margin-right: 12px;
              `}
            >
              Aa
            </span>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
        onCancel={onCancel}
        onConfirm={async (value) => {
          const updatedData: CustomCarouselBlock = {
            ...value,
            data: {
              blocks,
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div>
            {blocks.map((block, index) => {
              return (
                <div key={index}>
                  <div
                    css={css`
                      display: flex;
                      margin: 40px 0;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        white-space: nowrap;
                        font-weight: bold;
                        margin-right: 40px;
                      `}
                    >
                      {t("lesson.block.section")} {index + 1}
                    </div>

                    <TinyEditor
                      value={block || ""}
                      onChange={(value) => onContentChange(index, value)}
                    />

                    <Row
                      css={css`
                        margin-left: 40px;
                      `}
                    >
                      <IconButton
                        onClick={() => move(index, index - 1)}
                        title={t("actions.move-up")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <ChevronUp size={25} />
                      </IconButton>

                      <IconButton
                        onClick={() => move(index, index + 1)}
                        title={t("actions.move-down")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <ChevronDown size={25} />
                      </IconButton>

                      <IconButton
                        onClick={() => removeBlock(index)}
                        title={t("actions.delete-item")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <Trash2 color={theme.colors.redMain} size={22} />
                      </IconButton>
                    </Row>
                  </div>

                  {index < blocks.length - 1 && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <LongDashedSeparator color={"E2E2E2"} />

                      <Button
                        background={theme.colors.transparent}
                        border="1px solid black"
                        color={theme.colors.dark}
                        hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
                        icon={<Plus color={theme.colors.primary} size={20} />}
                        onClick={() => addBlock(index)}
                        css={css`
                          margin: 0 20px;
                        `}
                        small
                      >
                        {t("lesson.block.add-section")}
                      </Button>

                      <LongDashedSeparator color={"E2E2E2"} />
                    </div>
                  )}
                </div>
              );
            })}

            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <LongDashedSeparator color={"E2E2E2"} />

              <Button
                background={theme.colors.transparent}
                border="1px solid black"
                color={theme.colors.dark}
                hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
                icon={<Plus color={theme.colors.primary} size={20} />}
                onClick={() => addBlock()}
                css={css`
                  margin: 0 20px;
                `}
                small
              >
                {t("lesson.block.add-section")}
              </Button>

              <LongDashedSeparator color={"E2E2E2"} />
            </div>
          </div>
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
