import { debounce } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { Playback, usePlayback } from "../hooks/usePlayback";
import { Video, VideoElement, VideoElementState } from "../types/Video";
import { useAuth } from "./UserContext";
import ReconnectingWebSocket from "reconnecting-websocket";

const StudioContext = React.createContext<{
  playback: Playback;
  video: Video | null;
  drawingMode: "drawing" | "highlighting" | null;
  enableDrawingMode: (mode: "drawing" | "highlighting") => void;
  stopDrawingMode: () => void;
  setVideo: (video: Video) => void;
  addNewVideoElement: (element: VideoElement) => void;
  removeVideoElement: (elementId: string) => void;
  updateVideoElement: (elementId: string, data: VideoElement) => void;
  createNewVideoElementState: (
    elementId: string,
    state: VideoElementState
  ) => void;
  updateVideoElementState: (
    elementId: string,
    stateId: string,
    state: Partial<VideoElementState>
  ) => void;
  onLocationChange: (location: { x: number; y: number }) => void;
}>({
  video: null,
  drawingMode: null,
  playback: {
    currentTime: 0,
    videoDuration: 0,
    playing: false,
    play: () => {},
    pause: () => {},
    setCurrentTime: (time: number) => {},
  },
  enableDrawingMode: () => {},
  stopDrawingMode: () => {},
  setVideo: () => {},
  addNewVideoElement: () => {},
  removeVideoElement: () => {},
  updateVideoElement: () => {},
  createNewVideoElementState: () => {},
  updateVideoElementState: () => {},
  onLocationChange: () => {},
});

export function StudioProvider(props: {
  children: (video: Video) => React.ReactNode;
  video: Video;
}) {
  const [drawingMode, setDrawingMode] = useState<
    "drawing" | "highlighting" | null
  >(null);
  const { userprofile } = useAuth();
  const [video, setVideo] = useState(props.video);
  const playback = usePlayback(video);
  const ws = useRef(
    new ReconnectingWebSocket(`ws://localhost:8082?videoId=${video.uuid}`)
  );

  useEffect(() => {
    const wsCurrent = ws.current;

    wsCurrent.onopen = () => {
      console.log("Opened websocket");
    };

    wsCurrent.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log("message received", data);
    };

    wsCurrent.onclose = () => {
      console.log("Closed websocket");
    };

    return () => {
      wsCurrent.close();
    };
  }, []);

  useEffect(() => {
    setVideo(props.video);
  }, [props.video]);

  return (
    <StudioContext.Provider
      value={{
        playback,
        video,
        drawingMode,
        enableDrawingMode: (mode) => {
          setDrawingMode(mode);
        },
        stopDrawingMode: () => {
          setDrawingMode(null);
        },
        onLocationChange: debounce((location) => {
          if (!userprofile) return;

          ws.current.send(
            JSON.stringify({
              type: "location",
              data: {
                x: location.x,
                y: location.y,
              },
            })
          );
        }, 1000),
        setVideo: (video: Video) => {
          setVideo(video);
        },
        addNewVideoElement: (element: VideoElement) => {
          setVideo((video) => {
            return {
              ...video,
              schema: {
                ...video.schema,
                elements: [...video.schema.elements, element],
              },
            };
          });
        },
        removeVideoElement: (elementId: string) => {
          setVideo((video) => {
            return {
              ...video,
              schema: {
                ...video.schema,
                elements: video.schema.elements.filter((el) => {
                  if (el.id === elementId) {
                    return false;
                  }

                  return true;
                }),
              },
            };
          });
        },
        updateVideoElement: (elementId: string, data: VideoElement) => {
          setVideo((video) => {
            return {
              ...video,
              schema: {
                ...video.schema,
                elements: video.schema.elements.map((el) => {
                  if (el.id === elementId) {
                    return data;
                  }

                  return el;
                }),
              },
            };
          });
        },
        createNewVideoElementState: (
          elementId: string,
          state: VideoElementState
        ) => {
          setVideo((video) => {
            return {
              ...video,
              schema: {
                ...video.schema,
                elements: video.schema.elements.map((el) => {
                  if (el.id === elementId) {
                    return {
                      ...el,
                      states: [...el.states, state],
                    };
                  }

                  return el;
                }),
              },
            };
          });
        },
        updateVideoElementState: (
          elementId: string,
          stateId: string,
          state: Partial<VideoElementState>
        ) => {
          setVideo((video) => {
            return {
              ...video,
              schema: {
                ...video.schema,
                elements: video.schema.elements.map((el) => {
                  if (el.id === elementId) {
                    return {
                      ...el,
                      states: el.states.map((s) => {
                        if (s.id === stateId) {
                          return {
                            ...s,
                            ...state,
                          };
                        }

                        return s;
                      }),
                    };
                  }

                  return el;
                }),
              },
            };
          });
        },
      }}
    >
      {props.children(video)}
    </StudioContext.Provider>
  );
}

export function useStudio() {
  const context = React.useContext(StudioContext);

  if (context === undefined) {
    throw new Error("useStudio must be used within a StudioProvider");
  }

  return context;
}
