export function capitalizeFirstLetters(text: string) {
  return text.toLocaleLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
/* Get the same number depends on the string. In any place if you will transfer the same string, you will get the same result */
export const getHashNumberByString = (value: string): number => {
  return value
    .split("")
    .map((el) => el.charCodeAt(0))
    .reduce((a, b) => a + b);
};
