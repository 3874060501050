import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getAnnouncements } from "../../../actions/admin/announcements/getAnnouncements";
import { Announcement } from "../../../types/admin/Announcement";
import { formatDate } from "../../../helpers/time";
import { theme } from "../../../themes/variables";
import { Button } from "../../Button";
import { TableHandler, TableHandlerColumn } from "../../TableHandler";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { TableOrderingSort } from "../../Table";

export default function AnnouncementsTable({
  forwardRef,
  onSetModal,
  onSetDeleteModal,
  onSelectAnnouncement,
}: {
  forwardRef: any;
  onSetModal: (modal: boolean) => void;
  onSetDeleteModal: (modal: boolean) => void;
  onSelectAnnouncement: (announcement: Announcement) => void;
}) {
  const { t } = useTranslation();

  const tableHandlerColumns: TableHandlerColumn<Announcement>[] = [
    {
      label: t("announcements.title"),
      render: (row) => (
        <div
          css={css`
            font-weight: 700;
          `}
        >
          {row.title}
        </div>
      ),
    },
    {
      label: t("announcements.created"),
      orderFields: ["created"],
      render: (row) => <div>{row.created && formatDate(row.created)}</div>,
    },
    {
      label: t("announcements.publish_date"),
      orderFields: ["publish_date"],
      render: (row) => <div>{formatDate(row.publish_date)}</div>,
    },
    {
      label: t("announcements.short"),
      render: (row) => <div>{row.short_description}</div>,
    },
    {
      label: t("announcements.actions"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            css={css`
              margin-right: 16px;
              min-width: 54px;
            `}
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.blueBackground}`}
            color={theme.colors.dark}
            icon={
              <EditIcon
                width={14}
                height={14}
                color={theme.colors.blueBackground}
              />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
            iconRight
            small
            onClick={() => {
              onSelectAnnouncement(row);
              onSetModal(true);
            }}
          ></Button>

          <Button
            css={css`
              min-width: 54px;
            `}
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.error}`}
            color={theme.colors.dark}
            small
            onClick={() => {
              onSelectAnnouncement(row);
              onSetDeleteModal(true);
            }}
            icon={
              <DeleteIcon width={14} height={14} color={theme.colors.error} />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
          ></Button>
        </div>
      ),
    },
  ];

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <TableHandler
        forwardRef={forwardRef}
        tableData={{
          columns: tableHandlerColumns,
        }}
        getFunction={getAnnouncements}
        options={{
          limit: 5,
        }}
        defaultOrdering={{
          index: 2,
          orderFields: ["publish_date"],
          sort: TableOrderingSort.DESC,
        }}
      />
    </div>
  );
}
