import { authorised } from "../../request";
import { Organisation } from "../../types/Organisation";

export async function getOrganisation() {
  const API = authorised();

  return API.request<Organisation>({
    method: "GET",
    url: `/api/studio/organisation/`,
  });
}
