import { FormikHelpers, Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { customToast } from "../../customToast";
import { css } from "styled-components/macro";
import * as Yup from "yup";

import { uploadImage } from "../../../actions/image";
import { ImageUploadField } from "../../../forms/fields/ImageUploadField";
import { InputField } from "../../../forms/fields/InputField";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { FormValues } from "../../../pages/Organisation/OrganisationExperts";
import { theme } from "../../../themes/variables";
import { Expert } from "../../../types/Expert";
import { ImageType } from "../../../types/Image";
import { Button } from "../../Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../Modal";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Plus.svg";

export function EditCreateExpert(props: {
  onSubmitCallback: (values: FormValues) => Promise<void>;
  initialData?: Expert | null;
  modal: boolean;
  closeModal: () => void;
}) {
  const { onSubmitCallback, initialData } = props;

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    description: Yup.string(),
  });

  const onSubmit = async (
    values: FormValues,
    {
      setSubmitting,
      resetForm,
    }: Pick<FormikHelpers<FormValues>, "setSubmitting" | "resetForm">
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        first_name: values.first_name || "",
        last_name: values.last_name || "",
        introduction: values.introduction || "",
        avatar: values.avatar || null,
      });

      resetForm();
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        first_name: initialData?.first_name || "",
        last_name: initialData?.last_name || "",
        introduction: initialData?.introduction || "",
        avatar: initialData?.avatar || null,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values, setSubmitting, resetForm }) => {
        return (
          <Form noValidate>
            <Modal
              modalIsOpen={props.modal}
              onClose={props.closeModal}
              contentLabel={t("organisation.edit-expert")}
              contentStyles={{
                maxWidth: "960px",
                padding: "40px",
                backgroundColor: theme.colors.gray1,
              }}
            >
              <ModalHeader closeIcon onClose={props.closeModal}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <EditIcon
                    width={20}
                    height={20}
                    color={theme.colors.primary}
                    css={css`
                      margin-right: 17px;
                    `}
                  />

                  <span>{t("organisation.edit-expert")}</span>
                </div>
              </ModalHeader>

              <ModalBody
                css={css`
                  margin-top: 32px;
                  margin-bottom: 40px;
                  padding: 40px;
                  background-color: ${theme.colors.white};
                `}
              >
                <div
                  css={css`
                    margin-bottom: 32px;
                  `}
                >
                  <InputField
                    label={`${t("auth.first-name")}:`}
                    name={"first_name"}
                  />
                </div>

                <div
                  css={css`
                    margin-bottom: 32px;
                  `}
                >
                  <InputField
                    label={`${t("auth.last-name")}:`}
                    name={"last_name"}
                  />
                </div>

                <div
                  css={css`
                    margin-bottom: 32px;
                  `}
                >
                  <InputField
                    label={`${t("account.description")}:`}
                    name={"introduction"}
                  />
                </div>

                <div>
                  <ImageUploadField
                    label={`${t("account.avatar")}:`}
                    disableLibrary
                    type={ImageType.HIDDEN}
                    name="avatar"
                    uploadFn={uploadImage}
                  />
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.transparent}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  onClick={props.closeModal}
                >
                  {t("actions.cancel")}
                </Button>

                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!isValid}
                  icon={
                    <PlusIcon
                      width={14}
                      height={14}
                      color={theme.colors.white}
                    />
                  }
                  iconRight
                  onClick={() => onSubmit(values, { setSubmitting, resetForm })}
                >
                  {!!props.initialData
                    ? t("actions.save")
                    : t("organisation.create-expert")}
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}
