import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Notification } from "../types/Notification";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { SEO } from "../components/SEO";
import { NotificationItem } from "../components/Notifications";
import { Pagination } from "../components/Pagination";

import { getNotifications, updateNotification } from "../actions/notification";
import { pageLoading } from "../helpers/pageLoading";
import { usePagination } from "../hooks/usePagination";

import { ReactComponent as SettingsIcon } from "../assets/icons/Settings.svg";
import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";
import { useHistory } from "react-router";
import { Footer } from "../components/Footer";

export function NotificationPage(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    response: {
      data: notifications,
      error: notificationsError,
      refetch: notificationsRefetch,
    },
    pagination: { onChose, onNext, onPrev, count, page },
  } =
    usePagination<
      {
        count: number;
        next: null | string;
        previous: null | string;
        results: Notification[];
      },
      {
        page: string;
      }
    >(getNotifications);

  useEffect(() => {
    const interval = setInterval(() => {
      notificationsRefetch();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [notificationsRefetch]);

  if (!notifications || notificationsError) {
    return pageLoading(notificationsError);
  }

  const {
    read,
    unread,
  }: {
    read: Notification[];
    unread: Notification[];
  } = notifications.results.reduce(
    (
      data: {
        read: Notification[];
        unread: Notification[];
      },
      row
    ) => {
      if (row.read) {
        data.read.push(row);
      } else if (!row.read) {
        data.unread.push(row);
      }

      return data;
    },
    {
      read: [],
      unread: [],
    }
  );

  const readAll = async () => {
    await Promise.all(
      unread.map(async (el) => {
        updateNotification(el.id, {
          read: true,
        });
      })
    );

    notificationsRefetch();
  };

  const readNotification = async (id: number) => {
    await updateNotification(id, {
      read: true,
    });

    notificationsRefetch();
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("notifications.new")}</title>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            border-radius: 20px;
            background: ${theme.colors.white};
            ul {
              list-style: none;
              margin: 0;
              padding: 0;
            }
            li {
              margin: 16px 0;
            }
          `}
        >
          {/* Header */}
          <div
            css={css`
              padding-bottom: 46px;
              padding-left: 20px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Headers.H3
                  css={css`
                    margin-right: 15px;
                  `}
                >
                  {unread.length}
                </Headers.H3>

                <Headers.H4
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {t("notifications.new")}
                </Headers.H4>
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <button
                  css={css`
                    ${!!unread.length && `cursor: pointer;`}
                    display: flex;
                    align-items: center;
                    border: none;
                    outline: none;
                    padding: 0;
                    background: none;
                    margin-right: 17px;
                  `}
                  disabled={!unread.length}
                  onClick={readAll}
                >
                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                      margin-right: 10px;
                    `}
                  >
                    {t("actions.mark-as-read")}
                  </span>

                  <ReadIcon
                    width={20}
                    height={20}
                    color={
                      !!unread.length
                        ? theme.colors.primary
                        : theme.colors.gray3
                    }
                  />
                </button>

                <button
                  css={css`
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    border: none;
                    outline: none;
                    padding: 0;
                    background: none;
                  `}
                  onClick={() => history.push("/account/me")}
                >
                  <span
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                      margin-right: 10px;
                    `}
                  >
                    {t("notifications.settings")}
                  </span>
                  <SettingsIcon
                    width={20}
                    height={20}
                    color={theme.colors.gray5}
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Unreaded */}
          {!!unread.length && (
            <div
              css={css`
                padding: 16px 24px 16px 24px;
                border-radius: 20px;
                background: ${theme.colors.yellowBackground};
              `}
            >
              <ul>
                {unread.map((el) => {
                  return (
                    <li
                      key={el.id}
                      css={css`
                        font-size: 16px;
                        line-height: 22px;
                        width: 100%;
                        position: relative;
                      `}
                    >
                      <NotificationItem
                        onClick={() => readNotification(el.id)}
                        data={el}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {/* Readed */}
          {!!read.length && (
            <div
              css={css`
                padding: 20px 24px 20px 24px;
              `}
            >
              <ul>
                {read.map((el) => {
                  return (
                    <li
                      key={el.id}
                      css={css`
                        font-size: 16px;
                        line-height: 22px;
                        width: 100%;
                        position: relative;
                      `}
                    >
                      <NotificationItem data={el} read />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <Pagination
            arrows
            currentItemIndex={page}
            itemsCount={count}
            onChose={onChose}
            onNext={onNext}
            onPrev={onPrev}
          />
        </div>
      </Block>
      <Footer />
    </Wrapper>
  );
}
