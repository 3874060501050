import { authorised } from "../../request";
import { Block } from "../../types/Block";

type Args = {
  lessonId: string;
  blockId: string;
  data: Partial<Pick<Block, "data" | "type">>;
};

export async function updateBlock({ lessonId, blockId, data }: Args) {
  const API = authorised();

  return API.request<Block>({
    method: "PUT",
    url: `/api/studio/lesson/${lessonId}/blocks/${blockId}/`,
    data,
  });
}
