import { buildQueryString } from "../../helpers/buildQueryString";
import { authorised } from "../../request";
import { Course } from "../../types/Course";

export async function getCourses({
  searchText,
  status,
}: {
  searchText?: string;
  status?: string;
} = {}) {
  const API = authorised();

  return API.request<Course[]>({
    method: "GET",
    url: `/api/studio/courses/?${buildQueryString({
      name: searchText,
      status: status,
    })}`,
  });
}

export async function getPaginatedCourses({
  searchText,
  status,
  limit,
  offset,
}: {
  limit?: number;
  offset?: number;
  searchText?: string;
  status?: string;
} = {}) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: Course[];
  }>({
    method: "GET",
    url: `/api/studio/courses/?${buildQueryString({
      name: searchText,
      status: status,
      limit: limit,
      offset: offset,
    })}`,
  });
}
