import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import {
  ContentEditorEmbed,
  ContentEditorCustomCode,
  ContentEditorPodcast,
  ContentEditorBlock,
} from "../../components/ContentEditorLesson";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { LessonType } from "../../types/Lesson";

import { useNewFetch } from "../../useAPI";
import { getLesson } from "../../actions/lesson";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export interface FormValues {
  video_url: string;
}

export function ContentEditorLessonPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const {
    data: lesson,
    error: lessonError,
    refetch,
  } = useNewFetch(getLesson, {
    courseId: params.courseId,
    lessonId: params.lessonId,
  });

  if (!lesson || lessonError) {
    return pageLoading(lessonError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.content_editor"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/content_editor`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/edit`,
    },
    {
      title: t("breadcrumbs.testing"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/testing`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: lesson.course!.name,
      url: `/courses/${lesson.course!.uuid}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{lesson.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{lesson.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block
          css={css`
            padding: 50px 40px;
          `}
        >
          {lesson.type === LessonType.EMBED && (
            <ContentEditorEmbed lesson={lesson} />
          )}
          {lesson.type === LessonType.CUSTOM_CODE && (
            <ContentEditorCustomCode lesson={lesson} />
          )}
          {lesson.type === LessonType.PODCAST && (
            <ContentEditorPodcast lesson={lesson} />
          )}
          {lesson.type === LessonType.BLOCK && (
            <ContentEditorBlock
              lesson={lesson}
              onRefetch={async () => {
                await refetch();
              }}
            />
          )}
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
