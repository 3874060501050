import React from "react";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Header } from "../components/Header";
import { Wrapper } from "../components/Wrapper";
import { Block } from "../components/Block";
import { Button } from "../components/Button";
import { SEO } from "../components/SEO";
import { Footer } from "../components/Footer";

export function NoAccessPage(props: {}) {
  const { t } = useTranslation();
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("page-404.sorry")}</title>
        <body className="bg-orange"></body>
      </SEO>
      <Header />
      <Block>
        <div
          css={css`
            max-width: 50%;
          `}
        >
          <div
            css={css`
              margin-top: 100px;
            `}
          >
            <div
              css={css`
                color: ${theme.colors.orange};
                font-size: 48px;
                font-weight: bold;
                line-height: 1.25;
              `}
            >
              {t("page-404.sorry")}.
            </div>
            <div
              css={css`
                color: ${theme.colors.dark};
                font-size: 48px;
                font-weight: bold;
                line-height: 1.25;
              `}
            >
              {t("no-access.title")}
            </div>
          </div>

          <div
            css={css`
              margin-top: 50px;
              font-size: 36px;
              font-weight: 400;
            `}
          >
            {t("no-access.advice")}
          </div>

          <Button
            color={theme.colors.dark}
            background={theme.colors.transparent}
            border={`2px solid ${theme.colors.dark}`}
            css={css`
              margin-top: 28px;
              padding: 10px 20px;
              font-weight: bold;
            `}
            onClick={() => goHome()}
          >
            {t("page-404.homepage")}
          </Button>
        </div>
      </Block>
      <Footer />
    </Wrapper>
  );
}
