import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Headers, Row } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { pageLoading } from "../../helpers/pageLoading";
import SelfAssessmentsPerformance from "../../components/SelfAssessmentPerformance";
import { getSelfAssessmentsCoursePerformance } from "../../actions/courseSelfAssessments/getSelfAssessmentsCoursePerformance";
import { SEO } from "../../components/SEO";
import { Footer } from "../../components/Footer";

export function CourseSelfAssessmentPerformance() {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
  }>();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  const { data: selfAssessments, error: selfAssessmentsError } = useNewFetch(
    getSelfAssessmentsCoursePerformance,
    {
      courseId: params.courseId,
    }
  );

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: true,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("self-assessment.performance")}</title>
        </SEO>
        <Helmet title={course.name + " " + t("self-assessment.performance")} />
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <Headers.H2>{course.name}</Headers.H2>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        {selfAssessments?.length ? (
          <div
            css={css`
              margin-top: 1rem;
            `}
          >
            <SelfAssessmentsPerformance selfAssessmentItems={selfAssessments} />
          </div>
        ) : (
          <Block>
            <Row justify="center">
              <Headers.H3
                css={css`
                  margin-bottom: 2rem;
                `}
              >
                {t("self-assessment.no_items")}
              </Headers.H3>
            </Row>
            <Row justify="center">
              <Link to={`/courses/${params.courseId}/self-assessments/new`}>
                <Button>{t("actions.create")}</Button>
              </Link>
            </Row>
          </Block>
        )}
        <Footer />
      </Wrapper>
    </>
  );
}
