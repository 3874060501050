import { authorised } from "../../request";

type Args = {
  menuType: string;
  menuLocation: string;
};

export async function createMenuItem({ menuType, menuLocation }: Args) {
  const API = authorised();

  return API.request<{ uuid: string }>({
    method: "POST",
    url: `/api/enterprise/menus/${menuType}/${menuLocation}/`,
  });
}
