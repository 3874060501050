import React from "react";
import { VideoAudioElement as VideoAudioElementType } from "../../../types/Video";

type Props = {
  element: VideoAudioElementType;
  currentTime: number;
  playing: boolean;
};

export class VideoAudioElement extends React.Component<Props> {
  public audio: HTMLAudioElement | null = null;

  componentDidMount() {
    this.audio = new Audio(this.props.element.audio.file);

    this.audio.currentTime = this.getAudioTime();
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.audio) return;

    if (this.props.playing) {
      this.audio.play();
    } else {
      this.audio.pause();
    }

    const diff = Math.abs(this.props.currentTime - prevProps.currentTime);

    if (diff > 1000) {
      this.audio.currentTime = this.getAudioTime();
    }
  }

  componentWillUnmount() {
    if (this.audio) {
      this.audio.pause();
    }
  }

  getAudioTime() {
    const startTime = this.props.element.states[0].start_time;
    const audioTime = (this.props.currentTime - startTime) / 1000;

    return audioTime;
  }

  render() {
    return null;
  }
}
