import { authorised } from "../../request";
import { Expert } from "../../types/Expert";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  query?: string;
};

export async function getExperts(args: Args) {
  const API = authorised();

  return API.request<{
      count: number;
      next: string | null;
      previous: string | null;
      results: Expert[];
    }>({
    method: "GET",
    url: `/api/studio/organisation/experts/?${buildQueryString(args)}`,
  });
}
