import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Trash2 } from "react-feather";
import { Field, FieldArray, FieldProps } from "formik";
import { TextareaField } from "../../../forms/fields/TextareaField";
import { Button } from "../../../components/Button";
import { RadioComponent } from "../../../components/RadioComponent";
import { theme } from "../../../themes/variables";
import { TestVariantAnswer } from "../../../types/Test";
import { InputLabel } from "../../InputLabel";

export function SingleChoice(props: { name: string; label?: string }) {
  const { t } = useTranslation();

  return (
    <Field
      name={props.name}
      render={({ field, form }: FieldProps) => {
        const answers: TestVariantAnswer[] = field.value || [];

        return (
          <FieldArray
            name={props.name}
            render={(arrayHelpers) => (
              <div
                css={css`
                  margin-bottom: 1rem;
                `}
              >
                <InputLabel label={props.label} />

                {answers.map((answer, idx) => {
                  return (
                    <div
                      css={css`
                        margin-bottom: 1rem;
                        display: grid;
                        grid-template-columns: 3rem 1fr 2rem;
                        align-items: center;
                        grid-gap: 1rem;
                      `}
                      key={idx}
                    >
                      <RadioComponent
                        onChange={() => {
                          const newAnswers = answers.map((a, index) => {
                            if (index === idx) {
                              return {
                                ...a,
                                is_correct: true,
                              };
                            }

                            return {
                              ...a,
                              is_correct: false,
                            };
                          });

                          form.setFieldValue(props.name, newAnswers);
                        }}
                        value={answer.is_correct}
                      />

                      <TextareaField
                        name={`${props.name}[${idx}].primary_text`}
                        maxLength={500}
                        css={css`
                          min-height: 50px;
                        `}
                      />

                      <button
                        type="button"
                        css={css`
                          padding: 0;
                          margin: 0;
                          cursor: pointer;
                          white-space: nowrap;
                          border: 0;
                          text-transform: none;
                          -webkit-appearance: none;
                          outline: none;
                          background: transparent;
                          position: relative;
                          top: 0;
                          :hover {
                            color: ${theme.colors.error};
                          }
                        `}
                        title={t("actions.delete")}
                        onClick={() => {
                          arrayHelpers.remove(idx);

                          const newAnswers = answers.filter(
                            (_, index) => index !== idx
                          );
                          form.setFieldValue(props.name, newAnswers);
                        }}
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  );
                })}
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                  `}
                >
                  <Button
                    onClick={() =>
                      arrayHelpers.push({
                        image: null,
                        is_correct: false,
                        primary_text: "",
                        secondary_text: "",
                      })
                    }
                    background={theme.colors.white}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  >
                    {t("lesson.quiz.question.add-choice")}
                  </Button>
                </div>
              </div>
            )}
          ></FieldArray>
        );
      }}
    />
  );
}
