import { authorised } from "../../request";

export async function deleteJourneyCourse(journeyId: string, courseId: string) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/journeys/${journeyId}/courses/${courseId}`,
  });
}
