import { Card, ProgressCard } from "../../../../components/Card";
import { css } from "styled-components/macro";
import { theme } from "../../../../themes/variables";
import { Headers } from "../../../../helpers/layout";
import { useTranslation } from "react-i18next";

export function TestPerformanceWidgets(props: {
  data: {
    totalSubmissions: number;
    successRate: number;
    successRateFirstAttempt: number;
  };
}) {
  const { data } = props;
  const { t } = useTranslation();

  const cardStyle = css`
    width: 240px;
    flex: 1 0 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 27px;

    &:last-child {
      margin-right: 0;
    }
  `;

  return (
    <div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Card
          color={theme.colors.yellowBackground}
          padding={24}
          css={cardStyle}
        >
          <Headers.H4>{t("test-performance.total-submissions")}:</Headers.H4>

          <span
            css={css`
              margin-top: 18px;
              font-size: 30px;
              line-height: 42px;
              color: ${theme.colors.primary};
            `}
          >
            {data.totalSubmissions}
          </span>
        </Card>

        <ProgressCard
          css={cardStyle}
          value={data.successRate}
          valueSuffix={`%`}
          text={`${t("test-performance.avg-percent-passed-tests")}:`}
          threshold={70}
        />

        <ProgressCard
          css={cardStyle}
          value={data.successRateFirstAttempt}
          valueSuffix={`%`}
          text={`${t(
            "test-performance.avg-percent-passed-tests-first-attempt"
          )}:`}
          threshold={70}
        />
      </div>
    </div>
  );
}
