import React, { forwardRef } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { X } from "react-feather";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker.css";
import { theme } from "../../themes/variables";
import {
  FeedbackStatus,
  FeedbackType,
  LessonFeedback,
} from "../../types/LessonFeedback";
import FilterItem from "./FilterItem";
import { Filters } from "../../hooks/useFilters";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";

export type Option = {
  value: string;
  label: string;
};

export enum FilterType {
  STATUS = "status",
  TYPE = "type",
  FROM_DATE = "from_date",
  TO_DATE = "to_date",
}

const CustomDatePicker = forwardRef<HTMLButtonElement>((props, ref) => {
  const { value, placeholder, className } = props as {
    value: string;
    placeholder: string;
    className?: string;
  };

  return (
    <button
      css={css`
        display: flex;
        justify-content: space-between;
        width: fit-content;
        margin-right: 30px;
        border: 1px solid ${theme.colors.black};
        background-color: ${theme.colors.white};
        border-radius: 20px;
        padding: 3px 20px;
        min-width: 150px;
        color: ${theme.colors.black};
        cursor: pointer;
        align-items: center;
      `}
      className={className}
      {...props}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {value || placeholder}
        </div>

        <CalendarIcon
          width={14}
          height={14}
          color={theme.colors.primary}
          css={css`
            min-width: 14px;
            min-height: 14px;
            margin-left: 10px;
          `}
        />
      </div>
    </button>
  );
});

export default function FeedbackFilter({
  items,
  onFilterChange,
  activeFilters,
  resetFilters,
}: {
  items: LessonFeedback[];
  activeFilters: Filters;
  onFilterChange: (key: string, value: string) => void;
  resetFilters: () => void;
}) {
  const { t } = useTranslation();
  const { status, type, from_date, to_date } = activeFilters;
  const dateFormat = "yyyy-MM-dd";
  const fromDate = from_date
    ? DateTime.fromFormat(from_date, dateFormat).toJSDate()
    : "";
  const toDate = to_date
    ? DateTime.fromFormat(to_date, dateFormat).toJSDate()
    : "";

  const statusOption = [
    {
      value: FeedbackStatus.ALL,
      label: t("feedback.all"),
    },
    {
      value: FeedbackStatus.NEW,
      label: t("feedback.new"),
    },
    {
      value: FeedbackStatus.READ,
      label: t("feedback.read"),
    },
  ];

  const typeOption = [
    {
      value: FeedbackType.ALL,
      label: t("feedback.all"),
    },
    {
      value: FeedbackType.POSITIVE,
      label: t("feedback.positive"),
    },
    {
      value: FeedbackType.NEGATIVE,
      label: t("feedback.negative"),
    },
  ];

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          align-items: center;
        `}
      >
        <FilterItem
          label={t("feedback.status")}
          options={statusOption}
          defaultOption={status}
          onChangeOption={(e) =>
            onFilterChange(FilterType.STATUS, e.target.value)
          }
        />

        <FilterItem
          label={t("feedback.type")}
          options={typeOption}
          defaultOption={type}
          onChangeOption={(e) =>
            onFilterChange(FilterType.TYPE, e.target.value)
          }
        />

        <span
          css={css`
            margin-right: 7px;
            white-space: nowrap;
            font-size: 16px;
          `}
        >
          {t("feedback.from")}
        </span>
        <DatePicker
          id={1}
          placeholderText={t("actions.select-date")}
          selected={fromDate}
          onChange={(date: Date) => {
            const formattedDate =
              DateTime.fromJSDate(date).toFormat(dateFormat);

            onFilterChange(FilterType.FROM_DATE, formattedDate);
          }}
          selectsStart
          startDate={fromDate}
          endDate={toDate}
          customInput={<CustomDatePicker />}
        />

        <span
          css={css`
            margin-right: 7px;
            white-space: nowrap;
            font-size: 16px;
          `}
        >
          {t("feedback.to")}
        </span>
        <DatePicker
          id={2}
          placeholderText={t("actions.select-date")}
          selected={toDate}
          onChange={(date: Date) => {
            const formattedDate =
              DateTime.fromJSDate(date).toFormat(dateFormat);

            onFilterChange(FilterType.TO_DATE, formattedDate);
          }}
          selectsEnd
          startDate={fromDate}
          endDate={toDate}
          minDate={fromDate}
          customInput={<CustomDatePicker />}
        />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: auto 0 0 auto;
          cursor: pointer;

          :hover {
            opacity: 0.7;
          }
        `}
        onClick={() => resetFilters()}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: 700;
            margin-right: 10px;
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          {t("feedback.reset")}
        </div>
        <X color={theme.colors.error} size={14} />
      </div>
    </div>
  );
}
