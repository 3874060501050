import { authorised } from "../../../request";

type Args = {
  id: number;
}

export async function deleteUserSegment({id}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/user-segment/${id}/`
  });
}
