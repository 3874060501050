import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { EditCreateCourse } from "../../components/EditCreateCourse";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { FormValues } from "../../components/EditCreateCourse";
import { DangerZone } from "../../components/DangerZone";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Course, CoursePublishRequestStatus } from "../../types/Course";
import {
  updateCourse,
  deleteCourse,
  publishCourse,
  unpublishCourse,
  cancelPublishCourse,
} from "../../actions/course";
import { Headers } from "../../helpers/layout";
import { hexToRgb } from "../../helpers/color";
import { theme } from "../../themes/variables";

import { ReactComponent as AcceptIcon } from "../../assets/icons/Accept.svg";
import { ReactComponent as SandwatchIcon } from "../../assets/icons/Sandwatch.svg";
import { ReactComponent as StopIcon } from "../../assets/icons/Stop.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as LeftIcon } from "../../assets/icons/Left.svg";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { pageLoading } from "../../helpers/pageLoading";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Footer } from "../../components/Footer";

export type CourseData = Pick<
  Course,
  | "description"
  | "name"
  | "learning_points"
  | "level"
  | "course_publish_request"
  | "image"
  | "price"
  | "faq_items"
  | "subtitle"
  | "summary_content_html"
  | "course_starts"
  | "course_for"
  | "visibility"
  | "has_certificate"
  | "logo"
>;

export function CourseStatusCard(props: {
  icon: React.ReactNode;
  title: string;
  background: string;
  subtitle?: string;
  button: React.ReactNode | React.ReactNode[];
}) {
  const { r, g, b } = hexToRgb(props.background);
  const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.4)`;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 20px;
        padding: 30px 40px;
        margin-bottom: 40px;
        background: ${rgbaColor};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: 24px;
            display: flex;
          `}
        >
          {props.icon}
        </div>

        <div>
          <div
            css={css`
              font-size: 22px;
              line-height: 28px;
            `}
          >
            {props.title}
          </div>

          {props.subtitle && (
            <div
              css={css`
                font-size: 16px;
                line-height: 22px;
              `}
            >
              {props.subtitle}
            </div>
          )}
        </div>
      </div>

      <div>{props.button}</div>
    </div>
  );
}

export function EditCoursePage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
  }>();

  const [deleteModal, setDeleteModal] = useState(false);
  const [publishCourseModal, setPublishCourseModal] = useState(false);
  const [unpublishCourseModal, setUnpublishCourseModal] = useState(false);

  const onConfirmDelete = async () => {
    try {
      await deleteCourse(params.courseId);
      setDeleteModal(false);
      history.push(`/courses`);
    } catch (error) {
      customToast.error(
        t("courses.modal.delete.error", {
          name: t("courses.name"),
        })
      );
    }
  };

  const onPublish = async () => {
    try {
      const { data } = await publishCourse(params.courseId);

      if (course) {
        setPublishCourseModal(false);

        setCourseData({
          ...course,
          course_publish_request: data.course_publish_request,
        });
        customToast.success(t("status.success"));
      }
    } catch (error) {
      customToast.error(
        t("courses.modal.publish.error", {
          name: t("courses.name"),
        })
      );
    }
  };

  const onCancelPublish = async () => {
    try {
      const { data } = await cancelPublishCourse(params.courseId);

      if (course) {
        setCourseData({
          ...course,
          course_publish_request: data.course_publish_request,
        });

        customToast.success(t("status.success"));
      }
    } catch (error) {
      customToast.error(
        t("status.error", {
          name: t("courses.name"),
        })
      );
    }
  };

  const onUnpublish = async () => {
    try {
      const { data } = await unpublishCourse(params.courseId);
      if (course) {
        setUnpublishCourseModal(false);

        setCourseData({
          ...course,
          course_publish_request: data.course_publish_request,
        });

        customToast.success(t("status.success"));
      }
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const history = useHistory();

  const {
    data: course,
    error: courseError,
    setData: setCourseData,
    refetch: refetchCourse,
  } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  const onSubmit = async (data: FormValues) => {
    await updateCourse(params.courseId, data);

    refetchCourse();
    customToast.success(t("status.success"));
  };

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: false,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {course.course_publish_request && (
            <div>
              {course.course_publish_request.status ===
                CoursePublishRequestStatus.PENDING && (
                <CourseStatusCard
                  background={theme.colors.yellowBackground}
                  title={t("courses.status.notification.pending.title")}
                  subtitle={t("courses.status.notification.pending.subtitle")}
                  icon={
                    <SandwatchIcon
                      color={theme.colors.primary}
                      width={32}
                      height={32}
                    />
                  }
                  button={
                    <Button
                      icon={
                        <DeleteIcon
                          width={12}
                          height={12}
                          color={theme.colors.error}
                        />
                      }
                      iconRight
                      background={theme.colors.transparent}
                      border={`1px solid ${theme.colors.dark}`}
                      color={theme.colors.dark}
                      hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
                      onClick={() => onCancelPublish()}
                    >
                      {t("courses.status.notification.remove-request")}
                    </Button>
                  }
                ></CourseStatusCard>
              )}

              {course.course_publish_request.status ===
                CoursePublishRequestStatus.REJECTED && (
                <CourseStatusCard
                  background={theme.colors.pomade}
                  title={t("courses.status.notification.rejected.title")}
                  subtitle={course.course_publish_request.rejection_reason}
                  icon={
                    <StopIcon
                      color={theme.colors.primary}
                      width={32}
                      height={32}
                    />
                  }
                  button={
                    <Button
                      icon={
                        <EditIcon
                          width={12}
                          height={12}
                          color={theme.colors.blueBackground}
                        />
                      }
                      iconRight
                      background={theme.colors.transparent}
                      border={`1px solid ${theme.colors.dark}`}
                      color={theme.colors.dark}
                      hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
                      onClick={() => onPublish()}
                    >
                      {t("actions.submit-again")}
                    </Button>
                  }
                ></CourseStatusCard>
              )}

              {course.course_publish_request.status ===
                CoursePublishRequestStatus.APPROVED && (
                <CourseStatusCard
                  background={theme.colors.haki}
                  title={t("courses.status.notification.approve.title")}
                  icon={
                    <AcceptIcon
                      color={theme.colors.primary}
                      width={32}
                      height={32}
                    />
                  }
                  button={
                    <>
                      <Button
                        icon={
                          <StopIcon
                            width={16}
                            height={16}
                            color={theme.colors.primary}
                          />
                        }
                        iconRight
                        background={theme.colors.transparent}
                        border={`1px solid ${theme.colors.transparent}`}
                        color={theme.colors.dark}
                        hoverStyles={`opacity: 0.85;`}
                        onClick={() => onUnpublish()}
                        css={css`
                          margin-right: 20px;
                        `}
                      >
                        {t("actions.unpublish")}
                      </Button>

                      <a
                        href={`${process.env.REACT_APP_CAMPUS_URL}/course/${course.uuid}/`}
                        target="__blank"
                      >
                        <Button
                          icon={
                            <LeftIcon
                              width={12}
                              height={12}
                              color={theme.colors.primary}
                              css={css`
                                transform: rotate(180deg);
                              `}
                            />
                          }
                          iconRight
                          background={theme.colors.transparent}
                          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                          color={theme.colors.dark}
                          border={`1px solid ${theme.colors.dark}`}
                        >
                          {t("courses.status.notification.preview")}
                        </Button>
                      </a>
                    </>
                  }
                ></CourseStatusCard>
              )}
            </div>
          )}

          <EditCreateCourse
            onSubmitCallback={onSubmit}
            onPublishCourse={() => {
              setPublishCourseModal(true);
            }}
            initialData={course}
            courseId={params.courseId}
            previewUrl={course.preview_link}
          />

          <DangerZone
            css={css`
              margin-top: 5rem;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("courses.delete")}
            </Button>

            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("courses.modal.delete.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>

            <Modal
              modalIsOpen={publishCourseModal}
              onClose={() => setPublishCourseModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader
                closeIcon
                onClose={() => setPublishCourseModal(false)}
              >
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("courses.modal.publish.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  onClick={() => setPublishCourseModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onPublish}>{t("actions.publish")}</Button>
              </ModalFooter>
            </Modal>

            <Modal
              modalIsOpen={unpublishCourseModal}
              onClose={() => setUnpublishCourseModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader
                closeIcon
                onClose={() => setUnpublishCourseModal(false)}
              >
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("courses.modal.unpublish.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  onClick={() => setUnpublishCourseModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onUnpublish}>{t("actions.unpublish")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
