import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { css } from "styled-components/macro";
import { Image } from "../../types/Image";
import { uploadImage } from "../../actions/image/";
import { ImageUploadModal } from "../../modals/ImageUploadModal";

const contentStyles = `
  @font-face {
    font-family: "Cadiz";
    font-style: normal;
    font-weight: 400;
    src: url("${process.env.PUBLIC_URL}/fonts/Cadiz-Book.woff2") format("woff2"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-Book.woff") format("woff"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-Book.otf") format("opentype");
  }

  @font-face {
    font-family: "Cadiz";
    src: url("${process.env.PUBLIC_URL}/fonts/Cadiz-BookItalic.woff2") format("woff2"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-BookItalic.woff") format("woff"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-BookItalic.otf") format("opentype");
    font-style: italic;
    font-weight: 400;
  }
  
  @font-face {
    font-family: "Cadiz";
    src: url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBd.woff2") format("woff2"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBd.woff") format("woff"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBd.otf") format("opentype");
    font-style: normal;
    font-weight: 500;
  }
  
  @font-face {
    font-family: "Cadiz";
    src: url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBdItalic.woff2") format("woff2"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBdItalic.woff") format("woff"),
      url("${process.env.PUBLIC_URL}/fonts/Cadiz-SemiBdItalic.otf") format("opentype");
    font-style: italic;
    font-weight: 500;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }
  body {
    font-family: "Cadiz";
    line-height: 1.6;
    font-size: 16px;
  }
  blockquote {
    border-left: none!important;
    margin-left: 0!important;
    padding-left: 0!important;
  }

  blockquote > p {
    display: inline;
  }

  blockquote > div {
    display: inline;
  }

  blockquote:before {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADiNXWtAAACU0lEQVRIDe2Uz2sTQRTH35tsmgrWiGn25smbHjwoPXi3J0W0WBX0oBUvrYl6sKKphySCHhQTGzFaCXgR4kX9B3LwoohCQRFRhKoo3YkIjYdGk3m+Cd26m93ND88dWHbm+z7vO/ve7g5Ch0HFYthalocRaNxMzuztgHpC8v61Iaj9PmV4IiuCzKV3WMuLD4BgKyEuBHF+ejWX3des1e9yzBR+gJXLnCaC59rcLx6kEZGwculCk9Rjba45TwVWPjNNiq4GmQTpVC6HqvlsiR/smJNxVSDz2SP/Y64N5bf3VxSRy1zrqxUs5jNbiNQdLbaNT4Jgtk1zLTl3lNt5ni/XQIRXqxsA0XUub4NNIGJZCCjGplIVnrel2hQAVSqGnH9WYABbKmKd8Xu8njMTl+dboryV3ama6qUG2OynADw+nEw9+WcTPLNuZiYIaK6VC/ARjdCh+NSl13ZGqwKl6OyK8MKA8Pim5IXPNtDtTkjndGt0xbh+4GR8YrrmzEEqlQbl0hdJhAuGiO6KJRJLTqDT/MdselujAW/Y/qmZTO3nTVQ7b1R/fR1lIBIKw8HYZO/m2kg18QD3+DsODRz1M9eMIAUj/IILw5Mz77TQz+DOjLDDxfa2OD30f7CdG3jbKfYx3xyHjQ878fyJ0x8+yD50gvxiVL6xjit4i4lE3S9uawYChq18+oQt+N6VqJlnUo+cMSkbUT5lI91yDX6K3aBgjzPZM0fSp6lrA/6foopgjD/RMQ/vEAS/4Ihj3fNUNWiwF9h12PWS0C+ztkHXjq21qGuL/gJpe9UTJPsKXAAAAABJRU5ErkJggg==);
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 10px;
    background-repeat: no-repeat;
  }

  blockquote:after {
    content: "";
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADiNXWtAAACOElEQVRIDe1UTWgTURCeyT5NNYpImpZCEQ8FTyIePYqK2IterNhLDkoDdklBELW0l4U0Wi9N6t+5ICgICupRcmiq2JMGjx6UithsEnLQ2nV/xtmUNOtz15dDj1147Mw333zz3rePxdU5g0D1IH7pn5g+GKStFo0j4MH7IBYWx8LArcS2Byjd3LZoayxCgnWlUgRBYAwuRdQ6MMGpTrIR9ezes2Kt/VD3yo1yTkSxasF4KuPd5NV7+SHlLaoXZ44D4PduBGUO2s5Z5QCXPB2BlP8cWZxKJUEImf8OqBVyJwDonEbasiygymuVpSwRDGIUsV68Peh6v5cBYa1vYnooiheG1+/OHHNct8S371XoCRrzxmGXrNcENABIT8JEorDqfO6M4zgvgSju9wqZaBaMMdvFObZmFyBaGux8IHPCct/zWmUxR553jeu+M59TPf3PWgNaxY/lYXLpikdwmsVbGsx6mMxe/xom2MZo4U7CbP46b1bKvudHN3sRDcxkbMF3PG9+KKdbdrS7+I2AK/FEwghAf4XN+/n9lm3fMhvrF3k7e9vCPgkRFlMDhxb8WPDUG8HiBgFdpo3uu3y14edhj+XYB/igY7wxudzQMD6KIyOswQPkKiK6vNKp7FRZrqlytrQZE2I4qXdslW4R/iTCCyz+SCUm19mWb7xO9uqT74K1zRPwrt+AEOm+8ZufgoRuYu59zN9rPMxSwcdaQg1ne/WpF0z8x9CoATsQPBvwuRDabFKffBvF+wOCwcYzVIl0VgAAAABJRU5ErkJggg==);
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 10px;
    background-repeat: no-repeat;
  }
  ul {
    padding-left: 25px;
  }
  ol {
    padding-left: 25px;
  }
`;

function blobToFile(theBlob: Blob, fileName: string): File {
  return new File([theBlob], fileName);
}

export function MinimalTinyEditor(props: {
  value: string;
  onChange: (value: string) => void;
}) {
  const editorRef = useRef<Editor | null>(null);

  return (
    <Editor
      ref={editorRef}
      value={props.value}
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      init={{
        content_style: contentStyles,
        height: 250,
        width: "100%",
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime table contextmenu paste imagetools wordcount",
        ],
        menubar: false,
        toolbar: `bold italic | strikethrough | bullist numlist`,
        file_picker_types: "image",
        images_reuse_filename: true,
        images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif",
        toolbar_mode: "sliding",
      }}
      onEditorChange={props.onChange}
    />
  );
}

export function TinyEditor(props: {
  value: string;
  id?: string;
  onChange: (value: string) => void;
}) {
  const editorRef = useRef<Editor | null>(null);
  const [modal, setModal] = useState(false);
  const [editor, setEditor] = useState<any>(null);

  const onConfirm = async (uploadedImage: Image) => {
    editor.selection.setContent(
      `<img src="${uploadedImage.image}" alt="${uploadedImage.upload_name}" />`
    );
  };

  return (
    <>
      {modal && (
        <ImageUploadModal
          isVisible={modal}
          onClose={() => {
            setModal(false);
          }}
          onSelect={onConfirm}
          onUpload={async ({ file, onUploadProgress }) => {
            const image = await uploadImage({
              file: file,
              onUploadProgress,
            });

            if (image) {
              await onConfirm(image);
            }
          }}
        />
      )}
      <div id={props.id}>
        <Editor
          css={css`
            border-radius: 20px;
          `}
          ref={editorRef}
          value={props.value}
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          init={{
            content_style: contentStyles,
            height: 400,
            width: "100%",
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime table contextmenu paste imagetools wordcount",
            ],
            toolbar: `undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | gallery`,
            file_picker_types: "image",
            images_reuse_filename: true,
            images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif",
            toolbar_mode: "sliding",
            invalid_elements: "div",
            images_upload_handler: async function (
              blobInfo,
              success,
              failure,
              progress
            ) {
              try {
                const res = await uploadImage({
                  file: blobToFile(blobInfo.blob(), blobInfo.filename()),
                  title: blobInfo.name(),
                  onUploadProgress: function (e) {
                    if (progress) progress((e.loaded / e.total) * 100);
                  },
                });

                success(res.image);
              } catch (e: any) {
                failure(e.message);
              }
            },
            setup: function (editor) {
              editor.ui.registry.addButton("gallery", {
                text: "My Gallery",
                icon: "gallery",
                onAction: function () {
                  setEditor(editor);
                  setModal(true);
                },
              });
              editor.on("init", function () {
                editor.getContainer().className =
                  "tox-tiny-mce-themis " + editor.getContainer().className;
              });
            },
          }}
          onEditorChange={props.onChange}
        />
      </div>
    </>
  );
}
