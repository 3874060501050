import { authorised } from "../../request";
import { Journey } from "../../types/Journey";

type Args = {
  journeyId: string;
};

export async function getJourney({ journeyId }: Args) {
  const API = authorised();

  return API.request<Journey>({
    method: "GET",
    url: `/api/enterprise/journeys/${journeyId}/`,
  });
}
