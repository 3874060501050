import { css } from "styled-components/macro";
import map from "lodash/map";
import reduce from "lodash/reduce";
import { theme } from "../themes/variables";
import { CheckboxComponent } from "./CheckboxComponent";
import { Headers } from "../helpers/layout";
import { ProgressBar } from "./ProgressBar";
import { FormSidebar } from "./FormSidebar";
import { useTranslation } from "react-i18next";

type Fields = {
  [key: string]: string;
};

type Values = {
  [key: string]: any;
};

declare global {
  interface Window {
    tinymce: any;
  }
}

function isFilled(value: any) {
  if (typeof value === "string") {
    return value.length > 0;
  }

  // if is array
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return !!value;
}

function Checklist(props: {
  fields: Fields;
  values: Values;
  title: string;
  allChecked?: boolean;
}) {
  const { fields, values } = props;

  const allChecked = reduce(
    fields,
    (allChecked, value, key) => {
      if (!allChecked) return false;

      return isFilled(values[key]);
    },
    true
  );

  return (
    <div>
      <div
        css={css`
          display: flex;
          flex-flow: row wrap;
        `}
      >
        <div
          css={css`
            margin-right: 10px;
            flex: 0 0 auto;
            width: 24px;
            padding-top: 4px;
            pointer-events: none;
          `}
        >
          <CheckboxComponent
            value={allChecked}
            checkColor={
              props.allChecked ? theme.colors.greenMain : theme.colors.primary
            }
          />
        </div>
        <div
          css={css`
            flex: 1 0 10%;
          `}
        >
          <Headers.H3>{props.title}</Headers.H3>
          <div>
            {map(fields, (value, key) => {
              const isChecked = isFilled(values[key]);

              return (
                <div
                  key={`checklist-field-${key}`}
                  css={css`
                    margin-bottom: 15px;
                    line-height: 1;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    ${isChecked &&
                    css`
                      color: ${theme.colors.gray4};
                      text-decoration: line-through;
                    `}
                  `}
                >
                  <label
                    onClick={() => {
                      const tinyField =
                        typeof window.tinymce !== "undefined"
                          ? window.tinymce.get(key)
                          : null;

                      if (tinyField) {
                        tinyField.focus();
                      }

                      const field = document.getElementById(key);

                      if (field) {
                        const offsetTop =
                          field.getBoundingClientRect().top +
                          document.documentElement.scrollTop;

                        setTimeout(() => {
                          window.scroll({
                            top: offsetTop - 50,
                            behavior: "smooth",
                          });
                        }, 100);
                      }
                    }}
                    htmlFor={key}
                  >
                    {value}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export function FormChecklist(props: {
  values: Values;
  fields: {
    required: Fields;
    optional: Fields;
  };
  children: React.ReactNode;
}) {
  const { fields, values } = props;

  const { t } = useTranslation();

  const allFields = { ...fields.required, ...fields.optional };
  const checked = reduce(
    allFields,
    (checked, value, key) => {
      if (isFilled(values[key])) {
        return checked + 1;
      }

      return checked;
    },
    0
  );

  const allChecked = checked === Object.keys(allFields).length;

  return (
    <FormSidebar
      css={css`
        background: ${theme.colors.gray1};

        h3 {
          font-size: 20px;
        }

        label {
          font-size: 16px;
          cursor: pointer;
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Headers.H3>{t("checklist.title")}</Headers.H3>
        <ProgressBar
          percent={(checked / Object.values(allFields).length) * 100}
          color={allChecked ? theme.colors.greenMain : theme.colors.primary}
          emptyColor={theme.colors.white}
          indicator
        />
      </div>

      <div
        css={css`
          margin-bottom: 40px;
        `}
      >
        <Checklist
          values={values}
          fields={fields.required}
          title={t("checklist.required_fields")}
          allChecked={allChecked}
        />
        <hr />
        <Checklist
          values={values}
          fields={fields.optional}
          title={t("checklist.optional_fields")}
          allChecked={allChecked}
        />
      </div>

      {props.children}
    </FormSidebar>
  );
}
