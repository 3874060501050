import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { customToast } from "../../customToast";
import { css } from "styled-components/macro";
import { Plus } from "react-feather";
import { createBlock, deleteBlock, updateBlock } from "../../../actions/block";
import { Lesson, LessonBlock } from "../../../types/Lesson";
import { Block, BlockType } from "../../../types/Block";
import {
  BlockAdd,
  BlockColumns,
  BlockCustomCode,
  BlockEmbedVideo,
  BlockPodcast,
  BlockCarousel,
  BlockCollapse,
} from "./blocks";
import { Button } from "../../Button";
import { Separator } from "../../Separator";

import { ReactComponent as GameIcon } from "../../../assets/icons/Game.svg";
import { ReactComponent as PodcastIcon } from "../../../assets/icons/Podcast.svg";
import { ReactComponent as TextIcon } from "../../../assets/icons/Text.svg";
import { ReactComponent as VideoIcon } from "../../../assets/icons/Video.svg";
import { ReactComponent as CarouselIcon } from "../../../assets/icons/Carousel.svg";
import { ReactComponent as OwlIcon } from "../../../assets/icons/Owl.svg";

import { theme } from "../../../themes/variables";
import { updateBlockOrder } from "../../../actions/block/updateBlockOrder";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { BlockPPT } from "./blocks/BlockPPT";
import { ReactComponent as PPTIcon } from "../../../assets/icons/Presentation.svg";
import { BlockInteractiveVideo } from "./blocks/BlockInteractiveEditor";

export function ContentEditorBlock(props: {
  lesson: Lesson;
  onRefetch: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const [addModal, setAddModal] = useState(false);

  const [editModalId, setEditModalId] = useState<string | null>(null);

  const [creationIndex, setCreationIndex] = useState<null | number>(null);

  const addBlock = async (type: BlockType) => {
    setAddModal(false);

    try {
      const {
        data: { uuid: id },
      } = await createBlock({
        lessonId: props.lesson.uuid,
        creationIndex,
        data: {
          type: type,
        },
      });

      setEditModalId(id);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }

    setCreationIndex(null);

    await props.onRefetch();
  };

  const deleteBlockFn = async (block: Block) => {
    try {
      await deleteBlock({
        lessonId: props.lesson.uuid,
        blockId: block.uuid,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }

    await props.onRefetch();
  };

  const updateBlockFn = async (block: Block) => {
    try {
      await updateBlock({
        lessonId: props.lesson.uuid,
        blockId: block.uuid,
        data: block,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }

    await props.onRefetch();
  };

  const updateBlocksOrder = async (data: LessonBlock[]) => {
    try {
      await updateBlockOrder({
        lessonId: props.lesson.uuid,
        data: { items: data },
      });
      await props.onRefetch();
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  function reorder(startIndex: number, endIndex: number) {
    if (
      props.lesson.lesson_blocks &&
      startIndex >= 0 &&
      startIndex <= props.lesson.lesson_blocks.length - 1 &&
      endIndex >= 0 &&
      endIndex <= props.lesson.lesson_blocks.length - 1
    ) {
      const result = Array.from(props.lesson.lesson_blocks);

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      updateBlocksOrder(result);
    }

    return;
  }

  function closeAddModal() {
    setAddModal(false);
    setCreationIndex(null);
  }

  return (
    <div
      css={css`
        padding: 60px 60px 30px 60px;
        border-radius: 20px;
        background: ${theme.colors.gray1};
      `}
    >
      {props.lesson.lesson_blocks &&
        props.lesson.lesson_blocks.map((el, index) => (
          <div
            key={el.id}
            css={css`
              :first-of-type > div {
                margin: 0 0 20px 0;
              }
            `}
          >
            <div
              css={css`
                display: flex;
                margin: 20px 0;
              `}
            >
              {el.block.type === BlockType.COLUMNS && (
                <BlockColumns
                  block={el.block}
                  label={t("lesson.block.types.column")}
                  icon={
                    <TextIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.CUSTOM_CODE && (
                <BlockCustomCode
                  block={el.block}
                  label={t("lesson.block.types.custom-code")}
                  icon={
                    <GameIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.EMBED_VIDEO && (
                <BlockEmbedVideo
                  block={el.block}
                  label={t("lesson.block.types.embed-video")}
                  icon={
                    <VideoIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.PODCAST && (
                <BlockPodcast
                  block={el.block}
                  label={t("lesson.block.types.podcast")}
                  icon={
                    <PodcastIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.CAROUSEL && (
                <BlockCarousel
                  block={el.block}
                  label={t("lesson.block.types.carousel")}
                  icon={
                    <CarouselIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.COLLAPSE_BLOCK && (
                <BlockCollapse
                  block={el.block}
                  label={t("lesson.block.types.collapse-block")}
                  icon={
                    <OwlIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.PPT_BLOCK && (
                <BlockPPT
                  block={el.block}
                  label={t("lesson.block.types.ppt-block")}
                  icon={
                    <PPTIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async (block) => {
                    await updateBlockFn(block);
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}

              {el.block.type === BlockType.INTERACTIVE_VIDEO && (
                <BlockInteractiveVideo
                  block={el.block}
                  label={t("lesson.block.types.interactive-video-block")}
                  icon={
                    <VideoIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                  }
                  index={index}
                  editModal={editModalId === el.block.uuid}
                  onEditModalClose={() => setEditModalId(null)}
                  onDelete={() => deleteBlockFn(el.block)}
                  onUpdate={async () => {
                    await props.onRefetch();
                  }}
                  reorder={(startIndex: number, endIndex: number) =>
                    reorder(startIndex, endIndex)
                  }
                />
              )}
            </div>

            {props.lesson.lesson_blocks &&
              index < props.lesson.lesson_blocks.length - 1 && (
                <div>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Separator />

                    <Button
                      background={theme.colors.transparent}
                      color={theme.colors.dark}
                      icon={<Plus size={20} />}
                      hoverStyles={`opacity: 0.85;`}
                      onClick={() => {
                        setAddModal(true);
                        setCreationIndex(el.order + 1);
                      }}
                      small
                    >
                      {t("lesson.block.insert-block")}
                    </Button>

                    <Separator />
                  </div>
                </div>
              )}
          </div>
        ))}

      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Separator />

          <Button
            background={theme.colors.transparent}
            color={theme.colors.dark}
            icon={<Plus size={20} />}
            onClick={() => {
              setAddModal(true);
              setCreationIndex(
                props.lesson.lesson_blocks
                  ? props.lesson.lesson_blocks.length
                  : 0
              );
            }}
            small
            hoverStyles={`opacity: 0.85;`}
          >
            {t("lesson.block.insert-block")}
          </Button>

          <Separator />
        </div>
      </div>

      <BlockAdd
        modal={addModal}
        closeAddModal={closeAddModal}
        onConfirm={(blockType) => addBlock(blockType)}
      />
    </div>
  );
}
