import { authorised } from "../../request";
import { Lesson } from "../../types/Lesson";
import { RequiredDataType } from "../../types/RequiredDataType";

export async function updateLesson(
  courseId: string,
  lessonId: string,
  data: Partial<Omit<Lesson, "uuid" | "tags" | "instructors">> & {
    instructors?: RequiredDataType[];
  }
) {
  const API = authorised();

  return await API.request<Lesson>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/lessons/${lessonId}/`,
    data,
  });
}
