import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "../../customToast";
import * as Yup from "yup";
import { InputField } from "../../../forms/fields/InputField";
import { TextareaField } from "../../../forms/fields/TextareaField";
import { ImageUploadField } from "../../../forms/fields/ImageUploadField";
import { Button } from "../../../components/Button";
import { TinyEditor } from "../../../components/DraftEditor/tinymce";
import { ActionModal } from "../../../components/ActionModal";
import { Page, PageStatus, PageVisibility } from "../../../types/admin/Page";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";

import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { uploadImage } from "../../../actions/image";
import { deletePage, checkPage } from "../../../actions/admin/page";

import { ReactComponent as LinkIcon } from "../../../assets/icons/Link.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/Eye.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/Lock.svg";
import {
  FormMain,
  FormParent,
  FormSidebar,
  FormSidebarContainer,
} from "../../FormSidebar";
import { RadioFields } from "../../../forms/fields/RadioFields";
import { FormChecklist } from "../../FormChecklists";

export type FormValues = Omit<Page, "uuid" | "author" | "created">;

const descriptionMaxLength = 150;

export function EditCreatePage(props: {
  title?: string;
  initialData?: Page | null;
  onSubmitCallback: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    permalink: Yup.string()
      .trim()
      .matches(/^[a-z0-9-_]+$/, t("admin.pages.not-correct-format"))
      .required()
      .test(
        "checkPermalink",
        t("admin.pages.permalink-exist"),
        async (value) => {
          if (!value) {
            return false;
          }

          if (value === props.initialData?.permalink) {
            return true;
          }

          try {
            const {
              data: { exists },
            } = await checkPage({
              pageSlug: value,
            });

            return !exists;
          } catch (error) {
            return true;
          }
        }
      ),
    content: Yup.string(),
    description: Yup.string().max(descriptionMaxLength).required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmitCallback({
        ...values,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const statusOptions = [
    {
      label: t("admin.pages.status.published"),
      value: PageStatus.PUBLISHED,
    },
    {
      label: t("admin.pages.status.draft"),
      value: PageStatus.DRAFT,
    },
  ];

  const visibilityOptions = [
    {
      label: t("courses.visibility-choices.private"),
      value: PageVisibility.PRIVATE,
      icon: <LockIcon width={14} height={14} />,
    },
    {
      label: t("courses.visibility-choices.public"),
      value: PageVisibility.PUBLIC,
      icon: <EyeIcon width={14} height={14} />,
    },
  ];

  const deleteModalOnConfirm = async () => {
    try {
      if (props.initialData) {
        await deletePage({ id: props.initialData?.uuid });
      }

      setDeleteModal(false);

      history.push(`/admin/pages`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };
  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          title: props.initialData?.title || "",
          description: props.initialData?.description || "",
          content: props.initialData?.content || "",
          image: props.initialData?.image || null,
          permalink: props.initialData?.permalink || "",
          status: props.initialData?.status || PageStatus.PUBLISHED,
          visibility: props.initialData?.visibility || PageVisibility.PUBLIC,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, setFieldValue, values }) => {
          return (
            <Form noValidate>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 32px;
                `}
              >
                <Headers.H3>{props.title}</Headers.H3>
              </div>

              <FormParent>
                <FormMain>
                  <div>
                    <InputField
                      name="title"
                      id="title"
                      label={`${t("admin.pages.page-title")}:`}
                      css={css`
                        margin-bottom: 32px;
                      `}
                      onChange={(e) => {
                        const val = e.target.value;
                        const handledValue = val
                          .toLowerCase()
                          .replace(" ", "-");
                        if (
                          !values.permalink ||
                          values.permalink ===
                            handledValue.substring(0, handledValue.length - 1)
                        ) {
                          setFieldValue("permalink", handledValue);
                        }
                      }}
                    />

                    <InputField
                      name="permalink"
                      id="permalink"
                      label={`${t("admin.pages.permalink")}:`}
                      predefinedText={`${process.env.REACT_APP_CAMPUS_URL}/pages/`}
                      css={css`
                        margin-bottom: 32px;
                      `}
                      onChange={(e) => {
                        const val = e.target.value;

                        setFieldValue("permalink", val.replace(" ", "-"));
                      }}
                    />
                    <div
                      css={css`
                        margin-bottom: 32px;
                      `}
                    >
                      <TextareaField
                        id="description"
                        name="description"
                        label={`${t("admin.pages.page-description")}:`}
                        maxLength={descriptionMaxLength}
                      />
                    </div>

                    <div>
                      <div
                        css={css`
                          margin-bottom: 16px;
                        `}
                      >
                        {t("rich-text-editor.content")}:
                      </div>

                      <TinyEditor
                        id="content"
                        value={values.content}
                        onChange={(val) => setFieldValue("content", val)}
                      />
                    </div>
                  </div>
                </FormMain>
                <FormSidebarContainer>
                  {!props.initialData ? (
                    <FormChecklist
                      values={values}
                      fields={{
                        required: {
                          title: t("pages.instructions.title"),
                          permalink: t("pages.instructions.permalink"),
                          description: t("pages.instructions.description"),
                        },
                        optional: {
                          content: t("pages.instructions.content"),
                        },
                      }}
                    >
                      <Button
                        isSubmitting={isSubmitting}
                        type="submit"
                        disabled={!isValid}
                      >
                        {t("actions.create")}
                      </Button>
                    </FormChecklist>
                  ) : (
                    <FormSidebar>
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                        `}
                      >
                        <div>
                          <div
                            css={css`
                              margin-bottom: 20px;
                            `}
                          >
                            <RadioFields
                              label={t("admin.publish-request.page.status")}
                              name="status"
                              choices={statusOptions}
                            />
                          </div>

                          <RadioFields
                            label={t("courses.visibility")}
                            name="visibility"
                            labelRight
                            choices={visibilityOptions}
                          />
                        </div>

                        <hr />

                        <div>
                          <Headers.H4
                            css={css`
                              margin-bottom: 24px;
                            `}
                          >
                            {t("admin.pages.featured-image")}
                          </Headers.H4>

                          <ImageUploadField
                            name="image"
                            uploadFn={uploadImage}
                          />
                        </div>

                        <hr />
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                          `}
                        >
                          <Button
                            isSubmitting={isSubmitting}
                            type="submit"
                            disabled={!isValid}
                          >
                            {t(
                              !!props.initialData
                                ? "actions.update"
                                : "actions.create"
                            )}
                          </Button>
                          <Button
                            icon={
                              <DeleteIcon
                                width={14}
                                height={14}
                                color={theme.colors.error}
                              />
                            }
                            iconRight
                            background={theme.colors.transparent}
                            border={`none`}
                            color={theme.colors.dark}
                            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error};`}
                            onClick={() => setDeleteModal(true)}
                          >
                            {t("actions.delete")}
                          </Button>
                        </div>
                        {!!props.initialData && (
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              margin-top: 20px;
                            `}
                          >
                            <a
                              css={css`
                                font-size: 14px;
                              `}
                              target="__blank"
                              href={`${process.env.REACT_APP_CAMPUS_URL}/pages/${values.permalink}`}
                            >
                              <span>{t("admin.pages.preview-changes")}</span>
                              <LinkIcon
                                css={css`
                                  margin-left: 10px;
                                `}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                    </FormSidebar>
                  )}
                </FormSidebarContainer>
              </FormParent>

              <ActionModal
                deleteModal={deleteModal}
                setActionModal={(modal: boolean) => setDeleteModal(modal)}
                contentBody={t("actions.no-undo")}
                actionButtonText={t("actions.delete")}
                onConfirm={deleteModalOnConfirm}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
