import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs, Tab } from "../../components/Tabs";
import { Button } from "../../components/Button";
import { InputComponent } from "../../components/InputComponent";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { SEO } from "../../components/SEO";
import { SelectComponent } from "../../components/SelectComponent";
import UserTable from "../../components/Organisation/UserTable";
import { useNewFetch } from "../../useAPI";
import { useAuth } from "../../contexts/UserContext";
import { Permission, UserMeta } from "../../types/UserProfile";

import {
  OrganisationUserRole,
  OrganisationUser,
} from "../../types/OrganisationUser";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { stopCollaborationOrganisationUser } from "../../actions/organisationUser";
import { getOrganisation } from "../../actions/organisation";
import { pageLoading } from "../../helpers/pageLoading";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";
import { ReactComponent as StopIcon } from "../../assets/icons/Stop.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/Invite.svg";
import { Footer } from "../../components/Footer";
import { OrganisationUserInvite } from "../../modals/OrganisationUserInvite";
import { useHistory } from "react-router-dom";

export function OrganisationUserManagmentPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const { hasOrganisationPermission, userprofile } = useAuth();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const [query, setQuery] = useState(params.get("query") || undefined);

  const [organisationUserRole, setOrganisationUserRole] = useState<
    OrganisationUserRole | string | null
  >(params.get("role"));

  const [inviteModal, setInviteModal] = useState(false);

  const [stopCollaborationModal, setStopCollaborationModal] = useState(false);

  const [stopCollaborationPretendent, setStopCollaborationPretendent] =
    useState<OrganisationUser | null>(null);

  const { data: organisation, error: organisationError } =
    useNewFetch(getOrganisation);

  if (!organisation || organisationError) {
    return pageLoading(organisationError);
  }

  const tabsData: Tab[] = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/organisation`,
    },
  ];

  if (hasOrganisationPermission(Permission.ORGANISATION_MANAGE_USERS)) {
    tabsData.push({
      title: t("breadcrumbs.users"),
      link: `/organisation/users`,
    });
    tabsData.push({
      title: t("organisation.invites"),
      link: `/organisation/invites`,
    });
    tabsData.push({
      title: t("organisation.experts"),
      link: `/organisation/experts`,
    });
  }

  const organisationRoles: {
    label: string;
    value: OrganisationUserRole | "";
  }[] = [
    {
      label: t("admin.pages.status.all"),
      value: "",
    },
    {
      label: t("organisation.role.admin"),
      value: OrganisationUserRole.ORGANISATION_ADMIN,
    },
    {
      label: t("organisation.role.editor"),
      value: OrganisationUserRole.ORGANISATION_EDITOR,
    },
  ];

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const onSelectRole = (val: string) => {
    ref.current.setQueryArgs("role", val);
    setOrganisationUserRole(val);
  };

  const closeStopCollaborationModal = () => {
    setStopCollaborationModal(false);
    setStopCollaborationPretendent(null);
  };

  const openStopCollaborationModal = (organisationUser: OrganisationUser) => {
    setStopCollaborationPretendent(organisationUser);
    setStopCollaborationModal(true);
  };

  const stopCollaboration = async () => {
    if (!stopCollaborationPretendent) {
      return;
    }
    try {
      await stopCollaborationOrganisationUser({
        user_id: stopCollaborationPretendent.user.id,
      });

      closeStopCollaborationModal();
      ref.current.refetch();
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const isMe = (targetUser: UserMeta): boolean => {
    return userprofile?.id === targetUser.id;
  };

  const organisationUserRoleLabels = [
    {
      key: OrganisationUserRole.ORGANISATION_ADMIN,
      value: t("organisation.role.admin"),
    },
    {
      key: OrganisationUserRole.ORGANISATION_EDITOR,
      value: t("organisation.role.editor"),
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("organisation.title")}</title>
        </SEO>
        <Header />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{organisation.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Headers.H3
                  css={css`
                    display: inline;
                    margin-right: 159px;
                  `}
                >
                  {t("breadcrumbs.users")}
                </Headers.H3>

                <SelectComponent
                  choices={organisationRoles}
                  value={organisationUserRole}
                  small
                  onChange={(e) => onSelectRole(e.target.value)}
                />
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <InputComponent
                  placeholder={t("admin.users.page.search")}
                  icon={<SearchIcon width={15} height={15} />}
                  value={query}
                  onChange={(e) => onSearch(e.target.value)}
                  css={css`
                    min-width: 300px;
                  `}
                />

                <Button
                  css={css`
                    margin-left: 65px;
                  `}
                  background={theme.colors.dark}
                  color={theme.colors.white}
                  icon={
                    <PlusIcon
                      width={14}
                      height={14}
                      color={theme.colors.white}
                    />
                  }
                  iconRight
                  onClick={() => setInviteModal(true)}
                >
                  {t("organisation.invite-user")}
                </Button>
              </div>
            </div>

            <div
              css={css`
                margin-top: 40px;
              `}
            >
              <div
                css={css`
                  position: relative;
                  min-height: 200px;
                `}
              >
                <UserTable
                  forwardRef={ref}
                  organisationUserRoleLabels={organisationUserRoleLabels}
                  isMe={(user: UserMeta) => isMe(user)}
                  openStopCollaborationModal={(user: OrganisationUser) =>
                    openStopCollaborationModal(user)
                  }
                />
              </div>
              <OrganisationUserInvite
                isVisible={inviteModal}
                onClose={() => {
                  setInviteModal(false);
                }}
                onSent={() => {
                  history.push("/organisation/invites");
                }}
              />
              <Modal
                modalIsOpen={stopCollaborationModal}
                onClose={closeStopCollaborationModal}
                contentLabel={t("organisation.stop-collaboration")}
                contentStyles={{
                  maxWidth: "560px",
                  padding: "25px 40px 40px 40px",
                }}
              >
                <ModalHeader
                  css={css`
                    justify-content: flex-end;
                  `}
                  closeIcon
                  onClose={closeStopCollaborationModal}
                />

                <ModalBody
                  css={css`
                    padding-top: 0;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 33px;
                    `}
                  >
                    <InviteIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                    <Headers.H2
                      css={css`
                        margin-left: 12px;
                      `}
                    >
                      {t("organisation.stop-collaboration")}
                    </Headers.H2>
                  </div>

                  <div>
                    {t("organisation.stop-collaboration-info")}{" "}
                    <strong>{stopCollaborationPretendent?.user.email}</strong>?
                  </div>
                </ModalBody>

                <ModalFooter
                  css={css`
                    justify-content: flex-start;
                  `}
                >
                  <Button
                    background={theme.colors.error}
                    color={theme.colors.white}
                    onClick={stopCollaboration}
                    icon={
                      <StopIcon
                        width={14}
                        height={14}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    {t("organisation.stop-collaboration")}
                  </Button>

                  <Button
                    background={theme.colors.transparent}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    onClick={closeStopCollaborationModal}
                  >
                    {t("actions.cancel")}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
