import React from "react";
import { VideoElementText } from "../../../types/Video";

export function VideoTextElement(props: {
  element: VideoElementText;
  currentTime: number;
}) {
  const { element } = props;

  return <div dangerouslySetInnerHTML={{ __html: element.text }}></div>;
}
