import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "./customToast";
import * as Yup from "yup";
import { InputField } from "../forms/fields/InputField";
import { InputMaskField } from "../forms/fields/InputMaskField";
import { TextareaField } from "../forms/fields/TextareaField";
import { SelectField } from "../forms/fields/SelectField";
import { InputRepeater } from "../forms/fields/InputRepeater";
import { ImageUploadField } from "../forms/fields/ImageUploadField";
import { EntitySelectionField } from "../forms/fields/EntitySelectionField";
import { Button } from "./Button";
import { TinyEditor } from "../components/DraftEditor/tinymce";
import { CourseData } from "../pages/Courses/EditCourse";
import { RequiredDataType } from "../types/RequiredDataType";
import {
  CourseLevel,
  CourseVisibility,
  CoursePublishRequestStatus,
} from "../types/Course";
import { CreateCourse } from "../actions/course";
import { uploadImage } from "../actions/image";
import { Column } from "../helpers/layout";
import { theme } from "../themes/variables";

import { getStudioFaqList } from "../actions/faq";

import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { CheckboxField } from "../forms/fields/CheckboxField";
import { Faq } from "../types/Faq";
import { FormChecklist } from "./FormChecklists";
import { RadioFields } from "../forms/fields/RadioFields";
import { FormMain, FormParent, FormSidebarContainer } from "./FormSidebar";
import { ReactComponent as LinkIcon } from "../assets/icons/Link.svg";

export interface FormValues extends Omit<CreateCourse, "faq_items"> {
  faq_items: RequiredDataType[];
}

const entityFaqSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getStudioFaqList({ searchText });

  return response.data.map((item) => {
    return {
      id: String(item.id),
      label: item.question,
    };
  });
};

const faqItemsHandler = (fieldValue: Faq[]): RequiredDataType[] => {
  return fieldValue.map((faq_item) => {
    return {
      id: String(faq_item.id),
      label: faq_item.question,
    };
  });
};

export function EditCreateCourse(props: {
  onSubmitCallback: (values: FormValues) => Promise<void>;
  onPublishCourse?: () => void;
  titleMaxLength?: number;
  descriptionMaxLength?: number;
  initialData?: CourseData | null;
  courseId?: string;
  previewUrl?: string;
}) {
  const { t } = useTranslation();

  const {
    onSubmitCallback,
    onPublishCourse,
    titleMaxLength = 150,
    descriptionMaxLength = 500,
    initialData,
  } = props;

  const levelChoices: { label: string; value: CourseLevel }[] = [
    {
      label: t("courses.level-choices.beginner"),
      value: CourseLevel.BEGINNER,
    },
    {
      label: t("courses.level-choices.intermediate"),
      value: CourseLevel.INTERMEDIATE,
    },
    {
      label: t("courses.level-choices.advanced"),
      value: CourseLevel.ADVANCED,
    },
  ];

  const visibilityChoices: { label: string; value: CourseVisibility }[] = [
    {
      label: t("courses.visibility-choices.public"),
      value: CourseVisibility.PUBLIC,
    },
    {
      label: t("courses.visibility-choices.private"),
      value: CourseVisibility.PRIVATE,
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(titleMaxLength).required(),
    description: Yup.string().max(descriptionMaxLength).required(),
    price: Yup.string().required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        name: values.name,
        description: values.description || "",
        level: values.level || CourseLevel.BEGINNER,
        learning_points: values.learning_points,
        image: values.image || null,
        logo: values.logo || null,
        price: values.price?.replace(/,/g, "") || "",
        faq_items: values.faq_items || [],
        subtitle: values.subtitle || "",
        summary_content_html: values.summary_content_html || "",
        course_starts: values.course_starts || "",
        course_for: values.course_for || "",
        visibility: values.visibility || CourseVisibility.PUBLIC,
        has_certificate: values.has_certificate || false,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const inputsContainer = useRef<HTMLDivElement>(null);

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          description: initialData?.description || "",
          name: initialData?.name || "",
          level: initialData?.level || levelChoices[0].value,
          learning_points: initialData?.learning_points || [],
          image: initialData?.image || null,
          logo: initialData?.logo || null,
          price: initialData?.price || "",
          faq_items: faqItemsHandler(initialData?.faq_items || []),
          subtitle: initialData?.subtitle || "",
          summary_content_html: initialData?.summary_content_html || "",
          course_starts: initialData?.course_starts || "",
          course_for: initialData?.course_for || "",
          visibility: initialData?.visibility || CourseVisibility.PUBLIC,
          has_certificate: initialData?.has_certificate || false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          isValid,
          getFieldMeta,
          setFieldValue,
          values,
          dirty,
        }) => {
          return (
            <Form noValidate>
              <FormParent>
                <FormMain>
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 32px;
                    `}
                  >
                    <SelectField
                      label={`${t("courses.level")}:`}
                      name="level"
                      choices={levelChoices}
                    />
                  </div>

                  <InputField
                    id={"name"}
                    label={`${t("courses.fields.title")}*:`}
                    name={"name"}
                    maxLength={titleMaxLength}
                  />

                  <InputMaskField
                    id="price"
                    type="price"
                    label={`${t("courses.price")}*:`}
                    name={"price"}
                  />

                  <div
                    css={css`
                      margin: 30px 0;
                      display: flex;
                    `}
                  >
                    <label
                      css={css`
                        margin-right: 20px;
                        font-size: 16px;
                        font-weight: bold;
                      `}
                    >
                      {`${t("courses.issue_certificate")}:`}
                    </label>
                    <CheckboxField
                      name={"has_certificate"}
                      value={values.has_certificate}
                    />
                  </div>

                  <hr />

                  <InputField
                    id={"subtitle"}
                    label={`${t("courses.fields.subtitle")}:`}
                    name={"subtitle"}
                    css={css`
                      margin-bottom: 32px;
                    `}
                  />

                  <TextareaField
                    id={"description"}
                    label={`${t("courses.fields.description")}*:`}
                    name={"description"}
                    maxLength={descriptionMaxLength}
                  />

                  <div
                    css={css`
                      margin-bottom: 32px;
                    `}
                  >
                    <div
                      css={css`
                        margin-bottom: 16px;
                        font-size: 16px;
                        font-weight: bold;
                      `}
                    >
                      {t("courses.summary")}:
                    </div>

                    <TinyEditor
                      id="summary_content_html"
                      value={values.summary_content_html}
                      onChange={(val) =>
                        setFieldValue("summary_content_html", val)
                      }
                    />
                  </div>

                  <div
                    css={css`
                      margin-bottom: 32px;
                    `}
                  >
                    <div
                      css={css`
                        margin-bottom: 16px;
                        font-size: 16px;
                        font-weight: bold;
                      `}
                    >
                      {t("courses.starts")}:
                    </div>

                    <TinyEditor
                      id="course_starts"
                      value={values.course_starts}
                      onChange={(val) => setFieldValue("course_starts", val)}
                    />
                  </div>

                  <div
                    css={css`
                      margin-bottom: 32px;
                    `}
                  >
                    <div
                      css={css`
                        margin-bottom: 16px;
                        font-size: 16px;
                        font-weight: bold;
                      `}
                    >
                      {t("courses.for")}:
                    </div>

                    <TinyEditor
                      value={values.course_for}
                      onChange={(val) => setFieldValue("course_for", val)}
                    />
                  </div>

                  <Column ref={inputsContainer}>
                    <InputRepeater<string>
                      name="learning_points"
                      label={`${t("courses.what-learn")}:`}
                    >
                      {({ idx, arrayHelpers }) => {
                        return (
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                              flex: 1 0 10%;
                            `}
                          >
                            <span
                              css={css`
                                font-weight: bold;
                                position: relative;
                                top: 0;
                                padding-right: 25px;
                              `}
                            >
                              {idx + 1}.
                            </span>

                            <InputField
                              css={css`
                                flex: 1 0 10%;
                              `}
                              name={`learning_points[${idx}]`}
                              onkeydown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  arrayHelpers.push("");
                                  setTimeout(() => {
                                    if (inputsContainer.current) {
                                      const tmpLastInput =
                                        inputsContainer.current.querySelector<HTMLInputElement>(
                                          ".input_repeater:last-of-type input"
                                        );
                                      if (tmpLastInput) {
                                        tmpLastInput.focus();
                                      }
                                    }
                                  }, 10);
                                }
                              }}
                            ></InputField>
                          </div>
                        );
                      }}
                    </InputRepeater>
                  </Column>

                  <hr />

                  <ImageUploadField
                    id="image"
                    label={`${t("courses.background_image")}:`}
                    name={"image"}
                    uploadFn={uploadImage}
                  />

                  <ImageUploadField
                    id="logo"
                    label={`${t("media.course_logo")}:`}
                    name={"logo"}
                    uploadFn={uploadImage}
                  />

                  <hr />

                  <EntitySelectionField
                    id="faq_items"
                    name={`faq_items`}
                    label={`${t("faq.faq-items")}:`}
                    searchCallback={entityFaqSearchCallback}
                  />
                </FormMain>
                <FormSidebarContainer>
                  <FormChecklist
                    values={values}
                    fields={{
                      required: {
                        name: t("courses.instructions.name"),
                        price: t("courses.instructions.price"),
                        description: t("courses.instructions.description"),
                      },
                      optional: {
                        subtitle: t("courses.instructions.subtitle"),
                        summary_content_html: t(
                          "courses.instructions.summary_content_html"
                        ),
                        course_starts: t("courses.instructions.course_starts"),
                        course_for: t("courses.instructions.course_for"),
                        learning_points: t(
                          "courses.instructions.learning_points"
                        ),
                        icon: t("courses.instructions.icon"),
                        image: t("courses.instructions.image"),
                        logo: t("courses.instructions.logo"),
                        faq_items: t("courses.instructions.faq_items"),
                      },
                    }}
                  >
                    <hr />
                    {!initialData ? (
                      <Button
                        isSubmitting={isSubmitting}
                        type="submit"
                        disabled={!isValid}
                      >
                        {t("actions.create")}
                      </Button>
                    ) : (
                      <>
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                        >
                          <RadioFields
                            label={`${t("courses.visibility")}:`}
                            name="visibility"
                            choices={visibilityChoices}
                            labelRight
                          />
                        </div>

                        <hr />

                        <div
                          css={css`
                            display: flex;
                            align-self: flex-start;
                          `}
                        >
                          {onPublishCourse &&
                            (!initialData.course_publish_request ||
                              initialData.course_publish_request.status ===
                                CoursePublishRequestStatus.CANCELLED) && (
                              <div
                                css={css`
                                  margin-right: 1rem;
                                `}
                              >
                                <Button
                                  type="button"
                                  color={theme.colors.dark}
                                  background={theme.colors.gray4}
                                  onClick={onPublishCourse}
                                >
                                  {t("actions.publish")}
                                </Button>
                              </div>
                            )}
                          <Button
                            isSubmitting={isSubmitting}
                            type="submit"
                            disabled={!isValid}
                            background={
                              dirty ? theme.colors.primary : theme.colors.black
                            }
                          >
                            {t("actions.update")}
                          </Button>
                        </div>

                        {!dirty && props.courseId && props.previewUrl && (
                          <div
                            css={css`
                              margin-top: 32px;
                            `}
                          >
                            <a
                              css={css`
                                font-size: 14px;
                              `}
                              target="_blank"
                              rel="noreferrer"
                              href={props.previewUrl}
                            >
                              {t("courses.preview_course")}
                              <LinkIcon
                                css={css`
                                  margin-left: 10px;
                                `}
                              />
                            </a>
                          </div>
                        )}
                      </>
                    )}
                  </FormChecklist>
                </FormSidebarContainer>
              </FormParent>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
