import React, { useEffect, useRef } from "react";
import { Mic } from "react-feather";
import { useReactMediaRecorder } from "react-media-recorder";
import { ControlButton } from "./AddVideoElements";

export function RecordAudio(props: {
  onRecordingStart: () => void;
  onRecordingStop: (audio: File, duration: number) => void;
}) {
  const startTime = useRef<number>(0);
  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: {
      type: "audio/webm",
    },
    onStop: async (blobUrl) => {
      const response = await fetch(blobUrl).then((r) => r.blob());

      const audiofile = new File([response], "audiofile.webm", {
        type: "audio/webm",
      });
      const endTime = new Date().getTime();
      props.onRecordingStop(audiofile, endTime - startTime.current);
    },
  });

  useEffect(() => {
    if (status === "recording") {
      props.onRecordingStart();
    }
  }, [props, status]);

  if (status !== "recording") {
    return (
      <ControlButton
        onClick={() => {
          startTime.current = new Date().getTime();
          startRecording();
        }}
        icon={<Mic />}
      >
        Record Voice
      </ControlButton>
    );
  }

  return (
    <ControlButton onClick={stopRecording} icon={<Mic />} isActive>
      Stop Recording
    </ControlButton>
  );
}
