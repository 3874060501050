import { authorised } from "../../request";

type Args = {
  expertId: number;
}

export async function deleteExpert({expertId}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/organisation/experts/${expertId}/`
  });
}
