import { authorised } from "../../request";
import { Faq } from "../../types/Faq";

type FaqInputArgs = {
  answer: string;
  question: string;
};

type Args = {
  id: string | number;
  data: FaqInputArgs;
};

export async function updateEnterpriseFaqItem({ id, data }: Args) {
  const API = authorised();

  return API.request<Faq>({
    method: "PUT",
    url: `/api/enterprise/faq-items/${id}/`,
    data,
  });
}
