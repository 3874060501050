import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "../../../components/customToast";
import * as Yup from "yup";
import { InputField } from "../../../forms/fields/InputField";
import { CheckboxField } from "../../../forms/fields/CheckboxField";
import { Button } from "../../../components/Button";
import { Competency } from "../../../types/admin/Competency";
import { Headers } from "../../../helpers/layout";

import { serverErrorHandler } from "../../../helpers/serverErrorHandler";

export type FormValues = Omit<Competency, "id">;

export function EditCreateCompetency(props: {
  title?: string;
  initialData?: Competency | null;
  onSubmitCallback: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string(),
    is_featured: Yup.boolean(),
    is_homepage_featured: Yup.boolean(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmitCallback({
        ...values,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: props.initialData?.name || "",
          description: props.initialData?.description || "",
          is_featured: props.initialData?.is_featured || false,
          is_homepage_featured:
            props.initialData?.is_homepage_featured || false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => {
          return (
            <Form noValidate>
              <div
                css={css`
                  margin-bottom: 32px;
                `}
              >
                <Headers.H3>{props.title}</Headers.H3>
              </div>

              <div
                css={css`
                  display: grid;
                  grid-template-columns: 640px 1fr;
                  grid-gap: 60px;
                `}
              >
                <div>
                  <InputField
                    name="name"
                    label={`${t("media.name")}:`}
                    css={css`
                      margin-bottom: 32px;
                    `}
                  />

                  <InputField
                    name="description"
                    label={`${t("slider.description")}:`}
                    css={css`
                      margin-bottom: 32px;
                    `}
                  />
                </div>
              </div>
              <div
                css={css`
                  margin-bottom: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                `}
              >
                <div>
                  <CheckboxField
                    name="is_featured"
                    label={t("admin.competency.is_featured")}
                  />

                  <CheckboxField
                    name="is_homepage_featured"
                    label={t("admin.competency.is_homepage_featured")}
                  />
                </div>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!isValid}
                  >
                    {t(
                      !!props.initialData ? "actions.update" : "actions.create"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
