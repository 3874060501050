import React, { useState, useCallback } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ChartConfiguration, TooltipItem } from "chart.js";
import { ChartLine } from "../../components/Charts/LineChart";
import { CheckboxComponent } from "../../components/CheckboxComponent";
import { hexToRgb, getChartColorByIndex } from "../../helpers/color";
import { theme } from "../../themes/variables";
import {
  getCoursePerformanceChart,
  PerformancePeriod,
} from "../../actions/performance/getCoursePerformanceChart";
import { useNewFetch } from "../../useAPI";
import { pageLoading } from "../../helpers/pageLoading";
import { getCoursePerformanceList } from "../../actions/performance/getCoursePerformanceList";
import { xor, map } from "lodash";
import { getFormattedDate } from "../../helpers/chart";

export function PerformanceChart(props: {
  period: PerformancePeriod;
  journeyUUID: string | null;
  segmentUUID: string | null;
}) {
  const { period, journeyUUID } = props;
  const { t } = useTranslation();
  const [chosenCourses, setChosenCourses] = useState<string[]>([]);

  const {
    data: courses,
    error: coursesError,
    isLoading: coursesLoading,
  } = useNewFetch(getCoursePerformanceList, {
    period: props.period,
    journey_id: props.journeyUUID || undefined,
    limit: 30,
    active: true,
  });

  const {
    data: chart,
    error: chartError,
    isLoading: chartLoading,
  } = useNewFetch(getCoursePerformanceChart, {
    period: period,
    journey_id: journeyUUID || undefined,
    segment_id: props.segmentUUID || undefined,
    courses_id: chosenCourses.join(","),
  });

  const getColors = useCallback(() => {
    if (!courses || courses.results.length === 0) {
      return [
        {
          value: getChartColorByIndex(0),
          id: "",
        },
      ];
    }

    const colors = courses.results.map((el, i) => {
      return {
        value: getChartColorByIndex(i),
        id: el.uuid,
      };
    });

    return colors;
  }, [courses]);

  const colors = getColors();

  const getDataset = useCallback(() => {
    if (!chart) {
      return [];
    }
    if (chosenCourses.length === 0) {
      const data: {
        x: string;
        y: number;
      }[] = chart
        .reduce(
          (
            acc: {
              x: string;
              y: number;
            }[],
            chartEl
          ) => {
            for (const [date, value] of Object.entries(
              chartEl.course_enrolments
            )) {
              const formatedDate = getFormattedDate(date, period, t);
              const handledData = acc.find((el) => el.x === formatedDate);

              if (handledData) {
                handledData.y += value;
              } else {
                acc.push({
                  x: formatedDate,
                  y: value,
                });
              }
            }

            return acc;
          },
          []
        )
        .reverse();

      const hexColor = colors[0].value;

      const color = hexToRgb(hexColor);

      return [
        {
          label: t("performance.all-courses"),
          data: data,
          fill: true,
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.05)`,
          borderColor: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
          borderWidth: 1.5,
          pointStyle: "circle",
          pointRadius: 0,
          pointHoverRadius: 8,
          pointBorderColor: theme.colors.white,
          pointBackgroundColor: hexColor,
          cubicInterpolationMode: "monotone",
        },
      ];
    } else {
      return chart.map((chartEl) => {
        const data = map(chartEl.course_enrolments, (value, date) => {
          return {
            x: getFormattedDate(date, period, t),
            y: value,
          };
        }).reverse();

        const hexColor =
          colors.find((el) => el.id === chartEl.uuid)?.value || colors[0].value;

        const color = hexToRgb(hexColor);

        return {
          label: chartEl.name,
          data: data,
          fill: true,
          backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.05)`,
          borderColor: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
          borderWidth: 1.5,
          pointStyle: "circle",
          pointRadius: 0,
          pointHoverRadius: 8,
          pointBorderColor: theme.colors.white,
          pointBackgroundColor: hexColor,
          cubicInterpolationMode: "monotone",
        };
      });
    }
  }, [chart, chosenCourses.length, colors, period, t]);

  if (!chart || chartError || chartLoading) {
    return pageLoading(chartError);
  }

  if (!courses || coursesError || coursesLoading) {
    return pageLoading(chartError);
  }

  const datasets = getDataset();

  const chartData = {
    datasets,
  };

  const chartOptions: ChartConfiguration<"line">["options"] = {
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        position: "nearest",
        intersect: true,
        padding: 16,
        cornerRadius: 20,
        backgroundColor: theme.colors.white,
        bodyColor: theme.colors.dark,
        titleColor: theme.colors.dark,
        borderColor: theme.colors.dark,
        footerColor: theme.colors.dark,
        callbacks: {
          title: (tooltipItems: TooltipItem<"line">[]) => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return sum + ` ${t("performance.enrolments")}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        grace: "10%",
      },
      xAxes: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      css={css`
        display: flex;
        margin-bottom: 60px;
      `}
    >
      <div
        css={css`
          background: ${theme.colors.gray1};
          padding: 40px;
          border-radius: 20px;
          width: 700px;
        `}
      >
        <div
          css={css`
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 25px;
          `}
        >
          {t("performance.course-enrolments")}
        </div>

        <ChartLine
          width={700}
          height={621}
          config={{
            data: chartData,
            options: chartOptions,
            type: "line",
          }}
        />
      </div>
      <div
        css={css`
          margin-top: 40px;
          margin-left: 40px;
        `}
      >
        <div
          css={css`
            margin-bottom: 25px;
            font-size: 20px;
            font-weight: bold;
          `}
        >
          {t("courses.title")}
        </div>

        <div>
          <div
            css={css`
              margin-bottom: 12px;
            `}
          >
            <CheckboxComponent
              value={chosenCourses.length === 0}
              label={t("performance.all-courses")}
              checkColor={colors[0].value}
              onChange={(e) => {
                setChosenCourses([]);
              }}
            />
          </div>

          {courses.results.map((course, key) => {
            return (
              <div
                key={key}
                css={css`
                  margin-bottom: 12px;
                `}
              >
                <CheckboxComponent
                  key={key}
                  value={chosenCourses.includes(course.uuid)}
                  label={course.name}
                  checkColor={
                    colors.find((el) => el.id === course.uuid)?.value ||
                    colors[0].value
                  }
                  onChange={() => {
                    setChosenCourses((chosenCourses) =>
                      xor(chosenCourses, [course.uuid])
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
