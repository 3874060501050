import React from "react";
import { css } from "styled-components/macro";
import {
  calculateElementDuration,
  calculateElementStartingTime,
  findElementState,
} from "../../helpers/video";
import { Video, VideoElement, VideoElementState } from "../../types/Video";
import { VideoTextElement } from "../VideoInteractive/Elements/VideoTextElement";
import { VideoChartElement } from "../VideoInteractive/Elements/VideoChartElement";
import { VideoImageElement } from "./Elements/VideoImageElement";
import { VideoAudioElement } from "./Elements/VideoAudioElement";
import { VideoDrawingElement } from "./Elements/VideoDrawingElement";

export function VideoElements(props: {
  currentTime: number;
  video: Video;
  playing: boolean;
  elementWrapper?: (
    rendered: React.ReactNode,
    element: VideoElement,
    state: VideoElementState,
    showElement: boolean
  ) => React.ReactNode;
}) {
  const { currentTime, video, playing } = props;

  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      `}
    >
      {video.schema.elements.map((element) => {
        const state = findElementState(element, currentTime);
        const startingTime = calculateElementStartingTime(element);
        const duration = calculateElementDuration(element);
        const renderElement =
          currentTime > startingTime - 50 &&
          currentTime < startingTime + duration + 50;

        const showElement =
          currentTime >= startingTime && currentTime <= startingTime + duration;

        const isWrappable = element.type !== "audio";

        if (!renderElement || !state) return null;

        const content =
          element.type === "image" ? (
            <VideoImageElement element={element} currentTime={currentTime} />
          ) : element.type === "text" ? (
            <VideoTextElement element={element} currentTime={currentTime} />
          ) : element.type === "drawing" ? (
            <VideoDrawingElement element={element} currentTime={currentTime} />
          ) : element.type === "chart-pie" ? (
            <VideoChartElement element={element} currentTime={currentTime} />
          ) : element.type === "audio" ? (
            <VideoAudioElement
              element={element}
              currentTime={currentTime}
              playing={playing}
            />
          ) : null;

        return props.elementWrapper && isWrappable
          ? props.elementWrapper(content, element, state, showElement)
          : content;
      })}
    </div>
  );
}
