import React, { useState } from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { useHistory } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { theme } from "../../themes/variables";
import { Column, Row, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { TextareaField } from "../fields/TextareaField";
import { SelectField } from "../fields/SelectField";
import { RadioFields } from "../fields/RadioFields";
import { CheckboxFields } from "../fields/CheckboxFields";
import { ImageUploadField } from "../fields/ImageUploadField";
import { Button } from "../../components/Button";
import { DangerZone } from "../../components/DangerZone";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";

import { SignupFieldType } from "../../types/admin/SignupField";
import { UserProfile } from "../../types/UserProfile";
import { Image, ImageType } from "../../types/Image";
import { deleteMe } from "../../actions/account";
import { uploadImage } from "../../actions/image";
import { useAuth } from "../../contexts/UserContext";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import {
  getSignupFieldsInitialValues,
  getSignupFieldsSchema,
  getSignupFieldsServerValues,
} from "../../helpers/signupFields";

export type FormValues = {
  first_name: string;
  last_name: string;
  introduction?: string;
  city: string;
  avatar: Image | null;
  position: string;
  email: string;
} & {
  signup_fields: { [key: string]: any };
};

export function AccountForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
  data: UserProfile;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const re = new RegExp("[a-zA-Z-]");

  const [deleteModal, setDeleteModal] = useState(false);

  const { deauthenticate } = useAuth();

  const onConfirmDelete = async () => {
    try {
      await deleteMe();
      setDeleteModal(false);
      deauthenticate();
      history.push(`/`);
    } catch (e) {
      customToast.error(t("status.error"));
    }
  };

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      const signupFields = getSignupFieldsServerValues(
        values.signup_fields,
        props.data.signup_field_values.map((field) => field.signup_field)
      );

      await props.onSubmit({
        ...values,
        signup_fields: signupFields,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const dynamicSchema = getSignupFieldsSchema(
    props.data.signup_field_values.map((el) => el.signup_field),
    t
  );

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(),
    last_name: Yup.string().matches(re).required(),
    introduction: Yup.string().max(500),
    city: Yup.string().matches(re).required(),
    signup_fields: Yup.object().shape(dynamicSchema),
  });

  const initialValues: FormValues = {
    first_name: props.data.first_name,
    last_name: props.data.last_name,
    introduction: props.data?.introduction,
    city: props.data.city,
    avatar: props.data.avatar || null,
    position: props.data.position || "",
    email: props.data.email,
    signup_fields: getSignupFieldsInitialValues(props.data.signup_field_values),
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => {
          return (
            <Form noValidate>
              <Column
                gutter={Spacing.xl}
                css={css`
                  display: flex;
                  width: 100%;
                `}
              >
                <ImageUploadField
                  label={`${t("account.avatar")}:`}
                  name={"avatar"}
                  type={ImageType.HIDDEN}
                  disableLibrary
                  uploadFn={uploadImage}
                />

                <Row gutter={Spacing.l}>
                  <InputField
                    name={"first_name"}
                    type="text"
                    label={t("account.first-name")}
                    css={css`
                      width: 100%;
                    `}
                  />

                  <InputField
                    name={"last_name"}
                    type="text"
                    label={t("account.last-name")}
                    css={css`
                      width: 100%;
                    `}
                  />
                </Row>

                <InputField
                  name={"email"}
                  type="text"
                  label={t("account.email")}
                  disabled
                  css={css`
                    width: 100%;
                    input {
                      border: none;
                      background-color: ${theme.colors.gray2};
                    }
                  `}
                />

                <TextareaField
                  label={`${t("account.introduction")}:`}
                  name={"introduction"}
                  maxLength={500}
                  css={css`
                    width: 100%;
                  `}
                />

                <InputField
                  name={"position"}
                  type="text"
                  label={`${t("account.position")}`}
                />

                <InputField
                  name={"city"}
                  type="text"
                  label={`${t("account.city")}`}
                  css={css`
                    width: 50%;
                  `}
                />

                {props.data.signup_field_values.map(
                  ({ signup_field: field }) => {
                    return (
                      <React.Fragment key={field.order}>
                        {field.type === SignupFieldType.TEXT_FIELD && (
                          <InputField
                            label={field.name}
                            name={`signup_fields[${field.uuid}][0]`}
                            hint={field.hint || ""}
                            placeholder={field.placeholder || ""}
                          />
                        )}
                        {field.type === SignupFieldType.TEXT_AREA && (
                          <TextareaField
                            label={field.name}
                            name={`signup_fields[${field.uuid}][0]`}
                            hint={field.hint || ""}
                            placeholder={field.placeholder || ""}
                          />
                        )}
                        {field.type === SignupFieldType.SELECT && (
                          <SelectField
                            label={field.name}
                            name={`signup_fields[${field.uuid}][0]`}
                            hint={field.hint || ""}
                            choices={field.options.map((option) => {
                              return {
                                label: option.value,
                                value: option.value,
                              };
                            })}
                          />
                        )}
                        {field.type === SignupFieldType.RADIO && (
                          <RadioFields
                            label={field.name}
                            labelRight
                            name={`signup_fields[${field.uuid}][0]`}
                            hint={field.hint || ""}
                            choices={field.options.map((option) => {
                              return {
                                label: option.value,
                                value: option.value,
                              };
                            })}
                          />
                        )}
                        {field.type === SignupFieldType.CHECKBOX && (
                          <CheckboxFields
                            label={field.name}
                            name={`signup_fields[${field.uuid}]`}
                            hint={field.hint || ""}
                            choices={field.options.map((option) => {
                              return {
                                label: option.value,
                                value: String(option.id),
                              };
                            })}
                          />
                        )}
                      </React.Fragment>
                    );
                  }
                )}

                <div>
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    {t("account.update")}
                  </Button>
                </div>

                <DangerZone
                  css={css`
                    margin-top: 5rem;
                  `}
                >
                  <Button onClick={() => setDeleteModal(true)}>
                    {t("account.delete")}
                  </Button>

                  <Modal
                    modalIsOpen={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    contentLabel={t("actions.sure")}
                  >
                    <ModalHeader
                      closeIcon
                      onClose={() => setDeleteModal(false)}
                    >
                      {t("actions.sure")}
                    </ModalHeader>

                    <ModalBody>{t("account.modal.body")}</ModalBody>

                    <ModalFooter>
                      <Button
                        background={theme.colors.white}
                        color={theme.colors.dark}
                        border={`1px solid ${theme.colors.dark}`}
                        hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                        onClick={() => setDeleteModal(false)}
                      >
                        {t("actions.cancel")}
                      </Button>

                      <Button onClick={onConfirmDelete}>
                        {t("actions.delete")}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </DangerZone>
              </Column>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
