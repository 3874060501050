import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { EditCreateFaq, FormValues } from "../../components/EditCreateFaq";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { RouteComponentProps } from "react-router";

import { createStudioFaqItem } from "../../actions/faq";
import { Tabs } from "../../components/Tabs";
import { Footer } from "../../components/Footer";

export function StudioNewFaqPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    await createStudioFaqItem(data);

    history.push(`/studio/faq`);
  };

  const tabsData = [
    {
      title: t("courses.title"),
      link: "/courses",
    },
    {
      title: t("header.navigation.faq"),
      link: `/studio/faq`,
      forceHightlight: true,
    },
    {
      title: t("feedback.feedback"),
      link: "/studio/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("faq.create-title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 30px;
            `}
          >
            <Headers.H3>{t("faq.create-title")}</Headers.H3>
          </div>
          <EditCreateFaq onSubmitCallback={onSubmit} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
