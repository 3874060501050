import { authorised } from "../../request";
import { Instructor } from "../../types/Instructor";

type Args = {
  searchText?: string;
};

export async function getInstructors({ searchText }: Args = {}) {
  const API = authorised();

  return API.request<Instructor[]>({
    method: "GET",
    url: `/api/studio/instructors/?${searchText ? `name=${searchText}` : ""}`,
  });
}
