import { buildQueryString } from "../../../helpers/buildQueryString";
import { authorised } from "../../../request";
import { STATUS_OPTIONS } from "../../../types/admin/Order";

type Args = {
  date_from?: string;
  date_to?: string;
  status?: STATUS_OPTIONS;
};

export async function downloadOrdersCsv(args?: Args) {
  const API = authorised();

  return await API.request<string>({
    method: "GET",
    url: `/api/enterprise/orders/csv/?${buildQueryString(args)}`,
  });
}
