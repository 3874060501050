import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getUsers } from "../../../actions/admin/user";
import { formatDate } from "../../../helpers/time";
import { TargetUserProfile } from "../../../types/admin/UserProfile";
import { theme } from "../../../themes/variables";
import { Button } from "../../Button";
import { Avatar } from "../../Avatar";
import { TableHandler, TableHandlerColumn } from "../../TableHandler";
import { ReactComponent as ReadSvg } from "../../../assets/icons/Read.svg";
import { ReactComponent as CloseSvg } from "../../../assets/icons/Close.svg";

export default function UserTable({
  forwardRef,
  type,
  loadingId,
  isMe,
  canChangeAdmin,
  activateUser,
  deactivateUser,
}: {
  forwardRef: any;
  type?: "STUDIO" | "CAMPUS";
  loadingId: number | null;
  isMe: (user: TargetUserProfile) => boolean;
  canChangeAdmin: (user: TargetUserProfile) => boolean;
  activateUser: (id: number) => void;
  deactivateUser: (id: number) => void;
}) {
  const { t } = useTranslation();

  const tableHandlerColumns: TableHandlerColumn<TargetUserProfile>[] = [
    {
      label: t("account.avatar"),
      render: (row) => (
        <Avatar
          css={css`
            margin: 0 auto;
          `}
          size={40}
          url={row.avatar?.image}
        />
      ),
    },
    {
      label: t("account.email"),
      render: (row) => (
        <div
          css={css`
            width: 170px;
            word-wrap: break-word;
          `}
        >
          {row.email}
        </div>
      ),
    },
    {
      label: t("admin.users.page.fullname"),
      render: (row) => <div>{row.first_name + " " + row.last_name}</div>,
    },
    {
      label: t("admin.users.page.refferal"),
      render: (row) => <div>{row.referral}</div>,
    },
    {
      label: t("admin.users.page.date-joined"),
      render: (row) => <div>{formatDate(row.date_joined)}</div>,
    },
    {
      label: t("signup_fields.title"),
      render: (row) => (
        <div>
          {row.signup_field_values.map((signup_field_value) => {
            let label = signup_field_value.signup_field.name.trim();

            if (label && label[label.length - 1] === ":") {
              label = label.slice(0, label.length - 1);
            }

            return (
              <div key={signup_field_value.signup_field.uuid}>
                <span
                  css={css`
                    font-weight: bold;
                    margin-right: 10px;
                  `}
                >
                  {label}:
                </span>

                <span>{signup_field_value.values.join(", ")}</span>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            white-space: nowrap;
          `}
        >
          {isMe(row) || !canChangeAdmin(row) ? null : row.is_verified ===
            null ? (
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <Button
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.dark}`}
                color={theme.colors.dark}
                icon={
                  <ReadSvg
                    color={theme.colors.success}
                    width={14}
                    height={14}
                  />
                }
                hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.success}; border: 1px solid ${theme.colors.transparent};`}
                iconRight
                small
                onClick={() => activateUser(row.id)}
                css={css`
                  margin-right: 10px;
                `}
                isSubmitting={loadingId === row.id}
              >
                {t("actions.accept")}
              </Button>

              <Button
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.error}`}
                color={theme.colors.error}
                icon={
                  <CloseSvg color={theme.colors.error} width={14} height={14} />
                }
                hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
                iconRight
                small
                css={css`
                  min-width: 0;
                `}
                onClick={() => deactivateUser(row.id)}
                isSubmitting={loadingId === row.id}
              ></Button>
            </div>
          ) : row.is_verified ? (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.dark}`}
              color={theme.colors.dark}
              icon={
                <CloseSvg color={theme.colors.dark} width={14} height={14} />
              }
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.dark}; border: 1px solid ${theme.colors.transparent};`}
              iconRight
              small
              onClick={() => deactivateUser(row.id)}
              isSubmitting={loadingId === row.id}
            >
              {t("admin.users.page.deactivate-user")}
            </Button>
          ) : (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.dark}`}
              color={theme.colors.dark}
              icon={
                <ReadSvg color={theme.colors.success} width={14} height={14} />
              }
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.success}; border: 1px solid ${theme.colors.transparent};`}
              iconRight
              small
              onClick={() => activateUser(row.id)}
              isSubmitting={loadingId === row.id}
            >
              {t("admin.users.page.activate-user")}
            </Button>
          )}{" "}
        </div>
      ),
    },
  ];

  return (
    <TableHandler
      forwardRef={forwardRef}
      tableData={{
        columns: tableHandlerColumns,
      }}
      getFunction={getUsers}
      options={{
        internalArgs: {
          type: type,
        },
      }}
    />
  );
}
