import React, { useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { InteractiveVideoBlock } from "../../../../types/Block";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { BlockControls } from "../components/BlockControls";
import { ReactComponent as VideoIcon } from "../../../../assets/icons/Video.svg";
import { Video } from "../../../../types/Video";
import { VideoEditor } from "../../../VideoEditor/VideoEditor";
import { VideoInteractive } from "../../../VideoInteractive/VideoInteractive";
import { updateVideo } from "../../../../actions/video/updateVideo";

export function BlockInteractiveVideo(props: {
  block: InteractiveVideoBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: () => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  function onDelete() {
    props.onDelete();
    setDeleteModal(false);
  }

  const block = props.block;

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.video && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              display: flex;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            <VideoInteractive
              show={true}
              video={block.data.video}
              onEnded={() => {}}
            />
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block || []}
        onCancel={onClose}
        hideBackgroundOptions
        backgroundColor={theme.colors.white}
        header={
          <div>
            <div
              css={css`
                margin-right: 12px;
                width: 30px;
                display: inline-block;
                vertical-align: middle;

                svg {
                  width: 100%;
                }
              `}
            >
              <VideoIcon color={theme.colors.primary} />
            </div>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
      >
        {block.data && block.data.video && (
          <VideoEditor
            video={block.data.video}
            onSave={async (video: Video) => {
              await updateVideo(video.uuid, {
                schema: video.schema,
              });

              onClose();

              await props.onUpdate();
            }}
          />
        )}
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
