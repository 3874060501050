import { authorised } from "../../../request";
import { Skill } from "../../../types/admin/Skill";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  ordering?: string;
};

export async function getSkills(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number;
    next: null | string;
    previous: null | string;
    results: Skill[];
  }>({
    method: "GET",
    url: `/api/enterprise/skills/?${buildQueryString(args)}`,
  });
}
