import React from "react";
import { SelfAssessmentPerformance } from "../types/SelfAssessment";
import SelfAssessmentItemPerformance from "./SelfAssessmentItemPerformance";

export default function SelfAssessmentsPerformance({
  selfAssessmentItems,
}: {
  selfAssessmentItems: SelfAssessmentPerformance[];
}) {
  return (
    <div>
      {selfAssessmentItems &&
        selfAssessmentItems.map(
          (item, id) =>
            item.answers.length > 0 && (
              <SelfAssessmentItemPerformance
                key={id}
                selfAssessmentItem={item}
              />
            )
        )}
    </div>
  );
}
