import { authorised } from "../../../request";
import { Announcement } from "../../../types/admin/Announcement";
import { RequiredDataType } from "../../../types/RequiredDataType";

type Args = {
  data: Pick<
    Announcement,
    "title" | "short_description" | "publish_date" | "url"
  > & {
    user_segments?: RequiredDataType[];
  };
};

export async function createAnnouncement({ data }: Args) {
  const API = authorised();

  return API.request<Announcement>({
    method: "POST",
    url: `/api/enterprise/announcements/`,
    data,
  });
}
