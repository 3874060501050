import { authorised } from "../../../request";
import { EnterpriseAddress } from "../../../types/Enterprise";

export async function updateEnterpriseAddressSettings(
  settings: EnterpriseAddress
) {
  const API = authorised();

  return await API.request<EnterpriseAddress>({
    method: "PUT",
    url: `/api/enterprise/settings/address/`,
    data: settings,
  });
}
