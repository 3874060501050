import { authorised } from "../../../request";
import { Skill } from "../../../types/admin/Skill";

type SkillArgs = Omit<Skill, "id">

type Args = {
  id: number;
  data: SkillArgs;
}

export async function updateSkill({id, data}: Args) {
  const API = authorised();

  return API.request<Skill>({
    method: "PUT",
    url: `/api/enterprise/skills/${id}/`,
    data
  });
}
