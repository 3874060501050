import { authorised } from "../../request";
import { File as FileType, Transcript } from "../../types/File";

type Args = {
  fileId: string;
  title?: string | null;
  transcript?: Transcript[] | null;
  onUploadProgress?: (progressEvent: any) => void;
}

export async function updateFile({fileId, title, transcript, onUploadProgress}: Args) {
  const API = authorised();
  
  const response = await API.request<FileType>({
    method: "PUT",
    url: `/api/studio/files/${fileId}/`,
    data: {
      title,
      transcript
    },
    onUploadProgress
  });

  return response.data;
}
