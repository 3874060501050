import React from "react";
import { VideoDrawing } from "../../../types/Video";
import { getDrawingRect } from "../../../helpers/draw";
import { DrawingRenderer } from "../DrawArea";

export function VideoDrawingElement(props: {
  element: VideoDrawing;
  currentTime: number;
}) {
  const { element } = props;
  const { width, height } = getDrawingRect(element.drawing);
  const drawingStartTime = element.states[0].start_time;
  const drawingTime = props.currentTime - drawingStartTime;

  const filteredDrawing = {
    ...element.drawing,
    marks: element.drawing.marks.map((mark) => {
      return {
        ...mark,
        points: mark.points.filter((point) => point[3] <= drawingTime),
      };
    }),
  };

  const sanitizedDrawing = {
    ...filteredDrawing,
    marks: filteredDrawing.marks.filter((mark) => {
      return mark.points.length > 0;
    }),
  };

  return (
    <DrawingRenderer
      drawing={sanitizedDrawing}
      viewBox={`0 0 ${width} ${height}`}
    />
  );
}
