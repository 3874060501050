import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Field, FieldProps } from "formik";
import { InputErrors } from "./InputErrors";
import { Duration } from "luxon";
import { InputComponent } from "../../components/InputComponent";
import { Row } from "../../helpers/layout";

export function InputDurationField(props: {
  id?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  className?: string;
  mask?: Array<RegExp | string> | ((value: string) => Array<RegExp | string>);
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { id, name, disabled, maxLength, readonly, validate } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Field name={name} validate={validate}>
        {({ form, field, meta }: FieldProps) => {
          const value = field.value ? Number(field.value) : 0;
          const duration = Duration.fromObject({
            seconds: value,
          })
            .shiftTo("hours", "minutes", "seconds")
            .toObject();

          const currentHours = duration.hours || "";
          const currentMinutes = duration.minutes || "";
          const currentSeconds = duration.seconds || "";
          console.log(currentHours, currentMinutes, currentSeconds);

          return (
            <Row
              justify={"space-between"}
              align={"flex-end"}
              css={css`
                margin-bottom: 32px;
              `}
            >
              <InputComponent
                id={`${id}-hours`}
                name={`${name}-hours`}
                label={t("lesson.meta.duration")}
                value={currentHours.toString()}
                disabled={disabled}
                readonly={readonly}
                placeholder={t("duration.hours")}
                onChange={(event) => {
                  const hours = Number(event.currentTarget.value) || 0;
                  const newDuration = Duration.fromObject({
                    seconds: value,
                  })
                    .minus({
                      hours: currentHours || 0,
                    })
                    .plus({
                      hours: hours,
                    });

                  form.setFieldValue(
                    name,
                    newDuration.as("seconds").toString()
                  );
                }}
                error={!!meta.error && meta.touched}
              />
              <InputComponent
                id={`${id}-minutes`}
                name={`${name}-minutes`}
                value={currentMinutes.toString()}
                disabled={disabled}
                readonly={readonly}
                placeholder={t("duration.minutes")}
                onChange={(event) => {
                  const minutes = Number(event.currentTarget.value) || 0;
                  const newDuration = Duration.fromObject({
                    seconds: value,
                  })
                    .minus({
                      minutes: currentMinutes || 0,
                    })
                    .plus({
                      minutes: minutes < 60 ? minutes : 59,
                    });

                  form.setFieldValue(
                    name,
                    newDuration.as("seconds").toString()
                  );
                }}
                error={!!meta.error && meta.touched}
              />
              <InputComponent
                id={`${id}-seconds`}
                name={`${name}-seconds`}
                value={currentSeconds.toString()}
                disabled={disabled}
                readonly={readonly}
                placeholder={t("duration.seconds")}
                onChange={(event) => {
                  const seconds = Number(event.currentTarget.value) || 0;

                  const newDuration = Duration.fromObject({
                    seconds: value,
                  })
                    .minus({
                      seconds: currentSeconds || 0,
                    })
                    .plus({
                      seconds: seconds < 60 ? seconds : 59,
                    });

                  form.setFieldValue(
                    name,
                    newDuration.as("seconds").toString()
                  );
                }}
                error={!!meta.error && meta.touched}
              />

              <InputErrors
                currentLength={String(field.value).length}
                maxLength={maxLength}
                error={meta.error}
                touched={meta.touched}
              />
            </Row>
          );
        }}
      </Field>
    </div>
  );
}
