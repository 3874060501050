import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Styles } from "react-modal";
import { theme } from "../../../themes/variables";
import { Modal } from "../../Modal/Modal";
import { ReactComponent as CloseSVG } from "../../../assets/icons/CloseSmall.svg";
import { Button } from "../../Button";
import { Announcement } from "../../../types/admin/Announcement";
import EditCreateAnnouncement, {
  FormValues,
} from "../../../forms/admin/announcements/EditCreateAnnouncement";

const customStyles: Styles["content"] = {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "960px",
  maxHeight: "calc(100vh - 100px)",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px, rgba(0, 0, 0, 0.12) 0px 1px 10px",
  overflow: "auto",
  borderRadius: "20px",
  position: "relative",
  backgroundColor: `${theme.colors.gray1}`,
  border: "none",
  willChange: "scroll-position",
  padding: "40px 60px",
};

export default function AnnouncementDetailsModal({
  announcement,
  isActive,
  onClose,
  onSubmit,
}: {
  announcement?: Announcement;
  isActive: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <Modal
      modalIsOpen={isActive}
      onClose={() => onClose}
      contentStyles={customStyles}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
          `}
        >
          <div
            css={css`
              font-size: 30px;
              line-height: 42px;
            `}
          >
            {announcement && announcement.title
              ? t("announcements.edit")
              : t("announcements.new")}
          </div>

          <Button
            css={css`
              display: flex;
              align-items: center;
              color: ${theme.colors.black};
              background-color: ${theme.colors.gray1};
            `}
            onClick={() => onClose()}
          >
            <div
              css={css`
                display: flex;
                margin-right: 10px;
                color: ${theme.colors.gray6};
              `}
            >
              {t("actions.cancel")}
            </div>
            <CloseSVG />
          </Button>
        </div>
        <EditCreateAnnouncement
          initialValues={announcement}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </div>
    </Modal>
  );
}
