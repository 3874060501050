import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { customToast } from "../../components/customToast";
import { useHistory, Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { Header } from "../../components/Header";
import { Wrapper } from "../../components/Wrapper";
import { Block } from "../../components/Block";
import { Button } from "../../components/Button";
import { CollapsableContainer } from "../../components/CollapsableContainer";
import { DraggableComponent } from "../../components/Draggable";
import { Pagination } from "../../components/Pagination";
import { SEO } from "../../components/SEO";
import { Journey } from "../../types/Journey";
import { JourneyCourse } from "../../types/JourneyCourse";
import { Spacing, Row } from "../../helpers/layout";
import { usePagination } from "../../hooks/usePagination";
import { theme } from "../../themes/variables";

import { updateJourneyCourseOrder } from "../../actions/journeyCourse/updateJourneyCourse";
import { pageLoading } from "../../helpers/pageLoading";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Tabs } from "../../components/Tabs";
import { getJourneys } from "../../actions/journeys";
import { EntitySelectionModal } from "../../components/EntitySelectionModal";
import { getCourses } from "../../actions/course/getCourses";
import { RequiredDataType } from "../../types/RequiredDataType";
import { addJourneyCourses } from "../../actions/journeys/addJourneyCourses";
import { ReactComponent as StudySvg } from "../../assets/icons/Study.svg";
import { ReactComponent as StopwatchSvg } from "../../assets/icons/Stopwatch.svg";
import { getDuration } from "../../helpers/time";
import { CoursePublishStatus } from "../../components/CoursePublishStatus";
import { Footer } from "../../components/Footer";

const entityCoursesSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getCourses({ searchText });

  return response.data.map((item) => {
    return {
      id: String(item.uuid),
      label: (
        <div
          style={{
            position: "relative",
          }}
        >
          {item.name}
          <CoursePublishStatus course={item} />
        </div>
      ),
    };
  });
};

export function JourneysPage(props: {}) {
  const { t, i18n } = useTranslation();
  const [showCourseSelection, setShowCourseSelection] =
    useState<Journey | null>(null);

  const history = useHistory();

  const editJourney = (id: string) => {
    history.push(`/admin/journeys/${id}/syllabus`);
  };

  const {
    response: { error, data, refetch },
    pagination: { onChose, onNext, onPrev, count, page },
  } = usePagination<
    {
      count: number;
      next: null | string;
      previous: null | string;
      results: Journey[];
    },
    {
      page: string;
    }
  >(getJourneys);

  if (!data || error) {
    return pageLoading(error);
  }

  const tabsData = [
    {
      title: t("journeys.title"),
      link: "/admin/journeys",
    },
  ];

  const onDragEnd = async (journeyId: string, items: JourneyCourse[]) => {
    try {
      await updateJourneyCourseOrder({ journeyId, items });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("journeys.title")}</title>
      </SEO>
      <Header />
      <Tabs tabs={tabsData} />
      <Block>
        <div
          css={css`
            padding-bottom: 20px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <Link to="/admin/journeys/new">
            <Button
              type="button"
              name="create_journey"
              children={t("journeys.create")}
            />
          </Link>
        </div>

        <div>
          {data.results.map((journey: Journey, idx: number) => {
            return (
              <CollapsableContainer
                key={journey.uuid}
                css={css`
                  margin-top: 30px;
                  :first-of-type {
                    margin-top: 0;
                  }
                `}
                isExpandable={journey.courses.length > 0}
                title={
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      flex: 1 0 auto;
                    `}
                  >
                    {journey.image && (
                      <div
                        css={css`
                          vertical-align: middle;
                          margin-right: 15px;
                          width: 70px;
                          height: 70px;
                          border-radius: 50%;
                          overflow: hidden;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background: #fff url(${journey.image.image}) no-repeat
                            center;
                          background-size: cover;
                        `}
                      ></div>
                    )}
                    <div>
                      <div>
                        <Link
                          to={`/admin/journeys/${journey.uuid}/syllabus`}
                          className="syllabus_journey"
                        >
                          <div
                            css={css`
                              font-weight: bold;
                              margin-bottom: ${Spacing.xs};
                            `}
                          >
                            {journey.name}
                          </div>
                        </Link>
                      </div>
                      <div
                        css={css`
                          display: flex;
                          flex-flow: row wrap;
                          margin-top: 5px;
                          font-size: 16px;
                          line-height: 22px;
                          width: 100%;
                        `}
                      >
                        <div
                          css={css`
                            flex: 0 0 auto;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                          `}
                        >
                          <StudySvg
                            width={20}
                            height={20}
                            color={theme.colors.dark}
                          />
                          <h4
                            css={css`
                              margin: 0px 25px 0 10px;
                              font-weight: normal;
                            `}
                          >
                            {journey.courses.length +
                              " " +
                              t("journey.courses")}
                          </h4>
                        </div>
                        <div
                          css={css`
                            flex: 0 0 auto;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                          `}
                        >
                          <StopwatchSvg
                            width={20}
                            height={20}
                            color={theme.colors.dark}
                          />
                          <h4
                            css={css`
                              margin: 0px 25px 0 10px;
                              font-weight: normal;
                            `}
                          >
                            {getDuration(journey.duration || 0, i18n.language)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                headerControls={[
                  <Button
                    small
                    border={`1px solid ${theme.colors.transparent}`}
                    name="edit_journey"
                    onClick={() => editJourney(journey.uuid)}
                  >
                    {t("journeys.edit")}
                  </Button>,
                ]}
              >
                <DraggableComponent
                  items={journey.courses}
                  getDraggableId={(row) => row.course.uuid}
                  isDragDisabled={false}
                  onDragEnd={(items) => onDragEnd(journey.uuid, items)}
                >
                  {(row) => {
                    return (
                      <div>
                        <Link
                          css={css`
                            position: relative;
                          `}
                          to={`/courses/${row.course.uuid}/syllabus`}
                        >
                          {row.course.name}
                          <CoursePublishStatus course={row.course} />
                        </Link>
                      </div>
                    );
                  }}
                </DraggableComponent>

                <div
                  css={css`
                    padding: 30px 20px;
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                  `}
                >
                  <Button
                    small
                    name="add_course"
                    children={t("journeys.add-course")}
                    onClick={() => {
                      setShowCourseSelection(journey);
                    }}
                  />
                </div>
              </CollapsableContainer>
            );
          })}
        </div>

        <Row align="center" justify="center">
          <Pagination
            css={css`
              margin-top: 40px;
            `}
            arrows
            currentItemIndex={page}
            itemsCount={count}
            onChose={onChose}
            onNext={onNext}
            onPrev={onPrev}
          />
        </Row>
      </Block>
      {showCourseSelection && (
        <EntitySelectionModal
          show={true}
          onClose={() => {
            setShowCourseSelection(null);
          }}
          selectedItems={[]}
          disabledItems={showCourseSelection.courses.map((c) => ({
            label: c.course.name,
            id: c.course.uuid,
          }))}
          searchCallback={entityCoursesSearchCallback}
          onConfirm={async (selected) => {
            await addJourneyCourses(
              showCourseSelection.uuid,
              selected.map((s) => s.id)
            );

            await refetch();

            setShowCourseSelection(null);
          }}
        />
      )}
      <Footer />
    </Wrapper>
  );
}
