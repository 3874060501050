import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { theme } from "../../themes/variables";
import { Button } from "../../components/Button";
import { SEO } from "../../components/SEO";
import { Headers, Row } from "../../helpers/layout";

import { pageLoading } from "../../helpers/pageLoading";
import { getPaginatedCourses } from "../../actions/course/getCourses";
import { ReactComponent as StudySvg } from "../../assets/icons/Study.svg";
import { ReactComponent as StopwatchSvg } from "../../assets/icons/Stopwatch.svg";
import { getDuration } from "../../helpers/time";
import { DraggableComponent } from "../../components/Draggable";
import { CollapsableContainer } from "../../components/CollapsableContainer";
import { updateCourseLessonOrder } from "../../actions/courseLesson/updateCourseLesson";
import { CourseLesson } from "../../types/CourseLesson";
import { customToast } from "../../components/customToast";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { CoursePublishStatus } from "../../components/CoursePublishStatus";
import { Footer } from "../../components/Footer";
import { Submenu } from "../../components/Submenu";
import { Pagination } from "../../components/Pagination";
import { usePagination } from "../../hooks/usePagination";
import { Course } from "../../types/Course";

export function CoursesPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const params = useParams<{
    status: "published" | "in-progress";
  }>();

  const {
    response: { error: coursesError, data: courses },
    pagination: { onChose, onNext, onPrev, count, page },
  } = usePagination<
    {
      count: number;
      next: null | string;
      previous: null | string;
      results: Course[];
    },
    {
      status: string;
    }
  >(getPaginatedCourses, {
    limit: 10,
    internalArgs: {
      status: params.status,
    },
  });

  if (!courses || coursesError) {
    return pageLoading(coursesError);
  }

  const onDragEnd = async (courseId: string, items: CourseLesson[]) => {
    try {
      await updateCourseLessonOrder({ courseId, items });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("courses.title")}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Wrapper>
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{t("courses.title")}</Headers.H2>
            <div
              css={css`
                flex: 1 0 10%;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Submenu
                items={[
                  {
                    label: t("courses-nav.all"),
                    to: "/courses",
                    isActive: !params.status,
                  },
                  {
                    label: t("courses-nav.published"),
                    to: "/courses/published",
                    isActive: params.status === "published",
                  },
                  {
                    label: t("courses-nav.in_progress"),
                    to: "/courses/in-progress",
                    isActive: params.status === "in-progress",
                  },
                ]}
              />
            </div>
            <Button
              type="button"
              name="create_course"
              children={t("actions.create-course")}
              onClick={() => history.push("/courses/new")}
            />
          </div>
        </Block>
        <Block>
          {courses.results.map((course) => {
            return (
              <CollapsableContainer
                key={course.uuid}
                css={css`
                  margin-top: 30px;
                  :first-of-type {
                    margin-top: 0;
                  }
                `}
                isExpandable={course.lessons.length > 0}
                isOpened={false}
                headerControls={
                  course.is_external
                    ? []
                    : [
                        <Button
                          small
                          name="edit-course"
                          border={`1px solid ${theme.colors.transparent}`}
                          onClick={() => {
                            history.push(`/courses/${course.uuid}/edit`);
                          }}
                        >
                          {t("actions.edit")}
                        </Button>,
                      ]
                }
                title={
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      flex: 1 0 auto;
                    `}
                  >
                    {course.logo && (
                      <div
                        css={css`
                          vertical-align: middle;
                          margin-right: 15px;
                          width: 70px;
                          height: 70px;
                          border-radius: 50%;
                          overflow: hidden;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          background: #fff url(${course.logo.image}) no-repeat
                            center;
                          background-size: 70% auto;
                        `}
                      ></div>
                    )}
                    <div>
                      <div
                        css={css`
                          display: inline;
                          position: relative;
                          font-size: 1.2rem;
                        `}
                      >
                        {course.is_external ? (
                          <span>
                            {course.name}
                            <CoursePublishStatus course={course} />
                          </span>
                        ) : (
                          <Link to={`/courses/${course.uuid}/syllabus`}>
                            {course.name}
                            <CoursePublishStatus course={course} />
                          </Link>
                        )}
                      </div>
                      <div
                        css={css`
                          display: flex;
                          flex-flow: row wrap;
                          margin-top: 5px;
                          font-size: 16px;
                          line-height: 22px;
                          width: 100%;
                        `}
                      >
                        <div
                          css={css`
                            flex: 0 0 auto;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                          `}
                        >
                          <StudySvg
                            width={20}
                            height={20}
                            color={theme.colors.dark}
                          />
                          <h4
                            css={css`
                              margin: 0px 25px 0 10px;
                              font-weight: normal;
                            `}
                          >
                            {course.lessons.length + " " + t("course.lessons")}
                          </h4>
                        </div>
                        <div
                          css={css`
                            flex: 0 0 auto;
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                          `}
                        >
                          <StopwatchSvg
                            width={20}
                            height={20}
                            color={theme.colors.dark}
                          />
                          <h4
                            css={css`
                              margin: 0px 25px 0 10px;
                              font-weight: normal;
                            `}
                          >
                            {getDuration(course.duration || 0, i18n.language)}
                          </h4>
                        </div>
                        <div>
                          <h4
                            css={css`
                              text-transform: capitalize;
                            `}
                          >
                            {t(`course.level.${course.level}`)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              >
                <DraggableComponent
                  items={course.lessons}
                  getDraggableId={(row) => row.lesson.uuid}
                  isDragDisabled={course.is_external}
                  onDragEnd={(items) => onDragEnd(course.uuid, items)}
                  controls={(row) => {
                    return [];
                  }}
                >
                  {(row) => {
                    return (
                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        {course.is_external ? (
                          <span>{row.lesson.name}</span>
                        ) : (
                          <Link
                            to={`/courses/${course.uuid}/lessons/${row.lesson.uuid}/content_editor`}
                          >
                            {row.lesson.name}
                          </Link>
                        )}
                      </div>
                    );
                  }}
                </DraggableComponent>
              </CollapsableContainer>
            );
          })}
          <Row align="center" justify="center">
            <Pagination
              css={css`
                margin-top: 40px;
              `}
              arrows
              currentItemIndex={page}
              itemsCount={count}
              onChose={onChose}
              onNext={onNext}
              onPrev={onPrev}
            />
          </Row>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
