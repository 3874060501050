import styled, { css } from "styled-components/macro";
import { theme } from "../themes/variables";

export const TooltipText = styled.span`
  visibility: hidden;
  min-width: 220px;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-align: left;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  margin-left: -30px;
  font-weight: 500;
  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 21px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.colors.primary} transparent transparent transparent;
  }
`;

export function Tooltip(props: {
  text?: string;
  children?: JSX.Element | string;
  className?: string;
}) {
  return (
    <div
      css={css`
        position: relative;
        display: inline-block;
        margin-right: 9px;
        z-index: 1;
        :hover ${TooltipText} {
          visibility: visible;
        }
      `}
      className={props.className}
    >
      {props.children}
      <TooltipText>{props.text}</TooltipText>
    </div>
  );
}
