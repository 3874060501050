import { authorised } from "../../../request";
import { Slider } from "../../../types/admin/Slider";

export async function getSlidersList() {
  const API = authorised();

  return await API.request<Slider[]>({
    method: "GET",
    url: `/api/enterprise/sliders/`,
  });
}
