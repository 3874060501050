import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { Download } from "react-feather";
import { downloadBlob } from "../../helpers/download";
import { theme } from "../../themes/variables";

export function MediaPreview(props: {
  url: string;
  label?: string;
  downloadUrl?: string;
}) {
  const { t } = useTranslation();

  const { label, url, downloadUrl } = props;

  return (
    <div
      css={css`
        margin-top: 1rem;
      `}
    >
      <div>
        {label && (
          <span
            css={css`
              display: block;
              margin-bottom: 16px;
              font-size: 16px;
              font-weight: bold;
            `}
          >
            {label}
          </span>
        )}

        {downloadUrl && (
          <div>
            <button
              css={css`
                display: block;
                padding: 5px 10px;
                border: 1px solid ${theme.colors.gray4};
                border-radius: 6px;
                height: 30px;
                line-height: 1;
                background-color: ${theme.colors.white};
                cursor: pointer;
                margin-bottom: 10px;
                :hover {
                  background-color: ${theme.colors.gray4};
                }
              `}
              type="button"
              title={t("actions.download")}
              onClick={() => downloadBlob(downloadUrl)}
            >
              <Download size={15} />
            </button>
          </div>
        )}

        <ReactPlayer url={url} controls />
      </div>
    </div>
  );
}
