import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { Button } from "../Button";
import { VideoElementText } from "../../types/Video";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { TinyEditor } from "../DraftEditor/tinymce";

export function EditTextElementModal(props: {
  videoId: string;
  initialValue?: VideoElementText;
  isOpen: boolean;
  onClose: () => void;
  position?: {
    x: number;
    y: number;
  };
  onSubmit: (element: VideoElementText) => void;
  currentTime: number;
}) {
  const { t } = useTranslation();
  const [state, setState] = useState<VideoElementText>(
    props.initialValue || {
      type: "text",
      id: uuidv4(),
      text: "",
      states: [
        {
          id: uuidv4(),
          start_time: props.currentTime,
          duration: 3000,
          top: props.position ? props.position.y : 0,
          left: props.position ? props.position.x : 0,
          scale: 1,
          rotation: 0,
        },
      ],
    }
  );

  const onSubmit = () => {
    props.onSubmit(state);
  };

  return (
    <Modal
      modalIsOpen={props.isOpen}
      onClose={props.onClose}
      contentLabel={t("video_editor.elements.add_element")}
    >
      <ModalHeader closeIcon onClose={props.onClose}>
        {t("video_editor.elements.add_element")}
      </ModalHeader>
      <ModalBody>
        <TinyEditor
          value={state.text}
          onChange={(val) => {
            setState((s) => ({ ...s, text: val }));
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onClose();
          }}
          color={"#eee"}
        >
          {t("actions.cancel")}
        </Button>
        <Button onClick={onSubmit}>{t("actions.save")}</Button>
      </ModalFooter>
    </Modal>
  );
}
