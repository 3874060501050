import React, { forwardRef } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import DatePick from "react-datepicker";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker.css";
import { theme } from "../themes/variables";
import { ReactComponent as CalendarIcon } from "../assets/icons/Calendar.svg";
import { InputLabel } from "./InputLabel";

const CustomDatePicker = forwardRef<HTMLButtonElement>((props, ref) => {
  const { value, placeholder, className } = props as {
    value: string;
    placeholder: string;
    className?: string;
  };

  return (
    <button
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 30px;
        border: 1px solid ${theme.colors.black};
        background-color: ${theme.colors.white};
        border-radius: 20px;
        padding: 3px 20px;
        min-width: 150px;
        color: ${theme.colors.black};
        cursor: pointer;
        align-items: center;
      `}
      className={className}
      {...props}
      ref={ref}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        <div
          css={css`
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {value || placeholder}
        </div>

        <CalendarIcon
          width={14}
          height={14}
          color={theme.colors.primary}
          css={css`
            min-width: 14px;
            min-height: 14px;
            margin-left: 10px;
          `}
        />
      </div>
    </button>
  );
});

export function DatePicker(props: {
  date: Date;
  onFilterChange: (value: string) => void;
  label?: string;
  fromDate?: string;
  toDate?: string;
  className?: string;
}) {
  const { t } = useTranslation();

  const dateFormat = "yyyy-MM-dd";

  const fromDate = props.fromDate
    ? DateTime.fromFormat(props.fromDate, dateFormat).toJSDate()
    : undefined;
  const toDate = props.toDate
    ? DateTime.fromFormat(props.toDate, dateFormat).toJSDate()
    : undefined;

  return (
    <label
      css={css`
        width: 100%;
      `}
      className={props.className}
    >
      <InputLabel label={props.label} />

      <DatePick
        placeholderText={t("actions.select-date")}
        selected={props.date}
        onChange={(date: Date) => {
          const formattedDate = DateTime.fromJSDate(date).toFormat(dateFormat);
          props.onFilterChange(formattedDate);
        }}
        selectsStart
        startDate={fromDate}
        endDate={toDate}
        customInput={<CustomDatePicker />}
      />
    </label>
  );
}
