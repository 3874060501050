import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { css } from "styled-components/macro";
import { customToast } from "../../../components/customToast";
import { theme } from "../../../themes/variables";
import { createAnnouncement } from "../../../actions/admin/announcements/createAnnouncement";
import { updateAnnouncement } from "../../../actions/admin/announcements/updateAnnouncement";
import { deleteAnnouncement } from "../../../actions/admin/announcements/deleteAnnouncement";
import { Announcement } from "../../../types/admin/Announcement";
import { Header } from "../../../components/Header";
import { InputComponent } from "../../../components/InputComponent";
import { Wrapper } from "../../../components/Wrapper";
import { Button } from "../../../components/Button";
import AnnouncementsTable from "../../../components/Admin/Announcements/AnnouncementsTable";
import { ActionModal } from "../../../components/ActionModal";
import AnnouncementDetailsModal from "../../../components/Admin/Announcements/AnnouncementDetailsModal";
import { ReactComponent as SearchSvg } from "../../../assets/icons/Search.svg";
import { ReactComponent as PlusSvg } from "../../../assets/icons/Plus.svg";
import { FormValues } from "../../../forms/admin/announcements/EditCreateAnnouncement";
import { Footer } from "../../../components/Footer";

export default function AnnouncementsPage() {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);

  const [query, setQuery] = useState(params.get("query") || undefined);

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedAnnouncement, setselectedAnnouncement] = useState<
    Announcement | undefined
  >(undefined);

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const onCreateAnnouncement = async (values: FormValues) => {
    await createAnnouncement({ data: values });
    ref.current.refetch();

    setModal(false);
    setselectedAnnouncement(undefined);
    customToast.success(t("status.success"));
  };

  const onUpdateAnnouncement = async (values: FormValues) => {
    if (selectedAnnouncement && selectedAnnouncement.uuid) {
      await updateAnnouncement({
        uuid: selectedAnnouncement.uuid,
        data: values,
      });
      ref.current.refetch();
    }

    setModal(false);
    setselectedAnnouncement(undefined);
    customToast.success(t("status.success"));
  };

  const onDeleteAnnouncement = async () => {
    if (selectedAnnouncement?.uuid) {
      await deleteAnnouncement({
        uuid: selectedAnnouncement?.uuid,
      });
      ref.current.refetch();
    }

    setDeleteModal(false);
    setselectedAnnouncement(undefined);
    customToast.success(t("status.success"));
  };

  return (
    <Wrapper>
      <Helmet title={t("announcements.announcements")} />
      <Header />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 60px;
          background-color: ${theme.colors.white};
          border-radius: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <div
            css={css`
              font-size: 30px;
              line-height: 42px;
            `}
          >
            {t("announcements.announcements")}
          </div>

          <div
            css={css`
              margin: 0 40px 0 auto;
            `}
          >
            <InputComponent
              css={css`
                min-width: 288px;
                min-height: 40px;
              `}
              value={query}
              placeholder={t("announcements.search")}
              icon={<SearchSvg width={20} height={20} />}
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
          <div
            css={css`
              margin-right: 0;
            `}
          >
            <Button
              css={css`
                min-height: 40px;
              `}
              iconRight
              icon={<PlusSvg width={13} height={13} />}
              background={theme.colors.black}
              onClick={() => setModal(true)}
            >
              {t("announcements.new")}
            </Button>
          </div>
        </div>
        <AnnouncementsTable
          forwardRef={ref}
          onSetModal={(modal: boolean) => setModal(modal)}
          onSetDeleteModal={(modal: boolean) => setDeleteModal(modal)}
          onSelectAnnouncement={(announcement: Announcement) =>
            setselectedAnnouncement(announcement)
          }
        />
        {modal && (
          <AnnouncementDetailsModal
            announcement={
              selectedAnnouncement ? selectedAnnouncement : undefined
            }
            isActive={modal}
            onClose={() => {
              setModal(false);
              setselectedAnnouncement(undefined);
            }}
            onSubmit={
              selectedAnnouncement ? onUpdateAnnouncement : onCreateAnnouncement
            }
          />
        )}
        {deleteModal && selectedAnnouncement && (
          <ActionModal
            deleteModal={deleteModal}
            setActionModal={(modal: boolean) => setDeleteModal(modal)}
            contentBody={t("announcements.delete_modal_text")}
            actionButtonText={t("actions.delete")}
            onConfirm={onDeleteAnnouncement}
          />
        )}
      </div>
      <Footer />
    </Wrapper>
  );
}
