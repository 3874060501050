import { authorised } from "../../request";
import { SelfAssessmentPerformance } from "../../types/SelfAssessment";

export async function getSelfAssessmentsSignUpPerformance() {
  const API = authorised();

  return API.request<SelfAssessmentPerformance[]>({
    method: "GET",
    url: `/api/enterprise/self-assessments/performance/`,
  });
}
