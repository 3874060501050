import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { css } from "styled-components/macro";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { customToast } from "../../components/customToast";
import axios from "axios";
import { SignupField, SignupFieldType } from "../../types/admin/SignupField";
import { useAuth } from "../../contexts/UserContext";
import { CheckboxField } from "../fields/CheckboxField";
import { InputField } from "../fields/InputField";
import { TextareaField } from "../fields/TextareaField";
import { SelectField } from "../fields/SelectField";
import { RadioFields } from "../fields/RadioFields";
import { CheckboxFields } from "../fields/CheckboxFields";
import { Button } from "../../components/Button";
import { theme } from "../../themes/variables";
import { Column, Row, Spacing } from "../../helpers/layout";
import { pageLoading } from "../../helpers/pageLoading";

import { FormValues, registration } from "../../actions/auth/registration";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
import PasswordInstructions from "../../components/PasswordInstructions";
import { useEnterprise } from "../../contexts/EnterpriseContext";
import {
  getSignupFieldsInitialEmptyValues,
  getSignupFieldsSchema,
  getSignupFieldsServerValues,
} from "../../helpers/signupFields";

export function RegistrationForm() {
  const { authenticate } = useAuth();
  const { enterprise } = useEnterprise();
  const history = useHistory();
  const location = useLocation();
  const re = new RegExp("[a-zA-Z-]");
  const passwordRe = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%^*+,.:;<=>?({//\\\\})\\[\\]`~|\"'_-])(?=.{9,150})"
  );
  const { t } = useTranslation();

  const [signupFields, setSignupFields] = useState<SignupField[] | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const getSignupFields = async () => {
      if (!isLoading && !signupFields) {
        try {
          setLoading(true);
          const { data: signupFields } = await axios.get<SignupField[]>(
            "/api/studio/signup-field/",
            {
              baseURL: process.env.REACT_APP_BACKEND_URL,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "accept-language": process.env.REACT_APP_LANGUAGE,
              },
            }
          );

          setSignupFields(signupFields);
        } catch (e) {
          customToast.error(
            t("status.error", {
              error: serverErrorHandler(e),
            })
          );
        } finally {
          setLoading(false);
        }
      }
    };

    getSignupFields();
  }, [isLoading, signupFields, t]);

  if (!signupFields) {
    return pageLoading("");
  }

  const dynamicSchema = getSignupFieldsSchema(signupFields, t);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().matches(re).required(),
    last_name: Yup.string().matches(re).required(),
    email: Yup.string().email().required(),
    password: Yup.string().matches(passwordRe).required(),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("auth.pwd-not-match"))
      .required(t("auth.pwd-not-match")),
    checkbox: Yup.boolean().oneOf([true], t("auth.accept-checkbox")).required(),
    signup_fields: Yup.object().shape(dynamicSchema),
  });

  const initialValues: FormValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password2: "",
    signup_fields: getSignupFieldsInitialEmptyValues(signupFields),
  };

  return (
    <div>
      <Formik<FormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            /* Checkbox fields make array of strings */

            const signupFieldsValues = getSignupFieldsServerValues(
              values.signup_fields,
              signupFields
            );

            setSubmitting(true);

            const response = await registration({
              ...values,
              signup_fields: signupFieldsValues,
            });

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.email);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);

              history.push("/self-assessment");
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form noValidate>
              <Column gutter={Spacing.m}>
                <Row gutter={Spacing.none} justify="flex-start">
                  <InputField
                    name="first_name"
                    type="text"
                    placeholder={t("auth.first-name")}
                    css={css`
                      width: 100%;
                    `}
                  />
                  <InputField
                    name="last_name"
                    type="text"
                    placeholder={t("auth.last-name")}
                    css={css`
                      width: 100%;
                      margin-left: 8px;
                    `}
                  />
                </Row>
                <InputField
                  name="email"
                  type="email"
                  placeholder={t("auth.email")}
                />
                <InputField
                  hideTooltip
                  name="password"
                  type="password"
                  placeholder={t("auth.password")}
                />
                <InputField
                  name="password2"
                  type="password"
                  placeholder={t("auth.repeat-password")}
                  css={css`
                    border: none;
                  `}
                />

                <PasswordInstructions password={values.password} />

                {signupFields.map((field) => {
                  return (
                    <React.Fragment key={field.order}>
                      {field.type === SignupFieldType.TEXT_FIELD && (
                        <InputField
                          label={field.name}
                          name={`signup_fields[${field.uuid}][0]`}
                          hint={field.hint || ""}
                          placeholder={field.placeholder || ""}
                        />
                      )}
                      {field.type === SignupFieldType.TEXT_AREA && (
                        <TextareaField
                          label={field.name}
                          name={`signup_fields[${field.uuid}][0]`}
                          hint={field.hint || ""}
                          placeholder={field.placeholder || ""}
                        />
                      )}
                      {field.type === SignupFieldType.SELECT && (
                        <SelectField
                          label={field.name}
                          name={`signup_fields[${field.uuid}][0]`}
                          hint={field.hint || ""}
                          choices={field.options.map((option) => {
                            return {
                              label: option.value,
                              value: option.value,
                            };
                          })}
                        />
                      )}
                      {field.type === SignupFieldType.RADIO && (
                        <RadioFields
                          label={field.name}
                          labelRight
                          name={`signup_fields[${field.uuid}][0]`}
                          hint={field.hint || ""}
                          choices={field.options.map((option) => {
                            return {
                              label: option.value,
                              value: option.value,
                            };
                          })}
                        />
                      )}
                      {field.type === SignupFieldType.CHECKBOX && (
                        <CheckboxFields
                          label={field.name}
                          name={`signup_fields[${field.uuid}]`}
                          hint={field.hint || ""}
                          choices={field.options.map((option) => {
                            return {
                              label: option.value,
                              value: String(option.id),
                            };
                          })}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Column>

              <CheckboxField
                name="checkbox"
                css={css`
                  margin-top: 40px;
                `}
              >
                {
                  <div
                    css={css`
                      width: 100%;
                      font-size: 14px;
                      margin-right: 40px;
                      a {
                        font-weight: bold;
                        :hover {
                          text-decoration: underline;
                        }
                      }
                    `}
                  >
                    {t("auth.agree")}&nbsp;
                    <a
                      href={enterprise.agb_link!}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("auth.terms-of-service")}
                    </a>
                    &nbsp;{t("auth.acknowledge")}&nbsp;
                    <a
                      href={enterprise.data_protection_link!}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("auth.privacy-policy")}
                    </a>
                  </div>
                }
              </CheckboxField>
              <div
                css={css`
                  margin-top: 40px;
                  display: flex;
                  flex-direction: column;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  color={theme.colors.primary}
                  disabled={!isValid}
                  css={css`
                    margin: 0 auto;
                    color: ${theme.colors.white};
                    font-weight: 500;
                  `}
                >
                  {t("auth.register")}
                </Button>

                <div
                  css={css`
                    display: flex;
                    justify-content: center;
                    margin-top: 40px;
                    margin-bottom: 10px;
                    font-weight: 500;
                  `}
                >
                  {t("auth.or")}
                </div>
                <Link
                  css={css`
                    display: flex;
                    text-decoration: underline;
                    justify-content: center;
                    font-size: 20px;
                    font-weight: 500;
                  `}
                  to={`/login${location.search}`}
                >
                  {t("auth.login")}
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
