import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "../customToast";
import * as Yup from "yup";
import { InputField } from "../../forms/fields/InputField";
import { RadioField } from "../../forms/fields/RadioField";
import { Button } from "../Button";
import { theme } from "../../themes/variables";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import {
  SelfAssessment,
  SelfAssessmentAnswer,
  SelfAssessmentType,
} from "../../types/SelfAssessment";
import { AnswerContent } from "./AnswerContent";

export function SectionWrapper(props: {
  label: string;
  content?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <section
      css={css`
        margin-bottom: 2rem;
      `}
    >
      <div
        css={css`
          margin-bottom: 1rem;
        `}
      >
        {props.label}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: ${props.content || "space-between"};
        `}
      >
        {props.children}
      </div>
    </section>
  );
}

export interface FormValues {
  title: string;
  type: SelfAssessmentType;
  answers: SelfAssessmentAnswer[];
}

interface SelfAssessmentData {
  initialData?: SelfAssessment | null;
  onSubmitCallback: (values: FormValues) => Promise<void>;
  nameMaxLength?: number;
}

export function EditCreateSelfAssessment({
  initialData,
  onSubmitCallback,
  nameMaxLength = 150,
}: SelfAssessmentData) {
  const { t } = useTranslation();

  const questionTypeFields: { label: string; value: SelfAssessmentType }[] = [
    {
      label: t("self-assessment.open"),
      value: SelfAssessmentType.OPEN_QUESTION,
    },
    {
      label: t("self-assessment.single"),
      value: SelfAssessmentType.SINGLE_CHOICE,
    },
    {
      label: t("self-assessment.multiple"),
      value: SelfAssessmentType.MULTIPLE_CHOICE,
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(nameMaxLength)
      .label(t("self-assessment.title"))
      .required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        type: values.type,
        title: values.title,
        answers: values.answers,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        type: initialData?.type || SelfAssessmentType.OPEN_QUESTION,
        title: initialData?.title || "",
        answers: initialData?.answers || [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form
          noValidate
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              width: 50%;
            `}
          >
            <InputField
              label={t("self-assessment.title")}
              name={"title"}
              maxLength={nameMaxLength}
            />
            <SectionWrapper label={t("self-assessment.type")}>
              {questionTypeFields.map((questionType, idx) => (
                <RadioField
                  labelRight
                  name="type"
                  label={questionType.label}
                  value={questionType.value}
                  key={idx}
                />
              ))}
            </SectionWrapper>

            {values.type !== SelfAssessmentType.OPEN_QUESTION && (
              <AnswerContent name="answers" type={values.type} />
            )}
          </div>
          <div
            css={css`
              margin-top: 35px;
              display: flex;
              align-self: flex-end;
            `}
          >
            <div
              css={css`
                text-align: right;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                type="submit"
                disabled={!isValid}
              >
                {t(!!initialData ? "actions.update" : "actions.create")}
              </Button>

              {!isValid && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    color: ${theme.colors.redMain};
                    margin-top: 10px;
                  `}
                >
                  {t("status.error-fill")}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
