import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  data: SelfAssessment;
};

export async function createSignUpSelfAssessment({ data }: Args) {
  const API = authorised();

  return API.request<SelfAssessment>({
    method: "POST",
    url: `/api/enterprise/self-assessments/`,
    data,
  });
}
