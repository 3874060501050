import { authorised } from "../../../request";
import { EnterpriseContact } from "../../../types/Enterprise";

export async function updateEnterpriseContactSettings(
  settings: EnterpriseContact
) {
  const API = authorised();

  return await API.request<EnterpriseContact>({
    method: "PUT",
    url: `/api/enterprise/settings/contact/`,
    data: settings,
  });
}
