import { authorised } from "../../request";
import { AdditionalMaterial } from "../../types/AdditionalMaterial";

type Args = {
  courseId: string;
  additionalMaterialId: string;
}

export async function deleteAdditionalMaterial({courseId, additionalMaterialId}: Args) {
  const API = authorised();

  return API.request<AdditionalMaterial>({
    method: "DELETE",
    url: `/api/studio/courses/${courseId}/additional-materials/${additionalMaterialId}/`,
  });
}
