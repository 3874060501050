import { authorised } from "../../../request";
import { Order } from "../../../types/admin/Order";

type Args = {
  uuid: string;
  status: string;
};

export async function updateOrderStatus(data: Args) {
  const API = authorised();

  return await API.request<Order>({
    method: "PUT",
    url: `/api/enterprise/orders/${data.uuid}/status/`,
    data: data,
  });
}
