import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { Search } from "react-feather";
import { theme } from "../themes/variables";
import { Button } from "./Button";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "./Modal";
import { InputComponent } from "./InputComponent";
import { CheckboxComponent } from "./CheckboxComponent";
import { useTranslation } from "react-i18next";
import { RequiredDataType } from "../types/RequiredDataType";
import { xorBy } from "lodash";

export function EntitySelectionModal<T extends RequiredDataType>(props: {
  show: boolean;
  searchCallback: (searchText: string) => Promise<T[]>;
  selectedItems: T[];
  disabledItems?: T[];
  emptyMessage?: string;
  onClose: () => void;
  onConfirm: (selectedItems: T[]) => void;
}) {
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const [items, setItems] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] = useState<T[]>(props.selectedItems);
  const searchCallback = props.searchCallback;

  const onClose = () => {
    setSearchText("");
    props.onClose();
  };

  const onConfirm = () => {
    setSearchText("");
    props.onConfirm(selectedItems);
  };

  useEffect(() => {
    searchCallback(searchText).then((res) => {
      setItems(res);
    });
  }, [searchCallback, searchText]);

  return (
    <Modal modalIsOpen={props.show} onClose={onClose}>
      <ModalHeader>{t("entity-selection.add-new")}</ModalHeader>

      <ModalBody>
        <div>
          <InputComponent
            icon={<Search size={15} />}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {items.length ? (
            items.map((el, idx) => {
              return (
                <div
                  css={css`
                    width: 100%;
                    padding: 1rem 0;
                    border-bottom: 1px solid ${theme.colors.gray4};
                  `}
                  key={idx}
                >
                  <CheckboxComponent
                    label={el.label}
                    name={`items[${el.id}]`}
                    onChange={(e) => {
                      setSelectedItems((selectedItems) =>
                        xorBy(selectedItems, [el], (el) => el.id)
                      );
                    }}
                    value={!!selectedItems.find((e) => e.id === el.id)}
                    disabled={
                      props.disabledItems &&
                      !!props.disabledItems.find((e) => e.id === el.id)
                    }
                  />
                </div>
              );
            })
          ) : props.emptyMessage ? (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <p>{props.emptyMessage}</p>
            </div>
          ) : null}
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
          onClick={onClose}
        >
          {t("actions.cancel")}
        </Button>

        <Button onClick={onConfirm}>{t("actions.select")}</Button>
      </ModalFooter>
    </Modal>
  );
}
