import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { Wrapper } from "../../../../components/Wrapper";
import { Header } from "../../../../components/Header";
import { Block } from "../../../../components/Block";
import { Tabs } from "../../../../components/Tabs";
import { Breadcrumbs } from "../../../../components/Breadcrumbs";
import { SEO } from "../../../../components/SEO";
import { Headers } from "../../../../helpers/layout";

import { useNewFetch } from "../../../../useAPI";
import { getLesson } from "../../../../actions/lesson";
import { pageLoading } from "../../../../helpers/pageLoading";
import { PerformancePeriod } from "../../../../actions/performance/getCoursePerformanceChart";
import { SelectComponent } from "../../../../components/SelectComponent";
import { TestPerformanceChart } from "./Chart";
import { getTestPerformance } from "../../../../actions/test/getTestPerformance";
import { TestPerformanceWidgets } from "./Widgets";
import { Footer } from "../../../../components/Footer";

export function TestPerformancePage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
    testId: string;
  }>();

  const [period, setPeriod] = useState<PerformancePeriod>(
    PerformancePeriod.WEEK
  );

  const { data: lesson, error: lessonError } = useNewFetch(getLesson, {
    courseId: params.courseId,
    lessonId: params.lessonId,
  });

  const { data: test, error: testError } = useNewFetch(getTestPerformance, {
    period: period,
    lessonId: params.lessonId,
    testId: params.testId,
  });

  if (!lesson || lessonError) {
    return pageLoading(lessonError);
  }

  if (!test || testError) {
    return pageLoading(testError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.content_editor"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/content_editor`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/edit`,
    },
    {
      title: t("breadcrumbs.testing"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/testing`,
      forceHightlight: true,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: lesson.course!.name,
      url: `/courses/${params.courseId}/syllabus`,
    },
  ];

  const periods: { label: string; value: PerformancePeriod }[] = [
    {
      label: t("period.last-week"),
      value: PerformancePeriod.WEEK,
    },
    {
      label: t("period.month"),
      value: PerformancePeriod.MONTH,
    },
    {
      label: t("period.quarter"),
      value: PerformancePeriod.QUARTER,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{test.internal_name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{lesson.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              flex-flow: row wrap;
              margin-bottom: 30px;
              align-items: flex-start;
            `}
          >
            <div
              css={css`
                flex: 1 0 20%;
              `}
            >
              <Headers.H3>{test.internal_name}</Headers.H3>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                padding-top: 7px;
                margin-left: 50px;
              `}
            >
              <span
                css={css`
                  margin-right: 8px;
                `}
              >
                {t("period.title")}:{" "}
              </span>

              <SelectComponent
                small
                value={period}
                choices={periods}
                onChange={(e) => setPeriod(e.target.value as PerformancePeriod)}
              />
            </div>
          </div>
          <div
            css={css`
              margin-bottom: 70px;
              margin-top: 70px;
            `}
          >
            <TestPerformanceWidgets
              data={{
                totalSubmissions: test.total_submissions,
                successRate: test.avg_percent_passed_tests,
                successRateFirstAttempt:
                  test.avg_percent_passed_tests_first_attempt,
              }}
            />
          </div>
          <TestPerformanceChart period={period} data={test} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
