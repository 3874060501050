import React from "react";
import { css } from "styled-components/macro";
import { Field, FieldProps } from "formik";
import { UserChose } from "../../components/Admin/UserChose";
import { TargetUserProfile } from "../../types/admin/UserProfile";

import { theme } from "../../themes/variables";

export function UserChoseField(props: {
  name: string;
  searchType: "CAMPUS" | "STUDIO";
  label?: string;
  hint?: string;
  className?: string;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { name, label, hint, searchType, className, validate } = props;

  return (
    <Field name={name} validate={validate}>
      {({ field, meta, form }: FieldProps) => {
        const users: TargetUserProfile[] = field.value;
        return (
          <>
            <UserChose
              label={label}
              hint={hint}
              searchType={searchType}
              chosenUsers={users}
              onChose={(user) => form.setFieldValue(name, users.concat([user]))}
              onRemove={(user) =>
                form.setFieldValue(
                  name,
                  users.filter((u) => u.id !== user.id)
                )
              }
              className={className}
            />
            {meta.error && meta.touched && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                  margin-left: 25px;
                `}
              >
                {meta.error}
              </div>
            )}
          </>
        );
      }}
    </Field>
  );
}
