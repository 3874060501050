import { useMemo, useState } from "react";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { ReactComponent as UpIcon } from "../assets/icons/Up.svg";
import { ReactComponent as DownIcon } from "../assets/icons/Down.svg";
import { ReactComponent as AnswerIcon } from "../assets/icons/Answer.svg";
import {
  SelfAssessmentPerformance,
  SelfAssessmentType,
} from "../types/SelfAssessment";
import { useTranslation } from "react-i18next";

const elementsToShow = 3;

const randomColor = [
  theme.colors.lightRedMain,
  theme.colors.blueBackground,
  theme.colors.violetMain,
  theme.colors.primary,
];

function AnswerBar(props: {
  title: string;
  votes: number;
  total: number;
  type: SelfAssessmentType;
  color: string;
}) {
  const userVotes = Math.round((props.votes * 100) / props.total);

  return (
    <div
      css={css`
        margin-bottom: 20px;

        :last-child {
          margin-bottom: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          align-items: baseline;
        `}
      >
        <div
          css={css`
            display: flex;
            width: 80%;
          `}
        >
          {props.title}
        </div>
        <UserVotes user_votes={props.votes} total_votes={props.total} />
      </div>
      <div
        css={css`
          width: 100%;
          height: 8px;
          background-color: ${theme.colors.gray2};
          border-radius: 20px;
        `}
      >
        <div
          css={css`
            height: 100%;
            width: ${userVotes + "%"};
            background-color: ${props.color};
            border-radius: inherit;
          `}
        ></div>
      </div>
    </div>
  );
}

function UserVotes(props: { user_votes: number; total_votes: number }) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        font-size: 16px;
        margin: 0 0 0 auto;
      `}
    >
      <div>{props.user_votes + " " + t("self-assessment.votes")}</div>
      <div>&nbsp;{t("self-assessment.from")}&nbsp;</div>
      <div>{props.total_votes}</div>
    </div>
  );
}

function ExpandListButton(props: {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <button
      css={css`
        display: flex;
        align-items: center;
        margin: auto 0 0 auto;
        padding: 3px 20px;
        border-radius: 20px;
        border: 1px solid ${theme.colors.dark};
        background: ${theme.colors.white};
        cursor: pointer;
        font-size: 16px;
      `}
      onClick={() => props.setExpanded(!props.expanded)}
    >
      <div>
        {!props.expanded
          ? t("self-assessment.expand")
          : t("self-assessment.collapse")}
      </div>

      {!props.expanded ? (
        <DownIcon
          css={css`
            margin-left: 10px;
          `}
          height={14}
          width={14}
        />
      ) : (
        <UpIcon
          css={css`
            margin-left: 10px;
          `}
          height={14}
          width={14}
        />
      )}
    </button>
  );
}

export default function SelfAssessmentItemPerformance({
  selfAssessmentItem,
}: {
  selfAssessmentItem: SelfAssessmentPerformance;
}) {
  const [expanded, setExpanded] = useState(
    selfAssessmentItem.answers.length <= elementsToShow
  );

  const barColor = useMemo(
    () => randomColor[Math.floor(Math.random() * randomColor.length)],
    []
  );

  return (
    <div
      css={css`
        padding: 60px 60px 67px 60px;
        margin-top: 20px;
        background-color: ${theme.colors.white};
        border-radius: 20px;

        :last-child {
          margin-bottom: 100px;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <Headers.H3>{selfAssessmentItem.title}</Headers.H3>
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: min(80%, 1000px);
              margin-top: 28px;
            `}
          >
            {selfAssessmentItem.answers &&
              selfAssessmentItem.answers.map((answer, id) => {
                if (
                  selfAssessmentItem.answers.length >= elementsToShow &&
                  id >= elementsToShow &&
                  !expanded
                ) {
                  return null;
                }
                return selfAssessmentItem.type !==
                  SelfAssessmentType.OPEN_QUESTION ? (
                  <AnswerBar
                    key={id}
                    title={answer.answer}
                    votes={answer.user_answers}
                    total={selfAssessmentItem.total_answers}
                    type={selfAssessmentItem.type}
                    color={barColor}
                  />
                ) : (
                  <div
                    key={id}
                    css={css`
                      margin-bottom: 20px;
                      display: flex;
                      align-items: baseline;
                      line-height: 28px;
                      width: 100%;

                      :last-child {
                        margin-bottom: 0;
                      }
                    `}
                  >
                    <div>
                      <AnswerIcon
                        css={css`
                          margin-right: 20px;
                        `}
                      />
                    </div>
                    <div
                      css={css`
                        display: flex;
                        width: 80%;
                      `}
                    >
                      {answer.answer}
                    </div>
                    <UserVotes
                      user_votes={answer.user_answers}
                      total_votes={selfAssessmentItem.total_answers}
                    />
                  </div>
                );
              })}
          </div>

          {selfAssessmentItem.answers.length > elementsToShow && (
            <ExpandListButton
              expanded={expanded}
              setExpanded={(expanded) => setExpanded(expanded)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
