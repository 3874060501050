import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
  data: SelfAssessment;
};

export async function createSelfAssessment({ courseId, data }: Args) {
  const API = authorised();

  return API.request<SelfAssessment>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/self-assessments/`,
    data,
  });
}
