import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import {
  EditCreateLesson,
  FormSubmitValues,
} from "../../components/EditCreateLesson";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { DangerZone } from "../../components/DangerZone";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { deleteLesson } from "../../actions/lesson";
import { useNewFetch } from "../../useAPI";
import { getLesson, updateLesson } from "../../actions/lesson";
import { pageLoading } from "../../helpers/pageLoading";
import { Footer } from "../../components/Footer";

export function EditLessonPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
  }>();

  const history = useHistory();

  const { data: lesson, error: lessonError } = useNewFetch(getLesson, {
    courseId: params.courseId,
    lessonId: params.lessonId,
  });

  const [deleteModal, setDeleteModal] = useState(false);

  if (!lesson || lessonError) {
    return pageLoading(lessonError);
  }

  const onConfirmDelete = async () => {
    try {
      await deleteLesson(params.courseId, params.lessonId);
      setDeleteModal(false);
      history.push(`/courses/${params.courseId}/syllabus`);
    } catch (error) {
      customToast.error(
        t("lesson.modal.delete.error", {
          name: t("lesson.name"),
        })
      );
    }
  };

  const onSubmit = async (data: FormSubmitValues) => {
    await updateLesson(params.courseId, params.lessonId, {
      ...data,
      duration: Number(data.duration),
    });

    customToast.success(t("status.success"));
  };

  const tabsData = [
    {
      title: t("breadcrumbs.content_editor"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/content_editor`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/edit`,
    },
    {
      title: t("breadcrumbs.testing"),
      link: `/courses/${params.courseId}/lessons/${params.lessonId}/testing`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: lesson.course!.name,
      url: `/courses/${params.courseId}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{lesson.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{lesson.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateLesson
            onSubmitCallback={onSubmit}
            initialData={lesson}
            previewLessonUrl={lesson.preview_link}
          />

          <DangerZone
            css={css`
              margin-top: 5rem;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("lesson.delete")}
            </Button>

            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("lesson.modal.delete.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
