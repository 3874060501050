import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { SEO } from "../../components/SEO";
import FeedbackContainer from "../../components/LessonFeedback/FeedbackContainer";
import FeedbackFilter, {
  FilterType,
} from "../../components/LessonFeedback/FeedbackFilter";
import { theme } from "../../themes/variables";
import { FeedbackStatus, LessonFeedback } from "../../types/LessonFeedback";

import { getStudioFeedback } from "../../actions/feedback/getStudioFeedback";
import { useFilters } from "../../hooks/useFilters";
import { RouteComponentProps } from "react-router";
import { Tabs } from "../../components/Tabs";
import { Block } from "../../components/Block";
import { Footer } from "../../components/Footer";

export function StudioFeedbackPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const [feedbacks, setFeedbacks] = useState<LessonFeedback[]>([]);
  const { filters, setFilter, resetFilters } = useFilters({
    [FilterType.STATUS]: FeedbackStatus.NEW,
  });

  useEffect(() => {
    getStudioFeedback(filters).then((results) => {
      setFeedbacks(results);
    });
  }, [filters]);

  const tabsData = [
    {
      title: t("courses.title"),
      link: "/courses",
    },
    {
      title: t("header.navigation.faq"),
      link: `/studio/faq`,
    },
    {
      title: t("feedback.feedback"),
      link: "/studio/feedback",
    },
  ];

  return (
    <Wrapper>
      <SEO>
        <title>{t("feedback.feedback")}</title>
      </SEO>
      <Header />
      <Tabs tabs={tabsData} />
      <Block>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 32px;
          `}
        >
          <div
            css={css`
              font-size: 30px;
              margin-bottom: 20px;
              width: 250px;
            `}
          >
            {t("feedback.user_feedback")}
          </div>
          <FeedbackFilter
            items={feedbacks}
            activeFilters={filters}
            onFilterChange={setFilter}
            resetFilters={resetFilters}
          />
        </div>
      </Block>
      {feedbacks.length > 0 ? (
        feedbacks.map((feedback) => (
          <FeedbackContainer
            type={"studio"}
            key={feedback.uuid}
            feedback={feedback}
          />
        ))
      ) : (
        <div
          css={css`
            margin-top: 40px;
            padding: 40px;
            border-radius: 20px;
            background-color: ${theme.colors.white};
          `}
        >
          <div
            css={css`
              font-size: 30px;
              line-height: 42px;
            `}
          >
            {t("feedback.no_results_label")}
          </div>
          <div
            css={css`
              font-size: 20px;
              line-height: 30px;
            `}
          >
            {t("feedback.no_results_text")}
          </div>
        </div>
      )}
      <Footer />
    </Wrapper>
  );
}
