import React from "react";
import { css } from "styled-components/macro";
import { ChevronLeft, ChevronRight } from "react-feather";
import { theme } from "../themes/variables";

const offset = 3;

function PaginationItem(props: {
  current?: boolean;
  children: string | React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      css={css`
        cursor: pointer;
        display: flex;
        position: relative;
        color: ${theme.colors.gray4};
        ${props.current &&
        css`
          color: ${theme.colors.dark};
          border-bottom: 2px solid ${theme.colors.primary};
        `}
        ${props.disabled &&
        css`
          pointer-events: none;
          opacity: 0;
        `}
      `}
      onClick={props.onClick}
      className={
        props.current
          ? "pagination__item pagination__item__current"
          : "pagination__item"
      }
    >
      {props.children}
    </div>
  );
}

export function Pagination(props: {
  infinite?: boolean;
  currentItemIndex: number;
  itemsCount: number;
  arrows?: boolean;
  onNext?: () => void;
  onPrev?: () => void;
  onChose?: (index: number) => void;
  className?: string;
}) {
  const {
    currentItemIndex,
    itemsCount,
    onNext,
    onPrev,
    onChose = () => {},
    className,
    infinite,
    arrows,
  } = props;

  if (itemsCount <= 1) {
    return null;
  }

  const arr = Array(itemsCount)
    .fill(null)
    .map((_, i) => i);

  let wasShortenedLeft = false;
  let wasShortenedRight = false;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      className={className}
    >
      {arrows && (
        <PaginationItem
          onClick={onPrev}
          disabled={!(infinite || currentItemIndex > 0)}
        >
          <ChevronLeft
            size={20}
            color={theme.colors.dark}
            css={css`
              cursor: pointer;
              opacity: 0.7;
              :hover {
                opacity: 1;
              }
            `}
          />
        </PaginationItem>
      )}

      <ul
        css={css`
          display: flex;
          align-items: center;
          list-style-type: none;
          margin: 0;
          padding: 0;
        `}
      >
        {arr.map((el) => {
          const canBeShort = arr.length > 5;
          const shortLeft =
            el > 0 && currentItemIndex - offset - 1 > el && el < arr.length - 1;
          const shortRight =
            el > 0 && currentItemIndex + offset - 1 < el && el < arr.length - 1;
          if (canBeShort && shortRight) {
            if (wasShortenedRight) return null;

            wasShortenedRight = true;

            return "...";
          }

          if (canBeShort && shortLeft) {
            if (wasShortenedLeft) return null;

            wasShortenedLeft = true;

            return "...";
          }

          return (
            <li
              key={el}
              css={css`
                line-height: 1;
                padding: 0 20px;
                font-size: 20px;
                line-height: 28px;
              `}
              onClick={() => onChose(el)}
            >
              <PaginationItem current={el === currentItemIndex}>
                {el + 1}
              </PaginationItem>
            </li>
          );
        })}
      </ul>

      {arrows && (
        <PaginationItem
          onClick={onNext}
          disabled={!(infinite || currentItemIndex < itemsCount - 1)}
        >
          <ChevronRight
            size={20}
            color={theme.colors.dark}
            css={css`
              cursor: pointer;
              opacity: 0.7;
              :hover {
                opacity: 1;
              }
            `}
          />
        </PaginationItem>
      )}
    </div>
  );
}
