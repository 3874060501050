import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Helmet } from "react-helmet";
import { useNewFetch } from "../../../useAPI";
import {
  getPublishRequestStats,
  updatePublishRequest,
} from "../../../actions/admin/publishRequest";
import { CoursePublishRequestStatus } from "../../../types/Course";
import { PublishRequest } from "../../../types/admin/PublishRequest";
import { pageLoading } from "../../../helpers/pageLoading";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { Tabs, Tab } from "../../../components/Tabs";
import RequestsTable from "../../../components/Admin/PublishRequest/RequestsTable";
import RejectModal from "../../../components/Admin/PublishRequest/RejectModal";
import { Footer } from "../../../components/Footer";

export function PublishRequestPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const [rejectModal, setRejectModal] = useState(false);

  const [rejectionReason, setRejectionReason] = useState("");

  const [publishRequestToReject, setPublishRequestToReject] =
    useState<PublishRequest | null>(null);

  const [loadingId, setLoadingId] = useState<number | null>(null);

  const {
    data: publishRequestStats,
    error: publishRequestStatsError,
    refetch: refetchStats,
  } = useNewFetch(getPublishRequestStats);

  if (!publishRequestStats || publishRequestStatsError) {
    return pageLoading(publishRequestStatsError);
  }

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  const updateStatus = async (
    id: number,
    status: CoursePublishRequestStatus,
    reason?: string
  ) => {
    setLoadingId(id);

    await updatePublishRequest({
      id,
      data: {
        status,
        rejection_reason: reason,
      },
    });

    setLoadingId(null);
    ref.current.refetch();
    refetchStats();
  };

  const openRejectionModal = (publishRequest: PublishRequest) => {
    setPublishRequestToReject(publishRequest);
    setRejectModal(true);
  };

  const closeRejectionModal = () => {
    setPublishRequestToReject(null);
    setRejectionReason("");
    setRejectModal(false);
  };

  return (
    <>
      <Wrapper>
        <Helmet title={t("admin.publish-request.page.title")} />
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={css`
                  margin-right: 24px;
                `}
              >
                <Headers.H4
                  css={css`
                    display: inline;
                    font-size: 20px;
                    line-height: 30px;
                    margin-right: 6px;
                    color: ${theme.colors.primary};
                  `}
                >
                  {publishRequestStats.pending_requests}
                </Headers.H4>

                <span
                  css={css`
                    font-size: 16px;
                    line-height: 22px;
                  `}
                >
                  {t("admin.publish-request.page.waiting")}
                </span>
              </div>
            </div>

            <div
              css={css`
                position: relative;
                min-height: 200px;
              `}
            >
              <RequestsTable
                forwardRef={ref}
                loadingId={loadingId}
                openRejectionModal={(request: PublishRequest) =>
                  openRejectionModal(request)
                }
                updateStatus={(
                  id: number,
                  status: CoursePublishRequestStatus
                ) => updateStatus(id, status)}
              />

              {publishRequestToReject && (
                <RejectModal
                  rejectModal={rejectModal}
                  publishRequestToReject={publishRequestToReject}
                  rejectionReason={rejectionReason}
                  loadingId={loadingId}
                  closeRejectionModal={() => closeRejectionModal()}
                  setRejectionReason={(response: string) =>
                    setRejectionReason(response)
                  }
                  updateStatus={(
                    id: number,
                    status: CoursePublishRequestStatus,
                    rejectionReason: string
                  ) => updateStatus(id, status, rejectionReason)}
                />
              )}
            </div>
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
