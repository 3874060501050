import { Video } from "./Video";
import { MetaCourse } from "./Course";
import { File } from "./File";
import { Block } from "./Block";
import { Instructor } from "./Instructor";
import { Skill } from "./Skill";

export type LessonBlock = {
  id: number;
  order: number;
  block: Block;
};

export type LessokSkill = {
  id?: number;
  skill: Skill;
  value: number;
};

export enum LessonType {
  TEXT = "TEXT",
  EMBED = "EMBED_VIDEO",
  CUSTOM_CODE = "CUSTOM_CODE",
  TEST = "TEST",
  CAROUSEL = "CAROUSEL",
  PODCAST = "PODCAST",
  BLOCK = "BLOCK",
}

export enum LessonIcon {
  TEXT = "TEXT",
  EMBED = "EMBED_VIDEO",
  QUIZ = "QUIZ",
  PODCAST = "PODCAST",
}

export type Podcast = {
  timecode: string;
  content: string;
};

export type Lesson = {
  uuid: string;
  name: string;
  description: string;
  duration?: number;
  tests_count?: number;
  type: LessonType;
  video: Video | null;
  video_url?: string;
  video_download_url?: string;
  audio?: File | null;
  course?: MetaCourse;
  custom_code?: string;
  lesson_blocks?: LessonBlock[];
  instructors: Instructor[];
  icon: LessonIcon;
  without_enrolment: boolean;
  skills: LessokSkill[];
  preview_link?: string;
};
