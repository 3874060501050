import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Trash2, ChevronUp, ChevronDown, Edit2 } from "react-feather";
import { theme } from "../../../../themes/variables";
import { Button } from "../../../Button";
import { Row } from "../../../../helpers/layout";
import { IconButton } from "./IconButton";

export function BlockControls(props: {
  index: number;
  reorder: (startIndex: number, endIndex: number) => void;
  setEditModal: (value: boolean) => void;
  setDeleteModal: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <Button
        small
        css={css`
          margin-right: 12px;
        `}
        background={theme.colors.transparent}
        color={theme.colors.blueMain}
        border={`1px solid ${theme.colors.blueMain}`}
        hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueMain};`}
        onClick={() => props.setEditModal(true)}
      >
        <Row>
          <span>{t("actions.edit")}</span>

          <Edit2 size={15} />
        </Row>
      </Button>

      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <IconButton
          onClick={() => {
            props.reorder(props.index, props.index - 1);
          }}
          title={t("actions.move-up")}
          css={css`
            margin-right: 12px;
            border: 1px solid black;
            border-radius: 20px;
            padding: 3px 10px 3px 10px;
            :hover {
              background: ${theme.colors.primary};
              border: 1px solid transparent;
              color: ${theme.colors.white};
            }
          `}
        >
          <ChevronUp size={25} />
        </IconButton>

        <IconButton
          onClick={() => {
            props.reorder(props.index, props.index + 1);
          }}
          title={t("actions.move-down")}
          css={css`
            margin-right: 12px;
            border: 1px solid black;
            border-radius: 20px;
            padding: 3px 10px 3px 10px;
            :hover {
              background: ${theme.colors.primary};
              border: 1px solid transparent;
              color: ${theme.colors.white};
            }
          `}
        >
          <ChevronDown size={25} />
        </IconButton>
      </div>

      <Button
        small
        background={theme.colors.transparent}
        color={theme.colors.redMain}
        border={`1px solid ${theme.colors.redMain}`}
        onClick={() => props.setDeleteModal(true)}
        hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.redMain};`}
      >
        <Row>
          <span>{t("actions.delete")}</span>

          <Trash2 size={15} />
        </Row>
      </Button>
    </div>
  );
}
