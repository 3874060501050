import { toast } from "react-toastify";
import { ReactComponent as StopIcon } from "../assets/icons/Stop.svg";
import { ReactComponent as AcceptIcon } from "../assets/icons/Checkmark.svg";
import { css } from "styled-components/macro";

const contentWrapperStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 14px;
`;

export const customToast = {
  success(msg, options = {}) {
    return toast.success(
      <div css={contentWrapperStyles}>
        <AcceptIcon width={20} height={20} style={{ marginRight: "12px" }} />
        {msg}
      </div>,
      {
        ...options,
        className: "toast-success-container toast-success-container-after",
        style: {
          backgroundColor: "#EAF7C7",
          width: "auto",
          height: "68px",
          borderRadius: "10px",
          color: "#242424",
          textAlign: "center",
          fontFamily: "Cadiz",
        },
      }
    );
  },
  error(msg, options = {}) {
    return toast.error(
      <div css={contentWrapperStyles}>
        <StopIcon width={20} height={20} style={{ marginRight: "12px" }} />
        {msg}
      </div>,
      {
        ...options,
        className: "toast-error-container toast-error-container-after",
        style: {
          backgroundColor: "#F2E7EA",
          width: "auto",
          height: "68px",
          borderRadius: "10px",
          color: "#242424",
          textAlign: "center",
          fontFamily: "Cadiz",
        },
      }
    );
  },
};
