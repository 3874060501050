import { authorised } from "../../../request";
import { UserData } from "../../../types/admin/UserProfile";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  query?: string;
  type?: "CAMPUS" | "STUDIO";
  is_verified?: "true" | "false";
};

export async function getUsers(args: Args) {
  const API = authorised();

  return await API.request<UserData>({
    method: "GET",
    url: `/api/enterprise/users/?${buildQueryString(args)}`,
  });
}
