import { authorised } from "../../../request";
import { Competency } from "../../../types/admin/Competency";

type Args = {
  competencyId: string | number;
}

export async function getCompetency({competencyId}: Args) {
  const API = authorised();

  return API.request<Competency>({
    method: "GET",
    url: `/api/enterprise/competencies/${competencyId}/`
  });
}
