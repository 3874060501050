import { useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { useNewFetch } from "../../../useAPI";
import { getOrderDetail } from "../../../actions/admin/orders/getOrderDetail";
import { updateOrderStatus } from "../../../actions/admin/orders/updateOrderStatus";
import { pageLoading } from "../../../helpers/pageLoading";
import { theme } from "../../../themes/variables";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import OrderDetailTable from "../../../components/Admin/Orders/OrderDetailTable";
import OrdersAction from "../../../components/Admin/Orders/OrdersAction";
import OrdersUserCard from "../../../components/Admin/Orders/OrdersUserCard";
import { Header } from "../../../components/Header";
import { Wrapper } from "../../../components/Wrapper";
import { ActionModal } from "../../../components/ActionModal";
import { Footer } from "../../../components/Footer";

type Status = {
  uuid: string;
  status: string;
};

export default function OrderDetailsPage() {
  const params = useParams<{
    order_id: string;
  }>();

  const { t } = useTranslation();
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [currentStatusData, setCurrentStatusData] = useState<Status>();

  const {
    data: order,
    error: orderError,
    refetch,
  } = useNewFetch(getOrderDetail, { order_id: params.order_id });

  if (!order || orderError) {
    pageLoading(orderError);
  }

  const onChangeStatus = async () => {
    try {
      if (currentStatusData) {
        await updateOrderStatus(currentStatusData);

        refetch();
        customToast.success(t("status.success"));
      }
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setChangeStatusModal(false);
      setCurrentStatusData(undefined);
    }
  };

  return (
    <Wrapper>
      <Helmet title={t("orders.order") + " #" + order?.invoice_number} />
      <Header />

      {order && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            background-color: ${theme.colors.white};
            border-radius: 20px;
            padding: 64px 60px;
            position: relative;
          `}
        >
          <OrdersAction
            order={order}
            onChangeStatus={(uuid: string, status: string) => {
              setCurrentStatusData({ uuid: uuid, status: status });
              setChangeStatusModal(true);
            }}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <OrdersUserCard order={order} />
            <OrderDetailTable order={order} />
          </div>
        </div>
      )}
      {changeStatusModal && currentStatusData && (
        <ActionModal
          deleteModal={changeStatusModal}
          setActionModal={(modal: boolean) => setChangeStatusModal(modal)}
          contentLabel={t("orders.change_action")}
          contentBody={t("orders.change_text")}
          actionButtonText={t("actions.confirm")}
          onConfirm={onChangeStatus}
        />
      )}
      <Footer />
    </Wrapper>
  );
}
