import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Wrapper } from "../components/Wrapper";
import { Header } from "../components/Header";
import { Block } from "../components/Block";
import { Tabs } from "../components/Tabs";
import { EditOrganisation, FormValues } from "../components/EditOrganisation";
import { SEO } from "../components/SEO";
import { Headers } from "../helpers/layout";

import { Permission } from "../types/UserProfile";
import { useAuth } from "../contexts/UserContext";

import { useNewFetch } from "../useAPI";
import { getOrganisation, updateOrganisation } from "../actions/organisation";
import { pageLoading } from "../helpers/pageLoading";
import { Footer } from "../components/Footer";

export function OrganisationPage(props: {}) {
  const { t } = useTranslation();
  const { hasOrganisationPermission } = useAuth();

  const { data: organisation, error: organisationError } =
    useNewFetch(getOrganisation);

  if (!organisation || organisationError) {
    return pageLoading(organisationError);
  }

  const onSubmit = async (data: FormValues) => {
    await updateOrganisation(data);
  };

  const tabsData = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/organisation`,
    },
  ];

  if (hasOrganisationPermission(Permission.ORGANISATION_MANAGE_USERS)) {
    tabsData.push({
      title: t("breadcrumbs.users"),
      link: `/organisation/users`,
    });
    tabsData.push({
      title: t("organisation.invites"),
      link: `/organisation/invites`,
    });
    tabsData.push({
      title: t("organisation.experts"),
      link: `/organisation/experts`,
    });
  }

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{organisation.name}</title>
        </SEO>
        <Header />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{organisation.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditOrganisation
            onSubmitCallback={onSubmit}
            initialData={organisation}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
