import { authorised } from "../../request";
import { Test } from "../../types/Test";

type Args = {
  lessonId: string;
  testId: string;
  data: Test;
};  

export async function updateTest({lessonId, testId, data}: Args) {
  const API = authorised();

  return API.request<Test>({
    method: "PUT",
    url: `/api/studio/lessons/${lessonId}/tests/${testId}/`,
    data
  });
}
