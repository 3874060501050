import React from "react";
import { css } from "styled-components/macro";
import { Field, FieldProps } from "formik";
import { TextareaComponent } from "../../components/TextareaComponent";
import { InputErrors } from "./InputErrors";
import { theme } from "../../themes/variables";

export function TextareaField(props: {
  name: string;
  id?: string;
  label?: string;
  description?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  hideTooltip?: boolean;
  className?: string;
  errorBottom?: boolean;
  validate?: (value: string) => Promise<void | string>;
}) {
  const {
    id,
    name,
    label,
    disabled,
    readonly,
    placeholder,
    maxLength,
    className,
    errorBottom,
    validate,
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <div>
            <TextareaComponent
              id={id}
              name={name}
              label={label}
              description={props.description}
              disabled={disabled}
              readonly={readonly}
              placeholder={placeholder}
              field={field}
              className={className}
              error={!!meta.error && meta.touched}
            />
            {!props.hideTooltip && (
              <InputErrors
                currentLength={String(field.value).length}
                maxLength={maxLength}
                error={meta.error}
                touched={meta.touched}
                css={css`
                  top: -180px;
                `}
              />
            )}
            {errorBottom && meta.error && meta.touched && (
              <div
                css={css`
                  color: ${theme.colors.error};
                  font-size: 16px;
                  margin-left: 25px;
                  margin-top: -32px;
                `}
              >
                {meta.error}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
