import { TFunction } from "i18next";
import { SignupField, SignupFieldType } from "../types/admin/SignupField";
import * as Yup from "yup";
import { SignupFieldValue } from "../types/SignupField";
import { reduce } from "lodash";

type BooleanObject = { [x: string]: boolean };

export const getSignupFieldsSchema = (
  signupFields: SignupField[],
  t: TFunction
) => {
  const fields = signupFields.reduce(
    (fields: { [key: string]: any }, field) => {
      if (field.required) {
        if (field.type === SignupFieldType.CHECKBOX) {
          fields[field.uuid] = Yup.object().test(
            "oneOfRequired",
            t("validation.oneOfRequired"),
            (value: BooleanObject) => {
              return Object.values(value).some(Boolean);
            }
          );
        } else {
          fields[field.uuid] = Yup.array()
            .of(Yup.string())
            .label(field.name)
            .required();
        }
      }

      return fields;
    },
    {}
  );

  return fields;
};

export const getSignupFieldsInitialValues = (
  signup_field_values: SignupFieldValue[]
) => {
  return signup_field_values.reduce((acc, val) => {
    if (val.signup_field.type === SignupFieldType.CHECKBOX) {
      acc[val.signup_field.uuid] = val.signup_field.options.reduce(
        (acc, el) => {
          const isChecked = val.values.includes(el.value);

          acc[el.id] = isChecked;

          return acc;
        },
        {} as {
          [key: string]: boolean;
        }
      );
    } else {
      acc[val.signup_field.uuid] = val.values;
    }

    return acc;
  }, {} as { [key: string]: any });
};

export const getSignupFieldsServerValues = (
  values: {
    [key: string]: any;
  },
  signupFields: SignupField[]
) => {
  return reduce(
    values,
    (serverValues: { [key: string]: string[] }, value, key) => {
      const field = signupFields.find((field) => field.uuid === key);

      if (!field) return serverValues;

      let newValue = [""];

      if (field.type === SignupFieldType.CHECKBOX) {
        newValue = reduce(
          value,
          (checkboxValue: string[], checked, key) => {
            if (!checked) return checkboxValue;

            const option = field.options.find(
              (option) => option.id === Number(key)
            );

            if (option) {
              checkboxValue.push(option.value);
            }

            return checkboxValue;
          },
          []
        );
      } else {
        newValue = value;
      }

      serverValues[key] = newValue;

      return serverValues;
    },
    {}
  );
};

export function getSignupFieldsInitialEmptyValues(signupFields: SignupField[]) {
  return signupFields.reduce((values, field) => {
    if (
      field.type === SignupFieldType.TEXT_FIELD ||
      field.type === SignupFieldType.TEXT_AREA
    ) {
      values[field.uuid] = [""];
    } else if (
      field.type === SignupFieldType.RADIO ||
      field.type === SignupFieldType.SELECT
    ) {
      values[field.uuid] = [""];
    } else if (field.type === SignupFieldType.CHECKBOX) {
      values[field.uuid] = field.options.reduce((acc, option) => {
        acc[option.id] = false;

        return acc;
      }, {} as BooleanObject);
    }

    return values;
  }, {} as { [key: string]: any });
}
