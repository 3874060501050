import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useLocation, useHistory } from "react-router-dom";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { ChevronDown } from "react-feather";

export type DropdownTab = {
  title: string;
  link: string;
};

export type Tab = {
  title: string;
  link?: string;
  forceHightlight?: boolean;
  notification?: number;
  dropDown?: boolean;
  dropDownContent?: DropdownTab[];
};

export function Tabs(props: { tabs: Tab[]; className?: string }) {
  const location = useLocation();

  const history = useHistory();
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const getTabsBorder = (link: string, forceHightlight: boolean = false) => {
    if (link === location.pathname || forceHightlight) {
      return `border-bottom: 3px solid ${theme.colors.primary}; font-weight: bold;`;
    }
    return "border: none;";
  };

  const onDropdownVisible = (tab: Tab) => {
    !tab.dropDown && tab.link
      ? history.push(tab.link)
      : setDropDownVisible(!dropDownVisible);
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 35px 60px;
        width: 100%;
        margin: 40px auto;
        background: ${theme.colors.white};
        max-width: 1280px;
        border-radius: 20px;
      `}
      className={props.className}
    >
      <div
        css={css`
          margin: 0 auto;
          width: 100%;
          max-width: 1280px;
          display: flex;
          align-items: center;
        `}
      >
        {props.tabs.map((tab, i) => (
          <React.Fragment key={i}>
            <div
              key={i}
              css={css`
                display: flex;
                align-items: center;
                position: relative;
                cursor: pointer;
              `}
              onClick={() => {
                onDropdownVisible(tab);
              }}
            >
              <Headers.H4
                css={css`
                  margin: 0;
                  margin-right: ${tab.dropDown ? "8px" : "40px"};
                  padding-bottom: 3px;
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 400;
                  color: ${(tab.link && tab.link === location.pathname) ||
                  tab.forceHightlight
                    ? theme.colors.dark
                    : theme.colors.gray5};
                  ${getTabsBorder(
                    tab.link ? tab.link : "",
                    tab.forceHightlight
                  )}
                `}
              >
                {tab.title}
              </Headers.H4>
              {tab.notification !== undefined && tab.notification > 0 && (
                <div
                  css={css`
                    position: absolute;
                    bottom: 15px;
                    right: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: ${theme.colors.error};
                    color: ${theme.colors.white};
                    font-size: 10px;
                    line-height: 22px;
                  `}
                >
                  {tab.notification}
                </div>
              )}
              {dropDownVisible && tab.dropDown && tab.dropDownContent && (
                <div
                  css={css`
                    position: absolute;
                    background-color: ${theme.colors.white};
                    box-shadow: 0 3px 8px 0 rgb(19 35 65 / 15%);
                    padding: 16px 27px;
                    top: 40px;
                    border-radius: 20px;
                  `}
                >
                  {tab.dropDownContent.map((el, id) => (
                    <div
                      key={id}
                      css={css`
                        font-size: 16px;
                        line-height: 22px;
                        margin-bottom: 7px;
                        border-bottom: 1px solid
                          ${theme.colors.yellowBackground};

                        :last-child {
                          margin: 0;
                        }

                        :hover {
                          border-bottom: 1px solid ${theme.colors.black};
                        }
                      `}
                      onClick={() => history.push(el.link)}
                    >
                      {el.title}
                    </div>
                  ))}
                </div>
              )}
              {tab.dropDown && <ChevronDown size={20} />}
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
