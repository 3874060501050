import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "../../customToast";
import * as Yup from "yup";
import { InputField } from "../../../forms/fields/InputField";
import { EntitySelectionField } from "../../../forms/fields/EntitySelectionField";
import { ImageUploadField } from "../../../forms/fields/ImageUploadField";
import { Button } from "../../../components/Button";
import { Skill } from "../../../types/admin/Skill";
import { RequiredDataType } from "../../../types/RequiredDataType";
import { Headers } from "../../../helpers/layout";

import { uploadImage } from "../../../actions/image";
import { getCompetencies } from "../../../actions/admin/competency";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";

export type FormValues = Omit<Skill, "id">;

const entityCompetencySearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getCompetencies({
    limit: 10,
    offset: 0,
    query: searchText,
  });

  return response.data.results.map((item) => {
    return {
      id: String(item.id),
      label: item.name,
    };
  });
};

export function EditCreateSkill(props: {
  title?: string;
  initialData?: Skill | null;
  onSubmitCallback: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmitCallback({
        ...values,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: props.initialData?.name || "",
          icon: props.initialData?.icon || null,
          competencies:
            props.initialData?.competencies.map((el) => {
              return {
                ...el,
                label: el.name,
              };
            }) || [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => {
          return (
            <Form noValidate>
              <div
                css={css`
                  margin-bottom: 32px;
                `}
              >
                <Headers.H3>{props.title}</Headers.H3>
              </div>

              <div
                css={css`
                  display: grid;
                  grid-template-columns: 640px 1fr;
                  grid-gap: 60px;
                `}
              >
                <div>
                  <InputField
                    name="name"
                    label={`${t("media.name")}:`}
                    css={css`
                      margin-bottom: 32px;
                    `}
                  />

                  <ImageUploadField
                    name="icon"
                    uploadFn={uploadImage}
                    label={`${t("media.icon")}:`}
                  />
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: flex-end;
                  justify-content: space-between;
                `}
              >
                <EntitySelectionField
                  name="competencies"
                  searchCallback={entityCompetencySearchCallback}
                  label={`${t("admin.competency.competencies")}:`}
                />
                <div
                  css={css`
                    margin-bottom: 32px;
                    display: flex;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!isValid}
                  >
                    {t(
                      !!props.initialData ? "actions.update" : "actions.create"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
