import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import {
  EditCreatePage,
  FormValues,
} from "../../../components/Admin/PageManagment/EditCreatePage";

import { useNewFetch } from "../../../useAPI";
import { pageLoading } from "../../../helpers/pageLoading";
import { getPage, updatePage } from "../../../actions/admin/page";
import { Tab, Tabs } from "../../../components/Tabs";
import { Footer } from "../../../components/Footer";

export function PageEditPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    pageId: string;
  }>();

  const history = useHistory();

  const { data: page, error: pageError } = useNewFetch(getPage, {
    pageId: params.pageId,
  });

  if (!page || pageError) {
    return pageLoading(pageError);
  }

  const onSubmit = async (data: FormValues) => {
    await updatePage({ id: params.pageId, data: data });

    history.push(`/admin/pages`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
      forceHightlight: true,
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.pages.edit-page")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreatePage
            initialData={page}
            onSubmitCallback={onSubmit}
            title={t("admin.pages.edit-page")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
