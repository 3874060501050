import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "./customToast";

import * as Yup from "yup";

import { InputField } from "../forms/fields/InputField";
import { TextareaField } from "../forms/fields/TextareaField";
import { SelectField } from "../forms/fields/SelectField";
import { FileUploadField } from "../forms/fields/FileUploadField";
import { RadioField } from "../forms/fields/RadioField";
import { Button } from "./Button";
import { SelectOption } from "./SelectComponent";
import {
  AdditionalMaterial,
  AdditionalMaterialIcon,
  AdditionalMaterialType,
} from "../types/AdditionalMaterial";
import { theme } from "../themes/variables";

import { serverErrorHandler } from "../helpers/serverErrorHandler";

export interface FormValues
  extends Omit<AdditionalMaterial, "course" | "uuid"> {}

export type AdditionalMaterialItem = Omit<
  AdditionalMaterial,
  "course" | "uuid"
>;

export function EditCreateAdditionalMaterial(props: {
  onSubmitCallback: (values: AdditionalMaterialItem) => Promise<void>;
  titileMaxLength?: number;
  initialData: AdditionalMaterialItem | null;
}) {
  const { onSubmitCallback, titileMaxLength = 150, initialData } = props;

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(titileMaxLength).required(),
    link: Yup.string().when("type", {
      is: AdditionalMaterialType.LINK,
      then: Yup.string().required(),
    }),
    type: Yup.string().required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        icon: values.icon,
        title: values.title,
        file: values.file,
        link: values.link,
        type: values.type,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const iconOptions: SelectOption[] = [
    {
      label: t("media.link"),
      value: AdditionalMaterialIcon.LINK,
    },
    {
      label: t("media.pdf"),
      value: AdditionalMaterialIcon.PDF,
    },
    {
      label: t("lesson.block.types.podcast"),
      value: AdditionalMaterialIcon.PODCAST,
    },
    {
      label: t("media.video"),
      value: AdditionalMaterialIcon.VIDEO,
    },
  ];

  const typeOptions: SelectOption[] = [
    {
      label: t("media.link"),
      value: AdditionalMaterialType.LINK,
    },
    {
      label: t("media.file"),
      value: AdditionalMaterialType.FILE,
    },
  ];

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        icon: initialData?.icon || AdditionalMaterialIcon.LINK,
        title: initialData?.title || "",
        link: initialData?.link || "",
        type: initialData?.type || AdditionalMaterialType.LINK,
        file: initialData?.file || undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form
          noValidate
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `}
        >
          <div>
            <InputField
              label={`${t("lesson.quiz.title")}*:`}
              name={"title"}
              maxLength={titileMaxLength}
            />

            <SelectField
              label={`${t("media.icon")}:`}
              name="icon"
              choices={iconOptions}
              css={css`
                margin-right: 20px;
              `}
            />

            <div
              css={css`
                margin-bottom: 32px;
              `}
            >
              <div
                css={css`
                  margin-bottom: 16px;
                  font-size: 16px;
                  font-weight: bold;
                `}
              >
                {t("media.type")}:
              </div>

              <div>
                {typeOptions.map((el, key) => (
                  <RadioField
                    labelRight
                    name={`type`}
                    label={el.label}
                    value={el.value}
                    key={key}
                    css={css`
                      margin-right: 1rem;
                    `}
                  />
                ))}
              </div>
            </div>

            {values.type === AdditionalMaterialType.FILE && (
              <FileUploadField label={`${t("media.file")}:`} name={"file"} />
            )}

            {values.type === AdditionalMaterialType.LINK && (
              <TextareaField label={`${t("media.link")}*:`} name="link" />
            )}
          </div>

          <div
            css={css`
              margin-top: 25px;
              display: flex;
              align-self: flex-end;
              flex-direction: row-reverse;
            `}
          >
            <div
              css={css`
                text-align: right;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                disabled={!isValid}
                type="submit"
              >
                {t(!!initialData ? "actions.update" : "actions.create")}
              </Button>

              {!isValid && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    color: ${theme.colors.redMain};
                    margin-top: 10px;
                  `}
                >
                  {t("status.error-fill")}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
