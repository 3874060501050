import React, { useState } from "react";
import { css } from "styled-components/macro";
import { ChevronDown, ChevronUp } from "react-feather";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { theme } from "../../themes/variables";

export const ContentContainer = (props: {
  hide?: boolean;
  maxHeight?: number;
  children: React.ReactNode;
}) => {
  return (
    <div
      css={css`
        max-height: ${props.hide ? 0 : props.maxHeight || 1500}px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
      `}
    >
      {props.children}
    </div>
  );
};

export function CollapsableContainer(props: {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  icon?: React.ReactNode;
  headerControls?: React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  footer?: React.ReactNode;
  flat?: boolean;
  className?: string;
  isOpened?: boolean;
  collapseText?: string;
  expandText?: string;
  isExpandable: boolean;
}) {
  const { t } = useTranslation();

  const [collapse, setCollapse] = useState(!props.isOpened);

  const collapseClick = () => {
    setCollapse(!collapse);
  };

  return (
    <div
      className={`collapsable-container ${props.className}`}
      css={css`
        border-radius: 20px;
        background: ${!collapse ? "#E1EAEE" : "#f2f2f2"};
      `}
    >
      <div
        css={css`
          padding: 30px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {props.icon && (
            <div
              css={css`
                vertical-align: middle;
              `}
            >
              {props.icon}
            </div>
          )}
          <div
            css={css`
              margin-left: 15px;
              display: flex;
              flex-direction: column;
            `}
          >
            {props.title && <div>{props.title}</div>}
            {props.subTitle && <div>{props.subTitle}</div>}
          </div>
        </div>

        <div>
          {props.headerControls &&
            props.headerControls.map((headerControl, i) => {
              return (
                <span
                  key={i}
                  css={css`
                    margin-right: 10px;
                  `}
                >
                  {headerControl}
                </span>
              );
            })}
          {props.isExpandable && (
            <Button
              onClick={collapseClick}
              children={
                collapse
                  ? props.expandText ?? t("actions.expand")
                  : props.collapseText ?? t("actions.minimize")
              }
              small
              name="collapsable"
              icon={
                collapse ? (
                  <ChevronUp
                    size={16}
                    css={css`
                      vertical-align: middle;
                    `}
                  />
                ) : (
                  <ChevronDown
                    size={16}
                    css={css`
                      vertical-align: middle;
                    `}
                  />
                )
              }
              iconRight
              background={theme.colors.white}
              color={theme.colors.dark}
              border={`1px solid ${theme.colors.dark}`}
              hoverStyles={`border: 1px solid ${theme.colors.transparent}; background: ${theme.colors.primary}; color: ${theme.colors.white};`}
            />
          )}
        </div>
      </div>

      <ContentContainer hide={collapse}>{props.children}</ContentContainer>

      {props.footer && (
        <div
          css={css`
            padding: 30px 20px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
          `}
        >
          {props.footer}
        </div>
      )}
    </div>
  );
}
