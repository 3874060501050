import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { customToast } from "../../components/customToast";
import { Button } from "../../components/Button";
import { InputField } from "../fields/InputField";
import { Column, Row, Spacing } from "../../helpers/layout";
import { login } from "../../actions/auth/login";
import { useAuth } from "../../contexts/UserContext";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { USER_CONFIRM_EMAIL } from "../../actions/auth/resendConfirmationEmail";
interface FormValues {
  username: string;
  password: string;
}

export function LoginForm() {
  const { t } = useTranslation();
  const { authenticate } = useAuth();
  const history = useHistory();
  const location = useLocation();

  return (
    <div>
      <Formik<FormValues>
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().email().required(),
          password: Yup.string().required(),
        })}
        onSubmit={async (values: FormValues, { setSubmitting }) => {
          try {
            setSubmitting(true);

            const response = await login(values.username, values.password);

            if (!response.data.email_confirmed) {
              localStorage.setItem(USER_CONFIRM_EMAIL, values.username);

              history.push("/confirm-email");
            } else if (!response.data.is_verified) {
              history.push("/verification-info");
            } else {
              authenticate(response.data.token, response.data.userprofile);
            }
          } catch (error: any) {
            customToast.error(
              t("status.error", {
                error: serverErrorHandler(error),
              })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form noValidate>
            <Column gutter={Spacing.m}>
              <InputField
                type="text"
                name="username"
                placeholder={t("auth.email")}
              />
              <InputField
                type="password"
                name="password"
                placeholder={t("auth.password")}
              />

              <Row
                justify="flex-end"
                align="center"
                css={css`
                  margin-top: 40px;
                `}
              >
                <Button
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  css={css`
                    margin: 0 auto;
                  `}
                >
                  {t("auth.login")}
                </Button>
              </Row>
            </Column>

            <div
              css={css`
                display: flex;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 10px;
                font-weight: 400;
              `}
            >
              {t("auth.or")}
            </div>

            <Link
              css={css`
                display: flex;
                text-decoration: underline;
                justify-content: center;
                font-size: 20px;
                font-weight: 500;
              `}
              to={`/register${location.search}`}
            >
              {t("auth.register")}
            </Link>

            <Link
              css={css`
                display: flex;
                font-size: 16px;
                justify-content: center;
                margin-top: 50px;

                :hover {
                  text-decoration: underline;
                }
              `}
              to={`/reset-password${location.search}`}
            >
              {t("auth.forgot-password")}?
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}
