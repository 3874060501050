import { authorised } from "../../../request";
import { UserSegment } from "../../../types/admin/UserSegment";

type Args = {
  id: string | number;
}

export async function getUserSegment({id}: Args) {
  const API = authorised();

  return API.request<UserSegment>({
    method: "GET",
    url: `/api/enterprise/user-segment/${id}/`
  });
}
