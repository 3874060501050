import React from "react";
import { css } from "styled-components/macro";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Test } from "../../types/Test";
import { ExpansionItem } from "../../components/ExpansionItem";
import { Separator } from "../../components/Separator";
import { TextInCircle } from "../../components/TextInCircle";

export function TestList(props: { items: Test[] }) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    lessonId: string;
    testId?: string;
  }>();

  const {
    videoQuiz,
    repetitionQuiz,
    evaluationQuiz,
    all,
  }: {
    videoQuiz: Test[];
    repetitionQuiz: Test[];
    evaluationQuiz: Test[];
    all: Test[];
  } = props.items.reduce(
    (
      data: {
        videoQuiz: Test[];
        repetitionQuiz: Test[];
        evaluationQuiz: Test[];
        all: Test[];
      },
      row
    ) => {
      if (row.in_video) {
        data.videoQuiz.push(row);
      }
      if (row.in_repetition) {
        data.repetitionQuiz.push(row);
      }
      if (row.in_exam) {
        data.evaluationQuiz.push(row);
      }

      data.all.push(row);

      return data;
    },
    {
      videoQuiz: [],
      repetitionQuiz: [],
      evaluationQuiz: [],
      all: [],
    }
  );

  const quizes = [
    {
      label: t("lesson.quiz.overview.video.title"),
      items: videoQuiz,
    },
    {
      label: t("lesson.quiz.overview.repetition.title"),
      items: repetitionQuiz,
    },
    {
      label: t("lesson.quiz.overview.evaluation.title"),
      items: evaluationQuiz,
    },
    {
      label: t("lesson.quiz.overview.all.title"),
      items: all,
    },
  ];

  return (
    <div
      css={css`
        margin-right: 20px;
      `}
    >
      {quizes.map((quiz, idx) => {
        return (
          <ExpansionItem
            key={idx}
            label={quiz.label}
            initialValue={true}
            css={css`
              border-radius: 4px;
            `}
            icon={<TextInCircle>{quiz.items.length}</TextInCircle>}
          >
            {quiz.items.map((item) => {
              return (
                <div key={item.uuid}>
                  <Link
                    to={`/courses/${params.courseId}/lessons/${params.lessonId}/testing/${item.uuid}/edit`}
                  >
                    <div
                      css={css`
                        padding: 24px 8px;
                        background: ${params.testId === item.uuid
                          ? "#fcf1ed"
                          : ""};
                        border-radius: 4px;
                      `}
                    >
                      {item.internal_name}
                    </div>
                  </Link>
                  <div
                    css={css`
                      padding: 0 20px;
                    `}
                  >
                    <Separator />
                  </div>
                </div>
              );
            })}
          </ExpansionItem>
        );
      })}
    </div>
  );
}
