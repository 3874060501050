import { authorised } from "../../../request";
import { EnterpriseDRM } from "../../../types/Enterprise";

export async function getEnterpriseDRMSettings() {
  const API = authorised();

  return await API.request<EnterpriseDRM>({
    method: "GET",
    url: `/api/enterprise/settings/drm/`,
  });
}
