import { theme } from "../../../themes/variables";
import { STATUS_OPTIONS } from "../../../types/admin/Order";

export const getStatusColor = (status: STATUS_OPTIONS) => {
  return status === STATUS_OPTIONS.COMPLETED
    ? theme.colors.haki
    : status === STATUS_OPTIONS.PAYMENT_FAILED
    ? theme.colors.error
    : status === STATUS_OPTIONS.REFUNDED
    ? theme.colors.violetMain
    : status === STATUS_OPTIONS.PENDING_PAYMENT
    ? theme.colors.blueBackground
    : status === STATUS_OPTIONS.PROCESSING
    ? theme.colors.blueBackground
    : status === STATUS_OPTIONS.CANCELED
    ? theme.colors.pomade
    : theme.colors.gray6;
};
