import React, { useState } from "react";
import { css } from "styled-components/macro";
import { Plus, Trash2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { Field, FieldArray, FieldProps, FieldArrayRenderProps } from "formik";
import { theme } from "../../themes/variables";
import { Button } from "../Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { MinimalTinyEditor } from "../../components/DraftEditor/tinymce";
import { TextareaField } from "../../forms/fields/TextareaField";
import { TestVariant, QuestionType } from "../../types/Test";
import { TrueFalse } from "./TestContentTypeFields/TrueFalse";
import { SingleChoice } from "./TestContentTypeFields/SingleChoice";
import { MultipleChoice } from "./TestContentTypeFields/MultipleChoice";

export function TestingContent(props: { name: string; type: QuestionType }) {
  const { t } = useTranslation();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const [deleteModal, setDeleteModal] = useState(false);

  const setCurrentTab = (idx: number) => {
    setCurrentTabIndex(idx);
  };

  const onDeleteModalConfirm = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.remove(currentTabIndex);
    setCurrentTabIndex(currentTabIndex === 0 ? 0 : currentTabIndex - 1);
    setDeleteModal(false);
  };

  return (
    <Field
      name={props.name}
      render={({ field, form }: FieldProps) => {
        const variants: TestVariant[] = field.value || [];

        return (
          <FieldArray
            name={props.name}
            render={(arrayHelpers) => (
              <div
                css={css`
                  max-width: 100%;
                  width: 100%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow-x: auto;
                  `}
                >
                  {variants.map((_, idx) => {
                    const chosen = idx === currentTabIndex;

                    return (
                      <div
                        key={idx}
                        onClick={() => setCurrentTab(idx)}
                        css={css`
                          ${chosen
                            ? `background: ${theme.colors.primaryLighter}; border: 1px solid transparent; border-bottom: none;`
                            : `border: 1px solid ${theme.colors.primaryLighter}; border-bottom: none;`}
                          cursor: pointer;
                          border-top-left-radius: 1rem;
                          border-top-right-radius: 1rem;
                          padding: 0.5rem;
                          margin-right: 10px;
                          white-space: nowrap;
                        `}
                      >
                        {t("lesson.quiz.variant")}: {idx + 1}
                      </div>
                    );
                  })}

                  <Button
                    type="button"
                    css={css`
                      padding: 0;
                      margin: 0;
                      min-width: 0;
                      background: none;
                    `}
                    onClick={() => {
                      arrayHelpers.push({
                        answers: [
                          {
                            image: null,
                            is_correct: true,
                            primary_text: "",
                            secondary_text: "",
                          },
                        ],
                        explanation: "",
                        title: "",
                      });

                      setCurrentTabIndex(variants.length);
                    }}
                  >
                    <Plus
                      size={24}
                      css={css`
                        border-radius: 50%;
                        background-color: ${theme.colors.primary};
                      `}
                      color={theme.colors.white}
                    />
                  </Button>
                </div>

                {variants.length > 0 && (
                  <div
                    css={css`
                      padding: 1rem;
                      border: 1px solid ${theme.colors.primaryLighter};
                      border-radius: 8px;
                      border-top-left-radius: 0;
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-direction: row-reverse;
                      `}
                    >
                      <button
                        type="button"
                        title={t("actions.delete")}
                        css={css`
                          padding: 0;
                          margin: 0;
                          cursor: pointer;
                          white-space: nowrap;
                          border: 0;
                          text-transform: none;
                          -webkit-appearance: none;
                          outline: none;
                          background: transparent;
                          position: relative;
                          top: 0;
                          padding-left: 20px;
                          :hover {
                            color: ${theme.colors.error};
                          }
                        `}
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        <Trash2 />
                      </button>
                    </div>

                    {props.type !== QuestionType.TRUE_FALSE && (
                      <div
                        css={css`
                          margin-bottom: 1rem;
                        `}
                      >
                        <div
                          css={css`
                            margin-bottom: 16px;
                            font-size: 16px;
                            font-weight: bold;
                          `}
                        >
                          {t("lesson.quiz.title")}:
                        </div>

                        <MinimalTinyEditor
                          value={variants[currentTabIndex].title || ""}
                          onChange={(val: string) =>
                            form.setFieldValue(
                              `variants[${currentTabIndex}].title`,
                              val
                            )
                          }
                        />
                      </div>
                    )}

                    {props.type === QuestionType.TRUE_FALSE && (
                      <TrueFalse
                        name={`variants[${currentTabIndex}].answers`}
                      />
                    )}
                    {props.type === QuestionType.SINGLE_CHOICE && (
                      <SingleChoice
                        label={`${t("lesson.quiz.options")}:`}
                        name={`variants[${currentTabIndex}].answers`}
                      />
                    )}
                    {props.type === QuestionType.MULTIPLE_CHOICE && (
                      <MultipleChoice
                        label={`${t("lesson.quiz.options")}:`}
                        name={`variants[${currentTabIndex}].answers`}
                      />
                    )}

                    <TextareaField
                      label={`${t("lesson.quiz.explanation")}:`}
                      name={`variants[${currentTabIndex}].explanation`}
                      maxLength={500}
                      css={css`
                        min-height: 100px;
                      `}
                    />
                  </div>
                )}

                <Modal
                  modalIsOpen={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  contentLabel={t("actions.sure")}
                >
                  <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                    {t("actions.sure")}
                  </ModalHeader>

                  <ModalBody>{t("actions.no-undo")}</ModalBody>

                  <ModalFooter>
                    <Button
                      background={theme.colors.white}
                      color={theme.colors.dark}
                      border={`1px solid ${theme.colors.dark}`}
                      hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                      onClick={() => setDeleteModal(false)}
                    >
                      {t("actions.cancel")}
                    </Button>

                    <Button
                      type="submit"
                      onClick={() => onDeleteModalConfirm(arrayHelpers)}
                    >
                      {t("actions.delete")}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            )}
          ></FieldArray>
        );
      }}
    />
  );
}
