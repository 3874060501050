import { authorised } from "../../request";

type Args = {
  lessonId: string;
  blockId: string;
}

export async function deleteBlock({lessonId, blockId}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/lesson/${lessonId}/blocks/${blockId}/`
  });
}
