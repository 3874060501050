import { LessonFeedback } from "../../types/LessonFeedback";
import { authorised } from "../../request";
import { Filters } from "../../hooks/useFilters";
import { buildQueryString } from "../../helpers/buildQueryString";

export async function getStudioFeedback(filters: Filters) {
  const API = authorised();

  const queryString = buildQueryString(filters);

  return API.request<LessonFeedback[]>({
    method: "GET",
    url: `/api/studio/feedback/?${queryString}`,
  }).then((r) => r.data);
}
