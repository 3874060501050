import React from "react";
import { css } from "styled-components/macro";
import { File, AcceptedMimeTypes } from "../types/File";
import { theme } from "../themes/variables";

import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";
import { ReactComponent as DocIcon } from "../assets/icons/DOC.svg";
import { ReactComponent as Mp3Icon } from "../assets/icons/MP3.svg";
import { ReactComponent as PDFIcon } from "../assets/icons/PDF.svg";
import { ReactComponent as PresentationIcon } from "../assets/icons/Presentation.svg";
import { ReactComponent as SVGIcon } from "../assets/icons/SVG.svg";

export function FilesRow(props: {
  files: File[];
  className?: string;
  value?: File | null;
  onSelect?: (image: File) => void;
  onDoubleClick?: (image: File) => void;
}) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: calc(100vh - 290px);
        > div {
          margin: auto;
        }
      `}
    >
      {props.files.map((file) => {
        return (
          <div
            key={file.uuid}
            css={css`
              width: 180px;
              height: 180px;
              border: 2px solid
                ${file.uuid === props.value?.uuid
                  ? theme.colors.primary
                  : theme.colors.gray2};
              border-radius: 8px;
              position: relative;
              cursor: pointer;
              overflow: hidden;
            `}
            className={props.className}
            onClick={() => {
              props.onSelect && props.onSelect(file);
            }}
            onDoubleClick={() => {
              props.onDoubleClick && props.onDoubleClick(file);
            }}
          >
            {file.uuid === props.value?.uuid && (
              <div
                css={css`
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  bottom: 19px;
                  right: 19px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transform: translate(50%, 50%);
                  border-radius: 50%;
                  background: ${theme.colors.primary};
                `}
              >
                <ReadIcon color={theme.colors.white} />
              </div>
            )}

            <div
              css={css`
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                background: ${theme.colors.white};
              `}
            >
              {(file.mime_type === AcceptedMimeTypes.MP3 ||
                file.mime_type === AcceptedMimeTypes.MPEG ||
                file.mime_type === AcceptedMimeTypes.WEBA ||
                file.mime_type === AcceptedMimeTypes.WEBM) && (
                <Mp3Icon width={40} height={40} color={theme.colors.dark} />
              )}

              {(file.mime_type === AcceptedMimeTypes.DOC ||
                file.mime_type === AcceptedMimeTypes.DOCX ||
                file.mime_type === AcceptedMimeTypes.XLS ||
                file.mime_type === AcceptedMimeTypes.XLSX) && (
                <DocIcon width={40} height={40} color={theme.colors.dark} />
              )}

              {file.mime_type === AcceptedMimeTypes.PDF && (
                <PDFIcon width={40} height={40} color={theme.colors.dark} />
              )}

              {(file.mime_type === AcceptedMimeTypes.PPTX ||
                file.mime_type === AcceptedMimeTypes.PPT) && (
                <PresentationIcon
                  width={40}
                  height={40}
                  color={theme.colors.dark}
                />
              )}

              {file.mime_type === AcceptedMimeTypes.SVG && (
                <SVGIcon width={40} height={40} color={theme.colors.dark} />
              )}

              <span
                css={css`
                  margin-top: 10px;
                  font-size: 14px;
                  text-align: center;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  max-width: 100%;
                `}
                title={file.title || file.upload_name}
              >
                {file.title || file.upload_name}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
