import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { InputLabel } from "./InputLabel";

export function Switcher(props: {
  id?: string;
  name?: string;
  label?: string;
  hint?: string;
  disabled?: boolean;
  readonly?: boolean;
  showStatus?: boolean;
  width?: number;
  height?: number;
  circleSize?: number;
  activeColor?: string;
  field?: FieldInputProps<any>;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
}) {
  const { t } = useTranslation();

  const {
    width = 32,
    height = 20,
    circleSize = 12,
    activeColor = theme.colors.primary,
  } = props;

  const transformSize = `translateX(${width - circleSize - 8 - 1}px)`;

  return (
    <div>
      <InputLabel label={props.label} />

      <div
        css={css`
          display: flex;
        `}
      >
        <label
          css={css`
            position: relative;
            display: inline-block;
            width: ${width}px;
            height: ${height}px;
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            input:checked + span {
              background-color: ${activeColor};
            }
            input:focus + span {
              box-shadow: 0 0 1px ${activeColor};
            }
            input:checked + span:before {
              transform: ${transformSize};
            }
            input:disabled + span:before {
              background-color: ${theme.colors.gray5};
            }
          `}
        >
          <input
            id={props.id}
            name={props.name || ""}
            type="checkbox"
            readOnly={props.readonly || false}
            disabled={props.disabled || false}
            checked={props.value || false}
            {...props.field}
            onChange={(e) => {
              if (props.field) {
                props.field.onChange(e);
              }

              if (props.onChange) {
                props.onChange(e);
              }
            }}
          />
          <span
            css={css`
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: ${theme.colors.gray3};
              border-radius: 20px;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              :before {
                position: absolute;
                content: "";
                height: ${circleSize}px;
                width: ${circleSize}px;
                left: 4px;
                bottom: 4px;
                background-color: ${theme.colors.white};
                border-radius: 50%;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }
            `}
          ></span>
        </label>
        {props.showStatus && (
          <div
            css={css`
              font-size: 16px;
              line-height: 22px;
              margin-left: 8px;
            `}
          >
            {props.value || props.field?.checked
              ? t("media.on")
              : t("media.off")}
          </div>
        )}
      </div>
      {props.hint && (
        <div
          css={css`
            margin-top: 8px;
            font-size: 16px;
            line-height: 22px;
            color: ${theme.colors.gray3};
          `}
        >
          {props.hint}
        </div>
      )}
    </div>
  );
}
