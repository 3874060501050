import React, { useEffect } from "react";
import ReactModal, { Styles } from "react-modal";
import { theme } from "../../themes/variables";

ReactModal.setAppElement("#root");

const disableBodyScroll = () => {
  document.body.style.overflow = "hidden";
};

const enableBodyScroll = () => {
  document.body.style.overflow = "unset";
};

export function Modal(props: {
  modalIsOpen: boolean;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  onClose?: () => void;
  contentLabel?: string;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  overlayStyles?: Styles["overlay"];
  contentStyles?: Styles["content"];
  persistent?: boolean;
}) {
  useEffect(() => {
    if (props.modalIsOpen) {
      disableBodyScroll();
    }

    return () => {
      enableBodyScroll();
    };
  }, [props.modalIsOpen]);

  function onClose() {
    if (props.onClose) {
      props.onClose();
    }

    enableBodyScroll();
  }

  const customStyles: Styles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "800px",
      maxHeight: "calc(100vh - 40px)",
      boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px, rgba(0, 0, 0, 0.12) 0px 1px 10px",
      overflow: "auto",
      borderRadius: "20px",
      position: "relative",
      backgroundColor: theme.colors.white,
      border: "none",
      padding: "30px 60px",
      willChange: "scroll-position",
    },
    overlay: {
      height: "100vh",
      overflowY: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      outline: "none",
      zIndex: 1200,
    },
  };

  if (props.contentStyles && customStyles.content) {
    customStyles.content = {
      ...customStyles.content,
      ...props.contentStyles,
    };
  }

  if (props.overlayStyles && customStyles.overlay) {
    customStyles.overlay = {
      ...customStyles.overlay,
      ...props.overlayStyles,
    };
  }

  return (
    <div>
      {props.modalIsOpen && (
        <ReactModal
          isOpen={true}
          onAfterOpen={props.onAfterOpen}
          onAfterClose={props.onAfterClose}
          onRequestClose={onClose}
          style={customStyles}
          contentLabel={props.contentLabel}
          shouldCloseOnOverlayClick={!props.persistent}
        >
          {props.children}
        </ReactModal>
      )}
    </div>
  );
}
