import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { css } from "styled-components/macro";
import { deleteSelfAssessment } from "../../actions/courseSelfAssessments/deleteCourseSelfAssessment";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../components/Modal";
import { Separator } from "../../components/Separator";
import { TextInCircle } from "../../components/TextInCircle";
import { theme } from "../../themes/variables";
import { SelfAssessment } from "../../types/SelfAssessment";

import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";

export function SelfAssessmentList(props: {
  title: string;
  courseId: string;
  selfAssessmentItems: SelfAssessment[];
  onItemDelete: () => void;
}) {
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);
  const [itemId, setItemId] = useState("");

  const onItemDelete = async (uuid: string) => {
    await deleteSelfAssessment({
      courseId: props.courseId,
      selfAssessmentId: uuid,
    }).then(() => {
      setDeleteModal(false);
      props.onItemDelete();
      toast.success(t("status.success"));
    });
  };

  return (
    <div
      css={css`
        margin-bottom: 2rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <TextInCircle>{props.selfAssessmentItems.length}</TextInCircle>
        <div>
          <div
            css={css`
              font-weight: bold;
            `}
          >
            {props.title}
          </div>
        </div>
      </div>

      {props.selfAssessmentItems.map((item, idx) => {
        return (
          <Fragment key={idx}>
            <div
              css={css`
                padding: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div>
                <span
                  css={css`
                    font-weight: bold;
                    margin-right: 1rem;
                  `}
                >
                  {idx + 1}
                </span>

                <Link
                  to={`/courses/${props.courseId}/self-assessments/${item.uuid}/edit`}
                >
                  {item.title}
                </Link>
              </div>

              <div>
                <Link
                  to={`/courses/${props.courseId}/self-assessments/${item.uuid}/edit`}
                >
                  <Button
                    small
                    css={css`
                      margin-right: 1rem;
                    `}
                    background={theme.colors.transparent}
                    hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                  >
                    {t("actions.edit")}
                  </Button>
                </Link>

                <Button
                  small
                  background={theme.colors.transparent}
                  border={`1px solid ${theme.colors.error}`}
                  color={theme.colors.error}
                  icon={
                    <DeleteIcon
                      color={theme.colors.error}
                      width={14}
                      height={14}
                    />
                  }
                  iconRight
                  hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
                  onClick={() => {
                    setItemId(item.uuid!);
                    setDeleteModal(true);
                  }}
                >
                  {t("actions.delete")}
                </Button>

                <Modal
                  modalIsOpen={deleteModal}
                  onClose={() => setDeleteModal(false)}
                  contentLabel={`${t("actions.sure")}?`}
                >
                  <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                    {`${t("actions.sure")}?`}
                  </ModalHeader>

                  <ModalBody>{t("actions.no-undo")}</ModalBody>

                  <ModalFooter>
                    <Button
                      background={theme.colors.white}
                      color={theme.colors.dark}
                      border={`1px solid ${theme.colors.dark}`}
                      onClick={() => setDeleteModal(false)}
                    >
                      {t("actions.cancel")}
                    </Button>

                    <Button
                      onClick={() => {
                        onItemDelete(itemId);
                      }}
                    >
                      {t("actions.delete")}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
            <Separator />
          </Fragment>
        );
      })}

      <div
        css={css`
          display: flex;
          flex-direction: row-reverse;
          margin-top: 1rem;
        `}
      >
        <Link to={`/courses/${props.courseId}/self-assessments/new`}>
          <Button small>{t("actions.create")}</Button>
        </Link>
      </div>
    </div>
  );
}
