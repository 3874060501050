import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../../../themes/variables";
import { InputComponent } from "../../../components/InputComponent";

import { ReactComponent as LockClosedIcon } from "../../../assets/icons/LockClosed.svg";

export function SignupDefaultFields(props: {}) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        padding: 40px;
        background: ${theme.colors.lightStone};
        border-radius: 20px;
      `}
    >
      <div
        css={css`
          flex: 0 1 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            margin-bottom: 32px;
          `}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <InputComponent
              disabled
              readonly
              placeholder={t("signup_fields.type_here")}
              label={`${t("account.first-name")}:`}
            />
          </div>

          <div
            css={css`
              width: 100%;
              margin-left: 12px;
            `}
          >
            <InputComponent
              disabled
              readonly
              placeholder={t("signup_fields.type_here")}
              label={`${t("account.last-name")}:`}
            />
          </div>
        </div>

        <div
          css={css`
            margin-bottom: 32px;
          `}
        >
          <InputComponent
            disabled
            readonly
            placeholder="yourmail@mail.de"
            label={`${t("account.email")}:`}
          />
        </div>

        <div
          css={css`
            margin-bottom: 32px;
          `}
        >
          <InputComponent
            disabled
            readonly
            placeholder={t("signup_fields.type_here")}
            label={`${t("auth.password")}:`}
          />
        </div>

        <div
          css={css`
            margin-bottom: 32px;
          `}
        >
          <InputComponent
            disabled
            readonly
            placeholder={t("signup_fields.type_here")}
            label={`${t("auth.repeat-password")}:`}
          />
        </div>

        <div
          css={css`
            margin-bottom: 32px;
          `}
        >
          <InputComponent
            disabled
            readonly
            placeholder={t("signup_fields.type_here")}
            label={`${t("admin.users.page.refferal")}:`}
          />
        </div>

        <div
          css={css`
            font-size: 16px;
          `}
        >
          <span
            css={css`
              font-weight: bold;
            `}
          >
            {t("signup_fields.note")}:
          </span>
          <span
            css={css`
              margin-left: 14px;
            `}
          >
            {t("signup_fields.default_fields_cannot_be_changed")}
          </span>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex: 1 0 140px;
          justify-content: flex-end;
        `}
      >
        <LockClosedIcon width={20} height={20} color={theme.colors.gray6} />
      </div>
    </div>
  );
}
