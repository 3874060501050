import { css } from "styled-components/macro";
import { useEffect, useState } from "react";
import { customToast } from "../../customToast";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";
import { reorderSliders } from "../../../actions/admin/sliders/reorderSliders";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { Slider } from "../../../types/admin/Slider";
import SliderCard from "./SliderCard";

export default function SlidersBlock({
  items,
  onSetModal,
  onSetDeleteModal,
  onSelectSlider,
  refetch,
}: {
  items: Slider[];
  onSetModal: (modal: boolean) => void;
  onSetDeleteModal: (modal: boolean) => void;
  onSelectSlider: (slider: Slider) => void;
  refetch: () => Promise<void>;
}) {
  const { t } = useTranslation();

  const [sliders, setSliders] = useState<Slider[]>(items);

  useEffect(() => {
    setSliders(items);
  }, [items]);

  const reorder = (
    list: Slider[],
    fromIndex: number,
    toIndex: number
  ): Slider[] => {
    const result = Array.from(list);
    const element = result[fromIndex];

    result.splice(fromIndex, 1);
    result.splice(toIndex, 0, element);

    return result;
  };

  const onDragEnd: OnDragEndResponder = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reordered = reorder(
      sliders,
      result.source.index,
      result.destination.index
    );

    setSliders(reordered);

    try {
      await reorderSliders(reordered);

      refetch();
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            {sliders.map((item) => (
              <SliderCard
                key={item.id}
                slider={item}
                onSetModal={onSetModal}
                onSetDeleteModal={onSetDeleteModal}
                onSelectSlider={onSelectSlider}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
