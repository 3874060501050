import { authorised } from "../../request";
import { CheatSheet } from "../../types/CheatSheet";

type Args = {
  courseId: string;
  cheatSheetId: string;
}

export async function getCheatSheet(data: Args) {
  const API = authorised();

  return API.request<CheatSheet>({
    method: "GET",
    url: `/api/studio/courses/${data.courseId}/cheat-sheet/${data.cheatSheetId}/`,
  });
}
