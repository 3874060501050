import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Tab, Tabs } from "../../../components/Tabs";
import {
  EditCreateCompetency,
  FormValues,
} from "../../../components/Admin/Competency/EditCreateCompetency";

import { useNewFetch } from "../../../useAPI";
import { pageLoading } from "../../../helpers/pageLoading";
import {
  getCompetency,
  updateCompetency,
} from "../../../actions/admin/competency";
import { Footer } from "../../../components/Footer";

export function CompetencyEditPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    competencyId: string;
  }>();

  const history = useHistory();

  const { data: competency, error: competencyError } = useNewFetch(
    getCompetency,
    {
      competencyId: params.competencyId,
    }
  );

  if (!competency || competencyError) {
    return pageLoading(competencyError);
  }

  const onSubmit = async (data: FormValues) => {
    await updateCompetency({ id: +params.competencyId, data: data });

    history.push(`/admin/competencies`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.competency.title"),
      link: `/admin/competencies`,
      forceHightlight: true,
    },
    {
      title: t("admin.skill.title"),
      link: "/admin/skills",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.competency.edit-competency")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateCompetency
            initialData={competency}
            onSubmitCallback={onSubmit}
            title={t("admin.competency.edit-competency")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
