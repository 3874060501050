import { TargetUserProfile } from "./UserProfile";
import { Image } from "../Image";

export enum PageVisibility {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export enum PageStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT"
}

export type Page = {
  uuid: string;
  title: string;
  author: TargetUserProfile;
  permalink: string;
  description: string;
  content: string;
  visibility: PageVisibility;
  status: PageStatus;
  image: Image | null;
  created: string;
}