import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getExperts } from "../../actions/organisationExpert";
import { Expert } from "../../types/Expert";
import { theme } from "../../themes/variables";
import { Button } from "../Button";
import { Avatar } from "../Avatar";
import { TableHandler, TableHandlerColumn } from "../TableHandler";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";

export default function ExpertsTable({
  forwardRef,
  setExpertCandidate,
  setDeleteCandidate,
  setExpertModal,
  setDeleteModal,
}: {
  forwardRef: any;
  setExpertCandidate: (expert: Expert) => void;
  setDeleteCandidate: (expert: Expert) => void;
  setExpertModal: (value: boolean) => void;
  setDeleteModal: (value: boolean) => void;
}) {
  const { t } = useTranslation();

  const tableHandlerColumns: TableHandlerColumn<Expert>[] = [
    {
      label: "ID",
      render: (row) => <div>{row.id}</div>,
    },
    {
      label: t("account.avatar"),
      render: (row) => (
        <Avatar
          css={css`
            margin: 0 auto;
          `}
          size={40}
          url={row.avatar?.image}
        />
      ),
    },
    {
      label: t("admin.users.page.fullname"),
      render: (row) => (
        <div>
          {row.first_name} {row.last_name}
        </div>
      ),
    },
    {
      label: t("account.description"),
      render: (row) => <div>{row.introduction}</div>,
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
          `}
        >
          <Button
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.blueBackground}`}
            color={theme.colors.blueBackground}
            icon={
              <EditIcon
                width={14}
                height={14}
                color={theme.colors.blueBackground}
              />
            }
            iconRight
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
            small
            css={css`
              margin-right: 16px;
            `}
            onClick={() => {
              setExpertCandidate(row);
              setExpertModal(true);
            }}
          >
            {t("actions.edit")}
          </Button>

          <Button
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.error}`}
            color={theme.colors.error}
            icon={
              <DeleteIcon width={14} height={14} color={theme.colors.error} />
            }
            iconRight
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
            small
            onClick={() => {
              setDeleteCandidate(row);
              setDeleteModal(true);
            }}
          >
            {t("actions.delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <TableHandler
      forwardRef={forwardRef}
      tableData={{
        columns: tableHandlerColumns,
      }}
      getFunction={getExperts}
    />
  );
}
