import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { css } from "styled-components/macro";
import { DateTime } from "luxon";
import { STATUS_OPTIONS } from "../../../types/admin/Order";
import { theme } from "../../../themes/variables";
import { downloadBlob } from "../../../helpers/download";
import { Headers } from "../../../helpers/layout";
import { Header } from "../../../components/Header";
import { InputComponent } from "../../../components/InputComponent";
import { SelectComponent } from "../../../components/SelectComponent";
import { Button } from "../../../components/Button";
import { Wrapper } from "../../../components/Wrapper";
import { DatePicker } from "../../../components/DatePicker";
import StatusFilter from "../../../components/Admin/Orders/StatusFilter";
import OrdersTable from "../../../components/Admin/Orders/OrdersTable";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal";
import { downloadOrdersCsv } from "../../../actions/admin/orders/downloadOrderCsv";

import { ReactComponent as SearchSvg } from "../../../assets/icons/Search.svg";
import { ReactComponent as DownloadSvg } from "../../../assets/icons/Download.svg";
import { Footer } from "../../../components/Footer";

const dateFormat = "yyyy-MM-dd";

export default function OrdersListPage() {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);

  const [downloadModal, setDownloadModal] = useState(false);
  const [query, setQuery] = useState(params.get("query") || undefined);
  const [type, setType] = useState(params.get("type") || STATUS_OPTIONS.ALL);
  const [isDownload, setIsDownload] = useState(false);

  const [fromDate, setFromDate] = useState<Date>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    )
  );
  const [toDate, setToDate] = useState<Date>(new Date());
  const [status, setStatus] = useState<STATUS_OPTIONS>(STATUS_OPTIONS.ALL);

  const onSearch = (val: string) => {
    ref.current.setQueryArgs("query", val);
    setQuery(val);
  };

  const onSelectType = (val: string) => {
    ref.current.setQueryArgs("type", val);
    setType(val);
  };

  const statusOptions = [
    { value: STATUS_OPTIONS.ALL, label: t("orders.status.all") },
    { value: STATUS_OPTIONS.COMPLETED, label: t("orders.status.completed") },
    { value: STATUS_OPTIONS.REFUNDED, label: t("orders.status.refunded") },
    {
      value: STATUS_OPTIONS.PENDING_PAYMENT,
      label: t("orders.status.pending"),
    },
    { value: STATUS_OPTIONS.CANCELED, label: t("orders.status.canceled") },
    { value: STATUS_OPTIONS.PAYMENT_FAILED, label: t("orders.status.failed") },
    { value: STATUS_OPTIONS.DRAFT, label: t("admin.pages.status.draft") },
    { value: STATUS_OPTIONS.PROCESSING, label: t("orders.status.processing") },
  ];

  const download = async () => {
    setIsDownload(true);
    const { data: content } = await downloadOrdersCsv({
      date_from: DateTime.fromJSDate(fromDate).toFormat(dateFormat),
      date_to: DateTime.fromJSDate(toDate).toFormat(dateFormat),
      status: status,
    });

    downloadBlob(content, "orders.csv", "text/csv;encoding:utf-8");
    setIsDownload(false);
  };

  const closeModal = () => {
    setStatus(STATUS_OPTIONS.ALL);
    setFromDate(new Date());
    setToDate(new Date());
    setDownloadModal(false);
  };

  return (
    <Wrapper>
      <Helmet title={t("orders.orders")} />
      <Header />

      <div
        css={css`
          display: flex;
          flex-direction: column;
          padding: 60px;
          background-color: ${theme.colors.white};
          border-radius: 20px;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <div
            css={css`
              font-size: 30px;
              line-height: 42px;
            `}
          >
            {t("orders.orders")}
          </div>
          <div
            css={css`
              margin-left: 140px;
            `}
          >
            <StatusFilter
              label={t("orders.status.status") + ":"}
              options={statusOptions}
              defaultOption={type}
              onChangeOption={(e) => onSelectType(e.target.value)}
            />
          </div>
          <div
            css={css`
              margin: 0 0 0 auto;
            `}
          >
            <InputComponent
              css={css`
                min-width: 288px;
              `}
              value={query}
              placeholder={t("orders.search")}
              icon={<SearchSvg width={20} height={20} />}
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>

          <Button
            css={css`
              margin-left: 40px;
            `}
            icon={<DownloadSvg width={14} height={14} />}
            iconRight
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary};`}
            onClick={() => setDownloadModal(true)}
          >
            {t("order.csv-download")}
          </Button>

          <Modal
            modalIsOpen={downloadModal}
            onClose={() => closeModal()}
            contentLabel={`${t("actions.sure")}?`}
            contentStyles={{
              padding: "40px",
              minHeight: "600px",
            }}
          >
            <ModalHeader closeIcon onClose={() => closeModal()}>
              <div></div>
            </ModalHeader>

            <ModalBody>
              <Headers.H1
                css={css`
                  margin-bottom: 20px;
                `}
              >
                {t("order.csv-download-title")}
              </Headers.H1>
              <div
                css={css`
                  display: flex;
                  margin-bottom: 15px;
                `}
              >
                <DatePicker
                  date={fromDate}
                  label={t("order.from-date")}
                  onFilterChange={(value) =>
                    setFromDate(
                      DateTime.fromFormat(value, dateFormat).toJSDate()
                    )
                  }
                />

                <DatePicker
                  date={toDate}
                  label={t("order.to-date")}
                  onFilterChange={(value) =>
                    setToDate(DateTime.fromFormat(value, dateFormat).toJSDate())
                  }
                  css={css`
                    margin-left: 20px;
                  `}
                />
              </div>

              <SelectComponent
                label={t("order.status")}
                value={status}
                choices={statusOptions}
                small
                onChange={(e) => setStatus(e.target.value as STATUS_OPTIONS)}
              />
            </ModalBody>

            <ModalFooter
              css={css`
                position: absolute;
                bottom: 40px;
                left: 40px;
                justify-content: flex-start;
              `}
            >
              <Button
                onClick={() => download()}
                background={theme.colors.primary}
                isSubmitting={isDownload}
              >
                {t("order.csv-download")}
              </Button>

              <Button
                background={theme.colors.white}
                color={theme.colors.dark}
                hoverStyles={`background: ${theme.colors.white}; opacity: 0.85;`}
                onClick={() => closeModal()}
              >
                {t("actions.cancel")}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <OrdersTable forwardRef={ref} />
      </div>
      <Footer />
    </Wrapper>
  );
}
