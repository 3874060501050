import { useEffect, useMemo, useState } from "react";
import useInterval from "@use-it/interval";
import { calculateVideoDuration } from "../helpers/video";
import { Video } from "../types/Video";

const interval = 15;

export type Playback = {
  currentTime: number;
  videoDuration: number;
  playing: boolean;
  play: () => void;
  pause: () => void;
  setCurrentTime: (time: number) => void;
};

export function usePlayback(video: Video) {
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const videoDuration = useMemo(() => calculateVideoDuration(video), [video]);

  useInterval(() => {
    if (playing) {
      setCurrentTime((time) => {
        if (time + interval <= videoDuration) {
          return time + interval;
        }

        return time;
      });
    }
  }, interval);

  useEffect(() => {
    if (currentTime >= videoDuration) {
      setPlaying(false);
    }
  }, [currentTime, videoDuration]);

  return {
    currentTime,
    videoDuration,
    playing,
    play: () => {
      setPlaying(true);
    },
    pause: () => {
      setPlaying(false);
    },
    setCurrentTime: (time: number) => {
      setCurrentTime(time);
    },
  };
}
