import { authorised } from "../../request";
import { CourseAdditionalMaterial } from "../../types/CourseAdditionalMaterial";

type Args = {
  courseId: string;
}

export async function getAdditionalMaterials({ courseId }: Args) {
  const API = authorised();

  return API.request<CourseAdditionalMaterial[]>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/additional-materials/`,
  });
}
