import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Order, STATUS_OPTIONS } from "../../../types/admin/Order";
import { ReactComponent as DownloadSVG } from "../../../assets/icons/Download.svg";
import StatusFilter from "./StatusFilter";
import { getStatusColor } from "./getStatusColor";

export default function OrdersAction({
  order,
  onChangeStatus,
}: {
  order: Order;
  onChangeStatus: (uuid: string, status: string) => void;
}) {
  const { t } = useTranslation();

  const getInvoice = () => {
    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/api/campus/orders/${order.uuid}/invoice/`
    );
  };

  const statusOptions = [
    { value: STATUS_OPTIONS.COMPLETED, label: t("orders.status.completed") },
    { value: STATUS_OPTIONS.REFUNDED, label: t("orders.status.refunded") },
    {
      value: STATUS_OPTIONS.PENDING_PAYMENT,
      label: t("orders.status.pending"),
    },
    { value: STATUS_OPTIONS.CANCELED, label: t("orders.status.canceled") },
    { value: STATUS_OPTIONS.PROCESSING, label: t("orders.status.processing") },
    { value: STATUS_OPTIONS.PAYMENT_FAILED, label: t("orders.status.failed") },
  ];

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 40px;
      `}
    >
      <div
        css={css`
          display: flex;
          font-size: 30px;
          line-height: 42px;
          width: fit-content;
        `}
      >
        <div>
          {t("orders.order")} #{order.invoice_number}
        </div>
        <div
          css={css`
            width: 12px;
            height: 12px;
            background-color: ${getStatusColor(order.status)};
            border-radius: 50%;
            margin-left: 8px;
          `}
        />
      </div>
      {order.status && order.status !== STATUS_OPTIONS.DRAFT && (
        <div
          css={css`
            display: flex;
            margin-left: 42px;
            cursor: pointer;
          `}
          onClick={() => getInvoice()}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <DownloadSVG />
          </div>
          <div
            css={css`
              margin-left: 8px;
              font-size: 16px;
            `}
          >
            {t("orders.download")}
          </div>
        </div>
      )}
      <div
        css={css`
          margin: auto 0 auto auto;
        `}
      >
        <StatusFilter
          label={t("orders.status.change") + ":"}
          options={statusOptions}
          defaultOption={order.status}
          onChangeOption={(e) => onChangeStatus(order.uuid, e.target.value)}
        />
      </div>
    </div>
  );
}
