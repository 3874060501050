import { RequiredDataType } from "../../types/RequiredDataType";
import { authorised } from "../../request";
import { Course } from "../../types/Course";

export type CreateCourse = Pick<
  Course,
  | "name"
  | "description"
  | "learning_points"
  | "level"
  | "image"
  | "price"
  | "subtitle"
  | "summary_content_html"
  | "course_starts"
  | "course_for"
  | "visibility"
  | "has_certificate"
  | "logo"
> & {
  faq_items: RequiredDataType[];
};

export async function createCourse(data: CreateCourse) {
  const API = authorised();

  return API.request<Course>({
    method: "POST",
    url: `/api/studio/courses/`,
    data,
  });
}
