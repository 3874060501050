import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { useEnterprise } from "../../../contexts/EnterpriseContext";
import { Button } from "../../../components/Button";
import { Column } from "../../../helpers/layout";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { theme } from "../../../themes/variables";
import { EnterpriseBilling } from "../../../types/Enterprise";
import { InputField } from "../../fields/InputField";
import { InputMaskField } from "../../fields/InputMaskField";
import { InputComponent } from "../../../components/InputComponent";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { ReactComponent as DetailsIcon } from "../../../assets/icons/Details.svg";
import { ReactComponent as BankIcon } from "../../../assets/icons/Bank.svg";

export type FormValues = EnterpriseBilling;

export default function EnterpriseBillingSettingsForm({
  data,
  onSubmit,
}: {
  data: EnterpriseBilling;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const { enterprise } = useEnterprise();

  const onSubmitChanges = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit({
        ...values,
        vat_rate: String(Number(values.vat_rate) / 100),
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        border-radius: 20px;
        background-color: ${theme.colors.white};
        width: 100%;
        padding: 65px 60px;
        margin-bottom: 60px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          company_name: data.company_name,
          ceo: data.ceo,
          tax_number: data.tax_number,
          register_court: data.register_court,
          vat: data.vat,
          vat_rate: String(Number(data.vat_rate) * 100),
          registry_code: data.registry_code,
          invoice_due: data.invoice_due,
          invoice_prefix: data.invoice_prefix,

          iban: data.iban,
          bic: data.bic,
          bank_name: data.bank_name,
          bank_address: data.bank_address,
          billing_email: data.billing_email,

          stripe_public_api_key: data.stripe_public_api_key,
          stripe_secret_api_key: data.stripe_secret_api_key,
          stripe_webhook_endpoint_secret: data.stripe_webhook_endpoint_secret,
        }}
        validationSchema={Yup.object().shape({
          company_name: Yup.string(),
          ceo: Yup.string(),
          tax_number: Yup.string(),
          register_court: Yup.string(),
          vat: Yup.string(),
          vat_rate: Yup.number(),
          registry_code: Yup.string(),
          invoice_due: Yup.string(),
          invoice_prefix: Yup.string().max(30),

          iban: Yup.string(),
          bic: Yup.string(),
          bank_name: Yup.string(),
          bank_address: Yup.string(),
          billing_email: Yup.string().email(),

          stripe_public_api_key: Yup.string(),
          stripe_secret_api_key: Yup.string(),
        })}
        onSubmit={onSubmitChanges}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <div
              css={css`
                display: flex;
              `}
            >
              <Column
                css={css`
                  display: flex;
                  width: 80%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <DetailsIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.company_details")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.company_name")}
                  name={"company_name"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.ceo")}
                  name={"ceo"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.tax_number")}
                  name={"tax_number"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.register_court")}
                  name={"register_court"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.vat")}
                  name={"vat"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputMaskField
                  label={`${t("enterprise_settings.vat_rate")} (%)`}
                  name={"vat_rate"}
                  type="percent"
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.registry_code")}
                  name={"registry_code"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.invoice_due")}
                  name={"invoice_due"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.invoice_prefix")}
                  name={"invoice_prefix"}
                  css={css`
                    margin-bottom: 60px;
                  `}
                />

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <BankIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.bank_account")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.iban")}
                  name={"iban"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.bic")}
                  name={"bic"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.bank_name")}
                  name={"bank_name"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.bank_address")}
                  name={"bank_address"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.billing_email")}
                  name={"billing_email"}
                  css={css`
                    margin-bottom: 60px;
                  `}
                />
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <BankIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.stripe_integration")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.public_api_key")}
                  name={"stripe_public_api_key"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.secret_api_key")}
                  name={"stripe_secret_api_key"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t(
                    "enterprise_settings.stripe_webhook_endpoint_secret"
                  )}
                  name={"stripe_webhook_endpoint_secret"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputComponent
                  label={t("enterprise_settings.webhook_for_stripe")}
                  value={`${process.env.REACT_APP_BACKEND_URL}/api/campus/stripe-webhook/${enterprise.id}/`}
                  disabled
                />
              </Column>
              <Column>
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                    css={css`
                      display: flex;
                      margin: auto 0 0 auto;
                      font-size: 16px;
                      line-height: 18px;
                    `}
                    icon={
                      <ReadIcon
                        width={16}
                        height={16}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    <div
                      css={css`
                        margin-right: 11px;
                      `}
                    >
                      {t("enterprise_settings.save")}
                    </div>
                  </Button>
                </div>
              </Column>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
