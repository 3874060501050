import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { CoursePublishRequestStatus } from "../../../types/Course";
import { PublishRequest } from "../../../types/admin/PublishRequest";
import { theme } from "../../../themes/variables";
import { Headers } from "../../../helpers/layout";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../Modal";
import { TextareaComponent } from "../../TextareaComponent";
import { Button } from "../../Button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close.svg";

export default function RejectModal({
  rejectModal,
  publishRequestToReject,
  rejectionReason,
  loadingId,
  closeRejectionModal,
  setRejectionReason,
  updateStatus,
}: {
  rejectModal: boolean;
  publishRequestToReject: PublishRequest;
  rejectionReason: string;
  loadingId: number | null;
  closeRejectionModal: () => void;
  setRejectionReason: (response: string) => void;
  updateStatus: (
    id: number,
    status: CoursePublishRequestStatus,
    rejectionReason: string
  ) => void;
}) {
  const { t } = useTranslation();
  return (
    <Modal
      modalIsOpen={rejectModal}
      onClose={() => closeRejectionModal()}
      contentLabel={t("actions.reject")}
      contentStyles={{
        maxWidth: "560px",
        padding: "25px 40px 40px 40px",
      }}
    >
      <ModalHeader
        css={css`
          justify-content: flex-end;
        `}
        closeIcon
        onClose={() => closeRejectionModal()}
      />

      <ModalBody
        css={css`
          padding-top: 0;
        `}
      >
        <Headers.H2
          css={css`
            margin-bottom: 12px;
          `}
        >
          {t("actions.reject")}
        </Headers.H2>

        <Headers.H4
          css={css`
            margin-bottom: 60px;
          `}
        >
          {publishRequestToReject?.course.name}
        </Headers.H4>

        <div
          css={css`
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 16px;
          `}
        >{`${t("admin.publish-request.page.rejection-reason")}:`}</div>

        <TextareaComponent
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </ModalBody>

      <ModalFooter
        css={css`
          justify-content: flex-start;
        `}
      >
        <Button
          background={theme.colors.error}
          icon={<CloseIcon width={14} height={14} color={theme.colors.white} />}
          iconRight
          onClick={() => {
            updateStatus(
              publishRequestToReject.id,
              CoursePublishRequestStatus.REJECTED,
              rejectionReason
            );
            closeRejectionModal();
          }}
          isSubmitting={loadingId === publishRequestToReject?.id}
          disabled={loadingId === publishRequestToReject?.id}
        >
          {t("actions.reject")}
        </Button>

        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          onClick={() => closeRejectionModal()}
        >
          {t("actions.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
