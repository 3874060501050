import { authorised } from "../../request";

export async function readAllNotifications() {
  const API = authorised();

  return API.request({
    method: "POST",
    url: `/api/studio/notification/read-all/`
  });
}
