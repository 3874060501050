import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getEnterpriseAddressSettings } from "../../../actions/admin/settings/getEnterpriseAddressSettings";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import EnterpriseAddressSettingsForm from "../../../forms/admin/settings/EnterpriseAddressSettingsForm";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { FormValues } from "../../../forms/admin/settings/EnterpriseAddressSettingsForm";
import { updateEnterpriseAddressSettings } from "../../../actions/admin/settings/updateEnterpriseAddressSettings";
import { customToast } from "../../../components/customToast";
import { Footer } from "../../../components/Footer";

export default function EnterpriseAddressSettings() {
  const { t } = useTranslation();

  const {
    data: addressSettings,
    error: addressSettingsError,
    setData,
  } = useNewFetch(getEnterpriseAddressSettings);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const response = await updateEnterpriseAddressSettings(values);

      customToast.success(t("status.success"));
      setData(response.data);
    },
    [setData, t]
  );

  if (!addressSettings || addressSettingsError) {
    return pageLoading(addressSettingsError);
  }

  const tabsData = [
    {
      title: t("enterprise_settings.general"),
      link: `/admin/settings/general`,
    },
    {
      title: t("enterprise_settings.address"),
      link: `/admin/settings/address`,
      forceHightlight: true,
    },
    {
      title: t("enterprise_settings.billing"),
      link: `/admin/settings/billing`,
    },
    {
      title: t("enterprise_settings.contact"),
      link: `/admin/settings/contact`,
    },
    {
      title: "DRM",
      link: `/admin/settings/drm`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("enterprise_settings.address")}</title>
        </SEO>
        <Helmet title={t("enterprise_settings.address")} />
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />
      <Wrapper>
        <EnterpriseAddressSettingsForm
          data={addressSettings}
          onSubmit={onSubmit}
        />
        <Footer />
      </Wrapper>
    </>
  );
}
