import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { Block } from "../../../components/Block";
import { Header } from "../../../components/Header";
import {
  EditCreateSelfAssessment,
  FormValues,
} from "../../../components/SelfAssessmentEditor/EditCreateSelfAssessment";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import { createSignUpSelfAssessment } from "../../../actions/signUpSelfAssessments/createSignUpSelfAssessment";
import { Footer } from "../../../components/Footer";

export function NewSignUpSelfAssessmentItem() {
  const { t } = useTranslation();
  const history = useHistory();

  const tabsData = [
    {
      title: t("self-assessment.questions"),
      link: `/admin/self-assessment`,
      forceHightlight: true,
    },
    {
      title: t("self-assessment.performance"),
      link: `/admin/self-assessment/performance`,
    },
  ];

  const submitUpdateTest = async (data: FormValues) => {
    await createSignUpSelfAssessment({
      data: {
        ...data,
        answers: data.answers ? data.answers : [],
      },
    });

    customToast.success(t("status.success"));

    history.push(`/admin/self-assessment`);
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("self-assessment.new")}`</title>
        </SEO>
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <div>
            <EditCreateSelfAssessment
              onSubmitCallback={(data) => submitUpdateTest(data)}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
