import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { SEO } from "../../components/SEO";
import { PerformancePeriod } from "../../actions/performance/getCoursePerformanceChart";
import { PerformanceFilters } from "./Filters";
import { PerformanceWidgets } from "./Widgets";
import { PerformanceChart } from "./Chart";
import { PerformanceCourses } from "./Courses";
import { Headers } from "../../helpers/layout";
import { Footer } from "../../components/Footer";

export function DashboardPage(props: {}) {
  const { t } = useTranslation();

  const [period, setPeriod] = useState<PerformancePeriod>(
    PerformancePeriod.WEEK
  );

  const [journeyUUID, setJourneyUUID] = useState<string | null>(null);
  const [segmentUUID, setSegmentUUID] = useState<string | null>(null);

  return (
    <Wrapper>
      <SEO>
        <title>{t("performance.dashboard")}</title>
      </SEO>
      <Header />
      <Block>
        <PerformanceFilters
          period={period}
          segmentUUID={segmentUUID}
          onPeriodChange={(period) => setPeriod(period)}
          journeyUUID={journeyUUID}
          onJourneyChange={(journey) => setJourneyUUID(journey)}
          onSegmentChange={(segment) => setSegmentUUID(segment)}
        />
      </Block>
      <Block>
        <div
          css={css`
            margin-bottom: 30px;
          `}
        >
          <PerformanceWidgets
            period={period}
            journeyUUID={journeyUUID}
            segmentUUID={segmentUUID}
          />
        </div>
        <div
          css={css`
            min-height: 50vh;
          `}
        >
          <PerformanceChart
            period={period}
            journeyUUID={journeyUUID}
            segmentUUID={segmentUUID}
          />
        </div>
      </Block>

      <Block>
        <Headers.H3
          css={css`
            margin-bottom: 32px;
          `}
        >
          {t("performance.all-courses")}
        </Headers.H3>
        <PerformanceCourses
          period={period}
          journeyUUID={journeyUUID}
          segmentUUID={segmentUUID}
        />
      </Block>
      <Footer />
    </Wrapper>
  );
}
