import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";

import { Field, FieldProps } from "formik";
import { InputMaskComponent } from "../../components/InputMaskComponent";
import { InputErrors } from "./InputErrors";

export function InputMaskField(props: {
  id?: string;
  name: string;
  type?: "time" | "price" | "percent";
  label?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxLength?: number;
  className?: string;
  mask?: Array<RegExp | string> | ((value: string) => Array<RegExp | string>);
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  validate?: (value: string) => Promise<void | string>;
}) {
  const {
    id,
    name,
    disabled,
    label,
    maxLength,
    placeholder,
    readonly,
    type,
    className,
    mask,
    onChange,
    validate,
  } = props;

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => {
        return (
          <div
            css={css`
              margin-bottom: 32px;
            `}
            className={className}
          >
            <InputMaskComponent
              id={id}
              name={name}
              label={label}
              disabled={disabled}
              readonly={readonly}
              placeholder={placeholder}
              type={type}
              onChange={onChange}
              field={field}
              mask={mask}
              error={!!meta.error && meta.touched}
            />

            <InputErrors
              currentLength={String(field.value).length}
              maxLength={maxLength}
              error={meta.error}
              touched={meta.touched}
            />
          </div>
        );
      }}
    </Field>
  );
}
