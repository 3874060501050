import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro";
import { ChevronRight } from "react-feather";
import { theme } from "../themes/variables";

export function Breadcrumbs(props: {
  items: {
    label: string;
    url: string;
  }[];
  className?: string;
}) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin: 20px 0;
      `}
    >
      {props.items.map((item, idx) => {
        const isNotLast = idx < props.items.length - 1;

        return (
          <Fragment key={idx}>
            <div
              css={css`
                color: ${isNotLast ? theme.colors.gray6 : theme.colors.dark};
              `}
            >
              {item.url ? (
                <Link to={item.url}>{item.label}</Link>
              ) : (
                <div>{item.label}</div>
              )}
            </div>
            {(isNotLast || props.items.length === 1) && (
              <ChevronRight size={20} color={theme.colors.gray6} />
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
