import React from "react";
import { Video, VideoElement, VideoElementState } from "../../types/Video";
import { css } from "styled-components/macro";
import {
  calculateElementDuration,
  calculateElementStartingTime,
  calculateVideoDuration,
} from "../../helpers/video";
import { Rnd } from "react-rnd";
import { useTranslation } from "react-i18next";
import { Headers } from "../../helpers/layout";

export function EditVideoElements(props: {
  video: Video;
  currentTime: number;
  onVideoUpdate: (video: Video) => void;
  onNewStateClick: (elementId: string, lastState: VideoElementState) => void;
}) {
  const { video, currentTime } = props;
  const videoDuration = calculateVideoDuration(video);
  const { t } = useTranslation();

  return (
    <div>
      <div
        css={css`
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #ccc;
        `}
      >
        <Headers.H4>{t("video_editor.scene.edit_elements_label")}:</Headers.H4>
      </div>
      <div
        css={css`
          position: relative;
        `}
      >
        {video.schema.elements.map((element) => {
          const startingTime = calculateElementStartingTime(element);
          const duration = calculateElementDuration(element);
          const showElement =
            currentTime >= startingTime &&
            currentTime <= startingTime + duration;

          const lastState = element.states[element.states.length - 1];

          return (
            <div
              key={element.id}
              css={css`
                position: relative;
                width: 100%;
                height: 13px;
                margin-bottom: 2px;
                font-size: 10px;
                opacity: 1;
                transition: all 400ms;
                text-transform: uppercase;

                ${!showElement &&
                css`
                  height: 2px;
                  font-size: 0;
                `}
              `}
            >
              {element.states.map((state) => {
                const width = (state.duration / videoDuration) * 100;
                const left = state.start_time / videoDuration;

                return (
                  <Rnd
                    key={state.id}
                    size={{
                      width: `${width}%`,
                      height: "100%",
                    }}
                    position={{
                      x: left * 800,
                      y: 0,
                    }}
                    css={css`
                      background: rgba(121, 162, 181, 0.45);
                      text-align: center;

                      ${!showElement &&
                      css`
                        background: #ccc;
                      `}

                      &:first-child {
                        border-radius: 5px 0 0 5px;
                      }

                      &:last-child {
                        border-radius: 0 5px 5px 0;
                      }
                    `}
                    dragAxis="x"
                    enableResizing={{
                      bottom: false,
                      bottomLeft: false,
                      bottomRight: false,
                      left: false,
                      right: true,
                      top: false,
                      topLeft: false,
                      topRight: false,
                    }}
                    onDragStop={(e, d) => {
                      const startingTime = (d.x / 800) * videoDuration;

                      props.onVideoUpdate({
                        ...video,
                        schema: {
                          ...video.schema,
                          elements: video.schema.elements.map((el) => {
                            if (el.id === element.id) {
                              return {
                                ...el,
                                states: el.states.map((s) => {
                                  if (s.id === state.id) {
                                    return {
                                      ...s,
                                      start_time: startingTime,
                                    };
                                  }

                                  return s;
                                }),
                              };
                            }

                            return el;
                          }),
                        },
                      });
                    }}
                    onResizeStop={(e, direction, ref) => {
                      const duration =
                        (parseFloat(ref.style.width) / 100) * videoDuration;

                      props.onVideoUpdate({
                        ...video,
                        schema: {
                          ...video.schema,

                          elements: video.schema.elements.map((el) => {
                            if (el.id === element.id) {
                              return {
                                ...el,
                                states: el.states.map((s) => {
                                  if (s.id === state.id) {
                                    return {
                                      ...s,
                                      duration: duration,
                                    };
                                  }

                                  return s;
                                }),
                              };
                            }

                            return el;
                          }),
                        },
                      });
                    }}
                  >
                    {Math.floor(state.duration / 1000)}s
                    {showElement && (
                      <button
                        css={css`
                          position: absolute;
                          top: 1px;
                          right: 5px;
                          width: 10px;
                          height: 10px;
                          line-height: 10px;
                          font-size: 10px;
                          background: #fff;
                          border-radius: 50%;
                          border: none;
                          cursor: pointer;
                        `}
                        onClick={() => {
                          props.onVideoUpdate({
                            ...video,
                            schema: {
                              ...video.schema,
                              elements: video.schema.elements.reduce(
                                (elements: VideoElement[], el) => {
                                  if (el.id === element.id) {
                                    const states = el.states.filter((s) => {
                                      if (s.id === state.id) {
                                        return false;
                                      }

                                      return true;
                                    });

                                    if (states.length > 0) {
                                      return [
                                        ...elements,
                                        {
                                          ...el,
                                          states,
                                        },
                                      ];
                                    } else {
                                      return elements;
                                    }
                                  }

                                  return [...elements, el];
                                },
                                []
                              ),
                            },
                          });
                        }}
                      >
                        x
                      </button>
                    )}
                  </Rnd>
                );
              })}
              <button
                css={css`
                  position: absolute;
                  left: ${((lastState.start_time + lastState.duration) /
                    videoDuration) *
                  100}%;
                  margin-left: 10px;
                  height: 15px;
                  padding: 0;
                  width: 15px;
                  line-height: 15px;
                  text-align: center;
                  opacity: 0;
                  background: #e85b2e;
                  color: #fff;
                  border: none;
                  border-radius: 50%;

                  ${showElement &&
                  css`
                    opacity: 1;
                  `}
                `}
                onClick={() => {
                  props.onNewStateClick(
                    element.id,
                    element.states[element.states.length - 1]
                  );
                }}
              >
                +
              </button>
            </div>
          );
        })}
        <div
          css={css`
            position: absolute;
            width: 1px;
            height: calc(100% + 20px);
            left: ${(props.currentTime / videoDuration) * 100}%;
            background: red;
            top: -10px;
          `}
        ></div>
      </div>
    </div>
  );
}
