import { authorised } from "../../request";
import { Notification } from "../../types/Notification";

export type UpdateNotification = Pick<
  Notification,
  "read"
>;

export async function updateNotification(id: number, data: UpdateNotification) {
  const API = authorised();

  return API.request<Notification>({
    method: "PUT",
    url: `/api/studio/notification/${id}/`,
    data,
  });
}
