import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { css } from "styled-components/macro";
import { getOrders } from "../../../actions/admin/orders/getOrders";
import { formatDate } from "../../../helpers/time";
import { theme } from "../../../themes/variables";
import {
  Order,
  PAYMENT_OPTIONS,
  STATUS_OPTIONS,
} from "../../../types/admin/Order";
import { Avatar } from "../../Avatar";
import { Button } from "../../Button";
import { TableHandler, TableHandlerColumn } from "../../TableHandler";
import { getStatusColor } from "./getStatusColor";
import { ReactComponent as DocSvg } from "../../../assets/icons/DocOrange.svg";

export default function OrdersTable({ forwardRef }: { forwardRef: any }) {
  const { t } = useTranslation();
  const history = useHistory();

  const getType = (type: PAYMENT_OPTIONS) => {
    return type === PAYMENT_OPTIONS.BANK_TRANSFER
      ? t("orders.bank_transfer")
      : type === PAYMENT_OPTIONS.CREDIT_CARD
      ? t("orders.credit_card")
      : type === PAYMENT_OPTIONS.PAYPAL
      ? t("orders.paypal")
      : type === PAYMENT_OPTIONS.DIRECT_DEBIT
      ? t("orders.direct_debit")
      : "";
  };

  const getStatus = (status: STATUS_OPTIONS) => {
    return status === STATUS_OPTIONS.COMPLETED
      ? t("orders.status.completed")
      : status === STATUS_OPTIONS.PAYMENT_FAILED
      ? t("orders.status.failed")
      : status === STATUS_OPTIONS.REFUNDED
      ? t("orders.status.refunded")
      : status === STATUS_OPTIONS.PENDING_PAYMENT
      ? t("orders.status.pending")
      : status === STATUS_OPTIONS.CANCELED
      ? t("orders.status.canceled")
      : status === STATUS_OPTIONS.PROCESSING
      ? t("orders.status.processing")
      : status === STATUS_OPTIONS.DRAFT
      ? t("orders.status.draft")
      : status;
  };

  const tableHandlerColumns: TableHandlerColumn<Order>[] = [
    {
      label: t("orders.invoice"),
      orderFields: ["invoice_number"],
      render: (row) => <div>{row.invoice_number}</div>,
    },
    {
      label: t("orders.user"),
      orderFields: ["initiator"],
      render: (row) => (
        <div
          css={css`
            display: flex;
            white-space: nowrap;
          `}
        >
          <Avatar
            url={row.initiator.avatar?.image}
            size={20}
            css={css`
              margin-right: 8px;
            `}
          />
          {row.initiator.first_name + " " + row.initiator.last_name}
        </div>
      ),
    },
    {
      label: t("orders.payment_type"),
      orderFields: ["payment_type"],
      render: (row) => <div>{getType(row.payment_type)}</div>,
    },
    {
      label: t("orders.date"),
      orderFields: ["created"],
      render: (row) => <div>{formatDate(row.created)}</div>,
    },
    {
      label: t("orders.status.status"),
      orderFields: ["status"],
      render: (row) => (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 8px;
              height: 8px;
              margin-right: 8px;
              background-color: ${getStatusColor(row.status)};
              border-radius: 50%;
            `}
          ></div>
          {getStatus(row.status)}
        </div>
      ),
    },
    {
      label: t("orders.total_price"),
      orderFields: ["total"],
      render: (row) => <div>{row.total + " EUR"}</div>,
    },
    {
      label: t("orders.actions"),
      render: (row) => (
        <div
          css={css`
            display: flex;
            white-space: nowrap;
          `}
        >
          <Button
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.dark}`}
            color={theme.colors.dark}
            small
            onClick={() => {
              history.push(`/admin/orders/${row.uuid}`);
            }}
            icon={<DocSvg width={14} height={14} color={theme.colors.error} />}
            iconRight
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.primary}; border: 1px solid ${theme.colors.transparent};`}
          >
            {t("orders.view_order")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <TableHandler
        forwardRef={forwardRef}
        tableData={{
          columns: tableHandlerColumns,
        }}
        getFunction={getOrders}
        options={{
          internalArgs: {
            type: STATUS_OPTIONS.ALL,
          },
        }}
      />
    </div>
  );
}
