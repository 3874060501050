import React from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  TableHandler,
  TableHandlerColumn,
} from "../../components/TableHandler";
import { Button } from "../../components/Button";
import { CoursePerformanceTableItem } from "../../types/Performance";
import { theme } from "../../themes/variables";

import {
  getCoursePerformanceList,
  PerformancePeriod,
} from "../../actions/performance/getCoursePerformanceList";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";

export function PerformanceCourses(props: {
  period: PerformancePeriod;
  journeyUUID: string | null;
  segmentUUID: string | null;
}) {
  const { t } = useTranslation();

  const history = useHistory();

  const tableHandlerColumns: TableHandlerColumn<CoursePerformanceTableItem>[] =
    [
      {
        label: t("performance.course-name"),
        orderFields: ["name"],
        render: (row) => <div>{row.name}</div>,
      },
      {
        label: t("performance.enrolments"),
        render: (row) => <div>{row.course_enrolments_count}</div>,
      },
      {
        label: t("performance.course-completions"),
        render: (row) => <div>{row.course_completions}</div>,
      },
      {
        label: t("performance.avg-exam-success-rate"),
        render: (row) => <div>{row.avg_exam_success_rate}%</div>,
      },
      {
        label: t("performance.avg-test-success-rate"),
        render: (row) => <div>{row.avg_test_success_rate}%</div>,
      },
      {
        label: t("performance.actions"),
        render: (row) => {
          if (row.is_external) {
            return null;
          }

          return (
            <div
              css={css`
                display: flex;
              `}
            >
              <Button
                background={theme.colors.transparent}
                border={`1px solid ${theme.colors.blueBackground}`}
                color={theme.colors.blueBackground}
                icon={
                  <EditIcon
                    width={14}
                    height={14}
                    color={theme.colors.blueBackground}
                  />
                }
                hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
                iconRight
                small
                onClick={() => history.push(`/courses/${row.uuid}/edit`)}
                css={css`
                  margin-right: 16px;
                `}
              >
                {t("actions.edit")}
              </Button>
            </div>
          );
        },
      },
    ];

  return (
    <TableHandler
      getFunction={getCoursePerformanceList}
      options={{
        limit: 10,
        internalArgs: {
          period: props.period,
          journey_id: props.journeyUUID || undefined,
          segment_id: props.segmentUUID || undefined,
        },
      }}
      tableData={{
        columns: tableHandlerColumns,
      }}
    />
  );
}
