import { authorised } from "../../request";
import { CourseLesson } from "../../types/CourseLesson";

export async function updateCourseLessonOrder(data: {
  courseId: string;
  items: CourseLesson[];
}) {
  const API = authorised();

  return API.request<CourseLesson[]>({
    method: "PUT",
    url: `/api/studio/courses/${data.courseId}/reorder/`,
    data: {
      items: data.items,
    },
  });
}
