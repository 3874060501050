import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Spacing } from "../helpers/layout";
import { Image, ImageType } from "../types/Image";
import { Button } from "../components/Button";
import { ImageUploadModal } from "../modals/ImageUploadModal";
import { InputLabel } from "./InputLabel";

const checkIsString = (p: any): p is string => {
  return p && typeof p === "string";
};

export function ImageUpload(props: {
  id?: string;
  label?: string;
  className?: string;
  disableLibrary?: boolean;
  image?: Image | string | null;
  type?: ImageType;
  onChange: (image: Image | string | null) => void;
  uploadFn: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<Image | string>;
}) {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  return (
    <div
      id={props.id}
      className={props.className}
      css={css`
        margin-bottom: 32px;
      `}
    >
      <InputLabel label={props.label} />

      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            width: 130px;
            height: 130px;
          `}
        >
          {props.image ? (
            <img
              src={checkIsString(props.image) ? props.image : props.image.image}
              alt={
                checkIsString(props.image)
                  ? props.image
                  : props.image.upload_name
              }
              css={css`
                border-radius: 10px;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
              `}
            />
          ) : (
            <div
              css={css`
                width: 100%;
                height: 100%;
                background: ${theme.colors.gray2};
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              `}
              onClick={() => setModal(true)}
            >
              {t("media.no-image")}
            </div>
          )}
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin-left: ${Spacing.xl};
            > button {
              margin: ${Spacing.xs};
            }
          `}
        >
          <Button onClick={() => setModal(true)}>{t("actions.change")}</Button>

          {props.image && (
            <Button
              background={theme.colors.transparent}
              color={theme.colors.dark}
              hoverStyles={`opacity: 0.85;`}
              onClick={() => props.onChange(null)}
            >
              <span
                css={css`
                  text-decoration: underline;
                `}
              >
                {t("actions.delete")}
              </span>
            </Button>
          )}
        </div>
      </div>

      {modal && (
        <ImageUploadModal
          isVisible={modal}
          onSelect={async (image) => {
            props.onChange(image);
          }}
          onClose={() => {
            setModal(false);
          }}
          type={props.type}
          disableLibrary={props.disableLibrary}
          onUpload={async ({ file, title, type, onUploadProgress }) => {
            const image = await props.uploadFn({
              file: file,
              title: title,
              type: type,
              onUploadProgress,
            });

            if (image) {
              props.onChange(image);
            }
          }}
        />
      )}
    </div>
  );
}
