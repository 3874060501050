import { authorised } from "../../../request";
import { Page } from "../../../types/admin/Page";

type Args = {
  pageId: string | number;
}

export async function getPage({pageId}: Args) {
  const API = authorised();

  return API.request<Page>({
    method: "GET",
    url: `/api/enterprise/pages/${pageId}/`
  });
}
