import { authorised } from "../../request";
import { CheatSheet } from "../../types/CheatSheet";

type Args = {
  courseId: string;
  cheatSheetId: string;
  data: Pick<CheatSheet, "name" | "text_content" | "icon" | "lesson">;
}

export async function updateCheatSheet({courseId, cheatSheetId, data}: Args) {
  const API = authorised();

  return API.request<CheatSheet>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/cheat-sheet/${cheatSheetId}/`,
    data
  });
}
