import React, { useEffect, useRef, useState } from "react";
import { css } from "styled-components/macro";
import { customToast } from "../components/customToast";
import { theme } from "../themes/variables";
import { Row } from "../helpers/layout";
import { DropImage, DroppedImage } from "../components/DropImage";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../components/Modal";
import { ImagesRow } from "../components/ImagesRow";
import { InputComponent } from "../components/InputComponent";
import { serverErrorHandler } from "../helpers/serverErrorHandler";
import { Image, ImageTag, ImageType } from "../types/Image";
import { getImages } from "../actions/image";
import { getImageTags } from "../actions/imageTag";
import { useNewFetch } from "../useAPI";
import { pageLoading } from "../helpers/pageLoading";
import { Headers } from "../helpers/layout";
import { ReactComponent as DownloadImageIcon } from "../assets/icons/DownloadImage.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/Search.svg";
import { ReactComponent as GalleryIcon } from "../assets/icons/Gallery.svg";
import { ReactComponent as LeftIcon } from "../assets/icons/Left.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/Cross.svg";

const limit = 10;

enum ModalState {
  INITIAL = "INITIAL",
  SEARCH = "SEARCH",
  LIBRARY = "LIBRARY",
  LIBRARY_SEARCH = "LIBRARY_SEARCH",
}

const checkDroppedImage = (p: any): p is DroppedImage => {
  return p && p.hasOwnProperty("file");
};

export function ImageUploadModal(props: {
  isVisible: boolean;
  disableLibrary?: boolean;
  type?: ImageType;
  onSelect?: (image: Image) => void;
  onUpload: (args: {
    file: File;
    title?: string | null;
    type?: ImageType;
    onUploadProgress?: (progressEvent: any) => void;
  }) => Promise<void>;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState<ModalState>(ModalState.INITIAL);

  const [type, setType] = useState<ImageType>(
    props.type || ImageType.USER_UPLOADS
  );

  const [imageTag, setImageTag] = useState<ImageTag | null>(null);

  const [searchText, setSearchText] = useState<string | null>("");

  const [maxImagesCount, setMaxImagesCount] = useState<number | null>(null);

  const [loadedImages, setLoadedImages] = useState<Image[]>([]);

  const offset = useRef(0);

  const [selectedImage, setSelectedImage] = useState<
    Image | DroppedImage | null
  >(null);

  const [droppedImage, setDroppedImage] = useState<DroppedImage | null>(null);

  const [loadMore, setLoadmore] = useState(false);

  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(0);

  const { data: imageTags, error: imageTagsError } = useNewFetch(getImageTags);

  const clear = () => {
    setLoadedImages([]);
    setDroppedImage(null);
    setSearchText("");
    setImageTag(null);
    setMaxImagesCount(0);
  };

  useEffect(() => {
    if (modalState === ModalState.INITIAL) {
      clear();
    }
  }, [modalState]);

  useEffect(() => {
    setLoadedImages([]);

    getImages({
      limit,
      name: searchText,
      offset: 0,
      type: type,
      tag: imageTag?.title,
    }).then((data) => {
      setLoadedImages(() => {
        const newImages = data.data.results;

        offset.current = newImages.length;

        return newImages;
      });
      setMaxImagesCount(data.data.count);
    });
  }, [searchText, props.isVisible, type, imageTag]);

  useEffect(() => {
    if (!loadMore) return;

    getImages({
      limit,
      offset: offset.current,
      name: searchText,
      type: type,
      tag: imageTag?.title,
    }).then((data) => {
      setLoadedImages((prev) => {
        const newImages = [...prev, ...data.data.results];

        offset.current = newImages.length;

        return newImages;
      });

      setMaxImagesCount(data.data.count);
      setLoadmore(false);
    });
  }, [imageTag, loadMore, type, searchText]);

  useEffect(() => {
    if (
      modalState === ModalState.LIBRARY ||
      modalState === ModalState.LIBRARY_SEARCH
    ) {
      setType(ImageType.LIBRARY);
    } else {
      setType(props.type || ImageType.USER_UPLOADS);
    }
  }, [modalState, props.type]);

  if (!imageTags || imageTagsError) {
    return pageLoading(imageTagsError);
  }

  const haveMoreToLoad =
    modalState !== ModalState.INITIAL &&
    maxImagesCount !== null &&
    loadedImages.length < maxImagesCount;

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    setProgress(percentCompleted);
  };

  const onUpload = async (image: DroppedImage) => {
    try {
      setLoading(true);
      setProgress(0);

      await props.onUpload({
        type: props.type,
        file: image.file,
        onUploadProgress,
      });

      setLoading(false);
      setProgress(0);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const onConfirm = async () => {
    if (checkDroppedImage(selectedImage) && droppedImage) {
      await onUpload(droppedImage);
    } else if (props.onSelect) {
      props.onSelect(selectedImage as Image);
    }

    onClose();
  };

  const onClose = () => {
    setModalState(ModalState.INITIAL);
    props.onClose();
    setMaxImagesCount(null);
    setSelectedImage(null);
    setDroppedImage(null);
    setLoadedImages([]);
  };

  return (
    <Modal
      modalIsOpen={props.isVisible}
      onClose={onClose}
      contentStyles={{
        padding: "25px 40px 40px 40px",
        backgroundColor: theme.colors.gray1,
        maxWidth: "1040px",
      }}
    >
      <ModalHeader
        onClose={onClose}
        closeIcon
        css={css`
          ${modalState === ModalState.INITIAL && `justify-content: flex-end;`}
        `}
      >
        {modalState !== ModalState.INITIAL ? (
          <Button
            icon={<LeftIcon width={20} height={20} />}
            flat
            background={theme.colors.transparent}
            color={theme.colors.dark}
            hoverStyles={`opacity: 0.85;`}
            css={css`
              font-size: 16px;
              line-height: 22px;
              font-weight: normal;
            `}
            onClick={() => {
              setModalState(ModalState.INITIAL);
            }}
          >
            {t("media.back")}
          </Button>
        ) : null}
      </ModalHeader>

      <ModalBody>
        {modalState === ModalState.LIBRARY && !props.disableLibrary ? (
          <div>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-bottom: 25px;
              `}
            >
              <Headers.H4>{t("media.library")}</Headers.H4>

              <div
                css={css`
                  display: flex;
                  > div {
                    cursor: pointer;
                    font-size: 16px;
                    line-height: 22px;
                    margin-right: 40px;
                  }
                `}
              >
                <div
                  css={css`
                    color: ${imageTag === null
                      ? theme.colors.dark
                      : theme.colors.gray4};
                    border-bottom: 2px solid
                      ${imageTag === null
                        ? theme.colors.primary
                        : theme.colors.transparent};
                  `}
                  onClick={() => setImageTag(null)}
                >
                  {t("feedback.all")}
                </div>
                {imageTags.map((el, key) => {
                  return (
                    <div
                      key={key}
                      css={css`
                        color: ${imageTag === el
                          ? theme.colors.dark
                          : theme.colors.gray4};
                        border-bottom: 2px solid
                          ${imageTag === el
                            ? theme.colors.primary
                            : theme.colors.transparent};
                      `}
                      onClick={() => setImageTag(el)}
                    >
                      {el.title}
                    </div>
                  );
                })}
              </div>

              <Button
                flat
                background={theme.colors.transparent}
                color={theme.colors.dark}
                hoverStyles={`opacity: 0.85;`}
                icon={<SearchIcon width={20} height={20} />}
                onClick={() => setModalState(ModalState.LIBRARY_SEARCH)}
                css={css`
                  font-size: 16px;
                `}
              >
                {t("media.library-search")}
              </Button>
            </div>

            <ImagesRow
              value={checkDroppedImage(selectedImage) ? null : selectedImage}
              onSelect={(img) => setSelectedImage(img)}
              onDoubleClick={async (img) => {
                setSelectedImage(img);
                await onConfirm();
              }}
              images={loadedImages}
            />
          </div>
        ) : modalState === ModalState.LIBRARY_SEARCH &&
          !props.disableLibrary ? (
          <div>
            <div
              css={css`
                margin-bottom: 25px;
              `}
            >
              <InputComponent
                placeholder={t("media.logo")}
                icon={<SearchIcon width={20} height={20} />}
                css={css`
                  border-radius: 20px;
                `}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <ImagesRow
              value={checkDroppedImage(selectedImage) ? null : selectedImage}
              onSelect={(img) => setSelectedImage(img)}
              onDoubleClick={async (img) => {
                setSelectedImage(img);
                await onConfirm();
              }}
              images={loadedImages}
            />
          </div>
        ) : modalState === ModalState.SEARCH && !props.disableLibrary ? (
          <div>
            <div
              css={css`
                margin-bottom: 25px;
              `}
            >
              <InputComponent
                placeholder={t("media.logo")}
                icon={<SearchIcon width={20} height={20} />}
                css={css`
                  border-radius: 20px;
                `}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>

            <ImagesRow
              value={checkDroppedImage(selectedImage) ? null : selectedImage}
              onSelect={(img) => setSelectedImage(img)}
              onDoubleClick={async (img) => {
                setSelectedImage(img);
                await onConfirm();
              }}
              images={loadedImages}
            />
          </div>
        ) : (
          <>
            <DropImage
              onImageDropped={(image) => {
                setDroppedImage(image);
                setSelectedImage(image);
              }}
              onImageDeleted={() => {
                setDroppedImage(null);
                if (checkDroppedImage(selectedImage)) {
                  setSelectedImage(null);
                }
              }}
              selectedImage={
                checkDroppedImage(selectedImage) ? selectedImage : null
              }
              onClickPreview={() => setSelectedImage(droppedImage)}
              initialMessage={
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                  `}
                >
                  <DownloadImageIcon
                    css={css`
                      margin-bottom: 12px;
                    `}
                  />
                  <div>
                    <Trans i18nKey="media.drag-and-drop">
                      Drag and drop your images here, or
                      <span
                        css={css`
                          color: ${theme.colors.primary};
                        `}
                      >
                        browse
                      </span>
                      from your computer
                    </Trans>
                  </div>
                  <div
                    css={css`
                      font-size: 16px;
                      line-height: 22px;
                      color: ${theme.colors.gray6};
                    `}
                  >
                    {t("media.supported-formats")}: Png, Jpeg, JPG
                  </div>
                </div>
              }
            />

            {!props.disableLibrary && (
              <>
                <Row
                  justify="space-between"
                  css={css`
                    margin: 30px 0 20px 0;
                  `}
                >
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("media.recent")}:
                  </div>

                  <div>
                    <Button
                      flat
                      background={theme.colors.transparent}
                      color={theme.colors.dark}
                      hoverStyles={`opacity: 0.85;`}
                      icon={<GalleryIcon width={20} height={20} />}
                      onClick={() => setModalState(ModalState.LIBRARY)}
                      css={css`
                        font-size: 16px;
                        margin-right: 40px;
                      `}
                    >
                      {t("media.library-select")}
                    </Button>

                    <Button
                      flat
                      background={theme.colors.transparent}
                      color={theme.colors.dark}
                      hoverStyles={`opacity: 0.85;`}
                      icon={<SearchIcon width={20} height={20} />}
                      onClick={() => setModalState(ModalState.SEARCH)}
                      css={css`
                        font-size: 16px;
                      `}
                    >
                      {t("media.search")}
                    </Button>
                  </div>
                </Row>

                <ImagesRow
                  value={
                    checkDroppedImage(selectedImage) ? null : selectedImage
                  }
                  onSelect={(img) => setSelectedImage(img)}
                  onDoubleClick={async (img) => {
                    setSelectedImage(img);
                    await onConfirm();
                  }}
                  images={loadedImages}
                />
              </>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {haveMoreToLoad && (
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 0;
              width: 100%;
            `}
          >
            <Button
              flat
              icon={
                <CrossIcon
                  width={20}
                  height={20}
                  color={theme.colors.primary}
                />
              }
              background={theme.colors.transparent}
              color={theme.colors.dark}
              hoverStyles={`opacity: 0.85;`}
              onClick={() => {
                setLoadmore(true);
              }}
              css={css`
                font-weight: bold;
              `}
            >
              {t("media.load")}
            </Button>
          </div>
        )}

        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          border={`1px solid ${theme.colors.dark}`}
          hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
          onClick={onClose}
          css={css`
            min-width: auto;
            z-index: 1000;
          `}
        >
          {t("actions.cancel")}
        </Button>

        <Button
          onClick={() => onConfirm()}
          disabled={!selectedImage || loading}
          background={
            loading
              ? `linear-gradient(90deg, ${theme.colors.dark} ${progress}%, ${theme.colors.gray3} 0%)`
              : !!selectedImage
              ? theme.colors.dark
              : theme.colors.gray3
          }
          color={theme.colors.white}
          css={css`
            min-width: auto;
            z-index: 1000;
          `}
        >
          {t("actions.select")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
