import { authorised } from "../../../request";
import { Skill } from "../../../types/admin/Skill";

type Args = {
  skillId: string | number;
}

export async function getSkill({skillId}: Args) {
  const API = authorised();

  return API.request<Skill>({
    method: "GET",
    url: `/api/enterprise/skills/${skillId}/`
  });
}
