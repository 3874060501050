import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "./customToast";

import * as Yup from "yup";
import { TinyEditor } from "../components/DraftEditor/tinymce";

import { InputField } from "../forms/fields/InputField";
import { SelectField } from "../forms/fields/SelectField";
import { Button } from "./Button";
import { SelectOption } from "./SelectComponent";
import { CheatSheet } from "../types/CheatSheet";
import { theme } from "../themes/variables";

import { serverErrorHandler } from "../helpers/serverErrorHandler";

export interface FormValues extends Omit<CheatSheet, "course" | "uuid"> {}

export type CheatSheetItem = Omit<CheatSheet, "course" | "uuid">;

export function EditCreateCheatSheet(props: {
  onSubmitCallback: (values: CheatSheetItem) => Promise<void>;
  titileMaxLength?: number;
  attachedLessonOptions: SelectOption[];
  initialData: CheatSheetItem | null;
}) {
  const {
    onSubmitCallback,
    titileMaxLength = 150,
    initialData,
    attachedLessonOptions,
  } = props;

  const { t } = useTranslation();

  const [editorState, setEditorState] = useState<string>(
    initialData && initialData.text_content ? initialData.text_content : ""
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(titileMaxLength).required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        name: values.name,
        text_content: editorState,
        lesson: values.lesson || "",
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        name: initialData?.name || "",
        text_content: initialData?.text_content || "",
        lesson: initialData?.lesson || attachedLessonOptions?.[0]?.value || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, setFieldValue }) => (
        <Form
          noValidate
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `}
        >
          <div>
            <InputField
              label={`${t("lesson.quiz.title")}*:`}
              name={"name"}
              maxLength={titileMaxLength}
            />

            <SelectField
              label={`${t("lesson.title")}:`}
              name="lesson"
              choices={attachedLessonOptions}
              css={css`
                margin-right: 20px;
              `}
            />

            <div
              css={css`
                margin-bottom: 1rem;
              `}
            >
              <div
                css={css`
                  margin-bottom: 16px;
                  font-size: 16px;
                  font-weight: bold;
                `}
              >
                {t("rich-text-editor.content")}:
              </div>

              <TinyEditor
                value={editorState}
                onChange={(value) => {
                  setEditorState(value);
                  setFieldValue("text_content", value);
                }}
              />
            </div>
          </div>

          <div
            css={css`
              margin-top: 25px;
              display: flex;
              align-self: flex-end;
              flex-direction: row-reverse;
            `}
          >
            <div
              css={css`
                text-align: right;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                disabled={!isValid}
                type="submit"
              >
                {t(!!initialData ? "actions.update" : "actions.create")}
              </Button>

              {!isValid && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    color: ${theme.colors.redMain};
                    margin-top: 10px;
                  `}
                >
                  {t("status.error-fill")}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
