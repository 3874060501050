import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getEnterpriseGeneralSettings } from "../../../actions/admin/settings/getEnterpriseGeneralSettings";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import EnterpriseGeneralSettingsForm from "../../../forms/admin/settings/EnterpriseGeneralSettingsForm";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { FormValues } from "../../../forms/admin/settings/EnterpriseGeneralSettingsForm";
import { updateEnterpriseGeneralSettings } from "../../../actions/admin/settings/updateEnterpriseGeneralSettings";
import { customToast } from "../../../components/customToast";
import { useEnterprise } from "../../../contexts/EnterpriseContext";
import { Footer } from "../../../components/Footer";

export default function EnterpriseGeneralSettings() {
  const { t } = useTranslation();
  const { refetch: refetchEnterprise } = useEnterprise();

  const {
    data: generalSettings,
    error: generalSettingsError,
    setData,
  } = useNewFetch(getEnterpriseGeneralSettings);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const response = await updateEnterpriseGeneralSettings(values);

      customToast.success(t("status.success"));
      setData(response.data);
      refetchEnterprise();
    },
    [setData, t, refetchEnterprise]
  );

  if (!generalSettings || generalSettingsError) {
    return pageLoading(generalSettingsError);
  }

  const tabsData = [
    {
      title: t("enterprise_settings.general"),
      link: `/admin/settings/general`,
      forceHightlight: true,
    },
    {
      title: t("enterprise_settings.address"),
      link: `/admin/settings/address`,
    },
    {
      title: t("enterprise_settings.billing"),
      link: `/admin/settings/billing`,
    },
    {
      title: t("enterprise_settings.contact"),
      link: `/admin/settings/contact`,
    },
    {
      title: "DRM",
      link: `/admin/settings/drm`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("enterprise_settings.general")}</title>
        </SEO>
        <Helmet title={t("enterprise_settings.general")} />
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />
      <Wrapper>
        <EnterpriseGeneralSettingsForm
          data={generalSettings}
          onSubmit={onSubmit}
        />
        <Footer />
      </Wrapper>
    </>
  );
}
