import React from "react";
import { css } from "styled-components/macro";
import { Headers } from "../helpers/layout";
import { theme } from "../themes/variables";
import { ProgressBar } from "../components/ProgressBar";
import CountAnimation from "./CountAnimation";

export function Card(props: {
  className?: string;
  square?: boolean;
  bordered?: boolean;
  shadowed?: boolean;
  color?: string;
  padding?: number;
  children: React.ReactNode | React.ReactNode[] | string | null;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      css={css`
        padding: ${props.padding || 20}px;
        background-color: ${props.color || theme.colors.white};
        border-radius: ${props.square ? 0 : 20}px;
        ${props.bordered &&
        `border: 1px solid ${theme.colors.transparentBlack1};`}
        ${props.shadowed &&
        `box-shadow: 0 1px 4px ${theme.colors.transparentBlack1} 0 2px 2px ${theme.colors.transparentBlack1} 0 3px 1px -2px ${theme.colors.transparentBlack2};`}
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}

export function ProgressCard(props: {
  className?: string;
  threshold: number;
  value: number;
  valueSuffix?: string;
  text: string;
}) {
  const { threshold, value, valueSuffix, text } = props;
  const isSuccessful = value >= threshold;

  return (
    <Card
      color={
        isSuccessful
          ? theme.colors.greenBackground
          : theme.colors.yellowBackground
      }
      padding={24}
      className={props.className}
    >
      <Headers.H3
        css={css`
          display: flex;
          justify-content: flex-end;
          margin-bottom: 13px;
          color: ${isSuccessful ? theme.colors.success : theme.colors.warning};
        `}
      >
        <CountAnimation end={value} suffix={valueSuffix} />
      </Headers.H3>

      <span
        css={css`
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 10px;
        `}
      >
        {text}
      </span>

      <ProgressBar
        percent={value}
        color={isSuccessful ? theme.colors.success : theme.colors.warning}
        emptyColor={theme.colors.gray2}
        backgroundColor={
          isSuccessful
            ? theme.colors.greenBackground
            : theme.colors.yellowBackground
        }
        height={8}
        indicator
      ></ProgressBar>
    </Card>
  );
}
