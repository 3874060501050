import { authorised } from "../../../request";
import { UserProfile } from "../../../types/admin/UserProfile";

type UserArgs = {
  is_verified: boolean;
};

type Args = {
  id: string | number;
  data: UserArgs;
}

export async function updateUser({id, data}: Args) {
  const API = authorised();

  return API.request<UserProfile>({
    method: "PUT",
    url: `/api/enterprise/users/${id}/`,
    data
  });
}
