import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "../../customToast";
import * as Yup from "yup";
import { theme } from "../../../themes/variables";
import { InputField } from "../../../forms/fields/InputField";
import { TextareaField } from "../../../forms/fields/TextareaField";
import { UserChoseField } from "../../../forms/fields/UserChoseField";
import { Button } from "../../../components/Button";
import { Avatar } from "../../../components/Avatar";
import { UserSegment } from "../../../types/admin/UserSegment";
import { Headers } from "../../../helpers/layout";

import { checkUserSegmentName } from "../../../actions/admin/userSegment";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";

export type FormValues = Omit<UserSegment, "id" | "users_count">;

export function EditCreateUserSegment(props: {
  title?: string;
  initialData?: UserSegment | null;
  onSubmitCallback: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const [hoveredUser, setHoveredUser] = useState<number | null>(null);

  const validationSchema = Yup.object().shape({
    description: Yup.string(),
    name: Yup.string()
      .trim()
      .required()
      .test("checkName", t("user_segment.name_taken"), async (value) => {
        if (!value) {
          return false;
        }

        if (value === props.initialData?.name) {
          return true;
        }

        try {
          const {
            data: { exists },
          } = await checkUserSegmentName({
            name: value,
          });

          return !exists;
        } catch (error) {
          return true;
        }
      }),
    users: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          first_name: Yup.string().required(),
          last_name: Yup.string().required(),
        })
      )
      .min(2)
      .required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmitCallback({
        ...values,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          name: props.initialData?.name || "",
          description: props.initialData?.description || "",
          users: props.initialData?.users || [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values, setFieldValue }) => {
          return (
            <Form noValidate>
              <div
                css={css`
                  margin-bottom: 40px;
                `}
              >
                <Headers.H3>{props.title}</Headers.H3>
              </div>

              <div>
                <InputField
                  name="name"
                  label={`${t("media.name")}:`}
                  css={css`
                    max-width: 660px;
                    margin-bottom: 32px;
                  `}
                />

                <TextareaField
                  name="description"
                  label={`${t("admin.competency.description")}:`}
                  css={css`
                    max-width: 660px;
                    margin-bottom: 32px;
                  `}
                />

                <div
                  css={css`
                    display: flex;
                  `}
                >
                  <UserChoseField
                    name="users"
                    label={t("user_segment.add_users")}
                    hint={t("user_segment.minimum_two_users")}
                    searchType="CAMPUS"
                    css={css`
                      flex: 1 0 660px;
                    `}
                  />

                  <div
                    css={css`
                      flex: 1 1 100%;
                      margin-top: 40px;
                      margin-left: 40px;
                    `}
                  >
                    <Headers.H4>
                      {values.users.length} {t("user_segment.users_selected")}:
                    </Headers.H4>

                    <div
                      css={css`
                        margin-top: 27px;
                        max-height: 400px;
                        overflow: auto;

                        ::-webkit-scrollbar {
                          width: 20px;
                        }

                        ::-webkit-scrollbar-thumb {
                          background-color: ${theme.colors.gray4};
                          border-radius: 20px;
                          border: 6px solid transparent;
                          background-clip: content-box;
                        }
                        ::-webkit-scrollbar-track {
                          background-color: ${theme.colors.gray3};
                          border: 9px solid transparent;
                          background-clip: content-box;
                        }
                      `}
                    >
                      {values.users.map((user) => (
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 12px;
                            :last-of-type {
                              margin-bottom: 0;
                            }
                          `}
                          key={user.id}
                          onMouseEnter={() => {
                            setHoveredUser(user.id);
                          }}
                          onMouseLeave={() => {
                            setHoveredUser(null);
                          }}
                        >
                          <div
                            css={css`
                              display: flex;
                              align-items: center;
                            `}
                          >
                            <Avatar url={user.avatar?.image} size={24} />

                            <div
                              css={css`
                                margin-left: 12px;
                                font-size: 16px;
                                line-height: 22px;
                              `}
                            >
                              {user.first_name} {user.last_name}
                            </div>
                          </div>

                          {hoveredUser === user.id && (
                            <button
                              css={css`
                                padding: 0;
                                margin: 0;
                                outline: none;
                                border: none;
                                background: none;
                                margin-right: 56px;
                                cursor: pointer;
                                :hover {
                                  opacity: 0.85;
                                }
                              `}
                              onClick={() => {
                                setFieldValue(
                                  "users",
                                  values.users.filter((u) => u.id !== user.id)
                                );
                              }}
                            >
                              <DeleteIcon
                                width={20}
                                height={20}
                                color={theme.colors.error}
                              />
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div
                css={css`
                  margin-top: 40px;
                `}
              >
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={!isValid}
                >
                  {t(
                    !!props.initialData
                      ? "user_segment.update"
                      : "user_segment.create"
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
