import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEventHandler,
  useState,
} from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { Hint } from "./Hint";
import { InputLabel } from "./InputLabel";

export enum InputStyle {
  DEFAULT = "default",
  CLEAR = "clear",
  UNDERLINE = "underline",
}

export function InputComponent(props: {
  id?: string;
  name?: string;
  type?:
    | "text"
    | "email"
    | "search"
    | "password"
    | "number"
    | "tel"
    | "url"
    | "select"
    | "time";
  style?: InputStyle;
  label?: string;
  hint?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  icon?: React.ReactNode;
  error?: boolean;
  predefinedText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (event: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onMouseEnter?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
}) {
  const [focused, setFocused] = useState(false);

  const { style = InputStyle.DEFAULT } = props;

  return (
    <label onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
      <InputLabel label={props.label} />
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          min-height: 42px;
          background-color: ${theme.colors.white};
          ${style === InputStyle.DEFAULT &&
          css`
            border-radius: 20px;
            border: 1px solid
              ${props.disabled
                ? theme.colors.gray2
                : props.error
                ? theme.colors.redMain
                : focused
                ? theme.colors.dark
                : theme.colors.gray4};
          `}

          ${style === InputStyle.UNDERLINE &&
          css`
            border-bottom: 1px solid
              ${props.disabled
                ? theme.colors.gray2
                : props.error
                ? theme.colors.redMain
                : focused
                ? theme.colors.dark
                : theme.colors.gray4};
          `}
          overflow: hidden;
          ${props.predefinedText && "padding-left: 24px;"}
        `}
      >
        {props.predefinedText && (
          <span
            css={css`
              font-size: 16px;
              line-height: 22px;
              color: ${theme.colors.gray4};
              white-space: nowrap;
            `}
          >
            {props.predefinedText}
          </span>
        )}
        <input
          id={props.id}
          type={props.type || "text"}
          value={props.value}
          placeholder={props.placeholder || ""}
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          aria-label={props.name || ""}
          css={css`
            flex-grow: 1;
            padding: ${props.predefinedText
              ? "10px 30px 10px 0"
              : `9px 30px 9px ${style === InputStyle.DEFAULT ? "24px" : "0"}`};
            border: none;
            display: block;
            width: 100%;
            height: 1rem;
            ${props.icon && `padding-right: 42px;`}
            min-height: 40px;
            background-color: ${theme.colors.white};
            line-height: 1.5;
            color: ${theme.colors.dark};
            outline: none;
          `}
          {...props.field}
          className={props.className}
          onChange={(e) => {
            props.field && props.field.onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={(e) => {
            if (props.field) {
              props.field.onBlur(e);
            }
            setFocused(false);
          }}
          onKeyDown={props.onKeyDown}
          onInput={props.onInput}
        />
        {props.icon && (
          <div
            css={css`
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(0%, -50%);
              height: 100%;
              ${style === InputStyle.DEFAULT &&
              css`
                padding: 0 15px;
              `}
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            {props.icon}
          </div>
        )}
      </div>

      {props.hint && <Hint>{props.hint}</Hint>}
    </label>
  );
}
