import { Image } from "./Image";
import { SignupFieldValue } from "./SignupField";

export enum Permission {
  ENTERPRISE_MANAGE_BILLING = "ENTERPRISE_MANAGE_BILLING",
  ENTERPRISE_MANAGE_USERS = "ENTERPRISE_MANAGE_USERS",
  ENTERPRISE_MANAGE_ADMIN_USERS = "ENTERPRISE_MANAGE_ADMIN_USERS",
  ENTERPRISE_MANAGE_HOMEPAGE_CONTENT = "ENTERPRISE_MANAGE_HOMEPAGE_CONTENT",
  ENTERPRISE_REVIEW_COURSES = "ENTERPRISE_REVIEW_COURSES",
  ORGANISATION_MANAGE_USERS = "ORGANISATION_MANAGE_USERS",
  ENTERPRISE_MANAGE_PAGE = "ENTERPRISE_MANAGE_PAGE",
  ENTERPRISE_MANAGE_SETTINGS = "ENTERPRISE_MANAGE_SETTINGS",
  ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES = "ENTERPRISE_MANAGE_SKILLS_AND_COMPETENCIES",
}

export type UserProfile = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  city: string;
  introduction: string;
  is_verified: boolean;
  email_confirmed: boolean;
  avatar: Image | null;
  position: string;
  email: string;
  permissions: {
    key: Permission;
  }[];
  organisation_permissions: {
    key: Permission;
  }[];
  signup_field_values: SignupFieldValue[];
};

export type UserMeta = {
  id: number;
  avatar: Image | null;
  email: string;
  last_name: string;
  first_name: string;
};
