import { authorised } from "../../request";
import { JourneyCourse } from "../../types/JourneyCourse";

export async function updateJourneyCourseOrder(data: {
  journeyId: string;
  items: JourneyCourse[];
}) {
  const API = authorised();

  return API.request<JourneyCourse[]>({
    method: "PUT",
    url: `/api/enterprise/journeys/${data.journeyId}/reorder/`,
    data: {
      items: data.items,
    },
  });
}
