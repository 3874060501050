import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
  selfAssessmentId: string;
  data: SelfAssessment;
};

export async function updateSelfAssessment({
  courseId,
  selfAssessmentId,
  data,
}: Args) {
  const API = authorised();

  return API.request<SelfAssessment>({
    method: "PUT",
    url: `/api/studio/courses/${courseId}/self-assessments/${selfAssessmentId}/`,
    data,
  });
}
