import React from "react";
import { Field, FieldProps } from "formik";
import { Switcher } from "../../components/Switcher";

export function SwitcherField(props: {
  id?: string;
  name: string;
  label?: string;
  hint?: string;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  showStatus?: boolean;
  width?: number;
  height?: number;
  circleSize?: number;
  activeColor?: string;
  validate?: (value: string) => Promise<void | string>;
}) {
  const { validate, ...other } = props;
  return (
    <Field type="checkbox" name={props.name} validate={validate}>
      {({ field }: FieldProps) => {
        return (
          <Switcher {...other} field={field} />
        );
      }}
    </Field>
  );
}
