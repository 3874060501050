import React, { ChangeEvent } from "react";
import { css } from "styled-components/macro";
import { FieldInputProps } from "formik";
import { theme } from "../themes/variables";
import { Spacing } from "../helpers/layout";
import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";

export function RadioComponent(props: {
  id?: string;
  name?: string;
  label?: React.ReactNode | React.ReactNode[] | string;
  labelRight?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  value?: boolean;
  className?: string;
  field?: FieldInputProps<any>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label
      css={css`
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        min-width: ${Spacing.l};
        min-height: ${Spacing.l};
        position: relative;
        font-size: 16px;
        ${props.labelRight ? "flex-direction: row-reverse;" : ""}
      `}
      className={props.className || ""}
    >
      {props.label && (
        <span
          css={css`
            vertical-align: middle;
          `}
        >
          {props.label}
        </span>
      )}

      <span
        css={css`
          position: relative;
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin: ${props.label
            ? props.labelRight
              ? "0 16px 0 0;"
              : "0 0 0 16px;"
            : "0"};
        `}
      >
        <input
          id={props.id}
          name={props.name || ""}
          type="radio"
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          checked={props.value || false}
          css={css`
            position: relative;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            border: 2px solid ${theme.colors.gray4};
            transition: 0.2s all linear;
            cursor: pointer;
            outline: none;
            appearance: none;
            :checked {
              background-color: ${theme.colors.primary};
              border: 2px solid ${theme.colors.transparent};
            }
          `}
          {...props.field}
          onChange={(e) => {
            if (props.field) {
              props.field.onChange(e);
            }
            if (props.onChange) {
              props.onChange(e);
            }
          }}
        />

        {(props.field?.checked || props.value) && (
          <ReadIcon
            width={12}
            height={12}
            color={theme.colors.white}
            css={css`
              position: absolute;
              left: 6px;
              top: 5px;
            `}
          />
        )}
      </span>
    </label>
  );
}
