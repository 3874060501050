import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ChartConfiguration, TooltipItem } from "chart.js";
import { ChartLine } from "../../../../components/Charts/LineChart";
import { CheckboxComponent } from "../../../../components/CheckboxComponent";
import { hexToRgb, getChartColorByIndex } from "../../../../helpers/color";
import { getHashNumberByString } from "../../../../helpers/text";
import { theme } from "../../../../themes/variables";
import { PerformancePeriod } from "../../../../actions/performance/getCoursePerformanceChart";
import { xor, map, forEach } from "lodash";
import { getFormattedDate } from "../../../../helpers/chart";
import { TFunction } from "i18next";
import {
  TestPerformance,
  TestVariantPerformance,
} from "../../../../types/Test";

function getTotalCountsData(
  variants: TestVariantPerformance[],
  period: PerformancePeriod,
  t: TFunction
) {
  const totalSubmissions = variants.reduce(
    (
      acc: {
        [date: string]: number;
      },
      variant
    ) => {
      forEach(variant.submissions, (value, date) => {
        if (acc[date]) {
          acc[date] += value.count;
        } else {
          acc[date] = value.count;
        }
      });

      return acc;
    },
    {}
  );

  const data = Object.values(
    map(totalSubmissions, (value, date) => {
      return {
        x: getFormattedDate(date, period, t),
        y: value,
      };
    })
  ).reverse();

  const hexColor = theme.colors.blueBackground;

  const color = hexToRgb(hexColor);

  return [
    {
      type: "bar",
      label: t("test-performance.total-submissions"),
      data: data,
      fill: true,
      barPercentage: 0.3,
      backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.9)`,
      borderColor: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
      borderWidth: 0,
      borderRadius: 15,
      pointStyle: "circle",
      pointRadius: 0,
      pointHoverRadius: 8,
      pointBorderColor: theme.colors.white,
      pointBackgroundColor: hexColor,
      yAxisID: "y1",
    },
  ];
}

export function TestPerformanceChart(props: {
  period: PerformancePeriod;
  data: TestPerformance;
}) {
  const { period, data } = props;
  const { t } = useTranslation();
  const [chosenVariants, setChosenVariants] = useState<number[]>([]);
  const variants =
    chosenVariants.length > 0
      ? data.variants.filter((v) => chosenVariants.includes(v.id))
      : data.variants;
  const successRate = variants.map((variant, index) => {
    const data = Object.values(
      map(variant.submissions, (value, date) => {
        return {
          x: getFormattedDate(date, period, t),
          y: value.avg,
        };
      })
    ).reverse();

    const hexColor = getChartColorByIndex(variant.id);

    const color = hexToRgb(hexColor);

    return {
      type: "line",
      label: `${t("test-performance.variant")} ${index + 1}`,
      data: data,
      fill: true,
      backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.4)`,
      borderColor: `rgba(${color.r}, ${color.g}, ${color.b}, 1)`,
      borderWidth: 0,
      pointStyle: "circle",
      pointRadius: 0,
      pointHoverRadius: 8,
      pointBorderColor: theme.colors.white,
      pointBackgroundColor: hexColor,
      yAxisID: "y",
      cubicInterpolationMode: "monotone",
    };
  });

  const counts = getTotalCountsData(variants, period, t);

  const datasets = [...successRate, ...counts];

  const chartData = {
    datasets,
  };

  const chartOptions: ChartConfiguration<"line">["options"] = {
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        position: "nearest",
        intersect: true,
        padding: 16,
        cornerRadius: 20,
        backgroundColor: theme.colors.white,
        bodyColor: theme.colors.dark,
        titleColor: theme.colors.dark,
        borderColor: theme.colors.dark,
        footerColor: theme.colors.dark,
        callbacks: {
          title: (tooltipItems: TooltipItem<"line">[]) => {
            return tooltipItems[0]?.label;
          },
          label: (tooltipItem: TooltipItem<any>) => {
            if (tooltipItem.dataset.type === "bar") {
              return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
            }

            return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}%`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        grace: "10%",
        position: "left",
        max: 100,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return `${value}%`;
          },
        },
      },
      y1: {
        beginAtZero: true,
        display: true,
        grace: "0",
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      xAxes: {
        display: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const getCheckColor = (stringId: string) => {
    return getChartColorByIndex(getHashNumberByString(stringId));
  };

  return (
    <div
      css={css`
        display: flex;
        margin-bottom: 60px;
      `}
    >
      <div
        css={css`
          background: ${theme.colors.gray1};
          padding: 40px;
          border-radius: 20px;
          width: 700px;
        `}
      >
        <div
          css={css`
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;
            margin-bottom: 25px;
          `}
        >
          {t("test-performance.title")}
        </div>

        <ChartLine
          width={700}
          height={621}
          config={{
            data: chartData,
            options: chartOptions,
            type: "line",
          }}
        />
      </div>
      <div
        css={css`
          margin-top: 40px;
          margin-left: 40px;
        `}
      >
        <div
          css={css`
            margin-bottom: 25px;
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;
          `}
        >
          {t("test-performance.variants")}
        </div>
        <div>
          <div
            css={css`
              margin-bottom: 12px;
            `}
          >
            <CheckboxComponent
              value={chosenVariants.length === 0}
              label={t("test-performance.all-variants")}
              checkColor={getCheckColor("all-variants")}
              onChange={(e) => {
                setChosenVariants([]);
              }}
            />
          </div>
          {data.variants.map((variant, index) => {
            return (
              <div
                key={variant.id}
                css={css`
                  margin-bottom: 12px;
                `}
              >
                <CheckboxComponent
                  key={variant.id}
                  value={chosenVariants.includes(variant.id)}
                  label={`${t("test-performance.variant")} ${index + 1}`}
                  checkColor={getChartColorByIndex(variant.id)}
                  onChange={(e) => {
                    setChosenVariants((chosenVariants) =>
                      xor(chosenVariants, [variant.id])
                    );
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
