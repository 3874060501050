import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { List } from "react-feather";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragUpdate,
} from "react-beautiful-dnd";
import { theme } from "../../themes/variables";

export function DraggableComponent<T>(props: {
  background?: (item: T) => string;
  items: T[];
  flat?: boolean;
  controls?: (item: T) => React.ReactNode | React.ReactNode[];
  children: (item: T) => React.ReactNode;
  getDraggableId: (item: T) => string;
  isDragDisabled?: boolean;
  hideControls?: boolean;
  onDragEnd?: (items: T[]) => void;
  dragIcon?: React.ReactNode;
  className?: string;
  showControlsOnHover?: boolean;
}) {
  const { isDragDisabled = true } = props;

  const [itemsArray, setItemsArray] = useState(props.items);

  useEffect(() => {
    setItemsArray(props.items);
  }, [props.items]);

  const onDragEnd = (result: DragUpdate) => {
    if (!result.destination) {
      return;
    }

    const items: T[] = reorder(
      itemsArray,
      result.source.index,
      result.destination.index
    );

    setItemsArray(items);

    if (props.onDragEnd) {
      props.onDragEnd(items);
    }
  };

  const reorder = (list: T[], startIndex: number, endIndex: number): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            css={css`
              width: 100%;
              overflow: auto;
            `}
          >
            {itemsArray.map((item: T, index: number) => {
              const draggableId = props.getDraggableId(item);
              const backgroundColor = props.background
                ? props.background(item)
                : theme.colors.white;

              return (
                <Draggable
                  key={draggableId}
                  draggableId={draggableId}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={props.className}
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: ${props.flat ? "20px 0" : "25px 35px"};
                        position: relative;
                        min-height: 30px;
                        background: ${backgroundColor};
                        border-radius: 25px;
                        margin-bottom: 20px;
                        transition: padding 300ms;

                        :hover {
                          ${isDragDisabled
                            ? ""
                            : props.flat
                            ? "padding-left: 35px;"
                            : "padding-left: 70px;"}
                        }

                        :last-of-type {
                          border: none;
                        }

                        .handle {
                          opacity: 0;
                          transition: opacity 300ms;
                        }

                        &:hover .handle {
                          opacity: 1;
                        }

                        ${props.showControlsOnHover &&
                        css`
                          .controls {
                            opacity: 0;
                            transition: opacity 300ms;
                          }

                          &:hover .controls {
                            opacity: 1;
                          }
                        `}
                      `}
                    >
                      {!isDragDisabled && (
                        <div
                          css={css`
                            display: flex;
                            align-items: center;
                          `}
                          tabIndex="0"
                          {...provided.dragHandleProps}
                        >
                          <div
                            className="handle"
                            css={css`
                              display: flex;
                              align-items: center;
                              cursor: move;
                              cursor: grab;
                              position: absolute;
                              left: ${props.flat ? 0 : 35}px;
                              :active {
                                cursor: grabbing;
                              }
                            `}
                          >
                            {props.dragIcon ? (
                              props.dragIcon
                            ) : (
                              <List size={20} />
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        css={css`
                          flex: 1 0 10%;
                        `}
                      >
                        {props.children(item)}
                      </div>

                      <div
                        css={css`
                          display: flex;
                          align-items: center;
                        `}
                      >
                        {props.controls && !props.hideControls && (
                          <div className="controls">{props.controls(item)}</div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
