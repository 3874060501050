import { authorised } from "../../request";
import { Journey } from "../../types/Journey";

export async function deleteJourney(id: string) {
  const API = authorised();

  return API.request<Journey>({
    method: "DELETE",
    url: `/api/enterprise/journeys/${id}/`,
  });
}
