import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { DraggableComponent } from "../../components/Draggable";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers, Spacing, Row } from "../../helpers/layout";
import { theme } from "../../themes/variables";
import { getDuration } from "../../helpers/time";
import { CourseLesson } from "../../types/CourseLesson";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { updateCourseLessonOrder } from "../../actions/courseLesson/updateCourseLesson";
import { pageLoading } from "../../helpers/pageLoading";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

import { ReactComponent as DurationSvg } from "../../assets/icons/duration_icon.svg";
import { ReactComponent as QuizSvg } from "../../assets/icons/quiz_icon.svg";
import { Footer } from "../../components/Footer";

export function CoursePage() {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const params = useParams<{
    courseId: string;
  }>();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: false,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  const onDragEnd = async (items: CourseLesson[]) => {
    try {
      await updateCourseLessonOrder({
        items,
        courseId: params.courseId,
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {course.lessons.length ? (
            <DraggableComponent
              items={course.lessons}
              background={() => "transparent"}
              getDraggableId={(row) => row.lesson.uuid}
              isDragDisabled={false}
              onDragEnd={(items) => onDragEnd(items)}
              flat
              controls={(item) => (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      min-width: 120px;
                      margin-right: 1rem;
                    `}
                  >
                    <DurationSvg
                      width={25}
                      height={25}
                      color={theme.colors.dark}
                      css={css`
                        margin-right: 1rem;
                      `}
                      title={t("lesson.meta.duration")}
                    />
                    {getDuration(item.lesson.duration || 0, i18n.language)}
                  </div>

                  <Link
                    to={`/courses/${course.uuid}/lessons/${item.lesson.uuid}/testing`}
                  >
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        min-width: 50px;
                        margin-right: 1rem;
                      `}
                    >
                      <QuizSvg
                        width={25}
                        height={25}
                        color={theme.colors.dark}
                        css={css`
                          margin-right: 1rem;
                        `}
                        title={t("lesson.meta.tests")}
                      />
                      {item.lesson.tests_count} {t("lesson.meta.tests")}
                    </div>
                  </Link>
                </div>
              )}
            >
              {(item) => {
                return (
                  <>
                    <Link
                      to={`/courses/${course.uuid}/lessons/${item.lesson.uuid}/content_editor`}
                    >
                      {item.lesson.name}
                    </Link>
                  </>
                );
              }}
            </DraggableComponent>
          ) : (
            <Headers.H3>{t("courses.no-courses")}</Headers.H3>
          )}
          <Row
            justify={!!course.lessons.length ? "space-between" : "flex-end"}
            align={"center"}
            css={css`
              margin-top: ${Spacing.xl};
            `}
          >
            {!!course.lessons.length && (
              <div
                css={css`
                  font-size: 1rem;
                `}
              >
                {t("time.total")}:
                <span
                  css={css`
                    font-weight: bold;
                    margin-left: 0.5rem;
                  `}
                >
                  {getDuration(course.duration || 0, i18n.language)}
                </span>
              </div>
            )}

            <Button
              small
              className="create-lesson"
              onClick={() => {
                history.push(`/courses/${course.uuid}/lessons/new`);
              }}
            >
              {t("courses.create-lesson")}
            </Button>
          </Row>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
