import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { getOrganisationInvites } from "../../actions/organisationInvite";
import {
  OrganisationInvite,
  OrganisationInviteStatus,
} from "../../types/OrganisationInvite";
import { theme } from "../../themes/variables";
import { Button } from "../Button";
import { Avatar } from "../Avatar";
import { TableHandler, TableHandlerColumn } from "../TableHandler";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { OrganisationUserInvite } from "../../modals/OrganisationUserInvite";
import { useState } from "react";

export default function InvitesTable({
  forwardRef,
  openRevokeModal,
}: {
  forwardRef: any;
  openRevokeModal: (invite: OrganisationInvite) => void;
}) {
  const { t } = useTranslation();
  const [inviteModal, setInviteModal] = useState(false);

  const tableHandlerColumns: TableHandlerColumn<OrganisationInvite>[] = [
    {
      label: "ID",
      render: (row) => <div>{row.user?.id || "-"}</div>,
    },
    {
      label: t("account.avatar"),
      render: (row) => (
        <Avatar
          css={css`
            margin: 0 auto;
          `}
          size={40}
          url={row.user?.avatar?.image}
        />
      ),
    },
    {
      label: t("account.email"),
      render: (row) => <div>{row.user?.email || row.email}</div>,
    },
    {
      label: t("admin.users.page.fullname"),
      render: (row) => (
        <div>
          {row.user ? `${row.user?.first_name} ${row.user.last_name}` : "-"}
        </div>
      ),
    },
    {
      label: t("admin.publish-request.page.status"),
      render: (row) => <div>{row.status}</div>,
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            white-space: nowrap;
          `}
        >
          {row.status !== OrganisationInviteStatus.PENDING ? (
            "-"
          ) : (
            <Button
              background={theme.colors.transparent}
              border={`1px solid ${theme.colors.error}`}
              color={theme.colors.error}
              icon={
                <DeleteIcon width={14} height={14} color={theme.colors.error} />
              }
              iconRight
              hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
              small
              disabled={row.status !== OrganisationInviteStatus.PENDING}
              onClick={() => openRevokeModal(row)}
            >
              {t("organisation.revoke-invite")}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <TableHandler
        forwardRef={forwardRef}
        tableData={{
          columns: tableHandlerColumns,
        }}
        getFunction={getOrganisationInvites}
        noDataMessage={
          <div
            css={css`
              text-align: center;
            `}
          >
            <h2>{t("organisation.no_invites_messages")}</h2>
            <Button
              css={css`
                margin-top: 20px;
              `}
              onClick={() => {
                setInviteModal(true);
              }}
            >
              {t("organisation.invite_button_label")}
            </Button>
          </div>
        }
      />
      <OrganisationUserInvite
        isVisible={inviteModal}
        onClose={() => {
          setInviteModal(false);
        }}
        onSent={() => {
          if (forwardRef.current) {
            forwardRef.current.refetch();
          }
        }}
      />
    </>
  );
}
