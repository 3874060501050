import React, { useState } from "react";
import { css } from "styled-components/macro";
import { Check, X } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  BackgroundSize,
  BackgroundPosition,
  BlockCommonProps,
} from "../../../../types/Block";
import { Image } from "../../../../types/Image";
import { theme } from "../../../../themes/variables";
import { Headers, Row } from "../../../../helpers/layout";
import { Button } from "../../../Button";
import { ColorPicker } from "../../../ColorPicker";
import { SelectComponent } from "../../../SelectComponent";
import { ImageHandler } from "./ImageHandler";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../Modal";
import { Separator } from "../../../Separator";

export function ModalEdit<T extends BlockCommonProps>(props: {
  block: T;
  modal: boolean;
  hideBackgroundOptions?: boolean;
  backgroundColor?: string;
  header?: React.ReactNode | React.ReactNode[] | string | null;
  children?: React.ReactNode | React.ReactNode[] | string | null;
  onConfirm?: (value: T) => Promise<void>;
  onClose: () => void;
  onCancel?: () => void;
}) {
  const { t } = useTranslation();

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [blockImage, setBlockImage] = useState<Image | null>(
    props.block.background_image || null
  );

  const [blockImageSize, setBlockImageSize] = useState<BackgroundSize>(
    props.block.background_size || BackgroundSize.AUTO
  );

  const [blockImagePosition, setBlockImagePosition] =
    useState<BackgroundPosition>(
      props.block.background_position || BackgroundPosition.CENTER
    );

  const [blockImageColor, setBlockImageColor] = useState<string>(
    props.block.background_color || "#ffffff"
  );

  const backgroundSizes: { label: string; value: BackgroundSize }[] = [
    {
      label: t("lesson.block.background-size.auto"),
      value: BackgroundSize.AUTO,
    },
    {
      label: t("lesson.block.background-size.contain"),
      value: BackgroundSize.CONTAIN,
    },
    {
      label: t("lesson.block.background-size.cover"),
      value: BackgroundSize.COVER,
    },
  ];

  const backgroundPositions: { label: string; value: BackgroundPosition }[] = [
    {
      label: t("lesson.block.background-position.center"),
      value: BackgroundPosition.CENTER,
    },
    {
      label: t("lesson.block.background-position.top"),
      value: BackgroundPosition.TOP,
    },
    {
      label: t("lesson.block.background-position.top-left"),
      value: BackgroundPosition.TOP_LEFT,
    },
    {
      label: t("lesson.block.background-position.top-right"),
      value: BackgroundPosition.TOP_RIGHT,
    },
    {
      label: t("lesson.block.background-position.bottom"),
      value: BackgroundPosition.BOTTOM,
    },
    {
      label: t("lesson.block.background-position.bottom-left"),
      value: BackgroundPosition.BOTTOM_LEFT,
    },
    {
      label: t("lesson.block.background-position.bottom-right"),
      value: BackgroundPosition.BOTTOM_RIGHT,
    },
    {
      label: t("lesson.block.background-position.left"),
      value: BackgroundPosition.LEFT,
    },
    {
      label: t("lesson.block.background-position.right"),
      value: BackgroundPosition.RIGHT,
    },
  ];

  const onBlockImageChange = async (img: Image) => {
    setBlockImage(img);
  };

  const onCancel = () => {
    setBlockImage(props.block.background_image || null);
    setBlockImageSize(props.block.background_size || BackgroundSize.AUTO);
    setBlockImagePosition(
      props.block.background_position || BackgroundPosition.CENTER
    );
    setBlockImageColor(props.block.background_color || "#ffffff");

    props.onClose();
    if (props.onCancel) props.onCancel();
  };

  return (
    <Modal
      modalIsOpen={props.modal}
      onClose={onCancel}
      contentStyles={{
        maxWidth: "1300px",
        backgroundColor: props.backgroundColor || theme.colors.gray1,
      }}
      overlayStyles={{
        zIndex: 1000,
      }}
      persistent
    >
      <ModalHeader onClose={onCancel} closeIcon>
        {props.header}
      </ModalHeader>

      <ModalBody>
        {props.children}

        {!props.hideBackgroundOptions && (
          <>
            <Separator
              css={css`
                margin-top: 30px !important;
              `}
            />

            <Headers.H4
              css={css`
                margin-top: 30px;
                margin-bottom: 16px;
                font-size: 16px;
                font-weight: bold;
              `}
            >
              {t("lesson.block.background")}:
            </Headers.H4>

            <Row align="flex-start" justify="space-between">
              <ImageHandler
                removeImage={() => setBlockImage(null)}
                image={blockImage}
                onImageChange={async (img) => await onBlockImageChange(img)}
              />

              <SelectComponent
                css={css`
                  span {
                    font-weight: bold;
                  }
                `}
                label={`${t("lesson.block.background-size.title")}:`}
                choices={backgroundSizes}
                value={blockImageSize}
                onChange={(e) =>
                  setBlockImageSize(e.target.value as BackgroundSize)
                }
              />

              <SelectComponent
                css={css`
                  span {
                    font-weight: bold;
                  }
                `}
                label={`${t("lesson.block.background-position.title")}:`}
                choices={backgroundPositions}
                value={blockImagePosition}
                onChange={(e) =>
                  setBlockImagePosition(e.target.value as BackgroundPosition)
                }
              />

              <label>
                <div
                  css={css`
                    margin-bottom: 16px;
                    font-size: 16px;
                    font-weight: bold;
                  `}
                >
                  {t("lesson.block.background-color.title")}:
                </div>

                <ColorPicker
                  value={blockImageColor}
                  onChange={setBlockImageColor}
                />
              </label>
            </Row>
          </>
        )}
      </ModalBody>

      {props.onConfirm && (
        <ModalFooter>
          <Button
            background={theme.colors.white}
            color={theme.colors.dark}
            border={`1px solid ${theme.colors.dark}`}
            hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
            onClick={onCancel}
          >
            <Row align="center">
              <span>{t("actions.cancel")}</span>
              <X size={15} />
            </Row>
          </Button>

          <Button
            isSubmitting={isSubmiting}
            background={theme.colors.primary}
            onClick={async () => {
              setIsSubmiting(true);

              await props.onConfirm!({
                ...props.block,
                background_image: blockImage,
                background_size: blockImageSize,
                background_position: blockImagePosition,
                background_color: blockImageColor,
              });

              setIsSubmiting(false);

              props.onClose();
            }}
          >
            <Row align="center">
              <span>{t("actions.save")}</span>
              <Check size={15} color={theme.colors.white} />
            </Row>
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
