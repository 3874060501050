import React, { useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { CustomCodeBlock } from "../../../../types/Block";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { InputComponent } from "../../../InputComponent";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { ModalEdit } from "../components/ModalEdit";
import { BlockControls } from "../components/BlockControls";

export function BlockCustomCode(props: {
  block: CustomCodeBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomCodeBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [content, setContent] = useState<string>(props.block.data.code || "");

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setContent(props.block.data.code || "");
  };

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.code && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              display: flex;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
            dangerouslySetInnerHTML={{
              __html: block.data.code || "",
            }}
          ></div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block || []}
        onCancel={onCancel}
        header={
          <div>
            <span
              css={css`
                color: ${theme.colors.primary};
                margin-right: 12px;
              `}
            >
              Aa
            </span>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {props.label}
            </Headers.H3>
          </div>
        }
        onConfirm={async (value) => {
          const updatedData: CustomCodeBlock = {
            ...value,
            data: {
              code: content,
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <InputComponent
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
