import { authorised } from "../../request";
import { OrganisationInvite } from "../../types/OrganisationInvite";

type Args = {
  invitationToken: string | null;
};

export async function getOrganisationInvite({ invitationToken }: Args) {
  const API = authorised();

  return API.request<OrganisationInvite>({
    method: "GET",
    url: `/api/studio/organisation/invite/${invitationToken}/`,
  });
}
