import { authorised } from "../../../request";

export async function getPublishRequestStats() {
  const API = authorised();

  return await API.request<{
    pending_requests: number;
  }>({
    method: "GET",
    url: `/api/enterprise/publish-request/stats/`,
  });
}
