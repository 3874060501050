import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";
import { NotificationType, Notification } from "../types/Notification";
import { Separator } from "./Separator";
import { Button } from "./Button";
import { Loader } from "./Loader";

import { useNewFetch } from "../useAPI";
import {
  getNotifications,
  updateNotification,
  readAllNotifications,
} from "../actions/notification";
import { pageLoading } from "../helpers/pageLoading";

import { ReactComponent as BellIcon } from "../assets/icons/Bell.svg";
import { ReactComponent as LeftIcon } from "../assets/icons/Left.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/Settings.svg";
import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";
import { ReactComponent as SandwatchIcon } from "../assets/icons/Sandwatch.svg";
import { ReactComponent as StopIcon } from "../assets/icons/Stop.svg";
import { ReactComponent as FeedbackIcon } from "../assets/icons/Feedback.svg";
import { useClickOutside } from "../hooks/useClickOutside";

const limit = 5;

export function NotificationItem(props: {
  data: Notification;
  cropText?: boolean;
  read?: boolean;
  onClick?: () => void;
}) {
  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <span
        css={css`
          margin-right: 20px;
        `}
      >
        {props.data.type === NotificationType.PUBLISH_REQUEST_APPROVED && (
          <SandwatchIcon
            width={14}
            height={14}
            color={props.read ? theme.colors.gray5 : theme.colors.primary}
          />
        )}
        {props.data.type === NotificationType.PUBLISH_REQUEST_REJECTED && (
          <StopIcon
            width={14}
            height={14}
            color={props.read ? theme.colors.gray5 : theme.colors.primary}
          />
        )}
        {props.data.type === NotificationType.NEW_COURSE_PUBLISH_REQUEST && (
          <FeedbackIcon
            width={14}
            height={14}
            color={props.read ? theme.colors.gray5 : theme.colors.primary}
          />
        )}
      </span>
      <a
        href={
          props.data.type === NotificationType.NEW_COURSE_PUBLISH_REQUEST
            ? `/admin/publish-request`
            : props.data.context.course_id
            ? `/courses/${props.data.context.course_id}/edit`
            : ""
        }
        css={css`
          ${props.cropText &&
          `text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;`}
        `}
        onClick={props.onClick}
      >
        {props.data.short_title}
      </a>
    </div>
  );
}

export function Notifications(props: {}) {
  const [isLoading, setIsLoading] = useState(false);
  const [opened, setOpened] = useState(false);

  const notificationRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    if (opened) setOpened(false);
  };

  useClickOutside(notificationRef, handleClose);

  const { t } = useTranslation();

  const history = useHistory();

  const {
    data: notifications,
    error: notificationsError,
    refetch: notificationsRefetch,
  } = useNewFetch(getNotifications, {
    limit,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      notificationsRefetch();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [notificationsRefetch]);

  if (!notifications || notificationsError) {
    return pageLoading(notificationsError);
  }

  const {
    read,
    unread,
  }: {
    read: Notification[];
    unread: Notification[];
  } = notifications.results.reduce(
    (
      data: {
        read: Notification[];
        unread: Notification[];
      },
      row
    ) => {
      if (row.read) {
        data.read.push(row);
      } else if (!row.read) {
        data.unread.push(row);
      }

      return data;
    },
    {
      read: [],
      unread: [],
    }
  );

  const readAll = async () => {
    setIsLoading(true);
    await readAllNotifications();
    setIsLoading(false);

    notificationsRefetch();
  };

  const readNotification = async (id: number) => {
    await updateNotification(id, {
      read: true,
    });

    notificationsRefetch();
  };

  return (
    <div
      ref={notificationRef}
      css={css`
        position: relative;
        padding: 7px 0;
      `}
    >
      <button
        css={css`
          width: 40px;
          height: 40px;
          background: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
        onClick={() => {
          opened ? handleClose() : handleOpen();
        }}
      >
        <BellIcon
          width={20}
          height={24}
          color={unread.length > 0 ? theme.colors.primary : theme.colors.gray3}
        />
      </button>

      {!!unread.length && (
        <div
          css={css`
            position: absolute;
            width: 8px;
            height: 8px;
            background: ${theme.colors.error};
            border-radius: 50%;
            top: 5px;
            right: 5px;
          `}
        ></div>
      )}

      <div
        css={css`
          position: absolute;
          background: #fff;
          top: calc(100% + 17px);
          margin-left: 50%;
          right: -10px;
          width: 360px;
          border-radius: 20px;
          box-sizing: border-box;
          visibility: hidden;
          opacity: 0;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          z-index: 2;
          transition: visibility 0.3s linear, opacity 0.3s linear;
          ${opened &&
          css`
            visibility: visible;
            opacity: 1;
          `}

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
          }

          li {
            margin: 16px 0;
          }
        `}
      >
        <div
          css={css`
            width: 20px;
            height: 20px;
            position: absolute;
            top: -15px;
            right: 10px;
            transform: translateX(-50%);
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              width: 20px;
              height: 20px;
              background: white;
              transform: translateX(-50%) translateY(-50%) rotate(45deg);
              top: 100%;
              left: 50%;
              box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.15);
              border-radius: 1px;
            }
          `}
        ></div>

        <div
          css={css`
            border-radius: 20px;
            background: ${theme.colors.white};
          `}
        >
          {/* Header */}
          <div
            css={css`
              padding: 18px 24px 20px 24px;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Headers.H3
                  css={css`
                    margin-right: 15px;
                  `}
                >
                  {unread.length}
                </Headers.H3>

                <Headers.H4
                  css={css`
                    white-space: nowrap;
                  `}
                >
                  {t("notifications.new")}
                </Headers.H4>
              </div>

              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <button
                  css={css`
                    ${!!unread.length && `cursor: pointer;`}
                    display: flex;
                    align-items: center;
                    border: none;
                    outline: none;
                    padding: 0;
                    background: none;
                    margin-right: 17px;
                  `}
                  disabled={!unread.length}
                  onClick={readAll}
                  title={t("actions.mark-as-read")}
                >
                  {isLoading ? (
                    <Loader size={20} />
                  ) : (
                    <ReadIcon
                      width={20}
                      height={20}
                      color={
                        !!unread.length
                          ? theme.colors.primary
                          : theme.colors.gray3
                      }
                    />
                  )}
                </button>

                <button
                  css={css`
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    border: none;
                    outline: none;
                    padding: 0;

                    background: none;
                  `}
                  title={t("notifications.settings")}
                  onClick={() => history.push("/account/me")}
                >
                  <SettingsIcon
                    width={20}
                    height={20}
                    color={theme.colors.gray3}
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Unreaded */}
          {!!unread.length && (
            <div
              css={css`
                padding: 16px 24px 16px 24px;
                border-radius: 20px;
                background: ${theme.colors.yellowBackground};
              `}
            >
              <ul>
                {unread.map((el) => {
                  return (
                    <li
                      key={el.id}
                      css={css`
                        font-size: 16px;
                        line-height: 22px;
                        width: 100%;
                        position: relative;
                      `}
                    >
                      <NotificationItem
                        data={el}
                        cropText
                        onClick={() => readNotification(el.id)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          {/* Readed */}
          {!!read.length && (
            <div
              css={css`
                padding: 4px 24px 20px 24px;
              `}
            >
              <ul>
                {read.map((el) => {
                  return (
                    <li
                      key={el.id}
                      css={css`
                        font-size: 16px;
                        line-height: 22px;
                        width: 100%;
                        position: relative;
                      `}
                    >
                      <NotificationItem data={el} cropText read />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

          <Separator
            css={css`
              padding: 0 20px;
            `}
          />

          <div
            css={css`
              padding: 20px 0;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <Link to={"/notifications"}>
              <Button
                icon={
                  <LeftIcon
                    width={14}
                    height={14}
                    color={theme.colors.primary}
                    css={css`
                      transform: rotate(180deg);
                    `}
                  />
                }
                background={theme.colors.transparent}
                color={theme.colors.dark}
                hoverStyles={`opacity: 0.85;`}
                iconRight
              >
                {t("actions.see-all")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
