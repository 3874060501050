import { UserMeta } from "./UserProfile";
import { Organisation } from "./Organisation";

export enum OrganisationInviteStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  REVOKED = "REVOKED",
  STOPPED = "STOPPED"
}

export type OrganisationInvite = {
  author: UserMeta;
  user?: UserMeta;
  organisation: Organisation;
  email: string;
  status: OrganisationInviteStatus;
  token: string;
}
