import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { getSignUpSelfAssessments } from "../../../actions/signUpSelfAssessments/getSignUpSelfAssessments";
import { Block } from "../../../components/Block";
import { Button } from "../../../components/Button";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Tabs } from "../../../components/Tabs";
import { Wrapper } from "../../../components/Wrapper";
import { Row, Headers } from "../../../helpers/layout";
import { pageLoading } from "../../../helpers/pageLoading";
import { useNewFetch } from "../../../useAPI";
import { SignUpSelfAssessmentList } from "./SignUpSelfAssessmentList";

export function SignUpSelfAssessments() {
  const { t } = useTranslation();

  const {
    data: selfAssessments,
    error: selfAssessmentsError,
    refetch,
  } = useNewFetch(getSignUpSelfAssessments);

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  const onItemDelete = () => {
    refetch();
  };

  const tabsData = [
    {
      title: t("self-assessment.questions"),
      link: `/admin/self-assessment`,
      forceHightlight: true,
    },
    {
      title: t("self-assessment.performance"),
      link: `/admin/self-assessment/performance`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("self-assessment.questions")}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {selfAssessments?.length ? (
            <div
              css={css`
                margin-top: 1rem;
              `}
            >
              <SignUpSelfAssessmentList
                title={t("self-assessment.questions")}
                selfAssessmentItems={selfAssessments}
                onItemDelete={onItemDelete}
              ></SignUpSelfAssessmentList>
            </div>
          ) : (
            <>
              <Row justify="center">
                <Headers.H3
                  css={css`
                    margin-bottom: 2rem;
                  `}
                >
                  {t("self-assessment.no_items")}
                </Headers.H3>
              </Row>
              <Row justify="center">
                <Link to={`/admin/self-assessment/new`}>
                  <Button small>{t("actions.create")}</Button>
                </Link>
              </Row>
            </>
          )}
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
