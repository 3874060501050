export const downloadResource = (url: string) => {
  const filename = url.split("/").pop();
  if (filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        downloadBlob(blobUrl, filename);
      })
      .catch((e) => console.error(e));
  }
};

export const downloadBlob = (content: string, fileName?: string, mimeType?: string) => {
  const a = document.createElement('a');
  mimeType = mimeType || 'application/octet-stream';

  a.href = URL.createObjectURL(new Blob([content], {
    type: mimeType
  }));
  if (fileName){
  a.setAttribute('download', fileName);
  }
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}