import styled from "styled-components/macro";
import { theme } from "../themes/variables";

export const TextInCircle = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1;
  border-radius: 50%;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  text-align: center;
  margin-right: 15px;
`;