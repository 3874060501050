import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { theme } from "../themes/variables";
import { ReactComponent as UserIcon } from "../assets/icons/User.svg";

export function Avatar(props: {
  url?: string | null;
  className?: string;
  noBorder?: boolean;
  size?: number;
}) {
  const { t } = useTranslation();

  const { url, className, size = 45 } = props;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${size}px;
        height: ${size}px;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
      `}
      className={className}
    >
      {url ? (
        <img
          css={css`
            object-fit: cover;
            width: 100%;
            height: 100%;
          `}
          src={url}
          alt={t("account.avatar")}
        />
      ) : (
        <div
          css={css`
            width: ${size}px;
            height: ${size}px;
            ${props.noBorder
              ? css`
                  border: none;
                `
              : css`
                  border: 1px solid ${theme.colors.dark};
                `}
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <UserIcon width={20} height={20} color={theme.colors.dark} />
        </div>
      )}
    </div>
  );
}
