import { authorised } from "../../../request";

type Args = {
  id: string;
}

export async function deletePage({id}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/enterprise/pages/${id}/`
  });
}
