import { authorised } from "../../request";
import { AcceptedMimeTypes, File as FileType } from "../../types/File";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  name?: string;
  mime_type?: AcceptedMimeTypes[];
};

export async function getFiles(args: Args) {
  const API = authorised();
  const { mime_type, ...rest } = args;

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: FileType[];
  }>({
    method: "GET",
    url: `/api/studio/files/?${buildQueryString({
      ...rest,
      mime_type: mime_type?.join(",")
    })}`,
  });
}
