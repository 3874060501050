import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import {
  EditCreateCheatSheet,
  CheatSheetItem,
} from "../../components/EditCreateCheatSheet";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { DangerZone } from "../../components/DangerZone";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import {
  getCheatSheet,
  updateCheatSheet,
  deleteCheatSheet,
} from "../../actions/cheatSheet";
import { pageLoading } from "../../helpers/pageLoading";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Footer } from "../../components/Footer";

export function EditCheatSheetPage(props: {}) {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
    cheatSheetId: string;
  }>();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const onConfirmDelete = async () => {
    try {
      await deleteCheatSheet({
        courseId: params.courseId,
        cheatSheetId: params.cheatSheetId,
      });
      setDeleteModal(false);
      history.push(`/courses/${params.courseId}/cheat_sheet`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  const { data: cheatSheet, error: cheatSheetError } = useNewFetch(
    getCheatSheet,
    { courseId: params.courseId, cheatSheetId: params.cheatSheetId }
  );

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  if (!cheatSheet || cheatSheetError) {
    return pageLoading(cheatSheetError);
  }

  const onSubmit = async (data: CheatSheetItem) => {
    const { text_content, ...others } = data;

    await updateCheatSheet({
      courseId: params.courseId,
      cheatSheetId: params.cheatSheetId,
      data: {
        ...others,
        text_content: data.text_content || "",
      },
    });

    customToast.success(t("status.success"));
  };

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
      forceHightlight: true,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: false,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
    {
      label: course.name,
      url: `/courses/${course.uuid}/syllabus`,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateCheatSheet
            onSubmitCallback={onSubmit}
            attachedLessonOptions={course.lessons.map((lesson) => {
              return {
                value: lesson.lesson.uuid,
                label: lesson.lesson.name,
              };
            })}
            initialData={cheatSheet}
          />

          <DangerZone
            css={css`
              margin-top: 5rem;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("actions.delete")}
            </Button>

            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("cheat-sheet.modal.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
