import { authorised } from "../../request";
import { buildQueryString } from "../../helpers/buildQueryString";

export enum PerformancePeriod {
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
}

type Args = {
  limit?: number;
  offset?: number;
  period: PerformancePeriod;
  journey_id?: string;
  segment_id?: string;
  courses_id?: string;
};

export async function getCoursePerformanceWidgets(args: Args) {
  const API = authorised();

  return API.request<{
    total_enrolments: number;
    avg_percent_course_completions: number;
    avg_percent_passed_tests: number;
    avg_percent_passed_tests_first_attempt: number;
  }>({
    method: "GET",
    url: `/api/studio/courses/performance/widgets/?${buildQueryString(args)}`,
  });
}
