import React from "react";
import { css } from "styled-components/macro";
import { theme } from "../themes/variables";

import { ReactComponent as ReadIcon } from "../assets/icons/Read.svg";
import { ReactComponent as SubtractIcon } from "../assets/icons/Subtract.svg";

export function ProgressBar(props: {
  percent: number;
  color: string;
  emptyColor: string;
  backgroundColor?: string;
  height?: number;
  indicator?: boolean;
  className?: string;
}) {
  const {
    percent,
    color,
    emptyColor,
    height = 8,
    backgroundColor = theme.colors.white,
  } = props;
  return (
    <div
      css={css`
        padding: 10px 0;
      `}
    >
      <div
        css={css`
          background: ${emptyColor};
          border-radius: 20px;
          height: ${height}px;
          width: 100%;
          position: relative;
          :after {
            content: "";
            display: block;
            background: ${color};
            width: ${percent}%;
            height: 100%;
            border-radius: 20px;
            transition: all 300ms;
          }
        `}
        className={props.className}
      >
        {props.indicator && (
          <>
            <SubtractIcon
              width={18}
              height={18}
              color={color}
              css={css`
                position: absolute;
                top: ${height / 2 - height}px;
                left: calc(${percent}% - 4px);
                overflow: hidden;
                border-radius: 50%;
                background: ${backgroundColor};
                transition: all 300ms;
              `}
            />

            <ReadIcon
              color={color}
              width={18}
              height={18}
              css={css`
                position: absolute;
                z-index: 1;
                top: ${height / 2 - 9}px;
                left: calc(${percent}% + 12px);
                border-radius: 50%;
                transform: translate(-50%, -50%);
                transition: all 300ms;
              `}
            />
          </>
        )}
      </div>
    </div>
  );
}
