import React, { useRef, useState } from "react";
import {
  VideoContainer,
  VideoSafeContainer,
} from "../VideoInteractive/VideoInteractive";
import { VideoElements } from "../VideoInteractive/VideoElements";
import { VideoControls } from "../VideoInteractive/VideoControls";
import { css } from "styled-components/macro";
import { EditVideoElements } from "./EditVideoElements";
import { useDrop } from "react-dnd";
import { EditTextElementModal } from "./EditTextElementModal";
import { Rnd } from "react-rnd";
import { v4 as uuidv4 } from "uuid";
import { useStudio } from "../../contexts/StudioContext";
import { useTranslation } from "react-i18next";
import { ImageUploadModal } from "../../modals/ImageUploadModal";
import { DrawArea } from "../VideoInteractive/DrawArea";
import { Image } from "../../types/Image";
import { uploadImage } from "../../actions/image";
import { Edit2 } from "react-feather";
import { VideoElement } from "../../types/Video";

type ElementPosition = {
  x: number;
  y: number;
};

type NewElement = {
  type: string;
  position: ElementPosition;
};

export function VideoSceneEditor(props: {}) {
  const {
    playback,
    video,
    setVideo,
    addNewVideoElement,
    removeVideoElement,
    createNewVideoElementState,
    updateVideoElementState,
    stopDrawingMode,
    updateVideoElement,
    drawingMode,
    onLocationChange,
  } = useStudio();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [newElement, setNewElement] = useState<NewElement | null>(null);
  const [editElement, setEditElement] = useState<VideoElement | null>(null);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "element",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (
      item: {
        type: string;
      },
      monitor
    ) => {
      const dragItemOffset = monitor.getSourceClientOffset();

      if (dragItemOffset && wrapperRef.current) {
        const wrapperRect = wrapperRef.current.getBoundingClientRect();

        setNewElement({
          type: item.type,
          position: {
            x: dragItemOffset.x - wrapperRect.x,
            y: dragItemOffset.y - wrapperRect.y,
          },
        });
      }
    },
  }));
  const { t } = useTranslation();

  if (!video) return null;

  const createImageElement = (image: Image) => {
    addNewVideoElement({
      type: "image",
      id: uuidv4(),
      image: image,
      states: [
        {
          id: uuidv4(),
          start_time: playback.currentTime,
          duration: 3000,
          top: newElement && newElement.position.y ? newElement.position.y : 0,
          left: newElement && newElement.position.x ? newElement.position.x : 0,
          scale: 1,
          rotation: 0,
          width: 300,
          height: (image.height / image.width) * 300,
        },
      ],
    });
  };

  return (
    <div ref={wrapperRef}>
      <VideoContainer>
        <VideoSafeContainer
          onMouseMove={(e) => {
            const rect = wrapperRef.current?.getBoundingClientRect();

            if (!rect) return;

            // Mouse position
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            onLocationChange({
              x: x,
              y: y,
            });
          }}
          show={true}
          ref={drop}
          role={"Dustbin"}
        >
          <div
            css={css`
              position: relative;
              width: 100%;
              height: 100%;
            `}
          >
            {video.schema.elements.length > 0 ? (
              <VideoElements
                currentTime={playback.currentTime}
                video={video}
                playing={playback.playing}
                elementWrapper={(rendered, element, state, showElement) => {
                  return (
                    <Rnd
                      key={element.id}
                      position={{
                        x: state.left,
                        y: state.top,
                      }}
                      size={
                        state.width && state.height
                          ? {
                              width: state.width,
                              height: state.height,
                            }
                          : undefined
                      }
                      css={css`
                        border: 2px solid #eee;
                        box-sizing: content-box !important;
                        opacity: ${showElement ? 1 : 0};
                      `}
                      enableResizing={{
                        bottom: false,
                        bottomLeft: false,
                        bottomRight: state.width && state.height ? true : false,
                        left: false,
                        right: false,
                        top: false,
                        topLeft: false,
                        topRight: false,
                      }}
                      lockAspectRatio
                      onResizeStop={(e, direction, ref) => {
                        updateVideoElementState(element.id, state.id, {
                          top: state.top,
                          left: state.left,
                          width: parseFloat(ref.style.width),
                          height: parseFloat(ref.style.height),
                        });
                      }}
                      onDragStop={(e, d) => {
                        updateVideoElementState(element.id, state.id, {
                          top: d.y,
                          left: d.x,
                        });
                      }}
                    >
                      {rendered}
                      <button
                        className="delete-element"
                        css={css`
                          width: 18px;
                          height: 18px;
                          position: absolute;
                          top: -10px;
                          right: -10px;
                          background: #e85b2e;
                          border: none;
                          border-radius: 50%;
                          line-height: 10px;
                          text-align: center;
                          font-size: 12px;
                          font-family: Arial;
                          letter-spacing: 0;
                          padding: 0;
                          color: #fff;
                          font-weight: bold;
                        `}
                        onClick={(e) => {
                          e.stopPropagation();
                          removeVideoElement(element.id);
                        }}
                      >
                        x
                      </button>
                      <button
                        className="edit-element"
                        css={css`
                          width: 18px;
                          height: 18px;
                          position: absolute;
                          top: -10px;
                          right: 15px;
                          background: #e85b2e;
                          border: none;
                          border-radius: 50%;
                          line-height: 10px;
                          text-align: center;
                          font-size: 12px;
                          font-family: Arial;
                          letter-spacing: 0;
                          padding: 0;
                          color: #fff;
                          font-weight: bold;
                        `}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditElement(element);
                        }}
                      >
                        <Edit2 size={10} />
                      </button>
                    </Rnd>
                  );
                }}
              />
            ) : (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  height: calc(100% - 44px);
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                `}
              >
                <h3>{t("video_editor.scene.initial_message")}</h3>
              </div>
            )}
            {drawingMode && (
              <DrawArea
                mode={drawingMode}
                onDrawEnd={(drawing, data) => {
                  if (!drawing) return;

                  addNewVideoElement({
                    type: "drawing",
                    id: uuidv4(),
                    drawing: drawing,
                    states: [
                      {
                        id: uuidv4(),
                        start_time: playback.currentTime,
                        duration: 3000,
                        scale: 1,
                        rotation: 0,
                        width: data.width,
                        height: data.height,
                        left: data.left,
                        top: data.top,
                      },
                    ],
                  });
                  stopDrawingMode();
                }}
              />
            )}
          </div>
          <VideoControls
            currentTime={playback.currentTime}
            videoDuration={playback.videoDuration}
            onChangeTime={(time) => {
              playback.setCurrentTime(time);
            }}
            onPlay={() => {
              if (playback.playing) {
                playback.pause();
              } else {
                playback.play();
              }
            }}
            onRestart={() => {
              playback.setCurrentTime(0);
              playback.play();
            }}
            isPlaying={playback.playing}
            isFullscreen={false}
          />
          <div
            css={css`
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: #d6ef8e;
              opacity: 0;
              visibility: hidden;
              transition: all 500ms;

              ${isOver &&
              css`
                opacity: 0.5;
                visibility: visible;
              `}
            `}
          ></div>
        </VideoSafeContainer>
      </VideoContainer>
      <div
        css={css`
          margin-top: 20px;
        `}
      >
        <EditVideoElements
          video={video}
          currentTime={playback.currentTime}
          onVideoUpdate={(video) => setVideo(video)}
          onNewStateClick={(elementId, lastState) => {
            createNewVideoElementState(elementId, {
              ...lastState,
              id: uuidv4(),
              start_time: lastState.start_time + lastState.duration,
            });
          }}
        />
      </div>
      {newElement ? (
        newElement.type === "image" ? (
          <ImageUploadModal
            isVisible={true}
            onSelect={async (image) => {
              if (!image) return;

              createImageElement(image);
            }}
            onUpload={async ({ file, onUploadProgress }) => {
              const image = await uploadImage({
                file: file,
                onUploadProgress,
              });

              if (image) {
                createImageElement(image);
              }
            }}
            onClose={() => {
              setNewElement(null);
            }}
          />
        ) : (
          <EditTextElementModal
            videoId={video.uuid}
            isOpen={true}
            position={newElement.position}
            onClose={() => {
              setNewElement(null);
            }}
            currentTime={playback.currentTime}
            onSubmit={(element) => {
              addNewVideoElement(element);
              setNewElement(null);
            }}
          />
        )
      ) : null}
      {editElement ? (
        editElement.type === "image" ? (
          <ImageUploadModal
            isVisible={true}
            onSelect={async (image) => {
              if (!image) return;

              updateVideoElement(editElement.id, {
                ...editElement,
                image,
                states: editElement.states.map((state) => {
                  const width = state.width ? state.width : 300;

                  return {
                    ...state,
                    height: (image.height / image.width) * width,
                  };
                }),
              });
            }}
            onUpload={async ({ file, onUploadProgress }) => {
              const image = await uploadImage({
                file: file,
                onUploadProgress,
              });

              if (image) {
                updateVideoElement(editElement.id, {
                  ...editElement,
                  image,
                });
              }
            }}
            onClose={() => {
              setEditElement(null);
            }}
          />
        ) : editElement.type === "text" ? (
          <EditTextElementModal
            initialValue={editElement}
            videoId={video.uuid}
            isOpen={true}
            onClose={() => {
              setEditElement(null);
            }}
            currentTime={playback.currentTime}
            onSubmit={(element) => {
              updateVideoElement(editElement.id, element);

              setEditElement(null);
            }}
          />
        ) : null
      ) : null}
    </div>
  );
}
