import { Form, Formik, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { theme } from "../../../themes/variables";
import { EnterpriseContact } from "../../../types/Enterprise";
import { Column } from "../../../helpers/layout";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { Button } from "../../../components/Button";
import { InputField } from "../../fields/InputField";

export type FormValues = EnterpriseContact;

export default function EnterpriseContactSettingsForm({
  data,
  onSubmit,
}: {
  data: EnterpriseContact;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation();

  const onSubmitChanges = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        border-radius: 20px;
        background-color: ${theme.colors.white};
        width: 100%;
        padding: 65px 60px;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          email: data.email,
          contact_person: data.contact_person,
          website: data.website,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(),
          contact_person: Yup.string(),
          website: Yup.string(),
        })}
        onSubmit={onSubmitChanges}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <div
              css={css`
                display: flex;
              `}
            >
              <Column
                css={css`
                  display: flex;
                  width: 80%;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 17px;
                  `}
                >
                  <MailIcon
                    css={css`
                      margin-right: 16px;
                    `}
                  />
                  <div
                    css={css`
                      font-size: 20px;
                      line-height: 30px;
                    `}
                  >
                    {t("enterprise_settings.contact_info")}
                  </div>
                </div>
                <InputField
                  label={t("enterprise_settings.email")}
                  name={"email"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.contact_person")}
                  name={"contact_person"}
                  css={css`
                    margin-bottom: 17px;
                  `}
                />
                <InputField
                  label={t("enterprise_settings.website")}
                  name={"website"}
                />
              </Column>
              <Column>
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    align-items: center;
                  `}
                >
                  <Button
                    isSubmitting={isSubmitting}
                    type="submit"
                    disabled={!dirty || !isValid}
                    css={css`
                      display: flex;
                      margin: auto 0 0 auto;
                      font-size: 16px;
                      line-height: 18px;
                    `}
                    icon={<ReadIcon color={theme.colors.white} />}
                    iconRight
                  >
                    <div
                      css={css`
                        margin-right: 11px;
                      `}
                    >
                      {t("enterprise_settings.save")}
                    </div>
                  </Button>
                </div>
              </Column>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
