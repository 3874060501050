import { authorised } from "../../request";
import { OrganisationInvite } from "../../types/OrganisationInvite";
import { buildQueryString } from "../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
};

export async function getOrganisationInvites(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: OrganisationInvite[];
  }>({
    method: "GET",
    url: `/api/studio/organisation/users/invites/?${buildQueryString(args)}`,
  });
}
