import { authorised } from "../../request";
import { Organisation } from "../../types/Organisation";

type Args = Pick<Organisation, "name" | "description" | "icon">

export async function updateOrganisation(data: Args) {
  const API = authorised();

  return API.request<Organisation>({
    method: "PUT",
    url: `/api/studio/organisation/`,
    data
  });
}
