import humanizeDuration, { Options } from "humanize-duration";

export const humanize = humanizeDuration.humanizer({
  language: "en"
});

export const getDuration = (seconds: number, locale: string = "en", options: Options = {
  language: locale,
  units: ["h", "m"],
  round: true
}) => {
  return humanize(seconds * 1000, options);
}

export const fromStringToTimestamp = (time: string) => {
  const [H, M, S] = time.split(":");

  return +H * 3600 + +M * 60 + +S;
}

export const fromTimestampToString = (seconds: number | undefined) => {
  if (!seconds) {
    return;
  }

  let date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substr(11, 8);
}

export const formatDate = (date: string) => {
  const d = new Date(date);
  
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
}

export const formatDateTime = (date: string) => {
  const d = new Date(date);
  
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}`;
}
