import { authorised } from "../../request";
import { Course } from "../../types/Course";

type Args = {
  courseId: string;
};

export async function getCourse({ courseId }: Args) {
  const API = authorised();

  return API.request<Course>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/`,
  });
}
