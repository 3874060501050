import orderBy from "lodash/orderBy";
import { Video, VideoElement, VideoElementState } from "../types/Video";

export function calculateElementDuration(element: VideoElement) {
  return element.states.reduce((duration, state) => {
    return duration + state.duration;
  }, 0);
}

export function calculateElementStartingTime(element: VideoElement) {
  return element.states ? element.states[0].start_time : 0;
}

export function calculateVideoDuration(video: Video) {
  const stats = video.schema.elements.reduce(
    (found, element) => {
      const elementStartingTime = calculateElementStartingTime(element);
      const elementDuration = calculateElementDuration(element);
      const min = Math.min(found.min, elementStartingTime);
      const max = Math.max(found.max, elementStartingTime + elementDuration);

      return {
        min,
        max,
      };
    },
    {
      min: 0,
      max: 0,
    }
  );

  return stats.max - stats.min;
}

export function findElementState(element: VideoElement, currentTime: number) {
  // order reverse
  const states = orderBy(element.states, (s) => s.start_time, ["desc"]);

  return states.reduce((found: VideoElementState | null, state) => {
    if (found) return found;

    if (state.start_time <= currentTime) {
      return state;
    }

    return found;
  }, null);
}

export function calculateCanvasStartingTime(
  canvasesDuration: number[],
  i: number
) {
  if (i === 0) {
    return 0;
  }

  if (i < canvasesDuration.length) {
    return canvasesDuration.slice(0, i).reduce((sum, d) => sum + d);
  }

  return 0;
}
