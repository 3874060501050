import { authorised } from "../../request";

type Args = {
  courseId: string;
  selfAssessmentId: string;
};

export async function deleteSelfAssessment({
  courseId,
  selfAssessmentId,
}: Args) {
  const API = authorised();

  return API.request({
    method: "DELETE",
    url: `/api/studio/courses/${courseId}/self-assessments/${selfAssessmentId}/`,
  });
}
