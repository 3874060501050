import { authorised } from "../../../request";

export async function getUserStats() {
  const API = authorised();

  return await API.request<{
    users_count_campus: number;
    users_count_studio: number;
    pending_users_count_campus: number;
    pending_users_count_studio: number;
    approved_users_count_campus: number;
    approved_users_count_studio: number;
    rejected_users_count_campus: number;
    rejected_users_count_studio: number;
  }>({
    method: "GET",
    url: `/api/enterprise/users/stats/`,
  });
}
