import { authorised } from "../../request";
import { Lesson } from "../../types/Lesson";

type Args = {
  courseId: string;
  lessonId: string;
};

export async function getLesson({ courseId, lessonId }: Args) {
  const API = authorised();

  return API.request<Lesson>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/lessons/${lessonId}/`,
  });
}
