import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { customToast } from "../../../components/customToast";
import { UserSegment } from "../../../types/admin/UserSegment";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Button } from "../../../components/Button";
import { ModalDelete } from "../../../modals/ModalDelete";
import { UserSegmentCard } from "../../../components/Admin/UserSegment/UserSegmentCard";
import { Headers } from "../../../helpers/layout";

import {
  getUserSegments,
  deleteUserSegment,
} from "../../../actions/admin/userSegment";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { useNewFetch } from "../../../useAPI";
import { pageLoading } from "../../../helpers/pageLoading";
import { Footer } from "../../../components/Footer";

export function UserSegmentOverviewPage(props: {}) {
  const { t } = useTranslation();

  const {
    data: userSegments,
    error: userSegmentsError,
    refetch,
  } = useNewFetch(getUserSegments, {
    limit: 100,
    offset: 0,
  });

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteCandidate, setDeleteCandidate] = useState<UserSegment | null>(
    null
  );

  if (!userSegments || userSegmentsError) {
    return pageLoading(userSegmentsError);
  }

  const onDelete = async () => {
    if (deleteCandidate) {
      try {
        await deleteUserSegment({ id: deleteCandidate.id });

        setDeleteCandidate(null);
        setDeleteModal(false);
        refetch();
      } catch (error) {
        customToast.error(
          t("status.error", {
            error: serverErrorHandler(error),
          })
        );
      }
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("user_segment.title")}</title>
        </SEO>
        <Header />
      </Wrapper>

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <Headers.H3>{t("user_segment.title")}</Headers.H3>

              <Link to={"/admin/user-segment/new"}>
                <Button>{t("user_segment.add_new")}</Button>
              </Link>
            </div>

            {userSegments.results.length === 0 && (
              <div
                css={css`
                  text-align: center;
                  font-size: 22px;
                `}
              >
                <div
                  css={css`
                    margin-bottom: 20px;
                  `}
                >
                  {t("user_segment.no_segment")}
                </div>

                <Link to={"/admin/user-segment/new"}>
                  <Button>{t("user_segment.create_first")}</Button>
                </Link>
              </div>
            )}

            {userSegments.results.length > 0 && (
              <div
                css={css`
                  margin-top: 40px;
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  grid-gap: 30px;
                `}
              >
                {userSegments.results.map((userSegment, index) => {
                  return (
                    <UserSegmentCard
                      index={index}
                      key={userSegment.id}
                      segment={userSegment}
                      onDelete={(segment) => {
                        setDeleteCandidate(segment);
                        setDeleteModal(true);
                      }}
                    />
                  );
                })}
              </div>
            )}

            <ModalDelete
              isOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              onDelete={onDelete}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
