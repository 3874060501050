import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs, Tab } from "../../components/Tabs";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { SEO } from "../../components/SEO";
import InvitesTable from "../../components/Organisation/InvitesTable";
import { useNewFetch } from "../../useAPI";
import { useAuth } from "../../contexts/UserContext";
import { Permission } from "../../types/UserProfile";
import { OrganisationInvite } from "../../types/OrganisationInvite";

import { Headers } from "../../helpers/layout";
import { theme } from "../../themes/variables";

import { revokeInvite } from "../../actions/organisationInvite";
import { getOrganisation } from "../../actions/organisation";
import { pageLoading } from "../../helpers/pageLoading";

import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/Invite.svg";
import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Footer } from "../../components/Footer";

export function OrganisationInviteManagmentPage(props: {}) {
  const { t } = useTranslation();
  const ref = useRef<any>(null);

  const { hasOrganisationPermission } = useAuth();

  const [revokeModal, setRevokeModal] = useState(false);

  const [revokePretendent, setRevokePretendent] =
    useState<OrganisationInvite | null>(null);

  const { data: organisation, error: organisationError } =
    useNewFetch(getOrganisation);

  if (!organisation || organisationError) {
    return pageLoading(organisationError);
  }

  const tabsData: Tab[] = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/organisation`,
    },
  ];

  if (hasOrganisationPermission(Permission.ORGANISATION_MANAGE_USERS)) {
    tabsData.push({
      title: t("breadcrumbs.users"),
      link: `/organisation/users`,
    });
    tabsData.push({
      title: t("organisation.invites"),
      link: `/organisation/invites`,
    });
    tabsData.push({
      title: t("organisation.experts"),
      link: `/organisation/experts`,
    });
  }

  const revoke = async () => {
    if (!revokePretendent) {
      return;
    }

    try {
      await revokeInvite({
        invitationToken: revokePretendent.token,
      });

      ref.current.refetch();
      closeRevokeModal();
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const openRevokeModal = (invite: OrganisationInvite) => {
    setRevokePretendent(invite);
    setRevokeModal(true);
  };

  const closeRevokeModal = () => {
    setRevokePretendent(null);
    setRevokeModal(false);
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("organisation.title")}</title>
        </SEO>
        <Header />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{organisation.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                margin-top: 25px;
                margin-bottom: 25px;
              `}
            >
              <div
                css={css`
                  position: relative;
                  min-height: 200px;
                `}
              >
                <InvitesTable
                  forwardRef={ref}
                  openRevokeModal={(invite: OrganisationInvite) =>
                    openRevokeModal(invite)
                  }
                />
              </div>

              <Modal
                modalIsOpen={revokeModal}
                onClose={closeRevokeModal}
                contentLabel={t("organisation.stop-collaboration")}
                contentStyles={{
                  maxWidth: "560px",
                  padding: "25px 40px 40px 40px",
                }}
              >
                <ModalHeader
                  css={css`
                    justify-content: flex-end;
                  `}
                  closeIcon
                  onClose={closeRevokeModal}
                />

                <ModalBody
                  css={css`
                    padding-top: 0;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      margin-bottom: 33px;
                    `}
                  >
                    <InviteIcon
                      width={20}
                      height={20}
                      color={theme.colors.primary}
                    />
                    <Headers.H2
                      css={css`
                        margin-left: 12px;
                      `}
                    >
                      {t("organisation.revoke-invite")}
                    </Headers.H2>
                  </div>

                  <div>
                    {t("organisation.revoke-invite-text")}{" "}
                    <strong>{revokePretendent?.email}</strong>?
                  </div>
                </ModalBody>

                <ModalFooter
                  css={css`
                    justify-content: flex-start;
                  `}
                >
                  <Button
                    background={theme.colors.error}
                    color={theme.colors.white}
                    onClick={revoke}
                    icon={
                      <DeleteIcon
                        width={14}
                        height={14}
                        color={theme.colors.white}
                      />
                    }
                    iconRight
                  >
                    {t("organisation.revoke-invite")}
                  </Button>

                  <Button
                    background={theme.colors.transparent}
                    color={theme.colors.dark}
                    border={`1px solid ${theme.colors.dark}`}
                    onClick={closeRevokeModal}
                  >
                    {t("actions.cancel")}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
