import { authorised } from "../../../request";
import { SignupField } from "../../../types/admin/SignupField";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  section: "campus" | "studio";
};

export async function getSignupFields(args: Args) {
  const API = authorised();

  const {section, ...rest} = args;

  return await API.request<SignupField[]>({
    method: "GET",
    url: `/api/enterprise/signup-field/${section}/?${buildQueryString(rest)}`,
  });
}
