import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { DraggableComponent } from "../../components/Draggable";
import { Button } from "../../components/Button";
import { SEO } from "../../components/SEO";
import { Headers, Spacing, Row } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getEnterpriseFaqList } from "../../actions/faq";
import { pageLoading } from "../../helpers/pageLoading";
import { RouteComponentProps } from "react-router";
import { Tab, Tabs } from "../../components/Tabs";
import { Footer } from "../../components/Footer";

export function AdminFaqOverviewPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const { data: faqItems, error: faqItemsError } =
    useNewFetch(getEnterpriseFaqList);

  if (!faqItems || faqItemsError) {
    return pageLoading(faqItemsError);
  }

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("faq.title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
            `}
          >
            <Row
              justify="space-between"
              align="center"
              css={css`
                width: 100%;
              `}
            >
              <Headers.H3>{t("faq.title")}</Headers.H3>
              <Button
                className="create-faq-top"
                onClick={() => {
                  history.push(`/admin/faq/new`);
                }}
              >
                {t("faq.create")}
              </Button>
            </Row>
          </div>

          {faqItems.length ? (
            <div>
              <DraggableComponent
                items={faqItems}
                background={() => "transparent"}
                getDraggableId={(row) => String(row.id)}
                flat
              >
                {(item) => {
                  return (
                    <>
                      <Link
                        to={`/admin/faq/${item.id}/edit`}
                        title={item.answer}
                      >
                        {item.question}
                      </Link>
                    </>
                  );
                }}
              </DraggableComponent>

              <Row
                justify="flex-end"
                align={"center"}
                css={css`
                  margin-top: ${Spacing.xl};
                `}
              >
                <Button
                  className="create-faq"
                  onClick={() => {
                    history.push(`/admin/faq/new`);
                  }}
                >
                  {t("faq.create")}
                </Button>
              </Row>
            </div>
          ) : (
            <Headers.H3>{t("faq.no-faq")}</Headers.H3>
          )}
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
