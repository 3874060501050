import { authorised } from "../../../request";
import { Skill } from "../../../types/admin/Skill";

type SkillArgs = Omit<Skill, "id">

type Args = {
  data: SkillArgs;
}

export async function createSkill({data}: Args) {
  const API = authorised();

  return API.request<Skill>({
    method: "POST",
    url: `/api/enterprise/skills/`,
    data
  });
}
