import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Formik, Form, FormikHelpers, useFormikContext } from "formik";
import { customToast } from "../customToast";
import * as Yup from "yup";
import { QuestionType, TestVariant, Test } from "../../types/Test";
import { LessonType } from "../../types/Lesson";
import { InputField } from "../../forms/fields/InputField";
import { InputMaskField } from "../../forms/fields/InputMaskField";
import { RadioField } from "../../forms/fields/RadioField";
import { CheckboxField } from "../../forms/fields/CheckboxField";
import { Button } from "../Button";
import { TestingContent } from "./TestingContent";
import { theme } from "../../themes/variables";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

export function SectionWrapper(props: {
  label: string;
  content?: string;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <section
      css={css`
        margin-bottom: 32px;
      `}
    >
      <div
        css={css`
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: bold;
        `}
      >
        {props.label}
      </div>

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: ${props.content || "flex-start"};
          > label {
            margin-right: 1.5rem;
          }
        `}
        className={props.className}
      >
        {props.children}
      </div>
    </section>
  );
}

export interface FormValues {
  internal_name: string;
  in_exam: boolean;
  in_repetition: boolean;
  in_video: boolean;
  timecode: string;
  is_required: boolean;
  uuid?: string;
  question_type: QuestionType;
  variants?: TestVariant[];
}

export function EditCreateQuiz({
  initialData,
  type,
  onSubmitCallback,
  nameMaxLength = 150,
}: {
  initialData?: Test | null;
  nameMaxLength?: number;
  type: LessonType;
  onSubmitCallback: (values: FormValues) => Promise<void> | void;
}) {
  const { t } = useTranslation();

  const settingsBoxes: { label: string; value: string }[] = [
    { label: t("lesson.quiz.include.final"), value: "in_exam" },
    { label: t("lesson.quiz.include.repetition"), value: "in_repetition" },
  ];

  if (type === LessonType.EMBED || type === LessonType.BLOCK) {
    settingsBoxes.push({
      label: t("lesson.quiz.include.video"),
      value: "in_video",
    });
  }

  const questionTypeFields: { label: string; value: QuestionType }[] = [
    {
      label: t("lesson.quiz.question.true-false"),
      value: QuestionType.TRUE_FALSE,
    },
    {
      label: t("lesson.quiz.question.single-choice"),
      value: QuestionType.SINGLE_CHOICE,
    },
    {
      label: t("lesson.quiz.question.multiple-choice"),
      value: QuestionType.MULTIPLE_CHOICE,
    },
  ];

  const validationSchema = Yup.object().shape({
    internal_name: Yup.string()
      .max(nameMaxLength)
      .label(t("lesson.quiz.internal-title"))
      .required(),
    timecode: Yup.string().when("in_video", {
      is: true,
      then: Yup.string().min(8).required(t("lesson.quiz.error.timecode")),
      otherwise: Yup.string(),
    }),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        internal_name: values.internal_name,
        in_exam: values.in_exam,
        in_repetition: values.in_repetition,
        in_video: values.in_video,
        timecode: values.timecode || "00:00:00",
        is_required: values.is_required,
        question_type: values.question_type,
        variants: values.variants || [],
      });
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const ChangeTabHelper = () => {
    const { setFieldValue, getFieldMeta } = useFormikContext();

    const question_type = getFieldMeta<QuestionType>("question_type");

    const { value: variants } = getFieldMeta<TestVariant[]>("variants");

    useDeepCompareEffect(() => {
      variants.forEach((variant, idx) => {
        if (
          question_type.value === QuestionType.TRUE_FALSE &&
          variant.answers
        ) {
          setFieldValue(`variants[${idx}].answers[0]`, variant.answers[0]);
        }

        if (
          question_type.value === QuestionType.SINGLE_CHOICE &&
          variant.answers
        ) {
          let is_correct_counter = 0;
          const res = variant.answers.map((answer) => {
            if (answer.is_correct && is_correct_counter === 0) {
              is_correct_counter++;

              return {
                ...answer,
                is_correct: true,
              };
            }

            return {
              ...answer,
              is_correct: false,
            };
          });

          setFieldValue(`variants[${idx}].answers`, res);
        }
      });
    }, [question_type.value, setFieldValue, variants]);

    return null;
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        internal_name: initialData?.internal_name || "",
        in_exam: initialData?.in_exam || false,
        in_repetition: initialData?.in_repetition || false,
        in_video: initialData?.in_video || false,
        timecode: initialData?.timecode || "",
        is_required: initialData?.is_required || false,
        question_type: initialData?.question_type || QuestionType.TRUE_FALSE,
        variants: initialData?.variants || [],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values }) => (
        <Form
          noValidate
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <ChangeTabHelper />
          <div
            css={css`
              max-width: 70%;
            `}
          >
            <InputField
              label={`${t("lesson.quiz.internal-title")}*:`}
              name={"internal_name"}
              maxLength={nameMaxLength}
            />

            <SectionWrapper label={`${t("lesson.quiz.settings")}:`}>
              {settingsBoxes.map((settingBox, idx) => (
                <CheckboxField
                  name={settingBox.value}
                  label={settingBox.label}
                  key={idx}
                  css={css`
                    margin-bottom: 1rem;
                    :last-of-type {
                      margin-bottom: 0;
                    }
                  `}
                />
              ))}
            </SectionWrapper>

            {values.in_video && (
              <InputMaskField
                css={css`
                  max-width: 50%;
                  margin-bottom: 1rem;
                `}
                label={`${t("lesson.quiz.show-timecode")}*:`}
                name={"timecode"}
                placeholder="HH:MM:SS"
              />
            )}

            <SectionWrapper label={`${t("lesson.quiz.question.type")}:`}>
              {questionTypeFields.map((questionType, idx) => (
                <RadioField
                  labelRight
                  name="question_type"
                  label={questionType.label}
                  value={questionType.value}
                  key={idx}
                />
              ))}
            </SectionWrapper>

            <SectionWrapper label={`${t("lesson.quiz.question.content")}:`}>
              <TestingContent name="variants" type={values.question_type} />
            </SectionWrapper>
          </div>
          <div
            css={css`
              margin-top: 25px;
              display: flex;
              align-self: flex-end;
              flex-direction: row-reverse;
            `}
          >
            <div
              css={css`
                text-align: right;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                type="submit"
                disabled={!isValid}
              >
                {t(!!initialData ? "actions.update" : "actions.create")}
              </Button>

              {!isValid && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    color: ${theme.colors.redMain};
                    margin-top: 10px;
                  `}
                >
                  {t("status.error-fill")}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
