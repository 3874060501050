import React from "react";
import { useTranslation } from "react-i18next";
import { customToast } from "../../../components/customToast";
import { css } from "styled-components/macro";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { theme } from "../../../themes/variables";
import { uploadImage } from "../../../actions/image";
import { Slider } from "../../../types/admin/Slider";
import { Column } from "../../../helpers/layout";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { InputField } from "../../fields/InputField";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { TextareaField } from "../../fields/TextareaField";
import { Button } from "../../../components/Button";

export default function NewEditSliderForm({
  initialValues,
  onSubmit,
  onClose,
}: {
  initialValues?: Slider;
  onSubmit: (values: Slider) => Promise<void>;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const onSubmitValues = async (
    values: Slider,
    { setSubmitting }: FormikHelpers<Slider>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<Slider>
      enableReinitialize
      initialValues={{
        title: initialValues?.title || "",
        cover_image: initialValues?.cover_image,
        description: initialValues?.description || "",
        link_label: initialValues?.link_label || "",
        link_url: initialValues?.link_url || "",
        testimonial_author_title: initialValues?.testimonial_author_title || "",
        testimonial_author_subtitle:
          initialValues?.testimonial_author_subtitle || "",
        author_avatar: initialValues?.author_avatar || "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
        link_label: Yup.string().required(),
        link_url: Yup.string().url().required(),
        testimonial_author_title: Yup.string().required(),
        testimonial_author_subtitle: Yup.string().required(),
      })}
      onSubmit={onSubmitValues}
    >
      {({ isSubmitting, dirty }) => (
        <Form noValidate>
          <div
            css={css`
              display: flex;
              border-radius: 20px;
              background-color: ${theme.colors.white};
              width: 100%;
              padding: 40px;
              margin-bottom: 40px;
            `}
          >
            <Column
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <InputField
                label={t("slider.title")}
                name={"title"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <ImageUploadField
                label={t("slider.image")}
                name={"cover_image"}
                uploadFn={uploadImage}
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <TextareaField
                label={t("slider.description")}
                name={"description"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <InputField
                label={t("slider.url")}
                name={"link_url"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <InputField
                label={t("slider.label")}
                name={"link_label"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <InputField
                label={t("slider.author_name")}
                name={"testimonial_author_title"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <InputField
                label={t("slider.author_role")}
                name={"testimonial_author_subtitle"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <ImageUploadField
                label={t("slider.author_avatar")}
                name={"author_avatar"}
                uploadFn={uploadImage}
                css={css`
                  margin-bottom: 32px;
                `}
              />
            </Column>
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: end;
            `}
          >
            <Button
              css={css`
                margin-right: 20px;
                background-color: ${theme.colors.gray1};
                color: ${theme.colors.black};
              `}
              hoverStyles={`opacity: 0.85;`}
              onClick={onClose}
            >
              {t("actions.cancel")}
            </Button>
            <Button isSubmitting={isSubmitting} type="submit" disabled={!dirty}>
              {t("slider.save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
