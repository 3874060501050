import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Field, FieldProps } from "formik";
import { theme } from "../../themes/variables";
import { File as FileType, AcceptedMimeTypes } from "../../types/File";
import { Button } from "../../components/Button";
import { TextBlurred } from "../../components/TextBlurred";
import { FileUploadModal } from "../../modals/FileUploadModal";

import { ReactComponent as DeleteIcon } from "../../assets/icons/Delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as DocIcon } from "../../assets/icons/DOC.svg";
import { ReactComponent as Mp3Icon } from "../../assets/icons/MP3.svg";
import { ReactComponent as PDFIcon } from "../../assets/icons/PDF.svg";
import { ReactComponent as PresentationIcon } from "../../assets/icons/Presentation.svg";
import { ReactComponent as SVGIcon } from "../../assets/icons/SVG.svg";
import { InputLabel } from "../../components/InputLabel";

export function FileUploadField(props: {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  acceptedMimeTypes?: AcceptedMimeTypes[];
  validate?: (value: string) => Promise<void | string>;
}) {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);

  const checkIfContainsMimeType = (type: AcceptedMimeTypes) => {
    if (!props.acceptedMimeTypes) return true;

    return props.acceptedMimeTypes.includes(type);
  };

  return (
    <Field name={props.name} validate={props.validate}>
      {({ field, form }: FieldProps) => {
        const file: FileType | null = field.value;

        return (
          <div
            className={props.className}
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <InputLabel label={props.label} />

            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding: 40px;
                border-radius: 20px;
                background: ${theme.colors.gray1};
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex: 1;
                `}
              >
                <div
                  css={css`
                    width: ${file ? `100%` : "auto"};
                    display: flex;
                    align-items: center;
                  `}
                >
                  {file ? (
                    <>
                      {checkIfContainsMimeType(file.mime_type) &&
                        (file.mime_type === AcceptedMimeTypes.MP3 ||
                          file.mime_type === AcceptedMimeTypes.MPEG ||
                          file.mime_type === AcceptedMimeTypes.WEBA ||
                          file.mime_type === AcceptedMimeTypes.WEBM) && (
                          <Mp3Icon
                            width={40}
                            height={40}
                            color={theme.colors.primary}
                          />
                        )}

                      {checkIfContainsMimeType(file.mime_type) &&
                        (file.mime_type === AcceptedMimeTypes.DOC ||
                          file.mime_type === AcceptedMimeTypes.DOCX ||
                          file.mime_type === AcceptedMimeTypes.XLS ||
                          file.mime_type === AcceptedMimeTypes.XLSX) && (
                          <DocIcon
                            width={40}
                            height={40}
                            color={theme.colors.primary}
                          />
                        )}

                      {checkIfContainsMimeType(file.mime_type) &&
                        file.mime_type === AcceptedMimeTypes.PDF && (
                          <PDFIcon
                            width={40}
                            height={40}
                            color={theme.colors.primary}
                          />
                        )}

                      {checkIfContainsMimeType(file.mime_type) &&
                        (file.mime_type === AcceptedMimeTypes.PPTX ||
                          file.mime_type === AcceptedMimeTypes.PPT) && (
                          <PresentationIcon
                            width={40}
                            height={40}
                            color={theme.colors.primary}
                          />
                        )}

                      {checkIfContainsMimeType(file.mime_type) &&
                        file.mime_type === AcceptedMimeTypes.SVG && (
                          <SVGIcon
                            width={40}
                            height={40}
                            color={theme.colors.primary}
                          />
                        )}

                      <TextBlurred
                        css={css`
                          font-size: 16px;
                          line-height: 22px;
                          margin-left: 12px;
                        `}
                        title={file.title || file.upload_name}
                      >
                        {file.title || file.upload_name}
                      </TextBlurred>
                    </>
                  ) : (
                    <div
                      css={css`
                        width: 150px;
                        height: 100%;
                        background: ${theme.colors.gray2};
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                      `}
                      onClick={() => setModal(true)}
                    >
                      {t("media.no-file")}
                    </div>
                  )}
                </div>
              </div>

              <div
                css={css`
                  display: flex;
                `}
              >
                <Button
                  background={theme.colors.primary}
                  color={theme.colors.dark}
                  onClick={() => setModal(true)}
                  title={t("actions.change")}
                  css={css`
                    min-width: 52px;
                  `}
                  border={`1px solid ${theme.colors.transparent}`}
                >
                  <EditIcon width={14} height={14} color={theme.colors.white} />
                </Button>

                {file && (
                  <Button
                    background={theme.colors.transparent}
                    color={theme.colors.error}
                    onClick={() => form.setFieldValue(props.name, null)}
                    title={t("actions.delete")}
                    css={css`
                      min-width: 52px;
                      margin-left: 12px;
                    `}
                    border={`1px solid ${theme.colors.error}`}
                    hoverStyles={`background: ${theme.colors.error}; border: 1px solid ${theme.colors.error}; color: ${theme.colors.white};`}
                    icon={
                      <DeleteIcon
                        width={14}
                        height={14}
                        color={theme.colors.error}
                      />
                    }
                  ></Button>
                )}
              </div>
            </div>

            <FileUploadModal
              isVisible={modal}
              value={file}
              onSelect={async (file) => {
                form.setFieldValue(props.name, file);
              }}
              onClose={() => {
                setModal(false);
              }}
              acceptedMimeTypes={props.acceptedMimeTypes}
            />
          </div>
        );
      }}
    </Field>
  );
}
