import { css } from "styled-components/macro";
import { Draggable } from "react-beautiful-dnd";
import { Slider } from "../../../types/admin/Slider";
import { theme } from "../../../themes/variables";
import { ReactComponent as EditSVG } from "../../../assets/icons/Edit.svg";
import { ReactComponent as MoveSVG } from "../../../assets/icons/Move.svg";
import { ReactComponent as TrashSVG } from "../../../assets/icons/Delete.svg";
import { Button } from "../../Button";

export default function SliderCard({
  slider,
  onSetModal,
  onSetDeleteModal,
  onSelectSlider,
}: {
  slider: Slider;
  onSetModal: (modal: boolean) => void;
  onSetDeleteModal: (modal: boolean) => void;
  onSelectSlider: (slider: Slider) => void;
}) {
  return (
    <Draggable draggableId={String(slider.id)} index={Number(slider.order)}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          css={css`
            display: flex;
            width: 60%;
            flex-direction: column;
            border-radius: 20px;
            padding: 25px;
            margin-bottom: 20px;
            background-image: url(${slider.cover_image?.image});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              width: 100%;
              height: fit-content;
              margin-bottom: 10px;
            `}
          >
            <div
              css={css`
                font-size: 30px;
                line-height: 42px;
                margin-right: 40px;
              `}
            >
              {slider.title}
            </div>
            <div
              css={css`
                display: flex;
                margin: 0 0 auto auto;
              `}
            >
              <Button
                color={theme.colors.blueBackground}
                background={theme.colors.transparent}
                css={css`
                  margin-right: 8px;
                  border: 1px solid ${theme.colors.blueBackground};
                  padding: 5px 20px;
                  min-width: auto;
                  height: 25px;
                `}
                onClick={() => {
                  onSelectSlider(slider);
                  onSetModal(true);
                }}
              >
                <EditSVG />
              </Button>
              <div
                {...provided.dragHandleProps}
                color={theme.colors.primary}
                css={css`
                  display: flex;
                  margin-right: 8px;
                  border: 1px solid ${theme.colors.black};
                  border-radius: 20px;
                  padding: 5px 20px;
                  min-width: auto;
                  height: 25px;
                `}
              >
                <MoveSVG color={theme.colors.primary} />
              </div>
              <Button
                color={theme.colors.error}
                background={theme.colors.transparent}
                css={css`
                  border: 1px solid ${theme.colors.error};
                  padding: 5px 20px;
                  min-width: auto;
                  height: 25px;
                `}
                onClick={() => {
                  onSelectSlider(slider);
                  onSetDeleteModal(true);
                }}
              >
                <TrashSVG />
              </Button>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              width: 70%;
              font-size: 16px;
            `}
          >
            {slider.description}
          </div>
          <div
            css={css`
              display: flex;
              height: 60px;
              margin: auto auto 0 0;
            `}
          >
            <div
              css={css`
                margin: auto auto 0 0;
              `}
            >
              <Button
                css={css`
                  padding: 3px 20px;
                  font-size: 16px;
                `}
                onClick={() => (window.location.href = slider.link_url)}
              >
                {slider.link_label}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
