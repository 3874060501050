import getStroke from "perfect-freehand";
import { Drawing, DrawingMark } from "../types/Drawing";

export const penStrokeConfig = {
  size: 10,
  thinning: 0,
  smoothing: 0.5,
  streamline: 0.5,
};

export const highligterStrokeConfig = {
  size: 30,
  thinning: 0,
  smoothing: 0.5,
  streamline: 0.5,
};

// Create SVG path data using the points from perfect-freehand.
export function getSvgPathFromStroke(points: number[][]) {
  if (points.length === 0) return "";

  const d = [];

  let [p0, p1] = points;

  d.push("M", p0[0], p0[1], "Q");

  for (let i = 1; i < points.length; i++) {
    d.push(p0[0], p0[1], (p0[0] + p1[0]) / 2, (p0[1] + p1[1]) / 2);
    p0 = p1;
    p1 = points[i];
  }

  d.push("Z");

  return d.join(" ");
}

export function getDrawingMarkRect(
  currentMark: DrawingMark,
  type: "highlighter" | "pen"
) {
  const strokeConfig =
    type === "highlighter" ? highligterStrokeConfig : penStrokeConfig;

  return getStroke(currentMark.points, strokeConfig).reduce(
    (positions, point) => {
      return {
        left: point[0] < positions.left[0] ? point : positions.left,
        top: point[1] < positions.top[1] ? point : positions.top,
        right: point[0] > positions.right[0] ? point : positions.right,
        bottom: point[1] > positions.bottom[1] ? point : positions.bottom,
      };
    },
    {
      left: currentMark.points[0],
      top: currentMark.points[1],
      right: currentMark.points[0],
      bottom: currentMark.points[1],
    }
  );
}

export function getDrawingRect(drawing: Drawing) {
  const positions = drawing.marks.reduce(
    (positions, mark) => {
      const rect = getDrawingMarkRect(mark, drawing.type);

      return {
        left: rect.left[0] < positions.left[0] ? rect.left : positions.left,
        top: rect.top[1] < positions.top[1] ? rect.top : positions.top,
        right:
          rect.right[0] > positions.right[0] ? rect.right : positions.right,
        bottom:
          rect.bottom[1] > positions.bottom[1] ? rect.bottom : positions.bottom,
      };
    },
    {
      left: drawing.marks[0].points[0],
      top: drawing.marks[0].points[1],
      right: drawing.marks[0].points[0],
      bottom: drawing.marks[0].points[1],
    }
  );

  const trimmed = {
    ...drawing,
    marks: drawing.marks.map((mark) => {
      return {
        points: mark.points.map((point) => {
          return [
            point[0] - positions.left[0],
            point[1] - positions.top[1],
            point[2],
            point[3],
          ];
        }),
      };
    }),
  };

  const width = positions.right[0] - positions.left[0];
  const height = positions.bottom[1] - positions.top[1];

  return {
    ...positions,
    width,
    height,
    trimmed,
  };
}
