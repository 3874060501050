import { authorised } from "../../../request";
import { EnterpriseContact } from "../../../types/Enterprise";

export async function getEnterpriseContactSettings() {
  const API = authorised();

  return await API.request<EnterpriseContact>({
    method: "GET",
    url: `/api/enterprise/settings/contact/`,
  });
}
