import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { X } from "react-feather";
import { theme } from "../themes/variables";
import { Button } from "./Button";
import { RequiredDataType } from "../types/RequiredDataType";
import { InputLabel } from "./InputLabel";
import { EntitySelectionModal } from "./EntitySelectionModal";

export function EntitySelectionComponent<T extends RequiredDataType>(props: {
  label?: string;
  description?: string;
  name?: string;
  value: T[];
  emptyMessage?: string;
  searchCallback: (searchText: string) => Promise<T[]>;
  onRemove: (index: number) => void;
  onConfirm: (items: T[]) => void;
}) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <div
      css={css`
        margin-bottom: 16px;
      `}
    >
      <InputLabel label={props.label} description={props.description} />

      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
        `}
      >
        {props.value.map((el, idx) => {
          return (
            <div
              key={idx}
              css={css`
                display: flex;
                align-items: center;
                padding: 8px 16px;
                border-radius: 20px;
                border: 1px solid ${theme.colors.dark};
                margin-right: 16px;
                margin-bottom: 16px;
              `}
            >
              <span
                css={css`
                  margin-right: 10px;
                  line-height: 1;
                `}
              >
                {el.label}
              </span>

              <Button
                flat
                css={css`
                  min-width: 0;
                `}
                color={theme.colors.dark}
                background={theme.colors.transparent}
                hoverStyles={`background: ${theme.colors.transparent};`}
                onClick={() => {
                  props.onRemove(idx);
                }}
              >
                <X
                  size={12}
                  css={css`
                    display: flex;
                  `}
                />
              </Button>
            </div>
          );
        })}

        <Button
          flat
          css={css`
            padding: 8px 16px;
            border-radius: 16px;
            margin-bottom: 16px;
          `}
          border={`1px solid ${theme.colors.dark}`}
          background={theme.colors.dark}
          color={theme.colors.white}
          hoverStyles={`background: ${theme.colors.primary}; color: ${theme.colors.white}; border: 1px solid ${theme.colors.primary};`}
          onClick={() => setModal(true)}
        >
          {t("entity-selection.add-new")}
        </Button>

        {modal && (
          <EntitySelectionModal
            show={true}
            onClose={() => {
              setModal(false);
            }}
            selectedItems={props.value}
            emptyMessage={props.emptyMessage}
            searchCallback={props.searchCallback}
            onConfirm={(selected) => {
              props.onConfirm(selected);
              setModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
}
