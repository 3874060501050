import { authorised } from "../../request";
import { AdditionalMaterial } from "../../types/AdditionalMaterial";

type Args = {
  courseId: string;
  data: Pick<AdditionalMaterial, "link" | "title" | "icon">;
}

export async function createAdditionalMaterial({courseId, data}: Args) {
  const API = authorised();

  return API.request<AdditionalMaterial>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/additional-materials/`,
    data
  });
}
