import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { customToast } from "../../components/customToast";
import { EditCreateFaq, FormValues } from "../../components/EditCreateFaq";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Headers } from "../../helpers/layout";
import { DangerZone } from "../../components/DangerZone";
import { Button } from "../../components/Button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal";
import { SEO } from "../../components/SEO";
import { theme } from "../../themes/variables";

import {
  getStudioFaqItem,
  updateStudioFaqItem,
  deleteStudioFaqItem,
} from "../../actions/faq";
import { useNewFetch } from "../../useAPI";
import { pageLoading } from "../../helpers/pageLoading";
import { RouteComponentProps } from "react-router";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";
import { Tabs } from "../../components/Tabs";
import { Footer } from "../../components/Footer";

export function StudioEditFaqPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const params = useParams<{
    id: string;
  }>();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const { data: faqItem, error: faqItemError } = useNewFetch(getStudioFaqItem, {
    id: params.id,
  });

  if (!faqItem || faqItemError) {
    return pageLoading(faqItemError);
  }

  const onConfirmDelete = async () => {
    try {
      await deleteStudioFaqItem({ id: params.id });
      setDeleteModal(false);
      history.push(`/studio/faq`);
    } catch (error) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    }
  };

  const onSubmit = async (data: FormValues) => {
    await updateStudioFaqItem({ id: params.id, data });
  };

  const tabsData = [
    {
      title: t("courses.title"),
      link: "/courses",
    },
    {
      title: t("header.navigation.faq"),
      link: `/studio/faq`,
      forceHightlight: true,
    },
    {
      title: t("feedback.feedback"),
      link: "/studio/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("faq.title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 30px;
            `}
          >
            <Headers.H3>{faqItem.question}</Headers.H3>
          </div>
          <EditCreateFaq onSubmitCallback={onSubmit} initialData={faqItem} />

          <DangerZone
            css={css`
              margin-top: 5rem;
            `}
          >
            <Button onClick={() => setDeleteModal(true)}>
              {t("actions.delete")}
            </Button>

            <Modal
              modalIsOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              contentLabel={t("actions.sure")}
            >
              <ModalHeader closeIcon onClose={() => setDeleteModal(false)}>
                {t("actions.sure")}
              </ModalHeader>

              <ModalBody>{t("faq.modal.body")}</ModalBody>

              <ModalFooter>
                <Button
                  background={theme.colors.white}
                  color={theme.colors.dark}
                  border={`1px solid ${theme.colors.dark}`}
                  hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary};`}
                  onClick={() => setDeleteModal(false)}
                >
                  {t("actions.cancel")}
                </Button>

                <Button onClick={onConfirmDelete}>{t("actions.delete")}</Button>
              </ModalFooter>
            </Modal>
          </DangerZone>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
