import React, { useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Image, ImageType } from "../../../../types/Image";
import { theme } from "../../../../themes/variables";
import { Button } from "../../../Button";
import { ImageUploadModal } from "../../../../modals/ImageUploadModal";
import { uploadImage } from "../../../../actions/image";

export function ImageHandler(props: {
  image?: Image | null;
  disableLibrary?: boolean;
  type?: ImageType;
  onImageChange: (img: Image) => Promise<void>;
  removeImage: () => void;
}) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-right: 15px;
      `}
    >
      <div>
        <div
          css={css`
            width: 130px;
            height: 130px;
          `}
        >
          {props.image ? (
            <img
              src={props.image.image}
              alt={props.image.upload_name}
              css={css`
                border-radius: 10px;
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
              `}
            />
          ) : (
            <div
              css={css`
                width: 100%;
                height: 100%;
                background: ${theme.colors.gray2};
                color: ${theme.colors.gray4};
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              `}
              onClick={() => setModal(true)}
            >
              {t("media.no-image")}
            </div>
          )}
        </div>
      </div>

      <ImageUploadModal
        disableLibrary={props.disableLibrary}
        isVisible={modal}
        onClose={() => {
          setModal(false);
        }}
        type={props.type}
        onUpload={async ({ file, onUploadProgress }) => {
          const image = await uploadImage({
            file: file,
            type: props.type,
            onUploadProgress,
          });

          if (image) {
            await props.onImageChange(image);
          }
        }}
        onSelect={async (img) => await props.onImageChange(img)}
      />

      <Button
        background={theme.colors.primary}
        color={theme.colors.white}
        onClick={() => setModal(true)}
        css={css`
          margin-top: 20px;
        `}
        small
      >
        {t("actions.change")}
      </Button>

      {props.image && (
        <Button
          background={theme.colors.gray2}
          color={theme.colors.dark}
          onClick={() => props.removeImage()}
          css={css`
            margin-top: 20px;
          `}
          small
        >
          {t("actions.delete")}
        </Button>
      )}
    </div>
  );
}
