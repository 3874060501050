import { Course } from "./Course";
import { File } from "./File";

export enum AdditionalMaterialIcon {
  VIDEO = "VIDEO",
  LINK = "LINK",
  PODCAST = "PODCAST",
  PDF = "PDF"
}

export enum AdditionalMaterialType {
  FILE = "FILE",
  LINK = "LINK"
}

export type AdditionalMaterial = {
  uuid: string;
  icon: AdditionalMaterialIcon;
  type: AdditionalMaterialType;
  file?: File;
  title: string;
  link?: string;
  course?: Course;
}
