import React from "react";
import { Field, FieldArray, FieldProps, FormikProps } from "formik";
import { EntitySelectionComponent } from "../../components/EntitySelectionComponent";
import { RequiredDataType } from "../../types/RequiredDataType";

export function EntitySelectionField<T extends RequiredDataType>(props: {
  searchCallback: (searchText: string) => Promise<T[]>;
  label: string;
  description?: string;
  name: string;
  id?: string;
}) {
  const onModalConfirm = (form: FormikProps<any>, items: T[]) => {
    form.setFieldValue(props.name, items);
  };

  return (
    <div id={props.id}>
      <Field
        name={props.name}
        render={({ field, form }: FieldProps) => {
          const fieldItems: T[] = field.value || [];

          return (
            <FieldArray
              name={props.name}
              render={(arrayHelpers) => (
                <EntitySelectionComponent<T>
                  value={fieldItems}
                  label={props.label}
                  description={props.description}
                  searchCallback={props.searchCallback}
                  name={props.name}
                  onConfirm={(items) => onModalConfirm(form, items)}
                  onRemove={(idx) => arrayHelpers.remove(idx)}
                />
              )}
            ></FieldArray>
          );
        }}
      />
    </div>
  );
}
