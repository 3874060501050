import { UserMeta } from "./UserProfile";

export enum OrganisationUserRole {
  ORGANISATION_EDITOR = "ORGANISATION_EDITOR",
  ORGANISATION_ADMIN = "ORGANISATION_ADMIN"
}

export type OrganisationUser = {
  role?: {
    key: OrganisationUserRole;
  };
  user: UserMeta;
}
