import { authorised } from "../../../request";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  name: string;
}

export async function checkUserSegmentName(args: Args) {
  const API = authorised();

  return API.request<{
    exists: boolean;
  }>({
    method: "GET",
    url: `/api/enterprise/user-segment/check/?${buildQueryString(args)}`
  });
}
