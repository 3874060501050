import { authorised } from "../../request";
import { SelfAssessment } from "../../types/SelfAssessment";

type Args = {
  courseId: string;
};

export async function getSelfAssessments({ courseId }: Args) {
  const API = authorised();

  return API.request<SelfAssessment[]>({
    method: "GET",
    url: `/api/studio/courses/${courseId}/self-assessments/`,
  });
}
