import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useNewFetch } from "../../../useAPI";
import { customToast } from "../../../components/customToast";
import { pageLoading } from "../../../helpers/pageLoading";
import { createSlider } from "../../../actions/admin/sliders/createSlider";
import { deleteSlider } from "../../../actions/admin/sliders/deleteSlider";
import { getSlidersList } from "../../../actions/admin/sliders/getSlidersList";
import { updateSlider } from "../../../actions/admin/sliders/updateSlider";
import { Slider } from "../../../types/admin/Slider";
import { theme } from "../../../themes/variables";
import EditNewSliderModal from "../../../components/Admin/Slider/EditNewSliderModal";
import SlidersBlock from "../../../components/Admin/Slider/SlidersBlock";
import { Header } from "../../../components/Header";
import { SEO } from "../../../components/SEO";
import { Wrapper } from "../../../components/Wrapper";
import SlidersActions from "../../../components/Admin/Slider/SlidersActions";
import { ActionModal } from "../../../components/ActionModal";
import { Footer } from "../../../components/Footer";

export function SlidersEditorPage() {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [selectedSlider, setSelectedSlider] = useState<Slider | undefined>(
    undefined
  );

  const {
    data: sliders,
    error: slidersError,
    refetch,
  } = useNewFetch(getSlidersList);

  if (!sliders || slidersError) {
    pageLoading(slidersError);
  }

  const onCreateSlider = async (values: Slider) => {
    await createSlider(values);
    refetch();

    setModal(false);
    setSelectedSlider(undefined);
    customToast.success(t("status.success"));
  };

  const onUpdateSlider = async (values: Slider) => {
    if (selectedSlider?.id) {
      await updateSlider({
        slider: values,
        id: selectedSlider?.id,
      });
      refetch();
    }

    setModal(false);
    setSelectedSlider(undefined);
    customToast.success(t("status.success"));
  };

  const onDeleteSlider = async () => {
    if (selectedSlider?.id) {
      await deleteSlider({
        id: selectedSlider?.id,
      });
      refetch();
    }

    setDeleteModal(false);
    setSelectedSlider(undefined);
    customToast.success(t("status.success"));
  };

  return (
    <Wrapper>
      <SEO>
        <title>{t("slider.slides")}</title>
      </SEO>
      <Header />
      <div
        css={css`
          display: flex;
          background-color: ${theme.colors.white};
          padding: 40px 60px;
          flex-direction: column;
          border-radius: 20px;
        `}
      >
        <SlidersActions onSetModal={(modal: boolean) => setModal(modal)} />
        {sliders ? (
          <SlidersBlock
            items={sliders}
            onSetModal={(modal: boolean) => setModal(modal)}
            onSetDeleteModal={(modal: boolean) => setDeleteModal(modal)}
            onSelectSlider={(slider: Slider) => setSelectedSlider(slider)}
            refetch={refetch}
          />
        ) : (
          <div>
            <div
              css={css`
                font-size: 30px;
                line-height: 42px;
              `}
            >
              {t("slider.no_items_label")}
            </div>
            <div
              css={css`
                font-size: 20px;
                line-height: 30px;
              `}
            >
              {t("slider.no_items_text")}
            </div>
          </div>
        )}
      </div>
      {modal && (
        <EditNewSliderModal
          slider={selectedSlider ? selectedSlider : undefined}
          isActive={modal}
          onClose={() => {
            setModal(false);
            setSelectedSlider(undefined);
          }}
          onSubmit={selectedSlider ? onUpdateSlider : onCreateSlider}
        />
      )}
      {deleteModal && selectedSlider && (
        <ActionModal
          deleteModal={deleteModal}
          setActionModal={(modal: boolean) => setDeleteModal(modal)}
          contentBody={t("slider.delete")}
          actionButtonText={t("actions.delete")}
          onConfirm={onDeleteSlider}
        />
      )}
      <Footer />
    </Wrapper>
  );
}
