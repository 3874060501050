import React, { useRef, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Download } from "react-feather";
import Player from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { downloadResource } from "../helpers/download";
import { Dropdown, DropdownItem } from "./Dropdown";
import { TextBlurred } from "./TextBlurred";
import { theme } from "../themes/variables";

function PlaybackRate(props: {
  rate: number;
  onChange: (value: number) => void;
}) {
  const { t } = useTranslation();

  const values = [0.5, 0.75, 1, 1.5, 2];

  return (
    <Dropdown
      options={values.map((el, key) => {
        return (
          <DropdownItem key={key} onClick={() => props.onChange(el)}>
            {el}x
          </DropdownItem>
        );
      })}
    >
      <div
        css={css`
          display: inline-block;
          padding: 5px 10px;
          border: 1px solid ${theme.colors.gray4};
          border-radius: 6px;
          font-size: 15px;
          height: 30px;
          cursor: pointer;
          :hover {
            background: ${theme.colors.gray4};
          }
        `}
        title={t("media.rate")}
      >
        {props.rate}x
      </div>
    </Dropdown>
  );
}

function DownloadButton(props: { url: string; className?: string }) {
  const { t } = useTranslation();

  return (
    <button
      onClick={() => downloadResource(props.url)}
      type="button"
      title={t("actions.download")}
      css={css`
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid ${theme.colors.gray4};
        border-radius: 6px;
        height: 30px;
        line-height: 1;
        background-color: ${theme.colors.white};
        cursor: pointer;
        :hover {
          background-color: ${theme.colors.gray4};
        }
      `}
      className={props.className}
    >
      <Download size={15} />
    </button>
  );
}

export function AudioPlayer(props: { src?: string; title?: string }) {
  const player = useRef<Player | null>(null);
  const [rate, setRate] = useState(1);

  const onChange = (value: number) => {
    if (player.current && player.current.audio.current) {
      setRate(value);
      player.current.audio.current.playbackRate = value;
    }
  };

  const customAdditionalControls = [
    <PlaybackRate onChange={onChange} rate={rate}></PlaybackRate>,
  ];

  if (props.src) {
    customAdditionalControls.push(
      <DownloadButton
        css={css`
          margin-left: 10px;
        `}
        url={props.src}
      />
    );
  }

  return (
    <Player
      src={props.src}
      layout="stacked-reverse"
      customAdditionalControls={customAdditionalControls}
      loop={false}
      autoPlayAfterSrcChange={false}
      ref={player}
      header={
        props.title && (
          <TextBlurred
            css={css`
              font-size: 13px;
            `}
            title={props.title}
          >
            {props.title}
          </TextBlurred>
        )
      }
      css={css`
        border-radius: 10px;
        box-shadow: none;
        .rhap_main-controls-button {
          color: ${theme.colors.primary};
        }
        .rhap_volume-button {
          color: ${theme.colors.primary};
        }
        .rhap_progress-indicator {
          background: ${theme.colors.primary};
        }
        .rhap_progress-filled {
          background-color: ${theme.colors.primary};
        }
        .rhap_volume-indicator {
          background-color: ${theme.colors.primary};
        }
        .rhap_controls-section {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
      `}
    />
  );
}
