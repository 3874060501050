import { authorised } from "../../request";
import { Test } from "../../types/Test";

type Args = {
  lessonId: string;
  query?: string;
};  

export async function getTests({lessonId, query}: Args) {
  const API = authorised();

  return API.request<Test[]>({
    method: "GET",
    url: `/api/studio/lessons/${lessonId}/tests/?${query ? `query=${query}` : ""}`,
  });
}
