import { authorised } from "../../request";
import { Lesson, LessonBlock } from "../../types/Lesson";

type Args = {
  lessonId: string;
  data: {
    items: LessonBlock[];
  };
};

export async function updateBlockOrder({ lessonId, data }: Args) {
  const API = authorised();

  return API.request<Lesson>({
    method: "PUT",
    url: `/api/studio/lesson/${lessonId}/blocks/reorder/`,
    data,
  });
}
