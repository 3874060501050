import { authorised } from "../../../request";
import { UserSegment } from "../../../types/admin/UserSegment";
import { buildQueryString } from "../../../helpers/buildQueryString";

type Args = {
  limit?: number;
  offset?: number;
  searchText?: string;
};

export async function getUserSegments(args: Args) {
  const API = authorised();

  return await API.request<{
    count: number;
    next: null | string;
    previous: null | string;
    results: UserSegment[];
  }>({
    method: "GET",
    url: `/api/enterprise/user-segment/?${buildQueryString(args)}`,
  });
}
