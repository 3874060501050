import { authorised } from "../../request";
import { Block } from "../../types/Block";

type Args = {
  lessonId: string;
  creationIndex?: number | null;
  data: Partial<Pick<Block, "data" | "type">>;
}

export async function createBlock({lessonId, creationIndex, data}: Args) {
  const API = authorised();

  return API.request<Block>({
    method: "POST",
    url: `/api/studio/lesson/${lessonId}/blocks/`,
    data: {
      ...data,
      creation_index: creationIndex
    }
  });
}
