import { Course, CoursePublishRequestStatus } from "../types/Course";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";

export function getCourseStatusColor(
  status: CoursePublishRequestStatus | null
) {
  return status === CoursePublishRequestStatus.PENDING
    ? "#E9D2B0"
    : status === CoursePublishRequestStatus.APPROVED
    ? "#CDE38F"
    : status === CoursePublishRequestStatus.REJECTED
    ? "#E5BCC6"
    : "";
}

export function CoursePublishStatus(props: { course: Course }) {
  const { t } = useTranslation();

  const status = props.course.course_publish_request
    ? props.course.course_publish_request.status
    : null;

  const statusLabel =
    status === CoursePublishRequestStatus.PENDING
      ? t("course.status.in_moderation")
      : status === CoursePublishRequestStatus.APPROVED
      ? t("course.status.published")
      : status === CoursePublishRequestStatus.REJECTED
      ? t("course.status.not_published")
      : "";
  const statusColor = getCourseStatusColor(status);

  if (!statusLabel) return null;

  return (
    <div
      css={css`
        position: absolute;
        width: 10px;
        height: 10px;
        top: 0;
        right: -20px;
        background: ${statusColor};
        border-radius: 15px;

        &:hover .status-label {
          opacity: 1;
          visibility: visible;
        }
      `}
    >
      <div
        className={"status-label"}
        css={css`
          position: absolute;
          padding: 5px 10px;
          margin-left: 20px;
          font-size: 12px;
          left: 50%;
          top: 20px;
          margin-left: -50px;
          width: 100px;
          background: ${statusColor};
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          text-align: center;
        `}
      >
        {statusLabel}
      </div>
    </div>
  );
}
