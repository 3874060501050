import { authorised } from "../../request";
import { Course } from "../../types/Course";

export async function unpublishCourse(courseId: string) {
  const API = authorised();

  return API.request<Course>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/unpublish/`,
  });
}
