import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { customToast } from "../../components/customToast";
import {
  EditCreateCourse,
  FormValues,
} from "../../components/EditCreateCourse";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { createCourse } from "../../actions/course/createCourse";
import { Headers } from "../../helpers/layout";
import { Footer } from "../../components/Footer";

export function NewCoursePage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    const response = await createCourse(data);

    customToast.success(t("status.success"));

    history.push(`/courses/${response.data.uuid}/lessons/new`);
  };

  const tabsData = [
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/new`,
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>New Course</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>New Course</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          <EditCreateCourse onSubmitCallback={onSubmit} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
