import { authorised } from "../../request";
import { Test } from "../../types/Test";

type Args = {
  lessonId: string;
  data: Test;
};  

export async function createTest({lessonId, data}: Args) {
  const API = authorised();

  return API.request<Test>({
    method: "POST",
    url: `/api/studio/lessons/${lessonId}/tests/`,
    data
  });
}
