import React, { useEffect, useState } from "react";
import { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { ChevronUp, ChevronDown, Trash2, Plus } from "react-feather";
import { TinyEditor } from "../../../DraftEditor/tinymce";
import { Column, CustomColumnsBlock } from "../../../../types/Block";
import { theme } from "../../../../themes/variables";
import { Row, Headers, Spacing } from "../../../../helpers/layout";
import { Button } from "../../../Button";
import { LongDashedSeparator } from "../../../Separator";
import { ModalDelete } from "../../../../modals/ModalDelete";

import { LayoutItem } from "../components/LayoutItem";
import { ModalEdit } from "../components/ModalEdit";
import { ImageHandler } from "../components/ImageHandler";
import { IconButton } from "../components/IconButton";
import { BlockControls } from "../components/BlockControls";

const layoutConfigs = [
  [["100%"]],
  [
    ["50%", "50%"],
    ["33%", "66%"],
    ["66%", "33%"],
    ["75%", "25%"],
    ["25%", "75%"],
  ],
  [
    ["33%", "33%", "33%"],
    ["25%", "25%", "50%"],
    ["25%", "50%", "25%"],
    ["50%", "25%", "25%"],
  ],
  [["25%", "25%", "25%", "25%"]],
];

export function BlockColumns(props: {
  block: CustomColumnsBlock;
  label: string;
  icon: React.ReactNode;
  index: number;
  editModal?: boolean;
  onEditModalClose: () => void;
  onDelete: () => void;
  onUpdate: (block: CustomColumnsBlock) => Promise<void>;
  reorder: (startIndex: number, endIndex: number) => void;
}) {
  const { t } = useTranslation();

  const [editModal, setEditModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [columns, setColumns] = useState<Column[]>(
    props.block.data.columns || [{ content: "" }]
  );

  const [layout, setLayout] = useState<string[] | null>(
    props.block.data.layout ? props.block.data.layout.split(" ") : []
  );

  useEffect(() => {
    if (props.editModal) setEditModal(true);
  }, [props.editModal]);

  const onClose = () => {
    props.onEditModalClose();
    setEditModal(false);
  };

  const onCancel = () => {
    setDeleteModal(false);
    setColumns(props.block.data.columns || []);
    setLayout(
      props.block.data.layout ? props.block.data.layout.split(" ") : []
    );
  };

  const onContentChange = (index: number, value: string) => {
    setColumns((prev) => {
      return prev.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            content: value,
          };
        }

        return el;
      });
    });
  };

  function addColumn(index?: number) {
    if (columns.length < 4) {
      setColumns((prev) => {
        setLayout(layoutConfigs[prev.length][0]);
        if (typeof index === "number") {
          const firstPart = prev.slice(0, index + 1);
          const lastPart = prev.slice(index + 1, prev.length);

          return [...firstPart, { content: "" }, ...lastPart];
        } else {
          return prev.concat([
            {
              content: "",
            },
          ]);
        }
      });
    }
  }

  function removeColumn(index: number) {
    setColumns((prev) => {
      if (prev.length > 1) {
        setLayout(layoutConfigs[prev.length - 2][0]);
      } else {
        setLayout(null);
      }

      return prev.filter((_, idx) => idx !== index);
    });
  }

  function removeImage(index: number) {
    setColumns((prev) => {
      return prev.map((el, idx) => {
        if (idx === index) {
          return {
            ...el,
            image: null,
          };
        }

        return el;
      });
    });
  }

  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    if (
      startIndex >= 0 &&
      startIndex <= list.length - 1 &&
      endIndex >= 0 &&
      endIndex <= list.length - 1
    ) {
      const result = Array.from(list);

      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    }

    return list;
  }

  function move(startIndex: number, endIndex: number) {
    setColumns((prev) => reorder(prev, startIndex, endIndex));
  }

  function onDelete() {
    setDeleteModal(false);
    props.onDelete();
  }

  const block = props.block;
  const blockLayout = block.data.layout ? block.data.layout.split(" ") : [];

  return (
    <>
      <div
        css={css`
          padding: 32px;
          background: ${theme.colors.white};
          border-radius: 20px;
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Row justify={"space-between"} align={"center"}>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  display: flex;
                  margin-right: 10px;
                `}
              >
                {props.icon}
              </div>

              <span>{props.label}</span>
            </div>

            <BlockControls
              reorder={props.reorder}
              index={props.index}
              setDeleteModal={setDeleteModal}
              setEditModal={setEditModal}
            />
          </Row>
        </div>

        {block.data && block.data.columns && (
          <div
            css={css`
              background-image: url("${block.background_image?.image}");
              background-size: ${block.background_size};
              background-color: ${block.background_color};
              background-position: ${block.background_position};
              background-repeat: no-repeat;
              display: flex;
              padding: ${Spacing.xxl};
              border-radius: 20px;
            `}
          >
            {block.data.columns.map((el, key) => {
              return (
                <div
                  key={key}
                  css={css`
                    width: ${blockLayout[key]};
                    padding: 0 ${Spacing.l};
                  `}
                >
                  {el.image && (
                    <img
                      css={css`
                        width: auto;
                        max-width: 100%;
                        padding-bottom: ${Spacing.l};
                      `}
                      src={el.image.image}
                      alt={el.image.upload_name}
                    />
                  )}

                  <div
                    css={css`
                      p {
                        margin: 0;
                      }
                    `}
                    dangerouslySetInnerHTML={{
                      __html: el.content || "",
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <ModalEdit
        modal={editModal}
        onClose={onClose}
        block={block}
        onCancel={onCancel}
        header={
          <div>
            <span
              css={css`
                color: ${theme.colors.primary};
                margin-right: 12px;
              `}
            >
              Aa
            </span>
            <Headers.H3
              css={css`
                display: inline;
              `}
            >
              {t("lesson.block.text-block")}
            </Headers.H3>
          </div>
        }
        onConfirm={async (value) => {
          const updatedData: CustomColumnsBlock = {
            ...value,
            data: {
              columns,
              layout: layout ? layout.join(" ") : "",
            },
          };

          await props.onUpdate(updatedData);
        }}
      >
        <div>
          <div>
            {columns.map((column, index) => {
              return (
                <div key={index}>
                  <div
                    css={css`
                      display: flex;
                      margin: 40px 0;
                    `}
                  >
                    <Row
                      align="center"
                      css={css`
                        flex-direction: column;
                        margin-right: 40px;
                      `}
                    >
                      <div
                        css={css`
                          font-weight: bold;
                          margin-bottom: 12px;
                        `}
                      >
                        {t("lesson.block.section")} {index + 1}
                      </div>

                      <ImageHandler
                        removeImage={() => removeImage(index)}
                        image={column.image}
                        onImageChange={async (img) => {
                          setColumns((prev) => {
                            return prev.map((el, idx) => {
                              if (idx === index) {
                                return {
                                  ...el,
                                  image: img,
                                };
                              }
                              return el;
                            });
                          });
                        }}
                      />
                    </Row>

                    <TinyEditor
                      css={css`
                        margin-top: 10px;
                        width: 100%;
                      `}
                      value={column.content || ""}
                      onChange={(value) => onContentChange(index, value)}
                    />

                    <Row
                      css={css`
                        margin-left: 40px;
                      `}
                    >
                      <IconButton
                        onClick={() => move(index, index - 1)}
                        title={t("actions.move-up")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <ChevronUp size={25} />
                      </IconButton>

                      <IconButton
                        onClick={() => move(index, index + 1)}
                        title={t("actions.move-down")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <ChevronDown size={25} />
                      </IconButton>

                      <IconButton
                        onClick={() => removeColumn(index)}
                        title={t("actions.delete-item")}
                        css={css`
                          width: 35px;
                          height: 35px;
                        `}
                      >
                        <Trash2 color={theme.colors.redMain} size={22} />
                      </IconButton>
                    </Row>
                  </div>

                  {index < columns.length - 1 && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                      `}
                    >
                      <LongDashedSeparator color={"E2E2E2"} />

                      <Button
                        background={theme.colors.transparent}
                        border="1px solid black"
                        color={theme.colors.dark}
                        hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
                        disabled={columns.length === 4}
                        icon={<Plus color={theme.colors.primary} size={20} />}
                        onClick={() => addColumn(index)}
                        css={css`
                          margin: 0 20px;
                        `}
                        small
                      >
                        {t("lesson.block.add-section")}
                      </Button>

                      <LongDashedSeparator color={"E2E2E2"} />
                    </div>
                  )}
                </div>
              );
            })}

            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <LongDashedSeparator color={"E2E2E2"} />

              <Button
                background={theme.colors.transparent}
                border="1px solid black"
                color={theme.colors.dark}
                hoverStyles={`border: 1px solid ${theme.colors.primary}; color: ${theme.colors.white}; background: ${theme.colors.primary}; svg { stroke: ${theme.colors.white};}`}
                disabled={columns.length === 4}
                icon={<Plus color={theme.colors.primary} size={20} />}
                onClick={() => addColumn()}
                css={css`
                  margin: 0 20px;
                `}
                small
              >
                {t("lesson.block.add-section")}
              </Button>
              <LongDashedSeparator color={"E2E2E2"} />
            </div>
          </div>

          {!!columns.length && (
            <div>
              <Headers.H4
                css={css`
                  margin-bottom: 16px;
                  font-size: 16px;
                  font-weight: bold;
                `}
              >
                {t("lesson.block.layout")}:
              </Headers.H4>
              <Row>
                {columns.length === 1 && (
                  <Row wrap="wrap">
                    {layoutConfigs[0].map((el, idx) => (
                      <LayoutItem
                        key={idx}
                        items={el}
                        chosen={
                          !!layout &&
                          el.length === layout.length &&
                          el.every((value, index) => value === layout[index])
                        }
                        onClick={(items) => setLayout(items)}
                      />
                    ))}
                  </Row>
                )}

                {columns.length === 2 && (
                  <Row wrap="wrap">
                    {layoutConfigs[1].map((el, idx) => (
                      <LayoutItem
                        key={idx}
                        items={el}
                        chosen={
                          !!layout &&
                          el.length === layout.length &&
                          el.every((value, index) => value === layout[index])
                        }
                        onClick={(items) => setLayout(items)}
                      />
                    ))}
                  </Row>
                )}

                {columns.length === 3 && (
                  <Row wrap="wrap">
                    {layoutConfigs[2].map((el, idx) => (
                      <LayoutItem
                        key={idx}
                        items={el}
                        chosen={
                          !!layout &&
                          el.length === layout.length &&
                          el.every((value, index) => value === layout[index])
                        }
                        onClick={(items) => setLayout(items)}
                      />
                    ))}
                  </Row>
                )}

                {columns.length === 4 && (
                  <Row wrap="wrap">
                    {layoutConfigs[3].map((el, idx) => (
                      <LayoutItem
                        key={idx}
                        items={el}
                        chosen={
                          !!layout &&
                          el.length === layout.length &&
                          el.every((value, index) => value === layout[index])
                        }
                        onClick={(items) => setLayout(items)}
                      />
                    ))}
                  </Row>
                )}
              </Row>
            </div>
          )}
        </div>
      </ModalEdit>

      <ModalDelete
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        onDelete={onDelete}
      />
    </>
  );
}
