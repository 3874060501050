import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik, FormikHelpers } from "formik";
import { css } from "styled-components/macro";
import { customToast } from "../../../components/customToast";
import { theme } from "../../../themes/variables";
import { Announcement } from "../../../types/admin/Announcement";
import { Column } from "../../../helpers/layout";
import { serverErrorHandler } from "../../../helpers/serverErrorHandler";
import { InputField } from "../../fields/InputField";
import DateField from "../../fields/DateField";
import { Button } from "../../../components/Button";
import { ReactComponent as ReadSvg } from "../../../assets/icons/Read.svg";
import { EntitySelectionField } from "../../fields/EntitySelectionField";
import { RequiredDataType } from "../../../types/RequiredDataType";
import { UserSegment } from "../../../types/admin/UserSegment";
import { getUserSegments } from "../../../actions/admin/userSegment";

const entityUserSegmentSearchCallback = async (
  searchText: string
): Promise<RequiredDataType[]> => {
  const response = await getUserSegments({ searchText, limit: 100 });

  return response.data.results.map((item) => {
    return {
      id: String(item.id),
      label: item.name,
    };
  });
};

const userSegmentsHandler = (fieldValue: UserSegment[]): RequiredDataType[] => {
  return fieldValue.map((item) => {
    return {
      id: String(item.id),
      label: item.name,
    };
  });
};

export interface FormValues extends Omit<Announcement, "user_segments"> {
  user_segments: RequiredDataType[];
}

export default function EditCreateAnnouncement({
  initialValues,
  onSubmit,
  onClose,
}: {
  initialValues?: Announcement;
  onSubmit: (values: FormValues) => Promise<void>;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const onSubmitValues = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        title: initialValues?.title || "",
        publish_date: initialValues?.publish_date || "",
        url: initialValues?.url || "",
        short_description: initialValues?.short_description || "",
        user_segments: userSegmentsHandler(initialValues?.user_segments || []),
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(),
        publish_date: Yup.date().required(),
        url: Yup.string().url(),
        short_description: Yup.string().required(),
      })}
      onSubmit={onSubmitValues}
    >
      {({ isSubmitting, dirty, values, setFieldValue }) => (
        <Form noValidate>
          <div
            css={css`
              display: flex;
              border-radius: 20px;
              background-color: ${theme.colors.white};
              width: 100%;
              padding: 40px;
              margin-bottom: 40px;
            `}
          >
            <Column
              css={css`
                display: flex;
                width: 100%;
              `}
            >
              <InputField
                label={t("announcements.title") + ":"}
                name={"title"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <DateField
                label={t("announcements.publish_date") + ":"}
                name={"publish_date"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 12px;
                `}
              />
              <InputField
                label={t("announcements.url") + ":"}
                name={"url"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <InputField
                label={t("announcements.short") + ":"}
                name={"short_description"}
                errorBottom
                hideTooltip
                css={css`
                  margin-bottom: 32px;
                `}
              />
              <EntitySelectionField
                id="user_segments"
                name={`user_segments`}
                label={`${t("announcements.fields.user_segments")}:`}
                description={
                  !values.user_segments.length
                    ? t("announcements.fields.user_segments_description")
                    : ""
                }
                searchCallback={entityUserSegmentSearchCallback}
              />
            </Column>
          </div>
          <div
            css={css`
              display: flex;
              width: 100%;
              justify-content: end;
            `}
          >
            <Button
              css={css`
                margin-right: 20px;
                background-color: ${theme.colors.gray1};
                color: ${theme.colors.black};
              `}
              onClick={onClose}
            >
              {t("actions.cancel")}
            </Button>
            <Button
              isSubmitting={isSubmitting}
              type="submit"
              disabled={!dirty}
              iconRight
              icon={<ReadSvg width={10} height={10} />}
            >
              {initialValues
                ? t("announcements.save")
                : t("announcements.publish")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
