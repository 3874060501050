import { authorised } from "../../request";
import { CheatSheet } from "../../types/CheatSheet";

type Args = {
  courseId: string;
  data: Pick<CheatSheet, "name" | "text_content" | "icon" | "lesson">;
};

export async function createCheatSheet({ courseId, data }: Args) {
  const API = authorised();

  return API.request<CheatSheet>({
    method: "POST",
    url: `/api/studio/courses/${courseId}/cheat-sheet/`,
    data,
  });
}
