import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { EditCreateFaq, FormValues } from "../../components/EditCreateFaq";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { SEO } from "../../components/SEO";
import { Headers } from "../../helpers/layout";
import { RouteComponentProps } from "react-router";

import { createEnterpriseFaqItem } from "../../actions/faq";
import { Tab, Tabs } from "../../components/Tabs";
import { Footer } from "../../components/Footer";

export function AdminNewFaqPage(props: RouteComponentProps) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    await createEnterpriseFaqItem(data);

    history.push(`/admin/faq`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
      forceHightlight: true,
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("faq.create-title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{t("faq.create-title")}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateFaq onSubmitCallback={onSubmit} />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
