import React from "react";
import { css } from "styled-components/macro";
import { customToast } from "../../components/customToast";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Column, Spacing } from "../../helpers/layout";
import { InputField } from "../fields/InputField";
import { Button } from "../../components/Button";
import { UserProfile } from "../../types/UserProfile";

import { serverErrorHandler } from "../../helpers/serverErrorHandler";

export interface FormValues {
  password: string;
  new_password: string;
  confirm_new_password: string;
}

export function ChangePasswordForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
  data: UserProfile;
}) {
  const { t } = useTranslation();

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await props.onSubmit(values);
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Formik<FormValues>
        enableReinitialize
        initialValues={{
          password: "",
          new_password: "",
          confirm_new_password: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().required(),
          new_password: Yup.string().min(8).required(),
          confirm_new_password: Yup.string()
            .oneOf([Yup.ref("new_password"), null], t("auth.pwd-not-match"))
            .required(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form noValidate>
            <Column
              gutter={Spacing.xl}
              css={css`
                display: flex;
                width: 50%;
              `}
            >
              <InputField
                name={"password"}
                type="password"
                label={`${t("account.current-password")}`}
              />

              <InputField
                name={"new_password"}
                type="password"
                label={`${t("account.new-password")}`}
              />

              <InputField
                name={"confirm_new_password"}
                type="password"
                label={`${t("account.repeat-password")}`}
              />

              <div>
                <Button
                  isSubmitting={isSubmitting}
                  type="submit"
                  disabled={!dirty || !isValid}
                >
                  {t("account.change-password")}
                </Button>
              </div>
            </Column>
          </Form>
        )}
      </Formik>
    </div>
  );
}
