import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import { Tab, Tabs } from "../../../components/Tabs";
import {
  EditCreateSkill,
  FormValues,
} from "../../../components/Admin/Skill/EditCreateSkill";
import { createSkill } from "../../../actions/admin/skill";
import { Footer } from "../../../components/Footer";

export function SkillCreatePage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    await createSkill({ data });

    history.push(`/admin/skills`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.competency.title"),
      link: `/admin/competencies`,
    },
    {
      title: t("admin.skill.title"),
      link: "/admin/skills",
      forceHightlight: true,
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.skill.new-skill")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreateSkill
            onSubmitCallback={onSubmit}
            title={t("admin.skill.new-skill")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
