import React from "react";
import { css } from "styled-components/macro";

export function Wrapper(props: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  return (
    <div
      css={css`
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
