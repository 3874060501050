import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { Order } from "../../../types/admin/Order";

export default function OrderDetailTable({ order }: { order: Order }) {
  const { t } = useTranslation();

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-direction: column;
        border: 1px solid ${theme.colors.gray2};
        border-radius: 20px;
        margin-left: 40px;
        font-size: 18px;
        line-height: 22px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          border-radius: 20px 20px 0 0;
          border-bottom: 1px solid ${theme.colors.gray2};
          height: 65px;
          background-color: ${theme.colors.gray1};
          line-height: 18px;
          font-weight: 400;
          white-space: nowrap;
        `}
      >
        <div
          css={css`
            padding: 24px;
          `}
        >
          {t("orders.purchased")}
        </div>
        <div
          css={css`
            display: flex;
            margin: auto 0 auto auto;
          `}
        >
          <div
            css={css`
              width: 130px;
              padding: 24px;
              white-space: nowrap;
              border-right: 1px solid ${theme.colors.gray2};
              border-left: 1px solid ${theme.colors.gray2};
            `}
          >
            {t("orders.net_price")}
          </div>
          <div
            css={css`
              width: 130px;

              padding: 24px;
              border-right: 1px solid ${theme.colors.gray2};
            `}
          >
            {t("orders.vat_price")}
          </div>
          <div
            css={css`
              width: 130px;

              padding: 24px;
            `}
          >
            {t("orders.total")}
          </div>
        </div>
      </div>
      {order.items &&
        order.items.map((item, id) => (
          <div
            key={id}
            css={css`
              display: flex;
              flex-direction: row;
              height: 65px;
              border-bottom: 1px solid ${theme.colors.gray2};
              white-space: nowrap;
            `}
          >
            <div
              css={css`
                padding: 20px 24px;
              `}
            >
              {item.name}
            </div>
            <div
              css={css`
                display: flex;
                margin: auto 0 auto auto;
              `}
            >
              <div
                css={css`
                  width: 130px;
                  padding: 20px 24px;
                  border-right: 1px solid ${theme.colors.gray2};
                  border-left: 1px solid ${theme.colors.gray2};
                `}
              >
                {item.net_price + " EUR"}
              </div>
              <div
                css={css`
                  width: 130px;
                  padding: 20px 24px;
                  border-right: 1px solid ${theme.colors.gray2};
                `}
              >
                {item.vat_price + " EUR"}
              </div>
              <div
                css={css`
                  width: 130px;
                  padding: 20px 24px;
                `}
              >
                {item.total + " EUR"}
              </div>
            </div>
          </div>
        ))}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: ${theme.colors.gray1};
          min-height: 145px;
          height: 100%;
          border-radius: 0 0 20px 20px;
          white-space: nowrap;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: row;
            margin: auto 0 0 0;
          `}
        >
          <div
            css={css`
              width: 100%;
              padding: 24px 20px 16px;
              text-align: right;
            `}
          >
            {t("orders.total") + ":"}
          </div>
          <div
            css={css`
              width: 145px;
              padding: 24px 20px 16px;
            `}
          >
            {order.net_price + " EUR"}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={css`
              width: 100%;
              padding: 0 20px 16px;
              text-align: right;
            `}
          >
            {t("orders.total_VAT") + ":"}
          </div>
          <div
            css={css`
              width: 145px;
              padding: 0 20px 16px;
            `}
          >
            {order.vat_price + " EUR"}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={css`
              width: 100%;
              padding: 0 20px 25px;
              text-align: right;
            `}
          >
            {t("orders.grand_total") + ":"}
          </div>
          <div
            css={css`
              width: 145px;
              padding: 0 20px 25px;
            `}
          >
            {order.total + " EUR"}
          </div>
        </div>
      </div>
    </div>
  );
}
