import { authorised } from "../../request";
import { TestPerformance } from "../../types/Test";
import { PerformancePeriod } from "../performance/getCoursePerformanceChart";

type Args = {
  lessonId: string;
  testId: string;
  period: PerformancePeriod;
};

export async function getTestPerformance({ lessonId, testId, period }: Args) {
  const API = authorised();

  return API.request<TestPerformance>({
    method: "GET",
    url: `/api/studio/lessons/${lessonId}/tests/${testId}/performance/?period=${period}`,
  });
}
