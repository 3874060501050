import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { UserSegment } from "../../../types/admin/UserSegment";
import { getBrandedColorByIndex, hexToRgb } from "../../../helpers/color";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";
import { Button } from "../../Button";
import { Card } from "../../Card";

import { ReactComponent as UserIcon } from "../../../assets/icons/User.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";

export function UserSegmentCard(props: {
  index: number;
  segment: UserSegment;
  onDelete: (segment: UserSegment) => void;
}) {
  const { t } = useTranslation();

  const [isShownDelete, setIsShownDelete] = useState(false);

  const { r, g, b } = hexToRgb(getBrandedColorByIndex(props.index));

  const rgbaColor = `rgba(${r}, ${g}, ${b}, 0.4)`;

  return (
    <div
      onMouseEnter={() => setIsShownDelete(true)}
      onMouseLeave={() => setIsShownDelete(false)}
    >
      <Card
        color={rgbaColor}
        padding={24}
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <Headers.H4>{props.segment.name}</Headers.H4>

          {isShownDelete && (
            <button
              css={css`
                padding: 0;
                margin: 0;
                outline: none;
                border: none;
                background: none;
                cursor: pointer;
                :hover svg {
                  color: ${theme.colors.error};
                }
              `}
              onClick={() => props.onDelete(props.segment)}
            >
              <DeleteIcon width={20} height={20} />
            </button>
          )}
        </div>

        {props.segment.description && (
          <div
            css={css`
              margin-top: 12px;
              font-size: 16px;
            `}
          >
            {props.segment.description}
          </div>
        )}

        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <UserIcon width={20} height={20} />

            <div>
              {props.segment.users_count} {t("breadcrumbs.users")}
            </div>
          </div>

          <Link to={`/admin/user-segment/${props.segment.id}`}>
            <Button
              icon={
                <EditIcon width={14} height={14} color={theme.colors.white} />
              }
              iconRight
            >
              {t("actions.edit")}
            </Button>
          </Link>
        </div>
      </Card>
    </div>
  );
}
