import { authorised } from "../../request";
import { CoursePerformanceTableItem } from "../../types/Performance";
import { buildQueryString } from "../../helpers/buildQueryString";

export enum PerformancePeriod {
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
}

type Args = {
  limit?: number;
  offset?: number;
  period: PerformancePeriod;
  journey_id?: string;
  segment_id?: string;
  courses_id?: string;
  active?: boolean;
};

export async function getCoursePerformanceList(args: Args) {
  const API = authorised();

  return API.request<{
    count: number;
    next: string | null;
    previous: string | null;
    results: CoursePerformanceTableItem[];
  }>({
    method: "GET",
    url: `/api/studio/courses/performance/list/?${buildQueryString(args)}`,
  });
}
