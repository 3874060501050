import { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../components/Modal";
import { SelectComponent } from "../components/SelectComponent";
import {
  downloadUsersCsv,
  UserStatus,
} from "../actions/admin/user/downloadUserCsv";
import { Button } from "../components/Button";
import { downloadBlob } from "../helpers/download";
import { theme } from "../themes/variables";
import { Headers } from "../helpers/layout";

export function UsersCSVModal(props: {
  onClose: () => void;
  isVisible: boolean;
  type: "CAMPUS" | "STUDIO";
}) {
  const { t } = useTranslation();

  const statusOptions = [
    { value: "all", label: t("users-status.all") },
    { value: "activated", label: t("users-status.activated") },
    { value: "deactivated", label: t("users-status.deactivated") },
    { value: "pending", label: t("users-status.pending") },
  ];
  const [status, setStatus] = useState<UserStatus>("all");
  const [isDownload, setIsDownload] = useState(false);

  const download = async () => {
    setIsDownload(true);
    const { data: content } = await downloadUsersCsv({
      status,
      type: props.type,
    });

    downloadBlob(content, "users.csv", "text/csv;encoding:utf-8");
    setIsDownload(false);
  };

  return (
    <Modal
      modalIsOpen={props.isVisible}
      onClose={props.onClose}
      contentLabel={`${t("actions.sure")}?`}
      contentStyles={{
        padding: "40px",
        minHeight: "370px",
      }}
    >
      <ModalHeader closeIcon onClose={props.onClose}>
        <Headers.H1
          css={css`
            margin-bottom: 20px;
          `}
        >
          {t("user.csv-download-title")}
        </Headers.H1>
      </ModalHeader>

      <ModalBody>
        <SelectComponent
          label={t("users-status.label")}
          value={status}
          choices={statusOptions}
          onChange={(e) => setStatus(e.target.value as UserStatus)}
        />
      </ModalBody>

      <ModalFooter
        css={css`
          position: absolute;
          bottom: 40px;
          left: 40px;
          justify-content: flex-start;
        `}
      >
        <Button
          onClick={() => download()}
          background={theme.colors.primary}
          isSubmitting={isDownload}
        >
          {t("user.csv-download")}
        </Button>

        <Button
          background={theme.colors.white}
          color={theme.colors.dark}
          hoverStyles={`background: ${theme.colors.white}; opacity: 0.85;`}
          onClick={props.onClose}
        >
          {t("actions.cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
