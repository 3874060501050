import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Wrapper } from "../../components/Wrapper";
import { Header } from "../../components/Header";
import { Block } from "../../components/Block";
import { Tabs } from "../../components/Tabs";
import { Button } from "../../components/Button";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { SEO } from "../../components/SEO";
import { Headers, Row } from "../../helpers/layout";

import { useNewFetch } from "../../useAPI";
import { getCourse } from "../../actions/course";
import { pageLoading } from "../../helpers/pageLoading";
import { getSelfAssessments } from "../../actions/courseSelfAssessments/getCourseSelfAssessments";
import { SelfAssessmentList } from "./SelfAssessmentList";
import { Footer } from "../../components/Footer";

export function CourseSelfAssessment() {
  const { t } = useTranslation();

  const params = useParams<{
    courseId: string;
  }>();

  const { data: course, error: courseError } = useNewFetch(getCourse, {
    courseId: params.courseId,
  });

  const {
    data: selfAssessments,
    error: selfAssessmentsError,
    refetch,
  } = useNewFetch(getSelfAssessments, {
    courseId: params.courseId,
  });

  if (!course || courseError) {
    return pageLoading(courseError);
  }

  if (!selfAssessments || selfAssessmentsError) {
    return pageLoading(selfAssessmentsError);
  }

  const onItemDelete = () => {
    refetch();
  };

  const tabsData = [
    {
      title: t("breadcrumbs.syllabus"),
      link: `/courses/${params.courseId}/syllabus`,
    },
    {
      title: t("breadcrumbs.general_information"),
      link: `/courses/${params.courseId}/edit`,
    },
    {
      title: t("breadcrumbs.cheat-sheet"),
      link: `/courses/${params.courseId}/cheat_sheet`,
    },
    {
      title: t("breadcrumbs.additional-materials"),
      link: `/courses/${params.courseId}/additional_materials`,
    },
    {
      title: t("breadcrumbs.self-assessments"),
      dropDown: true,
      forceHightlight: true,
      dropDownContent: [
        {
          title: t("self-assessment.questions"),
          link: `/courses/${params.courseId}/self-assessments`,
        },
        {
          title: t("self-assessment.performance"),
          link: `/courses/${params.courseId}/self-assessments/performance`,
        },
      ],
    },
  ];

  const breadcrumbsItems = [
    {
      label: t("courses.title"),
      url: "/courses",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{course.name}</title>
        </SEO>
        <Header />
        <Breadcrumbs items={breadcrumbsItems} />
        <Block>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Headers.H2>{course.name}</Headers.H2>
          </div>
        </Block>
      </Wrapper>

      <Tabs tabs={tabsData} />

      <Wrapper>
        <Block>
          {selfAssessments?.length ? (
            <div
              css={css`
                margin-top: 1rem;
              `}
            >
              <SelfAssessmentList
                title={t("self-assessment.questions")}
                courseId={params.courseId}
                selfAssessmentItems={selfAssessments}
                onItemDelete={onItemDelete}
              ></SelfAssessmentList>
            </div>
          ) : (
            <>
              <Row justify="center">
                <Headers.H3
                  css={css`
                    margin-bottom: 2rem;
                  `}
                >
                  {t("self-assessment.no_items")}
                </Headers.H3>
              </Row>
              <Row justify="center">
                <Link to={`/courses/${params.courseId}/self-assessments/new`}>
                  <Button>{t("actions.create")}</Button>
                </Link>
              </Row>
            </>
          )}
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
