import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { getPages } from "../../../actions/admin/page";
import { Page, PageStatus, PageVisibility } from "../../../types/admin/Page";
import { Button } from "../../Button";
import { TableHandler, TableHandlerColumn } from "../../TableHandler";
import { Avatar } from "../../Avatar";
import { SelectOption } from "../../SelectComponent";
import { ReactComponent as LinkIcon } from "../../../assets/icons/Link.svg";
import { ReactComponent as EyeIcon } from "../../../assets/icons/Eye.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/Lock.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";

export default function PagesTable({
  forwardRef,
  statusOptions,
  setDeleteCandidate,
  setDeleteModal,
}: {
  forwardRef: any;
  statusOptions: SelectOption[];
  setDeleteCandidate: (page: Page) => void;
  setDeleteModal: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const tableHandlerColumns: TableHandlerColumn<Page>[] = [
    {
      label: t("lesson.quiz.title"),
      render: (row) => (
        <div>
          {row.title}
          {row.permalink && (
            <a
              href={`${process.env.REACT_APP_CAMPUS_URL}/pages/${row.permalink}`}
              css={css`
                margin-left: 8px;
              `}
            >
              <LinkIcon width={14} height={14} color={theme.colors.dark} />
            </a>
          )}
        </div>
      ),
    },
    {
      label: t("admin.publish-request.page.author"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <Avatar
            css={css`
              margin-right: 8px;
            `}
            size={20}
            url={row.author.avatar?.image}
          />
          <span>
            {row.author.first_name} {row.author.last_name}
          </span>
        </div>
      ),
    },
    {
      label: t("admin.publish-request.page.status"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <div
            css={css`
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: ${row.status === PageStatus.PUBLISHED
                ? theme.colors.haki
                : theme.colors.pomade};
              margin-right: 4px;
            `}
          ></div>
          {statusOptions.find((el) => el.value === row.status)?.label}
        </div>
      ),
    },
    {
      label: t("courses.visibility"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          {row.visibility === PageVisibility.PUBLIC && (
            <>
              <EyeIcon
                width={14}
                height={14}
                color={theme.colors.dark}
                css={css`
                  margin-right: 4px;
                `}
              />
              <span>{t("courses.visibility-choices.public")}</span>
            </>
          )}
          {row.visibility === PageVisibility.PRIVATE && (
            <>
              <LockIcon
                width={14}
                height={14}
                color={theme.colors.dark}
                css={css`
                  margin-right: 4px;
                `}
              />
              <span>{t("courses.visibility-choices.private")}</span>
            </>
          )}
        </div>
      ),
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.blueBackground}`}
            color={theme.colors.blueBackground}
            icon={
              <EditIcon
                width={14}
                height={14}
                color={theme.colors.blueBackground}
              />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
            iconRight
            small
            onClick={() => history.push(`/admin/pages/${row.uuid}`)}
            css={css`
              margin-right: 16px;
            `}
          >
            {t("actions.edit")}
          </Button>

          <Button
            css={css`
              min-width: 54px;
            `}
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.error}`}
            color={theme.colors.error}
            small
            onClick={() => {
              setDeleteCandidate(row);
              setDeleteModal(true);
            }}
            icon={
              <DeleteIcon width={14} height={14} color={theme.colors.error} />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
          ></Button>
        </div>
      ),
    },
  ];

  return (
    <div
      css={css`
        margin-top: 40px;
      `}
    >
      <TableHandler
        forwardRef={forwardRef}
        tableData={{
          columns: tableHandlerColumns,
        }}
        getFunction={getPages}
        options={{
          limit: 10,
        }}
      />
    </div>
  );
}
