import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { Formik, Form, FormikHelpers } from "formik";
import { customToast } from "./customToast";
import * as Yup from "yup";
import { InputField } from "../forms/fields/InputField";
import { TextareaField } from "../forms/fields/TextareaField";
import { Button } from "./Button";
import { Faq } from "../types/Faq";
import { theme } from "../themes/variables";

import { serverErrorHandler } from "../helpers/serverErrorHandler";

export interface FormValues {
  answer: string;
  question: string;
}

type FaqItem = Pick<Faq, "answer" | "question">;

export function EditCreateFaq(props: {
  onSubmitCallback: (values: FaqItem) => Promise<void>;
  questionMaxLength?: number;
  answerMaxLength?: number;
  initialData?: FaqItem | null;
}) {
  const {
    onSubmitCallback,
    questionMaxLength = 150,
    answerMaxLength = 500,
    initialData,
  } = props;
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .label(t("faq.question"))
      .max(questionMaxLength)
      .required(),
    answer: Yup.string().label(t("faq.answer")).max(answerMaxLength).required(),
  });

  const onSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setSubmitting(true);

      await onSubmitCallback({
        answer: values.answer,
        question: values.question,
      });

      customToast.success(t("status.success"));
    } catch (error: any) {
      customToast.error(
        t("status.error", {
          error: serverErrorHandler(error),
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        answer: initialData?.answer || "",
        question: initialData?.question || "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          noValidate
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `}
        >
          <div>
            <InputField
              label={`${t("faq.question")}*:`}
              name={"question"}
              maxLength={questionMaxLength}
            />

            <TextareaField
              label={`${t("faq.answer")}*:`}
              name={"answer"}
              maxLength={answerMaxLength}
            />
          </div>

          <div
            css={css`
              margin-bottom: 25px;
              display: flex;
              align-self: flex-end;
              flex-direction: row-reverse;
            `}
          >
            <div
              css={css`
                text-align: right;
              `}
            >
              <Button
                isSubmitting={isSubmitting}
                disabled={!isValid}
                type="submit"
              >
                {t(!!initialData ? "actions.update" : "actions.create")}
              </Button>

              {!isValid && (
                <div
                  css={css`
                    display: flex;
                    flex-direction: row-reverse;
                    color: ${theme.colors.redMain};
                    margin-top: 10px;
                  `}
                >
                  {t("status.error-fill")}
                </div>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
