import React, { useRef, useEffect } from "react";

import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export function ChartLine(props: {
  config: any;
  width: number;
  height: number;
}) {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const myChartRef = chartRef.current.getContext("2d");
      if (myChartRef) {
        const chart = new Chart(myChartRef, {
          type: "line",
          data: props.config.data,
          options: props.config.options,
        });

        return () => {
          chart.destroy();
        };
      }
    }
  }, [props.config.data, props.config.options]);

  return (
    <canvas
      id="myChart"
      ref={chartRef}
      width={props.width}
      height={props.height}
    />
  );
}
