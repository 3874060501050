import React, { ChangeEvent, useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { Field, FieldProps } from "formik";
import { Check, X } from "react-feather";
import { MediaPreview } from "../../components/Video/MediaPreview";
import { InputComponent } from "../../components/InputComponent";
import { InputErrors } from "./InputErrors";
import ReactPlayer from "react-player";
import { useDebounce } from "use-debounce";

export function MediaInputFieldInternal(props: {
  name?: string;
  value: string;
  inputLabeL?: string;
  mediaLabel?: string;
  downloadUrl?: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  check?: boolean;
}) {
  const [videoUrl, setVideoUrl] = useState(props.value);
  const [debouncedValue] = useDebounce(videoUrl, 500);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const canplay = ReactPlayer.canPlay(debouncedValue);
    setIsValid(canplay);
  }, [debouncedValue]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;

    props.onChange(e);

    if (props.check) {
      const canplay = ReactPlayer.canPlay(url);

      if (canplay) {
        setVideoUrl(url);
      }
    } else {
      setVideoUrl(url);
    }
  };

  const field = props.name
    ? {
        field: {
          value: videoUrl,
          onBlur: () => {},
          name: props.name,
          onChange,
        },
      }
    : null;

  return (
    <div
      css={css`
        margin-top: 1rem;
      `}
    >
      <div>
        <InputComponent
          label={props.inputLabeL}
          type={"text"}
          icon={
            props.check ? (
              isValid ? (
                <Check size={20} color="green" />
              ) : (
                <X size={20} color="red" />
              )
            ) : null
          }
          value={props.value}
          onChange={onChange}
          {...field}
        />

        <InputErrors error={props.error} touched={props.touched} />
      </div>

      <MediaPreview
        url={videoUrl}
        label={props.mediaLabel}
        downloadUrl={props.downloadUrl}
      />
    </div>
  );
}

export function MediaInputField(props: {
  name: string;
  inputLabeL?: string;
  mediaLabel?: string;
  downloadUrl?: string;
  check?: boolean;
}) {
  const { name, inputLabeL, mediaLabel, downloadUrl, check } = props;

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        return (
          <MediaInputFieldInternal
            name={name}
            inputLabeL={inputLabeL}
            mediaLabel={mediaLabel}
            downloadUrl={downloadUrl}
            value={field.value}
            onChange={(e) => {
              const value = e.target.value;
              form.setFieldValue(name, value);
            }}
            error={meta.error}
            touched={meta.touched}
            check={check}
          />
        );
      }}
    </Field>
  );
}
