import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { X } from "react-feather";
import { theme } from "../../themes/variables";
import { Row, Headers } from "../../helpers/layout";

export function ModalHeader(props: {
  className?: string;
  children?: React.ReactNode[] | React.ReactNode | string | null;
  closeIcon?: boolean;
  onClose?: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Row
      align={"center"}
      justify={"space-between"}
      css={css`
        margin-bottom: 0;
      `}
      className={props.className}
    >
      {props.children && (
        <Headers.H2
          css={css`
            margin-bottom: 0;
          `}
        >
          {props.children}
        </Headers.H2>
      )}

      {props.closeIcon && (
        <button
          css={css`
            padding: 0;
            margin: 0;
            cursor: pointer;
            white-space: nowrap;
            border: 0;
            text-transform: none;
            -webkit-appearance: none;
            outline: none;
            background: transparent;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            :hover {
              opacity: 0.85;
            }
          `}
          onClick={props.onClose}
        >
          <span
            css={css`
              color: ${theme.colors.gray6};
            `}
          >
            {t("actions.close")}
          </span>
          <X
            size={20}
            css={css`
              margin-left: 8px;
            `}
          ></X>
        </button>
      )}
    </Row>
  );
}
