export enum NotificationType {
  PUBLISH_REQUEST_APPROVED = "PUBLISH_REQUEST_APPROVED",
  PUBLISH_REQUEST_REJECTED = "PUBLISH_REQUEST_REJECTED",
  NEW_COURSE_PUBLISH_REQUEST = "NEW_COURSE_PUBLISH_REQUEST",
}

export type Notification = {
  id: number;
  context: {
    course_id?: string;
  };
  short_title: string;
  short_version: string;
  read: boolean;
  created: string;
  type: NotificationType;
};
