import { css } from "styled-components/macro";
import { theme } from "../../../../themes/variables";

export function IconButton(props: {
  title: string;
  onClick: () => void;
  children: React.ReactNode | React.ReactNode[] | string | null;
  className?: string;
}) {
  return (
    <button
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme.colors.transparent};
        border-radius: 50%;
        padding: 5px;
        border: none;
        cursor: pointer;
        :hover {
          background: ${theme.colors.transparentBlack1};
        }
      `}
      className={props.className}
      onClick={props.onClick}
      title={props.title}
    >
      {props.children}
    </button>
  );
}
