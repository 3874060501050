import { authorised } from "../../request";
import { Video } from "../../types/Video";

export async function updateVideo(videoId: string, data: Omit<Video, "uuid">) {
  const API = authorised();

  return await API.request<Video>({
    method: "PUT",
    url: `/api/studio/videos/${videoId}/`,
    data,
  });
}
