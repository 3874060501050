import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { theme } from "../../../themes/variables";
import { Order, PAYMENT_OPTIONS } from "../../../types/admin/Order";
import { Image } from "../../../types/Image";

function RowItem({
  label,
  image,
  text,
}: {
  label: string;
  image?: Image | null;
  text: string;
}) {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        :last-child {
          margin-bottom: 0;
        }
      `}
    >
      <div
        css={css`
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 8px;
        `}
      >
        {label}
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {image && (
          <img
            src={image.image}
            alt={text}
            css={css`
              width: 20px;
              height: 20px;
              margin-right: 6px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            `}
          ></img>
        )}
        <div
          css={css`
            font-size: 16px;
          `}
        >
          {text}
        </div>
      </div>
    </div>
  );
}

export default function OrdersUserCard({ order }: { order: Order }) {
  const { t } = useTranslation();

  const getType = (type: PAYMENT_OPTIONS) => {
    return type === PAYMENT_OPTIONS.BANK_TRANSFER
      ? t("orders.bank_transfer")
      : type === PAYMENT_OPTIONS.CREDIT_CARD
      ? t("orders.credit_card")
      : type === PAYMENT_OPTIONS.PAYPAL
      ? t("orders.paypal")
      : type === PAYMENT_OPTIONS.DIRECT_DEBIT
      ? t("orders.direct_debit")
      : "";
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-width: 300px;
        height: fit-content;
        border: 1px solid ${theme.colors.gray2};
        border-radius: 20px;
        background-color: ${theme.colors.yellowBackground};
        padding: 24px;
      `}
    >
      {order.initiator && (
        <RowItem
          label={t("orders.user")}
          image={order.initiator.avatar}
          text={order.initiator.first_name + " " + order.initiator.last_name}
        />
      )}
      {order.payment_type && (
        <RowItem
          label={t("orders.payment_type")}
          text={getType(order.payment_type)}
        />
      )}
      {order.payment_date && (
        <RowItem label={t("orders.payment_date")} text={order.payment_date} />
      )}
      {order.billing_address && (
        <RowItem
          label={t("orders.billing")}
          text={
            order.billing_address.street_address +
            ", " +
            order.billing_address.postal_code +
            ", " +
            order.billing_address.city +
            ", " +
            order.billing_address.country
          }
        />
      )}
    </div>
  );
}
