import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import {
  EditCreatePage,
  FormValues,
} from "../../../components/Admin/PageManagment/EditCreatePage";
import { createPage } from "../../../actions/admin/page";
import { Tab, Tabs } from "../../../components/Tabs";
import { Footer } from "../../../components/Footer";

export function PageCreatePage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const onSubmit = async (data: FormValues) => {
    const response = await createPage({ data });

    history.push(`/admin/pages/${response.data.uuid}`);
  };

  const tabsData: Tab[] = [
    {
      title: t("admin.publish-request.page.title"),
      link: `/admin/publish-request`,
    },
    {
      title: t("admin.pages.title"),
      link: "/admin/pages",
      forceHightlight: true,
    },
    {
      title: t("header.navigation.faq"),
      link: "/admin/faq",
    },
    {
      title: t("feedback.feedback"),
      link: "/admin/feedback",
    },
  ];

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.pages.new-page")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper>
        <Block>
          <EditCreatePage
            onSubmitCallback={onSubmit}
            title={t("admin.pages.new-page")}
          />
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
