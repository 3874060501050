import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { css } from "styled-components/macro";
import { useHistory, Link } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { Header } from "../../../components/Header";
import { Block } from "../../../components/Block";
import { SEO } from "../../../components/SEO";
import {
  TableHandler,
  TableHandlerColumn,
} from "../../../components/TableHandler";
import { Button } from "../../../components/Button";
import { Tab, Tabs } from "../../../components/Tabs";
import { ModalDelete } from "../../../modals/ModalDelete";
import { Competency } from "../../../types/admin/Competency";
import { Headers } from "../../../helpers/layout";
import { theme } from "../../../themes/variables";

import {
  getCompetencies,
  deleteCompetency,
} from "../../../actions/admin/competency";

import { ReactComponent as CloseIcon } from "../../../assets/icons/Close.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/Plus.svg";
import { ReactComponent as ReadIcon } from "../../../assets/icons/Read.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/Delete.svg";
import { Footer } from "../../../components/Footer";

export function CompetencyOverviewPage(props: {}) {
  const { t } = useTranslation();

  const history = useHistory();

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteCandidate, setDeleteCandidate] = useState<Competency | null>(
    null
  );

  const ref = useRef<any>(null);

  const tabsData: Tab[] = [
    {
      title: t("admin.competency.title"),
      link: `/admin/competencies`,
    },
    {
      title: t("admin.skill.title"),
      link: "/admin/skills",
    },
  ];

  const tableHandlerColumns: TableHandlerColumn<Competency>[] = [
    {
      label: "ID",
      orderFields: ["id"],
      render: (row) => <div>{row.id}</div>,
    },
    {
      orderFields: ["name"],
      label: t("admin.competency.name"),
      render: (row) => <div>{row.name}</div>,
    },

    {
      label: t("admin.competency.description"),
      render: (row) => <div>{row.description}</div>,
    },
    {
      label: t("admin.competency.is_featured"),
      render: (row) => (
        <div>
          {row.is_featured ? (
            <ReadIcon width={18} height={18} color={theme.colors.success} />
          ) : (
            <CloseIcon width={18} height={18} color={theme.colors.error} />
          )}
        </div>
      ),
    },
    {
      label: t("admin.competency.is_homepage_featured"),
      render: (row) => (
        <div>
          {row.is_homepage_featured ? (
            <ReadIcon width={18} height={18} color={theme.colors.success} />
          ) : (
            <CloseIcon width={18} height={18} color={theme.colors.error} />
          )}
        </div>
      ),
    },
    {
      label: t("admin.users.page.actions"),
      render: (row) => (
        <div
          css={css`
            display: flex;
          `}
        >
          <Button
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.blueBackground}`}
            color={theme.colors.blueBackground}
            icon={
              <EditIcon
                width={14}
                height={14}
                color={theme.colors.blueBackground}
              />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.blueBackground}; border: 1px solid ${theme.colors.transparent};`}
            iconRight
            small
            onClick={() => history.push(`/admin/competencies/${row.id}`)}
            css={css`
              margin-right: 16px;
            `}
          >
            {t("actions.edit")}
          </Button>

          <Button
            css={css`
              min-width: 54px;
            `}
            background={theme.colors.transparent}
            border={`1px solid ${theme.colors.error}`}
            color={theme.colors.error}
            small
            onClick={() => {
              setDeleteCandidate(row);
              setDeleteModal(true);
            }}
            icon={
              <DeleteIcon width={14} height={14} color={theme.colors.error} />
            }
            hoverStyles={`color: ${theme.colors.white}; background: ${theme.colors.error}; border: 1px solid ${theme.colors.transparent};`}
          ></Button>
        </div>
      ),
    },
  ];

  const onDelete = async () => {
    if (deleteCandidate) {
      await deleteCompetency({ id: deleteCandidate.id });

      setDeleteCandidate(null);
      setDeleteModal(false);
      ref.current.refetch();
    }
  };

  return (
    <>
      <Wrapper>
        <SEO>
          <title>{t("admin.competency.title")}</title>
        </SEO>
        <Header />
        <Tabs tabs={tabsData} />
      </Wrapper>

      <Wrapper
        css={css`
          margin-bottom: 50px;
        `}
      >
        <Block>
          <div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                <Headers.H3
                  css={css`
                    margin-right: 150px;
                  `}
                >
                  {t("admin.competency.title")}
                </Headers.H3>
              </div>

              <Link to="/admin/competencies/new">
                <Button
                  css={css`
                    margin-left: 63px;
                  `}
                  background={theme.colors.dark}
                  icon={
                    <PlusIcon
                      width={14}
                      height={14}
                      color={theme.colors.white}
                    />
                  }
                  iconRight
                >
                  {t("admin.competency.new-competency")}
                </Button>
              </Link>
            </div>

            <div
              css={css`
                margin-top: 60px;
              `}
            >
              <TableHandler
                forwardRef={ref}
                tableData={{
                  columns: tableHandlerColumns,
                }}
                getFunction={getCompetencies}
                options={{
                  internalArgs: {
                    ordering: "-id",
                  },
                }}
              />
            </div>

            <ModalDelete
              isOpen={deleteModal}
              onClose={() => setDeleteModal(false)}
              onDelete={onDelete}
            />
          </div>
        </Block>
        <Footer />
      </Wrapper>
    </>
  );
}
