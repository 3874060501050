import { authorised } from "../../request";
import { CourseAdditionalMaterial } from "../../types/CourseAdditionalMaterial";

export async function updateCourseAdditionalMaterialOrder(data: {
  courseId: string;
  items: CourseAdditionalMaterial[];
}) {
  const API = authorised();

  return API.request<CourseAdditionalMaterial[]>({
    method: "PUT",
    url: `/api/studio/courses/${data.courseId}/additional-materials/reorder/`,
    data: {
      items: data.items
    }
  });
}
